import { LocalStorageService } from 'angular-web-storage';
import { PayService } from './../pay.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AlertDialogComponent } from './../../../shared/dialogs/alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  orderNumber: string;
  orderFromCart: string;
  orderConfirmationWithNewebPay: any;
  processingOrderResultByNewebPay: any;
  bsModalRef: BsModalRef;
  newebPayStatus: string;
  processResult: string;

  constructor(
    private route: ActivatedRoute,
    private payService: PayService,
    private local: LocalStorageService,
    private router: Router,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    this.processResult = this.route.snapshot.params.result;
    this.route.queryParams.subscribe(params => {
      if (params.number === undefined) {
        this.orderNumber = this.isExistedRespond(params, 'tradeInfo_Result_MerchantOrderNo');
      } else {
        this.orderNumber = params.number;
      }
    });

    this.orderFromCart = this.local.get('orderFromCart');

    if (this.orderNumber && this.orderFromCart !== null) {
      this.payService.removeAllItems('OrderItems');
      if (this.orderFromCart === 'true') {
        this.payService.removeAllItems('CartItems');
      }
      this.payService.removeAllItems('orderFromCart');
    } else {
      this.router.navigate(['/']);
    }
    this.newebPayFeedback();
  }

  newebPayFeedback() {
    this.route.queryParams.subscribe(params => {
      this.newebPayStatus = this.isExistedRespond(params, 'status');
    });
    if (this.newebPayStatus !== 'SUCCESS' && this.newebPayStatus !== '－') {
      this.showAlert('網路連線異常，請稍候再試，或與客服洽詢，謝謝您。');
    }
  }

  isExistedRespond(params, name) {
    return params[name] ? params[name] : '－';
  }

  showAlert(content: string) {
    const config = {
      initialState: {
        title: '金流交易失敗',
        content,
        btnClass: 'btn btn-danger'
      },
      class: 'modal-danger'
    };
    this.bsModalRef = this.modalService.show(AlertDialogComponent, config);
  }
}

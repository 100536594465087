import { IndexService } from './../index/index.service';
import { HotService } from './hot.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hot',
  templateUrl: './hot.component.html',
  styleUrls: ['./hot.component.scss']
})
export class HotComponent implements OnInit {
  popularData: any[] = [];

  constructor(private hotService: HotService, private indexService: IndexService) { }

  ngOnInit(): void {
    $('.navbar-mobile').removeClass('d-none');
    this.hotService.getPopulars()
      .subscribe((v: any) => {
        if (v.result && v.data) {
          this.popularData = v.data;

          this.indexService.getGrabToday()
            .subscribe((r: any) => {
              if (r.result && r.data) {
                this.popularData.map(product => {
                  product.isSale = false;
                  product.deduct = 0;

                  r.data.GrabTodayPlan.map(sales => {
                    if (sales.product.ID === product.Product.ID) {
                      product.isSale = true;
                      product.deduct = sales.product.deduct;
                    }
                  });
                });
              }
            });
        }
      });
  }

}

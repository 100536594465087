import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { ProductService } from './../../modules/product/product.service';
import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  tags = [];
  filter = '';
  // bsModalRef: BsModalRef;

  constructor(
    private productService: ProductService,
    private router: Router,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
    this.getHotTags();
  }

  getHotTags() {
    this.productService.getHotTags()
    .subscribe((v: any) => {
      this.tags = v.data;
    },
      (e) => { console.log(e); },
    );
  }

  useTag(tag: any) {
    this.filter = tag.name;
  }

  search() {
    $('body').removeClass('example-open');
    this.bsModalRef.hide();
    this.router.navigate(['/productSearch', this.filter]);
  }


}

<div class="bg-white panel">
  <h5 class="panel-title">{{"旅遊訂單" | translate}}</h5>
  <div class="panel-content">
    <div class="row mt-n3 mt-md-n4">
      <div class="col-12">
        <div>
          <ul class="nav nav-tabs-custom mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link" [ngClass]="{'active': selectedTab == 1}" data-toggle="tab" style="cursor: pointer"
                (click)="selectedTab = 1; switchTab()" role="tab" aria-controls="order-available"
                aria-selected="true">{{"近期訂單" | translate}}</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" [ngClass]="{'active': selectedTab == 2}" data-toggle="tab" style="cursor: pointer"
                (click)="selectedTab = 2; switchTab()" role="tab" aria-controls="order-unavailable"
                aria-selected="false">{{"交易中訂單" | translate}}</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" [ngClass]="{'active': selectedTab == 3}" data-toggle="tab" style="cursor: pointer"
                (click)="selectedTab = 3; switchTab()" role="tab" aria-controls="order-all"
                aria-selected="false">{{"所有訂單" | translate}}</a>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent" *ngIf="orderData.length > 0">
            <div class="tab-pane fade show active" id="order-available" role="tabpanel"
              aria-labelledby="order-available">
              <div class="card card-member-order mb-4" *ngFor="let order of orderData">
                <div class="card-header">
                  <div class="row">
                    <div class="col-12 col-md-12">
                      <div class="d-block d-md-flex">
                        <div class="">{{"訂單編號" | translate}}
                          <span class="px-2 text-primary">{{order.orderNumber}}</span>
                        </div>
                        <div class=" pl-md-4">{{"訂購日期" | translate}} {{order.CreatedAt | date: 'yyyy/MM/dd'}}</div>
                      </div>
                      <p class="mb-lg-0 font-size-sm my-md-2">
                        {{"應付金額" | translate}}
                        <span class="font-weight-bold px-2"
                          [innerHTML]="order.amount - order.discount | currencyTransform:order.currency:order.rate"></span>
                        {{"已付訂金" | translate}}
                        <span class="font-weight-bold px-2"
                          [innerHTML]="order.payAmount | currencyTransform:order.currency:order.rate"></span>
                        <br class="d-block d-md-none">
                        {{"未付金額" | translate}}
                        <span class="font-weight-bold px-2 text-price"
                          [innerHTML]="order.amount - order.discount - order.payAmount | currencyTransform:order.currency:order.rate"></span>
                        <small>2023/10/31 23:59:59 前繳清</small>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="card-body">
                  <div *ngFor="let item of order.OrderDetail">
                    <div class="row no-gutters cart-item border-bottom-0 mb-0">
                      <div class="col-3 col-md-2 text-center">
                        <div class="product-img img-bg"
                          style="background-image: url(/api/images/{{item.productData.product.coverImg}});"></div>
                      </div>
                      <div class="col-9 col-md-10">
                        <div class="flex-grow-1">
                          <div class="d-flex justify-content-between">
                            <div class="product-name">{{item.productData.product.title | googleTranslate | async}}</div>
                            <div class="d-none d-md-inline text-right" style="min-width: 70px;">
                              <small class="text-danger" *ngIf="item.payStatus == 2">{{"未付完" | translate}}</small>
                              <small class="text-primary" *ngIf="item.payStatus == 1">{{"可使用" | translate}}</small>
                              <small class="text-warning">
                                <span *ngIf="item.payStatus == -1">{{"未付款" | translate}}</span>
                                <span *ngIf="item.payStatus == 6">{{"全額退款已完成" | translate}}</span>
                                <span *ngIf="item.payStatus == 3 || item.payStatus == 4">{{"退款處理中" | translate}}</span>
                                <span *ngIf="item.payStatus == 5">{{"部分退款已完成" | translate}}</span>
                                <span *ngIf="item.payStatus == 6">{{"全額退款已完成" | translate}}</span>
                                <span *ngIf="item.payStatus == 7">{{"退款申請中" | translate}}</span>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row no-gutters cart-item pt-0">
                      <div class="col-md-2"></div>
                      <div class="col-md-10">
                        <div class="flex-grow-1">
                          <div class="product-option">{{item.productData.planSpecification.name}}</div>
                          <div class="d-md-flex  justify-content-md-between">
                            <div class="product-quant d-md-flex align-items-center">
                              <span class="material-icons px-1">person_outline</span>
                              <span class="pr-2">X {{item.quantity}}</span>
                              <br class="d-block d-md-none">
                              <span class="material-icons px-1 ">calendar_today</span>
                              <span>{{order.TourGroupControl.startDate}}(
                                {{groupTourService.getDayOfWeek(order.TourGroupControl.startDate) | translate}}) -
                                {{order.endDate | date:'yyyy/MM/dd'}}
                                ({{groupTourService.getDayOfWeek(order.endDate) | translate}})
                              </span>
                            </div>
                            <div class="d-block d-md-none">
                              <!-- <small class="text-danger">{{"付款未完成" | translate}}</small> -->
                              <small class="text-danger" *ngIf="item.payStatus == 2">{{"未付完" | translate}}</small>
                              <small class="text-primary" *ngIf="item.payStatus == 1">{{"可使用" | translate}}</small>
                              <small class="text-warning">
                                <span *ngIf="item.payStatus == -1">{{"未付款" | translate}}</span>
                                <span *ngIf="item.payStatus == 6">{{"全額退款已完成" | translate}}</span>
                                <span *ngIf="item.payStatus == 3 || item.payStatus == 4">{{"退款處理中" | translate}}</span>
                                <span *ngIf="item.payStatus == 5">{{"部分退款已完成" | translate}}</span>
                                <span *ngIf="item.payStatus == 6">{{"全額退款已完成" | translate}}</span>
                                <span *ngIf="item.payStatus == 7">{{"退款申請中" | translate}}</span>
                              </small>
                            </div>

                            <div class="d-flex flex-row justify-content-end align-self-md-end"
                              *ngIf="item.payStatus !== -1 && order.orderStatus !== 2">
                              <a href="#" data-toggle="modal" [attr.data-target]="'#comment'+item.ID"
                                *ngIf="item.Comment.ID == 0" class="btn btn-outline-primary btn-sm rounded-0"
                                (click)="openModal(order.ID, item)">{{"給予評論" | translate}}</a>

                              <div class="d-flex flex-column" *ngIf="item.Comment.ID !== 0">
                                <div class="user-rating small d-flex flex-row">
                                  <div class="d-flex flex-row" *ngFor="let s of scores; let i = index;">
                                    <span class="material-icons checked" *ngIf="i < item.Comment.score">star</span>
                                    <span class="material-icons unchecked" *ngIf="i >= item.Comment.score">star</span>
                                  </div>
                                </div>
                                <small class="text-muted align-self-md-center">
                                  {{item.Comment.CreatedAt | date: 'yyyy/MM/dd HH:mm'}}</small>
                              </div>
                              <a class="btn btn-primary btn-sm btn-dl rounded-0 ml-2 dropdown-toggle text-white"
                                data-toggle="dropdown" *ngIf="item.voucherFile.length >= 1">
                                <i class="material-icons">get_app</i>{{"下載憑證" | translate}}
                              </a>
                              <div class="dropdown-menu dropdown-menu-right dropdown-style" role="menu">
                                <a class="dropdown-item" *ngFor="let file of item.voucherFile" target="_blank"
                                  href="/api/site/auth/orderDetail/voucherFile/{{file.file}}?detailId={{item.ID}}">{{file.fileName}}</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-footer">
                  <div class="row">
                    <div class="col-4 col-md-3 d-none d-md-block">
                      <a href="https://line.me/ti/p/%40unz8114z" target="_blank"
                        class="btn btn-outline-secondary btn-block rounded-0">
                        {{"聯繫客服" | translate}}
                      </a>
                    </div>
                    <div class="col-6 col-md-3 ml-auto">
                      <a [routerLink]="['/member/orderInfo', order.orderNumber]"
                        class="btn btn-outline-primary btn-block rounded-0">{{"查看訂單" | translate}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

import { LoginService } from './../services/login.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private loginService: LoginService
  ) { }

  canActivate() {
    return new Promise<boolean>((resolve, reject) => {
      if (this.loginService.userInfo === undefined) {
        this.loginService.isLogin().subscribe(resp => {
          if (resp.result) {
            this.loginService.userInfo = resp.user_info;
            resolve(resp.result);
          }
        }, err => {
          console.log(err);
          this.router.navigate(['/']);
          return resolve(false);
        }, () => { });
      } else {
        resolve(true);
      }
    });
  }

}

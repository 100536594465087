<div *ngIf="productData && !loading">
  <div class="container-fluid content-wrapper">

    <nav class="bg-transparent mt-4 mt-lg-2">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">{{'首頁' | translate }}</a></li>
        <!-- <li class="breadcrumb-item"><a [routerLink]="['/productCategory/0', productData.regionID]">
            {{productData.Region.name | googleTranslate | async}}</a></li> -->
      </ol>
    </nav>

    <h3>{{productData.title | googleTranslate | async}}</h3>
    <div class="row my-3">
      <div class="col-md-8 d-flex justify-content-between align-items-end">
        <div class="">
          <span class="tag" *ngFor="let tag of productData.tag; let i = index">
            <a [routerLink]="['/productCategory', productData.Tag[i]]">{{tag | googleTranslate | async}}</a>
          </span>
        </div>
        <div class="d-flex justify-content-end align-items-center" style="width:100px;">
          <button class="text-black-50 saveBtn" (click)="memberSavedChecked()">
            <span class="material-icons" *ngIf="!memberSaved">favorite_border</span>
            <span class="material-icons btn-save" *ngIf="memberSaved">favorite</span>
          </button>
          <small class="pl-1">{{'收藏' | translate}}</small>
        </div>
      </div>
      <div class="col-md-4 d-flex align-items-center mt-3 mt-md-0">
        <small class="float-left mr-1">{{'分享' | translate}}：</small>
        <ul class="list-inline float-left mb-0">
          <li class="list-inline-item">
            <a href="https://www.facebook.com/sharer/sharer.php?u={{pageUrl}}" target="_blank">
              <img src="assets/images/icon-share-fb.svg" width="28"></a>
          </li>
          <li class="list-inline-item">
            <a href="https://lineit.line.me/share/ui?url={{pageUrl}}" target="_blank">
              <img src="assets/images/icon-share-line.svg" width="28"></a>
          </li>
          <li class="list-inline-item">
            <a href="http://twitter.com/home/?status={{pageUrl}}" target="_blank">
              <img src="assets/images/icon-share-twitter.svg" width="28"></a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">

      <div class="col-md-8">
        <div class="product-sale" *ngIf="isSale"></div>
        <div class="js-banner">
          <div *ngIf="productData.coverVideo">
            <div class="embed-responsive embed-responsive-16by9" [innerHTML]="productData.coverVideo"></div>
          </div>
          <div>
            <div>
              <img [src]="'/api/images/' + this.productData.coverImg" class="img-fluid">
            </div>
          </div>
          <div *ngFor="let carousel of productData.carousel">
            <div>
              <img [src]="'/api/images/' + carousel" class="img-fluid">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card product-card-selected">
          <div class="card-body">

            <div class="product-card-selected-title">
              {{productData.title | googleTranslate | async}}
              <div class="d-flex flex-row">
                <span class="product-price text-black-50 mr-3" *ngIf="isSale">
                  <s>
                    <span [innerHTML]="productData.lowestPrice | currencyExchange:true:false | async"></span>
                    <small class="ml-1">{{'起' | translate}}</small>
                  </s>
                </span>
                <div class="product-price">
                  <span [innerHTML]="productData.lowestPrice - deduct | currencyExchange:true:false | async"></span>
                  <small class="ml-1">{{'起' | translate}}</small>
                </div>
              </div>
            </div>

            <div class="product-card-selected-action">
              <a class="btn btn-primary btn-block rounded-0" href="#planOption" pageScroll [pageScrollDuration]="500">
                {{'選擇方案' | translate}}</a>
              <div class="payment-option">
                <img src="assets/images/product-payment.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="stickyMenu" class="sticky-tab d-none">
      <div class="content-wrapper mx-auto">

        <nav>
          <ul class="nav product-tab product-tab-bg tab-scrollable" role="navigation" snScrollSpy id="foo">
            <li class="nav-item" *ngIf="productData.detail.changingThisBreaksApplicationSecurity">
              <a class="nav-link" href="#detail" uniScrollItem="detail-s" pageScroll [pageScrollDuration]="500"
                [ngClass]="{ 'active': tabSelected == 1 }" (click)="tabSelected = 1">{{'商品明細' | translate}}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#planOption" uniScrollItem="planOption-s" pageScroll [pageScrollDuration]="500"
                [ngClass]="{ 'active': tabSelected == 2 }" (click)="tabSelected = 2">{{'方案選項' | translate}}</a>
            </li>
            <li class="nav-item" *ngIf="productData.introduction.changingThisBreaksApplicationSecurity">
              <a class="nav-link" href="#introduction" uniScrollItem="introduction-s" pageScroll
                [pageScrollDuration]="500" [ngClass]="{ 'active': tabSelected == 3 }" (click)="tabSelected = 3">
                {{'行程介紹' | translate}}</a>
            </li>
            <li class="nav-item" *ngIf="productData.flightInfo && productData.flightInfo.length > 0">
              <a class="nav-link" href="#flight" uniScrollItem="flight-s" pageScroll [pageScrollDuration]="500"
                [ngClass]="{ 'active': tabSelected == 12 }" (click)="tabSelected = 12">{{'航班資訊' | translate}}</a>
            </li>
            <li class="nav-item" *ngIf="productData.itinerary && productData.itinerary.length > 0">
              <a class="nav-link" href="#itinerary" uniScrollItem="itinerary-s" pageScroll [pageScrollDuration]="500"
                [ngClass]="{ 'active': tabSelected == 11 }" (click)="tabSelected = 11">
                {{'參考行程' | translate}}</a>
            </li>
            <li class="nav-item" *ngIf="productData.videoContext.changingThisBreaksApplicationSecurity">
              <a class="nav-link" href="#videoContext" uniScrollItem="videoContext-s" pageScroll
                [pageScrollDuration]="500" [ngClass]="{ 'active': tabSelected == 4 }" (click)="tabSelected = 4">
                {{'圖文影音' | translate}}</a>
            </li>
            <li class="nav-item" *ngIf="productData.description.changingThisBreaksApplicationSecurity">
              <a class="nav-link" href="#description" uniScrollItem="description-s" pageScroll
                [pageScrollDuration]="500" [ngClass]="{ 'active': tabSelected == 5 }" (click)="tabSelected = 5">
                {{'費用說明' | translate}}</a>
            </li>
            <li class="nav-item" *ngIf="productData.instruction.changingThisBreaksApplicationSecurity">
              <a class="nav-link" href="#instruction" uniScrollItem="instruction-s" pageScroll
                [pageScrollDuration]="500" [ngClass]="{ 'active': tabSelected == 6 }" (click)="tabSelected = 6">
                {{'使用方式' | translate}}</a>
            </li>
            <li class="nav-item" *ngIf="productData.map.changingThisBreaksApplicationSecurity">
              <a class="nav-link" href="#map" uniScrollItem="map-s" pageScroll [pageScrollDuration]="500"
                [ngClass]="{ 'active': tabSelected == 7 }" (click)="tabSelected = 7">{{'地圖' | translate}}</a>
            </li>
            <li class="nav-item" *ngIf="productData.notice.changingThisBreaksApplicationSecurity">
              <a class="nav-link" href="#notice" uniScrollItem="notice-s" pageScroll [pageScrollDuration]="500"
                [ngClass]="{ 'active': tabSelected == 8 }" (click)="tabSelected = 8">{{'注意事項' | translate}}</a>
            </li>
            <!-- <li class="nav-item" *ngIf="productData.policy.changingThisBreaksApplicationSecurity">
              <a class="nav-link" href="#policy" uniScrollItem="policy-s" pageScroll [pageScrollDuration]="500"
                [ngClass]="{ 'active': tabSelected == 9 }" (click)="tabSelected = 9">{{'取消政策' | translate}}</a>
            </li> -->
            <li class="nav-item">
              <a class="nav-link" href="#comment" uniScrollItem="comment-s" pageScroll [pageScrollDuration]="500"
                [ngClass]="{ 'active': tabSelected == 10 }" (click)="tabSelected = 10">{{'旅客評價' | translate}}</a>
            </li>
          </ul>
        </nav>
      </div>

    </div>

    <ul id="productTab" class="nav product-tab product-tab-bg tab-scrollable  mx-n3 mx-lg-0">
      <li class="nav-item" *ngIf="productData.detail.changingThisBreaksApplicationSecurity">
        <a class="nav-link" href="#detail" uniScrollItem="detail-s" pageScroll [pageScrollDuration]="500"
          [ngClass]="{ 'active': currentSection == 'detail' }" (click)="currentSection = 'detail'">
          {{'商品明細' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#planOption" uniScrollItem="planOption-s" pageScroll [pageScrollDuration]="500"
          [ngClass]="{ 'active': currentSection == 'planOption' }" (click)="currentSection = 'planOption'">
          {{'方案選項' | translate}}</a>
      </li>
      <li class="nav-item" *ngIf="productData.introduction.changingThisBreaksApplicationSecurity">
        <a class="nav-link" href="#introduction" uniScrollItem="introduction-s" pageScroll [pageScrollDuration]="500"
          [ngClass]="{ 'active': currentSection == 'introduction' }" (click)="currentSection = 'introduction'">
          {{'行程介紹' | translate}}</a>
      </li>
      <li class="nav-item" *ngIf="productData.flightInfo && productData.flightInfo.length > 0">
        <a class="nav-link" href="#flight" uniScrollItem="flight-s" pageScroll [pageScrollDuration]="500"
          [ngClass]="{ 'active': currentSection == 'flight' }" (click)="currentSection = 'flight'">
          {{'航班資訊' | translate}}</a>
      </li>
      <li class="nav-item" *ngIf="productData.itinerary && productData.itinerary.length > 0">
        <a class="nav-link" href="#itinerary" uniScrollItem="itinerary-s" pageScroll [pageScrollDuration]="500"
          [ngClass]="{ 'active': currentSection == 'itinerary' }" (click)="currentSection = 'itinerary'">
          {{'參考行程' | translate}}</a>
      </li>
      <li class="nav-item" *ngIf="productData.videoContext.changingThisBreaksApplicationSecurity">
        <a class="nav-link" href="#videoContext" uniScrollItem="videoContext-s" pageScroll [pageScrollDuration]="500"
          [ngClass]="{ 'active': currentSection == 'videoContext' }" (click)="currentSection = 'videoContext'">
          {{'圖文影音' | translate}}</a>
      </li>
      <li class="nav-item" *ngIf="productData.description.changingThisBreaksApplicationSecurity">
        <a class="nav-link" href="#description" uniScrollItem="description-s" pageScroll [pageScrollDuration]="500"
          [ngClass]="{ 'active': currentSection == 'description' }" (click)="currentSection = 'description'">
          {{'費用說明' | translate}}</a>
      </li>
      <li class="nav-item" *ngIf="productData.instruction.changingThisBreaksApplicationSecurity">
        <a class="nav-link" href="#instruction" uniScrollItem="instruction-s" pageScroll [pageScrollDuration]="500"
          [ngClass]="{ 'active': currentSection == 'instruction' }" (click)="currentSection = 'instruction'">
          {{'使用方式' | translate}}</a>
      </li>
      <li class="nav-item" *ngIf="productData.map.changingThisBreaksApplicationSecurity">
        <a class="nav-link" href="#map" uniScrollItem="map-s" pageScroll [pageScrollDuration]="500"
          [ngClass]="{ 'active': currentSection == 'map' }" (click)="currentSection = 'map'">{{'地圖' | translate}}</a>
      </li>
      <li class="nav-item" *ngIf="productData.notice.changingThisBreaksApplicationSecurity">
        <a class="nav-link" href="#notice" uniScrollItem="notice-s" pageScroll [pageScrollDuration]="500"
          [ngClass]="{ 'active': currentSection == 'notice' }" (click)="currentSection = 'notice'">
          {{'注意事項' | translate}}</a>
      </li>
      <!-- <li class="nav-item" *ngIf="productData.policy.changingThisBreaksApplicationSecurity">
        <a class="nav-link" href="#policy" uniScrollItem="policy-s" pageScroll [pageScrollDuration]="500"
          [ngClass]="{ 'active': currentSection == 'policy' }" (click)="currentSection = 'policy'">
          {{'取消政策' | translate}}</a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link" href="#comment" uniScrollItem="comment-s" pageScroll [pageScrollDuration]="500"
          [ngClass]="{ 'active': currentSection == 'comment' }" (click)="currentSection = 'comment'">
          {{'旅客評價' | translate}}</a>
      </li>
    </ul>
  </div>
  <div>

    <section uniScrollSpy="detail-s" class="container-fluid content-wrapper">
      <div class="row section-product" *ngIf="productData.detail.changingThisBreaksApplicationSecurity"
        style="position:relative;">
        <div id="detail" class="scrollHere"></div>
        <div class="col">
          <h3 class="product-section-title">{{'商品明細' | translate}}</h3>
          <div class="pl-3" [innerHTML]="productData.detail">
          </div>
        </div>
      </div>
    </section>

    <section uniScrollSpy="planOption-s" class="container-fluid" style="position:relative;">
      <div id="planOption" class="scrollHere"></div>
      <div class="row section-product product-options">
        <div class="col-12 content-wrapper mx-auto">
          <h3 class="product-section-title">{{'方案選項' | translate}}</h3>

          <div class="row">
            <div class="col-md-8">

              <div *ngFor="let plan of productData.ProductPlans; let i = index">
                <div class="card product-options-item" *ngIf="plan.show">
                  <div class="product-options-item-title">
                    <h3>{{plan.name | googleTranslate | async}}
                      <span class="product-price float-right" *ngIf="plan.planLowestPrice !== '--'">
                        <span [innerHTML]="plan.planLowestPrice - deduct | currencyExchange:true:false | async"></span>
                        <small class="ml-1">{{"起" | translate}}</small>
                      </span>
                      <span class="product-price float-right text-black-50 mr-3"
                        *ngIf="isSale && plan.planLowestPrice !== '--'">
                        <s>
                          <span [innerHTML]="plan.planLowestPrice | currencyExchange:true:false | async"></span>
                          <small class="ml-1">{{"起" | translate}}</small>
                        </s>
                      </span>
                    </h3>
                  </div>

                  <div class="product-options-item-action" *ngIf="!plan.selected">
                    <button class="btn btn-outline-primary float-right"
                      (click)="plan.selected = true; setPlanSpec(plan.ID, plan.PlanSpecification[0].ID, i, 0); plan.PlanSpecification[0].checked=true">
                      {{'選擇' | translate}}</button>
                  </div>

                  <div class="product-options-item-expand" *ngIf="plan.selected">

                    <!-- <a href="javascript:void(0)" class="close">
                      <span class="material-icons" (click)="plan.selected = false">close</span>
                    </a> -->

                    <div class="form-group" *ngIf="false">
                      <div class="title">{{'票種' | translate}}</div>
                      <div class="d-flex flex-row">
                        <div *ngFor="let spec of plan.PlanSpecification; let si = index">
                          <input class="d-none check-label" type="radio" id="spec{{spec.ID}}"
                            [checked]="spec.checked" />
                          <label class="button-label" for="spec{{spec.ID}}"
                            (click)="setPlanSpec(plan.ID, spec.ID, i, si);spec.checked=true">
                            <span>{{spec.name | googleTranslate | async}}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="form-group" *ngIf="plan.ExtraPurchase">
                      <div class="title">{{'加購' | translate}}</div>
                      <div class="d-flex flex-row">
                        <div *ngFor="let extra of plan.ExtraPurchase">
                          <input class="d-none check-label" type="radio" id="extra{{extra.ID}}"
                            [checked]="extra.checked" />
                          <label class="button-label" for="extra{{extra.ID}}"
                            (click)="setPlanExtra(plan.ID, extra.ID, i);extra.checked=!extra.checked">
                            <span>{{extra.name | googleTranslate | async}}</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="row p-3 mt-2" *ngIf="plan.specData.id !== 0">
                      <div class="col-md-7">
                        <div class="title">{{'日期' | translate}}</div>
                        <div class="calendar-area">
                          <div id="calendar" class="my-4">
                            <div id="calendar_header">
                              <div class="title">
                                {{plan.specData.currentMonth}} - {{plan.specData.currentYear}}
                              </div>
                              <button class="arrow material-icons" (click)="goToLastMonth(i)">
                                navigate_before
                              </button>
                              <button class="arrow material-icons" (click)="goToNextMonth(i)">
                                navigate_next
                              </button>
                            </div>
                            <div id="calendar_weekdays">
                              <div *ngFor="let day of dayNames" class="calendar_weekday">{{day}}</div>
                            </div>

                            <div id="calendar_content">
                              <div *ngFor="let dayItem of plan.specData.daysInLastMonth">
                                <div class="week-date disabled">
                                  <span class="date-text">{{dayItem.day}}</span>
                                </div>
                              </div>

                              <div *ngFor="let dayItem of plan.specData.daysInThisMonth">
                                <div class="week-date disabled" *ngIf="(plan.specData.currentDate!=999 && dayItem.day < plan.specData.currentDate) ||
                                  dayItem.quantity < plan.personCount || dayItem.quantity == 0 || dayItem.quantity < plan.personCount || !dayItem.canBuy ||
                                  dayItem.quantity < plan.specData.minimumQuantity">
                                  <span class="date-text">{{dayItem.day}}</span>
                                </div>
                                <div class="week-date enabled clickable"
                                  (click)="setPlanOption(i, dayItem.id, dayItem.price, dayItem.day, dayItem.extraOptionId)"
                                  [ngClass]="{'selected' : dayItem.selected }" *ngIf="(plan.specData.currentDate == 999 ||
                                    dayItem.day >= plan.specData.currentDate) && dayItem.quantity != 0 && dayItem.quantity >= plan.personCount && dayItem.canBuy &&
                                    dayItem.quantity >= plan.specData.minimumQuantity">
                                  <div *ngIf="dayItem.selected" class="custom-tooltip">
                                    {{ '剩餘可售數量' | translate }} {{ remainQuantity }}
                                  </div>
                                  <span class="date-text" [ngClass]="{'today' : dayItem.today }">{{dayItem.day}}</span>
                                  <!-- <small class="date-content"
                                    [innerHTML]="dayItem.price - deduct | currencyExchange:false:false | async"
                                    (click)="setPlanOption(i, dayItem.id, dayItem.price, dayItem.day, dayItem.extraOptionId)"></small> -->
                                  <span class="date-content"
                                    (click)="setPlanOption(i, dayItem.id, dayItem.price, dayItem.day, dayItem.extraOptionId)">
                                    <small
                                      [innerHTML]="dayItem.price - deduct | currencyExchange:false:false | async"></small>
                                    <small class="text-danger"
                                      *ngIf="dayItem.sold < dayItem.defaultInfo.minimumGroupSize">將成團</small>
                                    <small class="text-danger"
                                      *ngIf="dayItem.sold >= dayItem.defaultInfo.minimumGroupSize && dayItem.sold < dayItem.defaultInfo.bookingSize">已成團</small>
                                    <small class="text-danger"
                                      *ngIf="dayItem.sold >= dayItem.defaultInfo.bookingSize && dayItem.sold < dayItem.defaultInfo.bookingSize + dayItem.defaultInfo.waitlistSize">可後補</small>
                                    <small class="text-danger"
                                      *ngIf="dayItem.sold >= dayItem.defaultInfo.bookingSize + dayItem.defaultInfo.waitlistSize">已額滿</small>
                                  </span>
                                </div>
                              </div>
                              <div *ngFor="let dayItem of plan.specData.daysInNextMonth">
                                <div class="week-date disabled">
                                  <span class="date-text">{{dayItem.day}}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="col-md-5 mt-4 mt-md-0">
                        <div class="row align-items-end justify-content-end">
                          <div class="col-6 mr-auto col-md-12" *ngIf="plan.price !== 0">
                            <div *ngFor="let tp of typePrices; let i = index;">
                              <div class="title">{{tp.roomType}}</div>
                              <div class="type-item font-weight-normal font-size-smaller" *ngFor="let detail of tp.detail;">
                                <div class="type-title">{{detail.name}}</div>
                                <div [innerHTML]="detail.price | currencyExchange:true:false | async" class="type-num"></div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 col-md-12 pl-1">
                            <div class="mb-4">
                              <div class="total-price text-right">{{'金額' | translate}}
                                <span class="text-primary" *ngIf="plan.price == 0">0</span>
                                <span class="text-primary" *ngIf="plan.price !== 0">
                                  <small [innerHTML]="(plan.price - deduct) * plan.personCount |
                                  currencyExchange:true:false | async"></small>
                                  <small> {{'起' | translate}}</small>
                                </span>
                              </div>
                            </div>

                            <div class="row no-gutters mt-4">
                              <div class="col-6 pr-1"></div>
                              <div class="col-6 pl-1">
                                <button class="btn btn-primary btn-block rounded-0" (click)="placeOrder(i)"
                                  [disabled]="plan.specOptionId === 0">{{'立即預訂' | translate}}
                                </button>
                              </div>
                            </div>

                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div class="col-md-4"></div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section uniScrollSpy="introduction-s" class="container-fluid content-wrapper">
      <div class="row section-product" *ngIf="productData.introduction.changingThisBreaksApplicationSecurity"
        style="position:relative;">
        <div id="introduction" class="scrollHere"></div>
        <div class="col-md-8">
          <h3 class="product-section-title">{{'行程介紹' | translate}}</h3>
          <div class="pl-3" [innerHTML]="productData.introduction"></div>
        </div>
      </div>
    </section>

    <section uniScrollSpy="flight-s" class="container-fluid content-wrapper">
      <div class="row section-product" *ngIf="productData.flightInfo && productData.flightInfo.length > 0"
        style="position:relative;">
        <div id="flight" class="scrollHere"></div>
        <div class="col-md-8">
          <div class="mb-5">
            <div class="h6 my-3">{{'航班資訊' | translate}}</div>
            <div class="row no-gutters mb-5" *ngIf="goFlight">
              <div class="col-12 flight-info-heading">
                <div class="d-inline-flex badge-pill badge-group-cart badge-primary px-3 py-1 ">{{'去程' | translate}}
                </div>
                <div class="a-to-b-tit">
                  {{goFlight.AirportDeparture.name | googleTranslate | async}}{{goFlight.AirportDeparture.code}}
                  ➝
                  {{goFlight.AirportArrival.name | googleTranslate | async}}{{goFlight.AirportArrival.code}}
                </div>

              </div>
              <div class="col-12">
                <div class="slider-flight-info-list">
                  <div class="row">
                    <div class="col-sm-3 col-10 align-items-center d-flex">
                      {{goFlight.airlineName | googleTranslate | async}}({{goFlight.code}})
                    </div>
                    <div class="col-sm-8 col-12">
                      <div class="flight-fromto">
                        <div class="row">
                          <div class="col-4">
                            <div class="station-info">
                              <div class="station-info-date">
                                <!-- {{groupControl.PlanOption.date | date:'MM/dd'}} -->
                                <!-- ({{groupTourService.getDayOfWeek(groupControl.PlanOption.date) | translate}}) -->
                              </div>
                              <div class="station-info-time">{{goFlight.departureTime}}</div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="flight-duration">
                              <div class="flight-duration-time text-center">{{goFlight.timeDifference}}</div>
                              <div class="flight-duration-arrow"></div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="station-info">
                              <div class="station-info-date">
                                <div class="station-info-date">
                                  <!-- {{goFlight.arrivalDate | date:'MM/dd'}} -->
                                  <!-- ({{groupTourService.getDayOfWeek(goFlight.arrivalDate) | translate}}) -->
                                </div>
                                <div class="station-info-time text-black">{{goFlight.arrivalTime}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row no-gutters mb-5" *ngIf="backFlight">
                <div class="col-12 flight-info-heading">
                  <div class="d-inline-flex badge-pill badge-group-cart badge-primary px-3 py-1 ">{{'回程' | translate}}
                  </div>
                  <div class="a-to-b-tit">
                    {{backFlight.AirportDeparture.name | googleTranslate | async}}{{backFlight.AirportDeparture.code}}
                    ➝
                    {{backFlight.AirportArrival.name | googleTranslate | async}}{{backFlight.AirportArrival.code}}
                  </div>

                </div>
                <div class="col-12">
                  <div class="slider-flight-info-list">
                    <div class="row">
                      <div class="col-sm-3 col-10 align-items-center d-flex">
                        {{backFlight.airlineName | googleTranslate | async}}({{backFlight.code}})
                      </div>
                      <div class="col-sm-8 col-12">
                        <div class="flight-fromto">
                          <div class="row">
                            <div class="col-4">
                              <div class="station-info">
                                <div class="station-info-date">
                                  <!-- 04/12(五) -->
                                </div>
                                <div class="station-info-time">{{backFlight.departureTime}}</div>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="flight-duration">
                                <div class="flight-duration-time text-center">{{backFlight.timeDifference}}</div>
                                <div class="flight-duration-arrow"></div>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="station-info">
                                <div class="station-info-date">
                                  <!-- 04/12(五) -->
                                </div>
                                <div class="station-info-time">{{backFlight.arrivalTime}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>

    <section uniScrollSpy="itinerary-s" class="container-fluid content-wrapper">
      <div class="row section-product" *ngIf="productData.itinerary && productData.itinerary.length > 0"
        style="position:relative;">
        <div id="itinerary" class="scrollHere"></div>
        <div class="col-md-8">
          <h3 class="product-section-title">{{'參考行程' | translate}}</h3>

          <div class="border-bottom mb-4" *ngFor="let dayItem of productData.itinerary; let i = index;">
            <div class="pl-3">
              <div class="d-inline-flex badge-pill badge-group-cart badge-primary px-3 py-1 mb-4 ">
                {{'第' + (i+1) + '天' | googleTranslate | async}}
              </div>
              <h4>{{dayItem.title | googleTranslate | async}}</h4>
              <p [innerHTML]="dayItem.introduction"></p>

              <div class="row">
                <div class="col-md-6 d-flex">
                  <div class="product-schedule-icon">
                    <span class="material-icons">restaurant</span>
                  </div>

                  <ul class="list-unstyled product-schedule-list">
                    <li><span class="text-primary">{{'早餐' | translate}}：</span>{{dayItem.breakfast | googleTranslate |
                      async}}</li>
                    <li><span class="text-primary">{{'中餐' | translate}}：</span>{{dayItem.lunch | googleTranslate |
                      async}}</li>
                    <li><span class="text-primary">{{'晚餐' | translate}}：</span>{{dayItem.dinner | googleTranslate |
                      async}}</li>
                  </ul>
                </div>
                <div class="col-md-6 d-flex" *ngIf="dayItem.hotel > 0">
                  <div class="product-schedule-icon">
                    <span class="material-icons">bed</span>
                  </div>
                  <p class="product-schedule-list">
                    <span class="text-primary">{{dayItem.hotelName | googleTranslate | async}}</span>　{{'或同級' |
                    translate}}
                    <!-- <a href="">成田GATEWAY</a>／<a href="">MARROAD</a>／<a href="">GARDEN</a>／<a href="">RADISSON</a>／<a
                      href="">MYSTAYS 成田</a>　或同級 -->
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section uniScrollSpy="videoContext-s" class="container-fluid content-wrapper">
      <div class="row section-product" *ngIf="productData.videoContext.changingThisBreaksApplicationSecurity"
        style="position:relative;">
        <div id="videoContext" class="scrollHere"></div>
        <div class="col-md-8">
          <h3 class="product-section-title">{{'圖文影音' | translate}}</h3>
          <div class="pl-3" [innerHTML]="productData.videoContext"></div>
        </div>
      </div>
    </section>

    <section uniScrollSpy="description-s" class="container-fluid content-wrapper">
      <div class="row section-product" *ngIf="productData.description.changingThisBreaksApplicationSecurity"
        style="position:relative;">
        <div id="description" class="scrollHere"></div>
        <div class="col-md-8">
          <h3 class="product-section-title">{{'費用說明' | translate}}</h3>
          <div class="pl-3" [innerHTML]="productData.description"></div>
        </div>
      </div>
    </section>

    <section uniScrollSpy="instruction-s" class="container-fluid content-wrapper">
      <div class="row section-product" *ngIf="productData.instruction.changingThisBreaksApplicationSecurity"
        style="position:relative;">
        <div id="instruction" class="scrollHere"></div>
        <div class="col-md-8">
          <h3 class="product-section-title">{{'使用方式' | translate}}</h3>
          <div class="pl-3" [innerHTML]="productData.instruction"></div>
        </div>
      </div>
    </section>

    <section uniScrollSpy="map-s" class="container-fluid content-wrapper">
      <div class="row section-product" *ngIf="productData.map.changingThisBreaksApplicationSecurity"
        style="position:relative;">
        <div id="map" class="scrollHere"></div>
        <div class="col-md-8">
          <h3 class="product-section-title">{{'地圖' | translate}}</h3>
          <div class="pl-3" [innerHTML]="productData.map"></div>
        </div>
      </div>
    </section>

    <section uniScrollSpy="notice-s" class="container-fluid content-wrapper">
      <div class="row section-product" *ngIf="productData.notice.changingThisBreaksApplicationSecurity"
        style="position:relative;">
        <div id="notice" class="scrollHere"></div>
        <div class="col-md-8">
          <h3 class="product-section-title">{{'注意事項' | translate}}</h3>
          <div class="pl-3" [innerHTML]="productData.notice"></div>
        </div>
      </div>
    </section>

    <!-- <section uniScrollSpy="policy-s" class="container-fluid content-wrapper">
      <div class="row section-product" *ngIf="productData.policy.changingThisBreaksApplicationSecurity"
        style="position:relative;">
        <div id="policy" class="scrollHere"></div>
        <div class="col-md-8">
          <h3 class="product-section-title">{{'取消政策' | translate}}</h3>
          <div class="pl-3" [innerHTML]="productData.policy"></div>
        </div>
      </div>
    </section> -->

    <section uniScrollSpy="comment-s" class="container-fluid content-wrapper">
      <div class="row section-product" style="position:relative;">
        <div id="comment" class="scrollHere"></div>
        <div class="col-md-8">
          <h3 class="product-section-title">{{'旅客評價' | translate}}</h3>
          <div class="review-summary w-100 ">
            <div class="d-flex align-items-center">
              <div class="number">{{avgScore}}</div>
              <div class="user-rating">
                <ngx-input-star-rating [(ngModel)]="avgScore" [disabled]="true"></ngx-input-star-rating>
              </div>
              <span class="comments">{{totalComments}}{{'則評價' | translate}}</span>
            </div>

          </div>
          <div class="pl-3">
            <ul class="list-unstyled">
              <li class="review-item" *ngFor="let comment of commentData">
                <img src="assets/images/avatar.jpg" class="mr-3 rounded-circle"
                  *ngIf="comment.fakeUserImg === 0 && !comment.Member.picture">
                <img
                  src="https://lh3.googleusercontent.com/-enN6EXCLIEU/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucn3Ad6kUbs8Dk02WlmNfI95yvm0aw/s96-c/photo.jpg"
                  class="mr-3 rounded-circle"
                  *ngIf="comment.fakeUserImg === 0 && comment.Member.picture && comment.Member.picture.length > 8">
                <img [src]="'/api/images/' + comment.Member.picture" class="mr-3 rounded-circle"
                  *ngIf="comment.fakeUserImg === 0 && comment.Member.picture && comment.Member.picture.length < 8">
                <img [src]="'/api/images/' + comment.fakeUserImg" class="mr-3 rounded-circle"
                  *ngIf="comment.fakeUserImg !== 0" width="64px" height="64px">
                <div class="review-body">
                  <div class="d-flex justify-content-between">
                    <div>
                      <div class="mt-0 mb-1">
                        <span *ngIf="comment.memberId==0">{{comment.fakeUserName}}</span>
                        <span *ngIf="comment.memberId!=0"></span>

                        <span class="badge badge-pill badge-primary ml-2">{{comment.tourist | googleTranslate |
                          async}}</span>
                      </div>
                      <div class="user-rating">
                        <ngx-input-star-rating [(ngModel)]="comment.score" [disabled]="true"></ngx-input-star-rating>
                      </div>
                    </div>

                    <div class="text-right">
                      <div class="date">{{comment.CreatedAt | date: 'yyyy/MM/dd HH:mm'}}</div>
                      <div class="option">{{comment.productData.plan.name | googleTranslate | async}}</div>
                    </div>
                  </div>

                  <div class="user-comment">{{comment.Content | googleTranslate:true | async}}
                    <ul class="review-photo-wrapper gallery" *ngIf="comment.photo.length > 0">
                      <li class="review-photo mr-2" *ngFor="let photo of comment.photo">
                        <a href="api/images/{{photo}}">
                          <img [src]="'/api/images/' + photo" width="80" height="60">
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="d-flex justify-content-center">
            <pagination [totalItems]="totalComments" [maxSize]="5" previousText="&lsaquo;" *ngIf="totalComments > 5"
              nextText="&rsaquo;" [itemsPerPage]="5" firstText="&laquo;" lastText="&raquo;" [boundaryLinks]="true"
              (numPages)="numPages = $event" (pageChanged)="pageIndex = $event.page-1;getComments()"></pagination>
          </div>
        </div>
      </div>

    </section>

    <div class="container-fluid content-wrapper">
      <app-others-viewed></app-others-viewed>
    </div>

  </div>

</div>


<div *ngIf="!productData && !loading">

  <div class="container-fluid content-wrapper " style="min-height: 45vh;">
    <div class="row my-5">
      <div class="col-md-8 mx-auto">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-4 col-xl-3 mx-auto">
                <div class="mx-auto">
                  <img src="assets/images/travel-img.svg" alt="" class="img-fluid">
                </div>

              </div>
              <div class="col-md-12 text-center my-4">
                <h4 class="">
                  {{'商品更新中' | translate}}
                </h4>
                <p class="text-muted">
                  Coming Soon…
                </p>
                <div class="col-6 col-md-4 col-lg-3 mx-auto">
                  <a (click)="locations.back()" class="btn btn-block btn-outline-primary rounded-0">
                    {{'回上頁' | translate}}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
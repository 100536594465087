<div class="js-banner">
  <div>
    <div class="banner-static" style="background-image: url(/api/images/{{banner}});"></div>
  </div>
</div>

<div class="container-fluid content-wrapper ">
  <section class="section-wrapper">
    <div class="section-title mb-4">
      <img src="assets/images/index-section-icon_03.png">
      <div>{{"精選主題" | translate}}</div>
    </div>
    <div class="row no-gutters" *ngIf="featuredData.length > 0">
      <div class="col-xl-3 col-sm-6 mb-3" *ngFor="let item of featuredData let i = index;">
        <div class="product-card theme" *ngIf="i < 16">
          <div class="product-card-image" style="display: block; background-image: url(/api/images/{{item.cover}});">
            <a [routerLink]="['/featuredDetail', item.ID]" class="link-spanner"></a>
          </div>
          <div class="product-card-body">
            <div class="product-title text-truncate">
              <a [routerLink]="['/featuredDetail', item.ID]" class="link-spanner">
                <div>{{item.title | googleTranslate | async}}</div>
                <small>{{item.productCount}}{{"個行程" | translate}}</small>
              </a>
            </div>
          </div>
        </div>

        <div *ngIf="showMore">
          <div class="product-card theme" *ngIf="i >= 16">
            <div class="product-card-image" style="display: block; background-image: url(/api/images/{{item.cover}});">
              <a [routerLink]="['/featuredDetail', item.ID]" class="link-spanner"></a>
            </div>
            <div class="product-card-body">
              <div class="product-title text-truncate">
                <a [routerLink]="['/featuredDetail', item.ID]" class="link-spanner">
                  <div>{{item.title | googleTranslate | async}}</div>
                  <small>{{item.productCount}}{{"個行程" | translate}}</small>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5" *ngIf="featuredData.length > 16 && !showMore">
      <div class="col-12 col-md-4 col-lg-3 mx-auto" (click)="showMore = true;">
        <a id="showmore" class="btn btn-outline-primary btn-lg btn-block rounded-0">{{"看更多" | translate}}</a>
      </div>
    </div>
  </section>
</div>

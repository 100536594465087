import { IndexService } from './../index/index.service';
import { LayoutService } from './../layout/layout.service';
import { ProductService } from './../product/product.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-others-viewed',
  templateUrl: './others-viewed.component.html',
  styleUrls: ['./others-viewed.component.scss']
})
export class OthersViewedComponent implements OnInit {
  region: number;
  tag: number;
  viewedData: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private indexService: IndexService,
    private productService: ProductService,
    private layoutService: LayoutService) { }

  ngOnInit(): void {
    this.route.url.subscribe(url => {
      if (url[0].path === 'product') {
        this.productService.getProductById(+url[1].path)
          .subscribe((v: any) => {
            if (v.result) {
              v.data.Tag.map(tag => {
                if (this.viewedData.length < 16) {
                  this.getProducts2(0, tag);
                }
              });
            }
          },
            (e) => { console.log(e); },
          );

      } else {
        if (url.length === 2) {
          this.tag = +url[1].path;
          this.region = 0;
        }
        if (url.length === 3) {
          this.tag = +url[1].path;
          this.region = + url[2].path;
        }
        this.getProducts();
      }
    });
  }

  getProducts(region = this.region, tag = this.tag) {
    this.productService.getOthersViewedProducts(0, tag)
      .subscribe((v: any) => {
        if (v.result) {
          this.viewedData = v.data;
          if (this.viewedData.length < 16 && this.region !== 0) {
            this.getProducts2(region, 0);
            if (this.viewedData.length < 16) {
              this.getProducts2(0, 0);
            }
          }
          if (this.viewedData.length < 16 && this.region === 0) {
            this.getProducts2(0, 0);
          }

          this.indexService.getGrabToday()
            .subscribe((r: any) => {
              if (r.result && r.data) {
                this.viewedData.map(product => {
                  product.isSale = false;
                  product.deduct = 0;

                  r.data.GrabTodayPlan.map(sales => {
                    if (sales.product.ID === product.ID) {
                      product.isSale = true;
                      product.deduct = sales.product.deduct;
                    }
                  });
                });
              }
            });
        }
        // this.loadScript('assets/js/app.js');
      },
        (e) => { console.log(e); },
      );
  }

  getProducts2(region, tag) {
    this.productService.getOthersViewedProducts(region, tag)
      .subscribe((v: any) => {
        if (v.result) {
          Array.prototype.push.apply(this.viewedData, v.data);
          this.viewedData = [...new Map(v.data.map(item => [item.ID, item])).values()];
        }
        // this.loadScript('assets/js/app.js');
      },
        (e) => { console.log(e); },
      );
  }

  // loadScript(url) {
  //   const node = document.createElement('script');
  //   node.src = url;
  //   node.type = 'text/javascript';
  //   document.getElementsByTagName('head')[0].appendChild(node);
  // }

}

<div class="bg-white panel">
  <h5 class="panel-title">
    {{"我的收藏" | translate}}
  </h5>
  <div class="panel-content" *ngIf="loaded">
    <div class="row">
      <div class="col-12 mb-2">
        <div class="d-flex justify-content-end align-items-center">
          <span class="d-inline">{{"顯示" | translate}}：</span>
          <select class="form-control col-4 col-md-2" [(ngModel)]="selectedCountry" (change)="getMemberSaved()">
            <option [value]="0">{{"所有國家" | translate}}</option>
            <option [value]="c.countryID" *ngFor="let c of countries">{{c.Country.name | googleTranslate | async}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12">
        <div *ngFor="let item of collectionData; let i = index;">
          <div class="row no-gutters cart-item border shadow-sm mb-3 pb-2"
            *ngIf="i >= pageIndex * 5 && i < pageIndex * 5 + 5">
            <div class="col-3 col-md-2 text-center">
              <div class="product-img img-bg" style="background-image: url(/api/images/{{item.Product.coverImg}});">
              </div>
            </div>
            <div class="col-9 col-md-9 d-md-flex" [routerLink]="['/product', item.Product.ID]">
              <div class="flex-grow-1">
                <div class="product-name mb-1">{{item.Product.title | googleTranslate | async}}</div>
                <div class="product-option mb-1">
                  <small class="text-gray-m ml-auto pr-2 align-self-center" *ngIf="item.isSale">
                    <s>
                      <span [innerHTML]="item.Product.lowestPrice | currencyExchange:true:false | async"></span>
                      <small>{{"起" | translate}}</small>
                    </s>
                  </small>
                  <span
                    [innerHTML]="item.Product.lowestPrice - item.deduct | currencyExchange:true:false | async"></span>
                  <small>{{"起" | translate}}</small>
                </div>
                <div class="product-quant d-flex align-items-center">
                  <span class="material-icons-outlined">location_on</span>
                  {{item.Country.name | googleTranslate | async}} {{item.Region.name | googleTranslate | async}}
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-link btn-xs btn-save" (click)="deleteMemberSaved(item.ID, i)">
              <span class="material-icons">favorite</span>
            </button>
          </div>
        </div>

        <div class="d-flex justify-content-center mt-5">
          <pagination [totalItems]="totalCount" [maxSize]="5" previousText="&lsaquo;" nextText="&rsaquo;"
            [itemsPerPage]="5" firstText="&laquo;" lastText="&raquo;" [boundaryLinks]="true"
            (numPages)="numPages = $event" (pageChanged)="pageIndex = $event.page-1;"></pagination>
        </div>
      </div>
    </div>
  </div>

</div>

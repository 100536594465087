<div class="content-cart" *ngIf="processResult === 'success' || processResult === undefined">
  <div class="container-fluid content-wrapper position-relative">
    <div class="bg-white p-5 mb-4">
      <div class="row">
        <div class="col-12 text-center">
          <img src="assets/images/pay-confirm.png" class="my-4">
          <h3 class="text-primary">{{"感謝您的訂購" | translate}}</h3>
          <p class="my-4">
            {{"訂單編號" | translate}}
            <a class="text-primary" [routerLink]="['/member/orderInfo', orderNumber]">{{orderNumber}}</a>
          </p>
          <a [routerLink]="['/productCategory']" class="btn btn-secondary col-12 col-md-3">
            {{"看其他行程" | translate}}
          </a>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>

<div class="content-cart" *ngIf="processResult === 'fail'">
  <div class="container-fluid content-wrapper " style="min-height: 45vh;">
    <div class="row my-5">
      <div class="col-md-8 mx-auto">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-4 col-xl-3 mx-auto">
                <div class="mx-auto">
                  <img src="assets/images/travel-img.svg" alt="" class="img-fluid">
                </div>

              </div>
              <div class="col-md-12 text-center my-4">
                <h4 class="">
                  {{"交易失敗" | translate}}
                </h4>
                <p class="text-muted">
                  {{"請聯繫客服人員或重新購買，造成不便請見諒。" | translate}}
                </p>
                <div class="col-6 col-md-4 col-lg-3 mx-auto">
                  <a [routerLink]="['/productCategory']" class="btn btn-block btn-outline-primary rounded-0">
                    {{"回商品頁" | translate}}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>

import { IndexService } from './../../index/index.service';
import { ProductService } from './../../product/product.service';
import { FeaturedService } from './../featured.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-featured-detail',
  templateUrl: './featured-detail.component.html',
  styleUrls: ['./featured-detail.component.scss']
})
export class FeaturedDetailComponent implements OnInit {
  id: number;
  pageIndex = 0;
  numPages = 0;
  featuredData: any;
  categoryData: any;
  products: any[] = [];
  itemLength = 0;

  productData: any = [];
  regionData: any = [];
  tagData: any = [];
  regionSelected = 0;
  tagSelected = 0;


  constructor(
    private indexService: IndexService,
    private featuredService: FeaturedService,
    private productService: ProductService,
    private route: ActivatedRoute) {
      this.getRegions();
      this.getTags();
      this.route.url.subscribe(url => {
        if (url.length === 2) {
          this.tagSelected = +url[1].path;
        }
        if (url.length === 3) {
          this.tagSelected = +url[1].path;
          this.regionSelected = + url[2].path;
        }
        // this.getProducts();
      });
    }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.route.url.subscribe(url => {
      if (url[0].path === 'featuredDetail') {
        this.featuredService.getFeaturedTopic(this.id)
        .subscribe((v: any) => {
          if (v.result && v.data) {
            this.featuredData = v.data;
            this.tagSelected = this.featuredData.tagID;
            this.getProducts();
          }
        });
      } else {
        this.featuredService.getHomeCategory(this.id)
        .subscribe((v: any) => {
          if (v.result && v.data) {
            this.categoryData = v.data;
            this.tagSelected = this.categoryData.tagID;
            this.getProducts();
          }
        });

      }
    });
  }

  getProducts(pageNumber = this.pageIndex, region = this.regionSelected, tag = this.tagSelected) {
    this.productService.getProducts(pageNumber, region, tag)
      .subscribe((v: any) => {
        if (v.result) {
          this.products = v.data;
          this.itemLength = v.total;

          this.indexService.getGrabToday()
            .subscribe((r: any) => {
              if (r.result && r.data) {
                this.products.map(product => {
                  product.isSale = false;
                  product.deduct = 0;

                  r.data.GrabTodayPlan.map(sales => {
                    if (sales.product.ID === product.ID) {
                      product.isSale = true;
                      product.deduct = sales.product.deduct;
                    }
                  });
                });
              }
            });
        }
      },
        (e) => { console.log(e); },
      );
  }

  getRegions() {
    this.productService.getRegions()
      .subscribe((v: any) => {
        if (v.result) {
          this.regionData = v.data;
        }
      },
        (e) => { console.log(e); },
      );
  }

  getTags(regionId = 0) {
    this.productService.getTags(regionId)
      .subscribe((v: any) => {
        if (v.result) {
          this.tagData = v.data;
        }
      },
        (e) => { console.log(e); },
      );
  }

}

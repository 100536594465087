<div class="modal-content">
  <div class="modal-header">
      <h4 class="modal-title">{{title | googleTranslate | async}}</h4>
      <button type="button" class="close" (click)="bsModalRef.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <p>{{content | googleTranslate:true | async}}</p>
  </div>
  <div class="modal-footer">
      <button type="button" [class]="btnSecondary" (click)="bsModalRef.hide()">{{"取消" | translate}}</button>
      <button type="button" [class]="btnDanger" (click)="bsModalRef.hide();ok()">{{"確定" | translate}}</button>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  getContactCategories() {
    return this.http.get('/api/site/contactCategories');
  }

  createContact(data: any) {
    return this.http.post<any>('/api/site/contact', data);
  }
}

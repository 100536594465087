import { DomSanitizer } from '@angular/platform-browser';
import { EditorPagesService } from './editor-pages.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LayoutService } from '../layout/layout.service';

@Component({
  selector: 'app-editor-pages',
  templateUrl: './editor-pages.component.html',
  styleUrls: ['./editor-pages.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditorPagesComponent implements OnInit {
  subject: string;
  content: any;
  title: string;

  constructor(
    public sanitizer: DomSanitizer,
    private layoutService: LayoutService,
    private editorService: EditorPagesService
  ) { }

  ngOnInit(): void {
    this.subject = window.location.pathname.split('/')[1];

    switch (this.subject) {
      case 'about':
        this.title = '關於禾藤';
        break;
      case 'privacy':
        this.title = '隱私權政策';
        break;
      case 'instruction':
        this.title = '訂購說明';
        break;
      case 'fraud':
        this.title = '防詐宣導';
        break;
    }

    this.editorService.getEditorData(this.subject).subscribe((v: any) => {
      if (v.result) {
        this.layoutService.translateHTML(v.data.Content).subscribe((res: any) => {
          this.content = res;
        });
      }
    },
      (e) => { console.log(e); },
    );
  }

}

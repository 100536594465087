import { CurrencyExchangePipe } from './../../../shared/pipes/currency-exchange.pipe';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertDialogComponent } from './../../../shared/dialogs/alert-dialog/alert-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupBuyService } from './../group-buy.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective, BsModalRef, BsModalService } from 'ngx-bootstrap';
import { LayoutService } from '../../layout/layout.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-group-buy-initiator',
  templateUrl: './group-buy-initiator.component.html',
  styleUrls: ['./group-buy-initiator.component.scss']
})
export class GroupBuyInitiatorComponent implements OnInit {
  @ViewChild('myModal') public myModal: ModalDirective;
  id: number;
  initiatorPwd: string;
  bsModalRef: BsModalRef;
  loginClick = 0;
  initiatorData: any;
  groupBuys = [];
  today = new Date();
  showDashboard = false;
  selectedCurrency: string;
  languageList = [{ name: '繁體中文', value: 'zh-TW' }, { name: '简体中文', value: 'zh-CN' }, { name: 'English', value: 'en' },
  { name: '日本語', value: 'ja' }];
  selectedLanguage: string;
  isMobile = this.deviceService.isMobile();
  isTablet = this.deviceService.isTablet();
  isDesktop = this.deviceService.isDesktop();

  constructor(
    private modalService: BsModalService,
    private groupBuyService: GroupBuyService,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private layoutService: LayoutService,
    private deviceService: DeviceDetectorService,
    private translateService: TranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.groupBuyService.getGroupBuyByInitiatorID(this.id).subscribe((v: any) => {
      console.log('v :>> ', v);
      if (v.result) {
        this.initiatorData = v.initiator;
        // this.initiatorData.topGraphic = this.initiatorData.topGraphic === 'null' || this.initiatorData.topGraphic === '' ?
        //   '' : this.sanitizer.bypassSecurityTrustHtml(this.initiatorData.topGraphic);
        // this.initiatorData.footer = this.initiatorData.footer === 'null' || this.initiatorData.footer === '' ?
        //   '' : this.sanitizer.bypassSecurityTrustHtml(this.initiatorData.footer);
        this.layoutService.translateHTML(this.initiatorData.topGraphic).subscribe((res: any) => {
          this.initiatorData.topGraphic = res;
        });
        this.layoutService.translateHTML(this.initiatorData.footer).subscribe((res: any) => {
          this.initiatorData.footer = res;
        });

        const storedCurrency = localStorage.getItem('selectedCurrency' + this.initiatorData.ID);
        if (!storedCurrency) {
          this.selectedCurrency = this.initiatorData.currencyList.length > 0 ? this.initiatorData.currencyList[0] : 'TWD';
        } else {
          this.selectedCurrency = storedCurrency;
        }
        this.changeCurrency(this.selectedCurrency);
        this.translateService.use(this.layoutService.language.value);
        switch (this.layoutService.language.value) {
          case 'zh-TW':
            this.selectedLanguage = '繁體中文';
            break;
          case 'zh-CN':
            this.selectedLanguage = '简体中文';
            break;
          case 'en':
            this.selectedLanguage = 'English';
            break;
          case 'ja':
            this.selectedLanguage = '日本語';
            break;
        }

        this.groupBuys = v.data;
        if (this.groupBuys) {
          this.groupBuys.map(item => {
            if (item.allowLimitedTime === 1) {
              item.leftTime = (new Date(item.endDate.split('Z')[0]).getTime() - new Date().getTime());
            }
            if (item.GroupBuyProduct.discountType === 2) {
              item.GroupBuyProduct.discountValue =
                Math.round(item.GroupBuyProduct.Product.lowestPrice * (item.GroupBuyProduct.discountValue / 100));
            }
          });
        }
      }
    },
      (e) => {
        console.log(e);
      },
    );
  }

  showLoginModal() {
    this.loginClick = this.loginClick + 1;
    if (this.loginClick > 5) {
      this.myModal.show();
      this.loginClick = 0;
    }
  }

  onSubmit() {
    this.groupBuyService.verifyInitiatorPwd({ id: +this.id, initiatorPwd: this.initiatorPwd }).subscribe(res => {
      if (res.result) {
        this.myModal.hide();
        this.showDashboard = true;
      } else {
        this.showAlert('密碼錯誤');
      }
    },
      (e) => {
        this.showAlert(e.statusText);
        console.log(e);
      },
    );
  }

  closeShowDashboard(e) {
    this.showDashboard = false;
  }

  // getCurrencies() {
  //   this.layoutService.getCurrencies().subscribe((v: any) => {
  //     if (v.result) {
  //       v.data.map(data => {
  //         this.currencyList.push(data.code);
  //         console.log('this.currencyList :>> ', this.currencyList);
  //       });
  //     }
  //   },
  //     (e) => { console.log(e); },
  //   );
  // }

  changeCurrency(currency: string) {
    this.selectedCurrency = currency;
    localStorage.setItem('selectedCurrency' + this.initiatorData.ID, currency); // 存入localStorage
    if (this.selectedCurrency === 'TWD') {
      this.layoutService.renewCurrency({
        currency,
      });
    }
    if (this.selectedCurrency !== 'TWD') {
      this.layoutService.getCurrencyRate().subscribe((v: any) => {

        this.layoutService.renewCurrency({
          currency,
          USD: v.data.USD.Exrate / v.data.USDTWD.Exrate,
          AUD: v.data.USDAUD.Exrate / v.data.USDTWD.Exrate,
          CAD: v.data.USDCAD.Exrate / v.data.USDTWD.Exrate,
          CNY: v.data.USDCNY.Exrate / v.data.USDTWD.Exrate,
          EUR: v.data.USDEUR.Exrate / v.data.USDTWD.Exrate,
          GBP: v.data.USDGBP.Exrate / v.data.USDTWD.Exrate,
          HKD: v.data.USDHKD.Exrate / v.data.USDTWD.Exrate,
          IDR: v.data.USDIDR.Exrate / v.data.USDTWD.Exrate,
          INR: v.data.USDINR.Exrate / v.data.USDTWD.Exrate,
          JPY: v.data.USDJPY.Exrate / v.data.USDTWD.Exrate,
          KHR: v.data.USDKHR.Exrate / v.data.USDTWD.Exrate,
          KRW: v.data.USDKRW.Exrate / v.data.USDTWD.Exrate,
          MAD: v.data.USDMAD.Exrate / v.data.USDTWD.Exrate,
          MMK: v.data.USDMMK.Exrate / v.data.USDTWD.Exrate,
          MOP: v.data.USDMOP.Exrate / v.data.USDTWD.Exrate,
          MYR: v.data.USDMYR.Exrate / v.data.USDTWD.Exrate,
          MXN: v.data.USDMXN.Exrate / v.data.USDTWD.Exrate,
          NPR: v.data.USDNPR.Exrate / v.data.USDTWD.Exrate,
          NZD: v.data.USDNZD.Exrate / v.data.USDTWD.Exrate,
          PHP: v.data.USDPHP.Exrate / v.data.USDTWD.Exrate,
          RUB: v.data.USDRUB.Exrate / v.data.USDTWD.Exrate,
          SGD: v.data.USDSGD.Exrate / v.data.USDTWD.Exrate,
          THB: v.data.USDTHB.Exrate / v.data.USDTWD.Exrate,
          TRY: v.data.USDTRY.Exrate / v.data.USDTWD.Exrate,
          VND: v.data.USDVND.Exrate / v.data.USDTWD.Exrate,
          ZAR: v.data.USDZAR.Exrate / v.data.USDTWD.Exrate,
        });
      },
        (e) => { console.log(e); },
      );
    }
  }

  changeLanguage(lang: string) {
    this.layoutService.renewLanguage(lang);
    this.translateService.use(lang);

    location.reload();
  }

  showAlert(content: string) {
    const config = {
      initialState: {
        title: '系統錯誤',
        content,
        btnClass: 'btn btn-danger'
      },
      class: 'modal-danger'
    };
    this.bsModalRef = this.modalService.show(AlertDialogComponent, config);
  }

}

<div class="scroll-up">
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"
    fill="currentColor" viewBox="0 0 24 24">
    <path d="M23.245 20l-11.245-14.374-11.219 14.374-.781-.619 12-15.381 12 15.391-.755.609z" />
  </svg>
</div>

<nav class="navbar navbar-expand fixed-top top-header d-none  d-xl-block">
  <div class="container-fluid">
    <a class="navbar-brand" [routerLink]="['/']">
      <div class="navbar-logo">

        <svg version="1.1" id="圖層_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px" y="0px" viewBox="0 0 253 134" xml:space="preserve">
          <style type="text/css">
            .st0 {
              fill: #FFFFFF;
            }

            .st1 {
              fill: #2AABD9;
            }

            .st2 {
              fill: #076BAB;
            }
          </style>
          <g>
            <g>
              <g>
                <g>
                  <path class="st0" d="M83.6,113l-0.2,8.8l-23.2,4.8l0.7-35.2l23.2-4.8l-0.2,8.8l-10.9,2.2l-0.1,4.4l7.6-1.6l-0.2,8.8l-7.6,1.6
                  l-0.1,4.3L83.6,113z" />
                </g>
                <g>
                  <path class="st0"
                    d="M111.9,106.4l-0.2,7.3l-26.1,5.1l11.9-24.1l-10.5,2l0.2-7.3l25.7-5L101,108.5L111.9,106.4z" />
                </g>
                <g>
                  <path class="st0"
                    d="M113.4,102.7l0.3-12.9l22-4.3l-0.3,12.9l-5.5,1.1l-0.4,16.3l-11,2.1l0.4-16.3L113.4,102.7z" />
                </g>
                <g>
                  <path class="st0" d="M150.5,108c-3.5,0.7-6.5-0.2-8.9-2.6c-2.4-2.4-3.5-5.8-3.4-10c0.1-4.2,1.4-8,3.9-11.4
                  c2.5-3.4,5.6-5.4,9.1-6.1c3.5-0.7,6.5,0.2,8.9,2.6c2.4,2.4,3.5,5.7,3.4,9.9c-0.1,4.2-1.4,8-3.9,11.4
                  C157.1,105.3,154,107.3,150.5,108z M150.9,89.1c-0.7,0.1-1.3,0.5-1.8,1.2c-0.5,0.7-0.7,1.4-0.7,2.3c0,0.9,0.2,1.6,0.6,2.1
                  c0.5,0.5,1,0.7,1.7,0.5c0.7-0.1,1.3-0.5,1.8-1.2c0.5-0.7,0.7-1.4,0.7-2.3c0-0.9-0.2-1.6-0.6-2.1C152.2,89.1,151.6,89,150.9,89.1
                  z" />
                </g>
                <g>
                  <path class="st0" d="M190.8,79l-5.5,9.8c-2.2-0.8-4.2-1-6.1-0.7c-1.2,0.2-2.2,0.8-3,1.7c-0.8,0.9-1.2,2-1.2,3.2
                  c-0.1,2.4,1.2,3.3,3.9,2.8l0.1-4.6l11-2.1l-0.4,15.7c-4.4,1.2-7.4,1.9-9,2.2c-6.1,1.2-10.2,0.5-12.5-2.1
                  c-2.3-2.6-3.4-5.9-3.3-9.9c0.1-2.8,0.7-5.4,1.8-8c1.2-2.5,2.8-4.6,4.9-6.3c2.1-1.7,4.6-2.8,7.4-3.3c2-0.4,4-0.4,6.2-0.2
                  C187.2,77.5,189.1,78.1,190.8,79z" />
                </g>
                <g>
                  <path class="st0" d="M205.9,96.4c-3.5,0.7-6.5-0.2-8.9-2.6c-2.4-2.4-3.5-5.8-3.4-10c0.1-4.2,1.4-8,3.9-11.4
                  c2.5-3.4,5.6-5.4,9.1-6.1c3.5-0.7,6.5,0.2,8.9,2.6c2.4,2.4,3.5,5.7,3.4,9.9c-0.1,4.2-1.4,8-3.9,11.4
                  C212.5,93.6,209.5,95.7,205.9,96.4z M206.4,77.5c-0.7,0.1-1.3,0.5-1.8,1.2c-0.5,0.7-0.7,1.4-0.7,2.3c0,0.9,0.2,1.6,0.6,2.1
                  c0.5,0.5,1,0.7,1.7,0.5c0.7-0.1,1.3-0.5,1.8-1.2c0.5-0.7,0.7-1.4,0.7-2.3c0-0.9-0.2-1.6-0.6-2.1
                  C207.7,77.5,207.1,77.3,206.4,77.5z" />
                </g>
              </g>
              <g>
                <g>
                  <path class="st1" d="M60.1,130.2c-0.8,0-1.6-0.3-2.2-0.8c-0.8-0.7-1.3-1.7-1.3-2.8l0.7-35.2c0-1.6,1.2-3,2.8-3.4l23.2-4.8
                  c1-0.2,2.1,0.1,2.9,0.7c0.8,0.7,1.3,1.7,1.3,2.8l-0.2,8.8c0,1.6-1.2,3-2.8,3.4l-0.9,0.2c0.2,0.5,0.3,1,0.3,1.5l-0.2,8.8
                  c0,0,0,0.1,0,0.1c0.7,0.1,1.4,0.3,2,0.8c0.8,0.7,1.3,1.7,1.3,2.8l-0.2,8.8c0,1.6-1.2,3-2.8,3.4l-23.2,4.8
                  C60.6,130.1,60.4,130.2,60.1,130.2z M64.3,94.3l-0.6,28.1l16.2-3.3l0-1.7l-6.6,1.4c-1,0.2-2.1-0.1-2.9-0.7
                  c-0.8-0.7-1.3-1.7-1.3-2.8l0.1-4.3c0-1.6,1.2-3,2.8-3.4l4.8-1l0-1.7l-3.3,0.7c-1,0.2-2.1-0.1-2.9-0.7s-1.3-1.7-1.3-2.8l0.1-4.4
                  c0-1.6,1.2-3,2.8-3.4l8.1-1.7l0-1.7L64.3,94.3z" />
                </g>
                <g>
                  <path class="st1" d="M85.6,122.3c-1.1,0-2.1-0.5-2.8-1.4c-0.8-1.1-0.9-2.5-0.4-3.7l8.8-17.7l-3.5,0.7c-1,0.2-2.1-0.1-2.9-0.8
                  c-0.8-0.7-1.3-1.7-1.2-2.8l0.2-7.3c0-1.6,1.2-3,2.8-3.4l25.7-5c1.3-0.2,2.6,0.3,3.5,1.3s0.9,2.5,0.4,3.7l-8.8,17.7l3.9-0.8
                  c1-0.2,2.1,0.1,2.9,0.8c0.8,0.7,1.3,1.7,1.2,2.8l-0.2,7.3c0,1.6-1.2,3-2.8,3.4l-26.1,5.1C86.1,122.3,85.8,122.3,85.6,122.3z
                   M97.5,91.2c1.1,0,2.1,0.5,2.8,1.4c0.8,1.1,0.9,2.5,0.4,3.7L91.9,114l16.4-3.2l0-0.2l-6.5,1.3c-1.3,0.3-2.6-0.3-3.5-1.3
                  c-0.8-1.1-0.9-2.5-0.4-3.7l8.8-17.7l-16,3.1l0,0.2l6.2-1.2C97.1,91.2,97.3,91.2,97.5,91.2z" />
                </g>
                <g>
                  <path class="st1" d="M118.5,121.3c-0.8,0-1.6-0.3-2.3-0.8c-0.8-0.7-1.3-1.7-1.2-2.8l0.3-11.9l-1.2,0.2c-1,0.2-2.1-0.1-2.9-0.8
                  c-0.8-0.7-1.3-1.7-1.2-2.8l0.3-12.9c0-1.6,1.2-3,2.8-3.4l22-4.3c1-0.2,2.1,0.1,2.9,0.8c0.8,0.7,1.3,1.7,1.2,2.8l-0.3,12.9
                  c0,1.6-1.2,3-2.8,3.4l-2.8,0.5l-0.3,13.4c0,1.6-1.2,3-2.8,3.4l-11,2.1C118.9,121.3,118.7,121.3,118.5,121.3z M118.9,98.1
                  c0.8,0,1.6,0.3,2.3,0.8c0.8,0.7,1.3,1.7,1.2,2.8l-0.3,11.9l3.9-0.8l0.3-13.4c0-1.6,1.2-3,2.8-3.4l2.8-0.5l0.1-5.8l-15,2.9
                  l-0.1,5.8l1.2-0.2C118.4,98.1,118.6,98.1,118.9,98.1z" />
                </g>
                <g>
                  <path class="st1" d="M148.2,111.8c-3.5,0-6.6-1.3-9.2-3.9c-3.1-3.1-4.6-7.3-4.4-12.5c0.1-4.9,1.7-9.4,4.6-13.4
                  c3.1-4.1,6.8-6.6,11.2-7.5c4.7-0.9,8.9,0.3,12.1,3.6c3.1,3.1,4.6,7.3,4.4,12.5c-0.1,4.9-1.7,9.4-4.6,13.4
                  c-3.1,4.1-6.8,6.6-11.3,7.5C150.1,111.7,149.2,111.8,148.2,111.8z M153.5,81.2c-0.5,0-1,0.1-1.6,0.2c-2.7,0.5-5,2.1-7,4.8
                  c-2.1,2.8-3.2,5.9-3.2,9.4c-0.1,3.3,0.7,5.7,2.4,7.5c1.6,1.6,3.3,2.1,5.7,1.6h0c2.7-0.5,5-2.1,7-4.8c2.1-2.8,3.2-5.9,3.3-9.4
                  c0.1-3.2-0.7-5.7-2.4-7.4C156.4,81.8,155.1,81.2,153.5,81.2z M150.3,98.8c-1.5,0-2.8-0.6-3.9-1.7c-0.7-0.8-1.6-2.3-1.6-4.5
                  c0-1.6,0.5-3,1.4-4.3c1-1.4,2.4-2.3,4-2.6c0,0,0,0,0,0c1.9-0.4,3.7,0.2,5,1.6c0.7,0.8,1.6,2.3,1.6,4.5c0,1.6-0.5,3-1.4,4.3
                  c-1,1.4-2.4,2.3-4,2.6C151.1,98.7,150.7,98.8,150.3,98.8z M150,91.8c0,0.1,0.1,0.1,0.1,0.2c0.4,0.5,1.1,0.6,1.5,0.5l-0.1-0.3
                  c-0.4-0.4-1-0.5-1.4-0.4C150,91.8,150,91.8,150,91.8z M151.5,92.2l0.1,0.3c0.1,0,0.1,0,0.2,0C151.7,92.4,151.7,92.4,151.5,92.2
                  C151.6,92.3,151.6,92.3,151.5,92.2z" />
                </g>
                <g>
                  <path class="st1" d="M175.5,111.1c-4.4,0-7.8-1.3-10-3.8c-2.9-3.2-4.3-7.4-4.2-12.3c0.1-3.2,0.8-6.4,2.1-9.3
                  c1.4-3,3.4-5.6,5.9-7.6c2.5-2,5.5-3.4,8.9-4c2.3-0.5,4.8-0.5,7.3-0.2c2.5,0.3,4.8,1,6.9,2.1c0.8,0.4,1.5,1.2,1.7,2.1
                  c0.3,0.9,0.2,1.9-0.3,2.7l-2.9,5.1c0.4,0.1,0.9,0.4,1.2,0.7c0.8,0.7,1.3,1.7,1.2,2.8l-0.4,15.7c0,1.6-1.1,2.9-2.6,3.3
                  c-4.6,1.2-7.6,1.9-9.2,2.2C179.2,110.9,177.3,111.1,175.5,111.1z M182.5,80.6c-1,0-2,0.1-2.9,0.3c-2.3,0.4-4.2,1.3-5.9,2.6
                  c-1.7,1.3-2.9,3-3.9,5c-1,2.1-1.5,4.3-1.5,6.6c-0.1,3.2,0.7,5.6,2.4,7.5c1.7,2,6,1.6,9.2,1c1.2-0.2,3.3-0.7,6.2-1.5l0.2-8.7
                  l-3.9,0.8l0,1.8c0,1.6-1.2,3-2.8,3.4c-2.5,0.5-4.5,0.1-6-1.2c-1-0.8-2.1-2.4-2.1-5.1c0-2,0.8-3.9,2-5.4c1.3-1.5,3-2.5,5-2.9
                  c1.6-0.3,3.3-0.3,5,0l2.1-3.8c-0.4-0.1-0.7-0.1-1.1-0.2C183.9,80.6,183.2,80.6,182.5,80.6z" />
                </g>
                <g>
                  <path class="st1" d="M203.7,100.1c-3.5,0-6.6-1.3-9.2-3.9c-3.1-3.1-4.6-7.3-4.4-12.5c0.1-4.9,1.7-9.4,4.6-13.4
                  c3.1-4.1,6.8-6.6,11.2-7.5c4.7-0.9,8.9,0.3,12.1,3.6c3.1,3.1,4.6,7.3,4.4,12.5c-0.1,4.9-1.7,9.4-4.6,13.4
                  c-3.1,4.1-6.8,6.6-11.3,7.5C205.6,100,204.6,100.1,203.7,100.1z M208.9,69.5c-0.5,0-1,0.1-1.6,0.2c-2.7,0.5-5,2.1-7,4.8
                  c-2.1,2.8-3.2,5.9-3.2,9.4c-0.1,3.3,0.7,5.7,2.4,7.5c1.6,1.6,3.3,2.1,5.7,1.6h0c2.7-0.5,5-2.1,7-4.8c2.1-2.8,3.2-5.9,3.3-9.4
                  c0.1-3.2-0.7-5.7-2.4-7.4C211.9,70.1,210.5,69.5,208.9,69.5z M205.8,87.1c-1.5,0-2.9-0.6-3.9-1.7c-0.7-0.8-1.6-2.2-1.6-4.5
                  c0-1.6,0.5-3,1.4-4.3c1-1.4,2.4-2.3,4-2.6c1.9-0.4,3.7,0.2,5,1.6c0.7,0.8,1.6,2.2,1.6,4.5c0,1.6-0.5,3-1.4,4.3
                  c-1,1.4-2.4,2.3-4,2.6C206.5,87.1,206.2,87.1,205.8,87.1z M205.4,80.2c0,0.1,0.1,0.1,0.1,0.2c0.4,0.5,1.1,0.6,1.5,0.5
                  c0.1,0,0.1,0,0.2,0c0-0.1-0.1-0.1-0.1-0.2c-0.4-0.5-1.1-0.6-1.5-0.5C205.5,80.1,205.5,80.2,205.4,80.2z M206.4,77.5L206.4,77.5
                  L206.4,77.5z" />
                </g>
              </g>
              <g>
                <g>
                  <path class="st0" d="M83.6,113l-0.2,8.8l-23.2,4.8l0.7-35.2l23.2-4.8l-0.2,8.8l-10.9,2.2l-0.1,4.4l7.6-1.6l-0.2,8.8l-7.6,1.6
                  l-0.1,4.3L83.6,113z" />
                </g>
                <g>
                  <path class="st0"
                    d="M111.9,106.4l-0.2,7.3l-26.1,5.1l11.9-24.1l-10.5,2l0.2-7.3l25.7-5L101,108.5L111.9,106.4z" />
                </g>
                <g>
                  <path class="st0"
                    d="M113.4,102.7l0.3-12.9l22-4.3l-0.3,12.9l-5.5,1.1l-0.4,16.3l-11,2.1l0.4-16.3L113.4,102.7z" />
                </g>
                <g>
                  <path class="st0" d="M150.5,108c-3.5,0.7-6.5-0.2-8.9-2.6c-2.4-2.4-3.5-5.8-3.4-10c0.1-4.2,1.4-8,3.9-11.4
                  c2.5-3.4,5.6-5.4,9.1-6.1c3.5-0.7,6.5,0.2,8.9,2.6c2.4,2.4,3.5,5.7,3.4,9.9c-0.1,4.2-1.4,8-3.9,11.4
                  C157.1,105.3,154,107.3,150.5,108z M150.9,89.1c-0.7,0.1-1.3,0.5-1.8,1.2c-0.5,0.7-0.7,1.4-0.7,2.3c0,0.9,0.2,1.6,0.6,2.1
                  c0.5,0.5,1,0.7,1.7,0.5c0.7-0.1,1.3-0.5,1.8-1.2c0.5-0.7,0.7-1.4,0.7-2.3c0-0.9-0.2-1.6-0.6-2.1C152.2,89.1,151.6,89,150.9,89.1
                  z" />
                </g>
                <g>
                  <path class="st0" d="M190.8,79l-5.5,9.8c-2.2-0.8-4.2-1-6.1-0.7c-1.2,0.2-2.2,0.8-3,1.7c-0.8,0.9-1.2,2-1.2,3.2
                  c-0.1,2.4,1.2,3.3,3.9,2.8l0.1-4.6l11-2.1l-0.4,15.7c-4.4,1.2-7.4,1.9-9,2.2c-6.1,1.2-10.2,0.5-12.5-2.1
                  c-2.3-2.6-3.4-5.9-3.3-9.9c0.1-2.8,0.7-5.4,1.8-8c1.2-2.5,2.8-4.6,4.9-6.3c2.1-1.7,4.6-2.8,7.4-3.3c2-0.4,4-0.4,6.2-0.2
                  C187.2,77.5,189.1,78.1,190.8,79z" />
                </g>
                <g>
                  <path class="st0" d="M205.9,96.4c-3.5,0.7-6.5-0.2-8.9-2.6c-2.4-2.4-3.5-5.8-3.4-10c0.1-4.2,1.4-8,3.9-11.4
                  c2.5-3.4,5.6-5.4,9.1-6.1c3.5-0.7,6.5,0.2,8.9,2.6c2.4,2.4,3.5,5.7,3.4,9.9c-0.1,4.2-1.4,8-3.9,11.4
                  C212.5,93.6,209.5,95.7,205.9,96.4z M206.4,77.5c-0.7,0.1-1.3,0.5-1.8,1.2c-0.5,0.7-0.7,1.4-0.7,2.3c0,0.9,0.2,1.6,0.6,2.1
                  c0.5,0.5,1,0.7,1.7,0.5c0.7-0.1,1.3-0.5,1.8-1.2c0.5-0.7,0.7-1.4,0.7-2.3c0-0.9-0.2-1.6-0.6-2.1
                  C207.7,77.5,207.1,77.3,206.4,77.5z" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <path class="st1" d="M105.2,81.9c-0.9,0-1.8-0.3-2.6-0.8c-1-0.7-1.6-1.8-1.8-2.9l-1-6.3c-0.3-1.7,0.4-3.3,1.7-4.3l-0.1-23.7
                      c-0.8-0.8-1.3-2-1.3-3.2l0-6.5c0-2.2,1.6-4.1,3.7-4.5l1.7-0.3c0-2.2,1.6-4,3.7-4.4l7.5-1.3c1.3-0.2,2.6,0.1,3.7,1
                      c0.6,0.5,1.1,1.1,1.3,1.9l0.3,0c0.1,0,0.3,0,0.4-0.1l0.1-0.5c0.3-1.9,1.8-3.3,3.7-3.7l7.5-1.3c1.4-0.2,2.8,0.2,3.8,1.1
                      l10.1-1.8c1.3-0.2,2.6,0.1,3.7,1c1,0.8,1.6,2.1,1.6,3.4l0,6.5c0,1.3-0.5,2.5-1.4,3.3l0,2.2c0,1.3-0.2,2.6-0.6,3.7
                      c0.5,0.8,0.8,1.8,0.8,2.7L151,54.3c0,1.6-0.2,3.4-0.7,5c0.3,0.2,0.7,0.4,0.9,0.6c1,0.8,1.6,2.1,1.6,3.4l0,6.5
                      c0,2.2-1.6,4.1-3.7,4.5l-6.3,1.1c-1.7,0.3-3.2,0.2-4.6-0.2c-0.7,0.7-1.6,1.3-2.6,1.5l-5.4,0.9c-2.4,0.4-4.2,0-5.5-0.6
                      c-1.3,1.3-3.1,2.2-5.2,2.5l-4.1,0.7c-0.9,0.1-1.8,0-2.6-0.3c-1,0.7-2.2,1.1-3.5,1.4l-3.5,0.6
                      C105.7,81.9,105.4,81.9,105.2,81.9z M129.7,69.8L129.7,69.8L129.7,69.8z" />
                    </g>
                    <g>
                      <path class="st1"
                        d="M155.8,72.8c-1,0-2.1-0.4-2.9-1c-1-0.8-1.6-2.1-1.6-3.4l-0.1-18.6c-1.3-0.6-2.2-1.8-2.5-3.3l-1.1-6.3
                      c-0.2-1.2,0.1-2.4,0.7-3.4c0.2-0.4,0.5-0.7,0.9-0.9c-0.3-0.5-0.5-1-0.6-1.5l-1.1-6.3c-0.2-1.2,0.1-2.4,0.7-3.4
                      s1.7-1.6,2.9-1.9l0.9-0.2l1.2-3.2c0.6-1.5,1.9-2.6,3.4-2.8l7.5-1.3c0.8-0.1,1.7-0.1,2.4,0.2c0.2-0.1,0.4-0.1,0.6-0.2l27-4.7
                      c1.3-0.2,2.6,0.1,3.7,1c1,0.8,1.6,2.1,1.6,3.4l0,6.5c0,1.3-0.6,2.6-1.5,3.4c0.3,0.2,0.6,0.3,0.8,0.6c1,0.8,1.6,2.1,1.6,3.4
                      l0,6.5c0,2.2-1.6,4.1-3.7,4.5l-0.2,0l0.1,17.2c0,0,0,0.1,0,0.1c0,1.9-0.7,3.9-1.9,5.7c0,0,0,0.1-0.1,0.1
                      c-1.5,2.2-3.7,3.6-6.2,4l-13.9,2.4c-1.2,0.2-2.4-0.1-3.4-0.8c-1-0.7-1.6-1.8-1.8-3l-0.9-5.9c-0.4-2.4,1.3-4.7,3.7-5.1l8-1.4
                      l-0.1-10.5l-12.5,2.2l0.1,21.9c0,2.2-1.6,4.1-3.7,4.5l-7.5,1.3C156.3,72.8,156.1,72.8,155.8,72.8z" />
                    </g>
                    <g>
                      <path class="st1" d="M207.2,65c-1,0-2.1-0.4-2.9-1c-0.6-0.5-1-1.1-1.3-1.8c-0.1,0-0.3,0.1-0.4,0.1l-0.7,0.1
                      c-1.2,0.2-2.4-0.1-3.4-0.8c-1-0.7-1.6-1.8-1.8-2.9l-1-6.3c-0.2-1.5,0.3-3,1.3-4l-0.1-14.6c-0.7-0.8-1.1-1.9-1.1-3l0-6.5
                      c0-1.3,0.5-2.5,1.4-3.3l0-5.8c0-2.2,1.6-4.1,3.7-4.5l17.7-3.1c1.3-0.2,2.6,0.1,3.7,1c0.7,0.6,1.2,1.3,1.4,2.2l1.5-0.3l0-0.9
                      c0-2.2,1.6-4.1,3.7-4.5l7.5-1.3c1.3-0.2,2.6,0.1,3.7,1c0.8,0.7,1.4,1.7,1.6,2.8l0.9-0.2c1.3-0.2,2.6,0.1,3.7,1
                      c1,0.8,1.6,2.1,1.6,3.4l0,6.5c0,2.2-1.6,4.1-3.7,4.5l-2.4,0.4l0.1,20.1l1.8-0.3c1.3-0.2,2.6,0.1,3.7,1c1,0.8,1.6,2.1,1.6,3.4
                      l0,6.5c0,2.2-1.6,4.1-3.7,4.5l-22.2,3.9c-1.3,0.2-2.6-0.1-3.7-1c-0.2-0.1-0.3-0.3-0.5-0.5c-0.6,1.4-1.8,2.5-3.4,2.8L208,65
                      C207.7,65,207.4,65,207.2,65z M199.5,47.2C199.5,47.2,199.5,47.2,199.5,47.2L199.5,47.2z M224.6,29.6c0,0.1,0,0.1,0,0.2
                      l0.1,16.6l0.6-0.1l-0.1-18.1C225.1,28.7,224.9,29.2,224.6,29.6z M232.8,17.6c0.1,0.1,0.2,0.2,0.2,0.3c0.2-0.6,0.6-1.1,1-1.5
                      c-0.1-0.1-0.2-0.2-0.2-0.3C233.6,16.6,233.2,17.1,232.8,17.6z" />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path class="st1" d="M105.2,81.9c-0.9,0-1.8-0.3-2.6-0.8c-1-0.7-1.6-1.8-1.8-2.9l-1-6.3c-0.3-1.7,0.4-3.3,1.7-4.3l-0.1-23.7
                      c-0.8-0.8-1.3-2-1.3-3.2l0-6.5c0-2.2,1.6-4.1,3.7-4.5l1.7-0.3c0-2.2,1.6-4,3.7-4.4l7.5-1.3c1.3-0.2,2.6,0.1,3.7,1
                      c0.6,0.5,1.1,1.1,1.3,1.9l0.3,0c0.1,0,0.3,0,0.4-0.1l0.1-0.5c0.3-1.9,1.8-3.3,3.7-3.7l7.5-1.3c1.4-0.2,2.8,0.2,3.8,1.1
                      l10.1-1.8c1.3-0.2,2.6,0.1,3.7,1c1,0.8,1.6,2.1,1.6,3.4l0,6.5c0,1.3-0.5,2.5-1.4,3.3l0,2.2c0,1.3-0.2,2.6-0.6,3.7
                      c0.5,0.8,0.8,1.8,0.8,2.7L151,54.3c0,1.6-0.2,3.4-0.7,5c0.3,0.2,0.7,0.4,0.9,0.6c1,0.8,1.6,2.1,1.6,3.4l0,6.5
                      c0,2.2-1.6,4.1-3.7,4.5l-6.3,1.1c-1.7,0.3-3.2,0.2-4.6-0.2c-0.7,0.7-1.6,1.3-2.6,1.5l-5.4,0.9c-2.4,0.4-4.2,0-5.5-0.6
                      c-1.3,1.3-3.1,2.2-5.2,2.5l-4.1,0.7c-0.9,0.1-1.8,0-2.6-0.3c-1,0.7-2.2,1.1-3.5,1.4l-3.5,0.6
                      C105.7,81.9,105.4,81.9,105.2,81.9z M129.7,69.8L129.7,69.8L129.7,69.8z" />
                    </g>
                    <g>
                      <path class="st1"
                        d="M155.8,72.8c-1,0-2.1-0.4-2.9-1c-1-0.8-1.6-2.1-1.6-3.4l-0.1-18.6c-1.3-0.6-2.2-1.8-2.5-3.3l-1.1-6.3
                      c-0.2-1.2,0.1-2.4,0.7-3.4c0.2-0.4,0.5-0.7,0.9-0.9c-0.3-0.5-0.5-1-0.6-1.5l-1.1-6.3c-0.2-1.2,0.1-2.4,0.7-3.4
                      s1.7-1.6,2.9-1.9l0.9-0.2l1.2-3.2c0.6-1.5,1.9-2.6,3.4-2.8l7.5-1.3c0.8-0.1,1.7-0.1,2.4,0.2c0.2-0.1,0.4-0.1,0.6-0.2l27-4.7
                      c1.3-0.2,2.6,0.1,3.7,1c1,0.8,1.6,2.1,1.6,3.4l0,6.5c0,1.3-0.6,2.6-1.5,3.4c0.3,0.2,0.6,0.3,0.8,0.6c1,0.8,1.6,2.1,1.6,3.4
                      l0,6.5c0,2.2-1.6,4.1-3.7,4.5l-0.2,0l0.1,17.2c0,0,0,0.1,0,0.1c0,1.9-0.7,3.9-1.9,5.7c0,0,0,0.1-0.1,0.1
                      c-1.5,2.2-3.7,3.6-6.2,4l-13.9,2.4c-1.2,0.2-2.4-0.1-3.4-0.8c-1-0.7-1.6-1.8-1.8-3l-0.9-5.9c-0.4-2.4,1.3-4.7,3.7-5.1l8-1.4
                      l-0.1-10.5l-12.5,2.2l0.1,21.9c0,2.2-1.6,4.1-3.7,4.5l-7.5,1.3C156.3,72.8,156.1,72.8,155.8,72.8z" />
                    </g>
                    <g>
                      <path class="st1" d="M207.2,65c-1,0-2.1-0.4-2.9-1c-0.6-0.5-1-1.1-1.3-1.8c-0.1,0-0.3,0.1-0.4,0.1l-0.7,0.1
                      c-1.2,0.2-2.4-0.1-3.4-0.8c-1-0.7-1.6-1.8-1.8-2.9l-1-6.3c-0.2-1.5,0.3-3,1.3-4l-0.1-14.6c-0.7-0.8-1.1-1.9-1.1-3l0-6.5
                      c0-1.3,0.5-2.5,1.4-3.3l0-5.8c0-2.2,1.6-4.1,3.7-4.5l17.7-3.1c1.3-0.2,2.6,0.1,3.7,1c0.7,0.6,1.2,1.3,1.4,2.2l1.5-0.3l0-0.9
                      c0-2.2,1.6-4.1,3.7-4.5l7.5-1.3c1.3-0.2,2.6,0.1,3.7,1c0.8,0.7,1.4,1.7,1.6,2.8l0.9-0.2c1.3-0.2,2.6,0.1,3.7,1
                      c1,0.8,1.6,2.1,1.6,3.4l0,6.5c0,2.2-1.6,4.1-3.7,4.5l-2.4,0.4l0.1,20.1l1.8-0.3c1.3-0.2,2.6,0.1,3.7,1c1,0.8,1.6,2.1,1.6,3.4
                      l0,6.5c0,2.2-1.6,4.1-3.7,4.5l-22.2,3.9c-1.3,0.2-2.6-0.1-3.7-1c-0.2-0.1-0.3-0.3-0.5-0.5c-0.6,1.4-1.8,2.5-3.4,2.8L208,65
                      C207.7,65,207.4,65,207.2,65z M199.5,47.2C199.5,47.2,199.5,47.2,199.5,47.2L199.5,47.2z M224.6,29.6c0,0.1,0,0.1,0,0.2
                      l0.1,16.6l0.6-0.1l-0.1-18.1C225.1,28.7,224.9,29.2,224.6,29.6z M232.8,17.6c0.1,0.1,0.2,0.2,0.2,0.3c0.2-0.6,0.6-1.1,1-1.5
                      c-0.1-0.1-0.2-0.2-0.2-0.3C233.6,16.6,233.2,17.1,232.8,17.6z" />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path class="st1" d="M105.2,81.9c-0.9,0-1.8-0.3-2.6-0.8c-1-0.7-1.6-1.8-1.8-2.9l-1-6.3c-0.3-1.7,0.4-3.3,1.7-4.3l-0.1-23.7
                      c-0.8-0.8-1.3-2-1.3-3.2l0-6.5c0-2.2,1.6-4.1,3.7-4.5l1.7-0.3c0-2.2,1.6-4,3.7-4.4l7.5-1.3c1.3-0.2,2.6,0.1,3.7,1
                      c0.6,0.5,1.1,1.1,1.3,1.9l0.3,0c0.1,0,0.3,0,0.4-0.1l0.1-0.5c0.3-1.9,1.8-3.3,3.7-3.7l7.5-1.3c1.4-0.2,2.8,0.2,3.8,1.1
                      l10.1-1.8c1.3-0.2,2.6,0.1,3.7,1c1,0.8,1.6,2.1,1.6,3.4l0,6.5c0,1.3-0.5,2.5-1.4,3.3l0,2.2c0,1.3-0.2,2.6-0.6,3.7
                      c0.5,0.8,0.8,1.8,0.8,2.7L151,54.3c0,1.6-0.2,3.4-0.7,5c0.3,0.2,0.7,0.4,0.9,0.6c1,0.8,1.6,2.1,1.6,3.4l0,6.5
                      c0,2.2-1.6,4.1-3.7,4.5l-6.3,1.1c-1.7,0.3-3.2,0.2-4.6-0.2c-0.7,0.7-1.6,1.3-2.6,1.5l-5.4,0.9c-2.4,0.4-4.2,0-5.5-0.6
                      c-1.3,1.3-3.1,2.2-5.2,2.5l-4.1,0.7c-0.9,0.1-1.8,0-2.6-0.3c-1,0.7-2.2,1.1-3.5,1.4l-3.5,0.6
                      C105.7,81.9,105.4,81.9,105.2,81.9z M129.7,69.8L129.7,69.8L129.7,69.8z" />
                    </g>
                    <g>
                      <path class="st1"
                        d="M155.8,72.8c-1,0-2.1-0.4-2.9-1c-1-0.8-1.6-2.1-1.6-3.4l-0.1-18.6c-1.3-0.6-2.2-1.8-2.5-3.3l-1.1-6.3
                      c-0.2-1.2,0.1-2.4,0.7-3.4c0.2-0.4,0.5-0.7,0.9-0.9c-0.3-0.5-0.5-1-0.6-1.5l-1.1-6.3c-0.2-1.2,0.1-2.4,0.7-3.4
                      s1.7-1.6,2.9-1.9l0.9-0.2l1.2-3.2c0.6-1.5,1.9-2.6,3.4-2.8l7.5-1.3c0.8-0.1,1.7-0.1,2.4,0.2c0.2-0.1,0.4-0.1,0.6-0.2l27-4.7
                      c1.3-0.2,2.6,0.1,3.7,1c1,0.8,1.6,2.1,1.6,3.4l0,6.5c0,1.3-0.6,2.6-1.5,3.4c0.3,0.2,0.6,0.3,0.8,0.6c1,0.8,1.6,2.1,1.6,3.4
                      l0,6.5c0,2.2-1.6,4.1-3.7,4.5l-0.2,0l0.1,17.2c0,0,0,0.1,0,0.1c0,1.9-0.7,3.9-1.9,5.7c0,0,0,0.1-0.1,0.1
                      c-1.5,2.2-3.7,3.6-6.2,4l-13.9,2.4c-1.2,0.2-2.4-0.1-3.4-0.8c-1-0.7-1.6-1.8-1.8-3l-0.9-5.9c-0.4-2.4,1.3-4.7,3.7-5.1l8-1.4
                      l-0.1-10.5l-12.5,2.2l0.1,21.9c0,2.2-1.6,4.1-3.7,4.5l-7.5,1.3C156.3,72.8,156.1,72.8,155.8,72.8z" />
                    </g>
                    <g>
                      <path class="st1" d="M207.2,65c-1,0-2.1-0.4-2.9-1c-0.6-0.5-1-1.1-1.3-1.8c-0.1,0-0.3,0.1-0.4,0.1l-0.7,0.1
                      c-1.2,0.2-2.4-0.1-3.4-0.8c-1-0.7-1.6-1.8-1.8-2.9l-1-6.3c-0.2-1.5,0.3-3,1.3-4l-0.1-14.6c-0.7-0.8-1.1-1.9-1.1-3l0-6.5
                      c0-1.3,0.5-2.5,1.4-3.3l0-5.8c0-2.2,1.6-4.1,3.7-4.5l17.7-3.1c1.3-0.2,2.6,0.1,3.7,1c0.7,0.6,1.2,1.3,1.4,2.2l1.5-0.3l0-0.9
                      c0-2.2,1.6-4.1,3.7-4.5l7.5-1.3c1.3-0.2,2.6,0.1,3.7,1c0.8,0.7,1.4,1.7,1.6,2.8l0.9-0.2c1.3-0.2,2.6,0.1,3.7,1
                      c1,0.8,1.6,2.1,1.6,3.4l0,6.5c0,2.2-1.6,4.1-3.7,4.5l-2.4,0.4l0.1,20.1l1.8-0.3c1.3-0.2,2.6,0.1,3.7,1c1,0.8,1.6,2.1,1.6,3.4
                      l0,6.5c0,2.2-1.6,4.1-3.7,4.5l-22.2,3.9c-1.3,0.2-2.6-0.1-3.7-1c-0.2-0.1-0.3-0.3-0.5-0.5c-0.6,1.4-1.8,2.5-3.4,2.8L208,65
                      C207.7,65,207.4,65,207.2,65z M199.5,47.2C199.5,47.2,199.5,47.2,199.5,47.2L199.5,47.2z M224.6,29.6c0,0.1,0,0.1,0,0.2
                      l0.1,16.6l0.6-0.1l-0.1-18.1C225.1,28.7,224.9,29.2,224.6,29.6z M232.8,17.6c0.1,0.1,0.2,0.2,0.2,0.3c0.2-0.6,0.6-1.1,1-1.5
                      c-0.1-0.1-0.2-0.2-0.2-0.3C233.6,16.6,233.2,17.1,232.8,17.6z" />
                    </g>
                  </g>
                </g>
                <g>
                  <path class="st1" d="M30.6,108.2c-1.1,0-2.1-0.4-2.9-1.1c-1-0.9-1.6-2.1-1.6-3.5l0.1-11.3l-0.8,4.5c0,0.1-0.1,0.3-0.1,0.4
                  c-1.3,4.8-4.4,7.8-8.8,8.5l-4.4,0.7c-1.2,0.2-2.4-0.1-3.4-0.8c-1-0.7-1.6-1.8-1.8-3L5.7,95c-0.4-2.4,1.3-4.7,3.7-5.1l0.1,0
                  L12,74.4L11,74.5c-1.3,0.2-2.6-0.1-3.6-1c-1-0.9-1.6-2.1-1.6-3.4l0-8c0-1.6,0.9-3.1,2.2-3.9c-0.2-0.4-0.3-0.8-0.4-1.3l-1.1-7.8
                  c-0.3-2.4,1.3-4.7,3.7-5.1l35.1-5.9c0.3-1.9,1.8-3.4,3.7-3.7l8.5-1.4c1.3-0.2,2.6,0.1,3.6,1s1.6,2.1,1.6,3.4l0,5.1
                  c0,0,0,0.1,0,0.1c-0.1,2.2-0.7,4.4-2,6.4c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c0.7,0.2,1.3,0.5,1.8,0.9
                  c1,0.9,1.6,2.1,1.6,3.4l0,8c0,2.2-1.6,4.1-3.8,4.4l-1.9,0.3l2.1,6.1c0.7,0.1,1.4,0.5,2,1c1,0.9,1.6,2.1,1.6,3.4l0,8
                  c0,2.2-1.6,4.1-3.8,4.4l-5.5,0.9c-3.9,0.6-8.6-1.1-10.1-8l-1-3.4l-0.1,23.2c0,2.2-1.6,4.1-3.8,4.4l-8.5,1.4
                  C31.1,108.2,30.8,108.2,30.6,108.2z M54,82L54,82L54,82z M52.9,78.6l0.4,1.5c0,0.1,0.1,0.2,0.1,0.3c0.1,0.5,0.2,0.8,0.3,1.1
                  l1.3-0.2l0-0.3C54.2,80.4,53.4,79.6,52.9,78.6z M61.2,75.2L61.2,75.2L61.2,75.2z M32.9,62.7c0.3,0.2,0.6,0.3,0.8,0.6
                  c0.5,0.4,0.8,0.9,1.1,1.4c0.2-1.4,1.1-2.7,2.4-3.4c-0.3-0.2-0.6-0.3-0.8-0.6c-0.5-0.4-0.8-0.9-1.1-1.4
                  C35.1,60.8,34.2,62,32.9,62.7z M33.2,49.4c0.2,0.1,0.4,0.2,0.5,0.4c0.5,0.4,0.8,0.9,1.1,1.4c0.1-0.8,0.5-1.6,1-2.2L33.2,49.4z
                   M53.2,43.9c-0.3,0.4-0.6,0.8-0.9,1.1l0.2,0c0.1,0,0.3-0.1,0.8-0.6c0.3-0.5,0.5-1.1,0.6-1.6C53.6,43.2,53.4,43.5,53.2,43.9z
                   M47.2,37.9C47.2,37.9,47.2,37.9,47.2,37.9L47.2,37.9z" />
                </g>
                <g>
                  <path class="st1"
                    d="M64.6,85c-0.9,0-1.9-0.3-2.7-0.9c-1-0.7-1.6-1.8-1.8-3l-0.8-5.8c-0.2-1.4,0.2-2.7,1.1-3.6l0-24.3
                  c0,0,0,0,0,0c-0.7-0.8-1-1.8-1-2.9l0-5.2c0-2.2,1.6-4.1,3.8-4.4l1.9-0.3c0.6-1.2,1.8-2.1,3.2-2.3l6.3-1.1c1.3-0.2,2.6,0.1,3.6,1
                  c0.1,0.1,0.1,0.1,0.2,0.2l6.8-1.1c0.6-1.2,1.8-2.1,3.2-2.3l6.3-1c1.3-0.2,2.6,0.2,3.6,1c0.1,0.1,0.1,0.1,0.2,0.2l1.5-0.2
                  c1.3-0.2,2.6,0.1,3.6,1c1,0.9,1.6,2.1,1.6,3.4l0,5.2c0,1-0.3,2-0.9,2.7c0.6,0.8,0.9,1.7,0.9,2.7l0,4.2c0,0.3,0,0.6-0.1,0.9
                  c0.1,0.3,0.1,0.6,0.1,0.9l0,4.7c0,0.5-0.1,0.9-0.2,1.4c0,0.2,0.1,0.5,0.1,0.7l0,3.8c0,0.2,0,0.4,0,0.6c0.1,0.7,0,1.4-0.2,2.1
                  l-0.9,2.6c-0.1,0.3-0.2,0.6-0.3,0.8c1,0.9,1.6,2.1,1.6,3.4l0,4.5c0,2.2-1.6,4.1-3.8,4.4l-2.4,0.4c-1.7,0.3-3.3,0-4.6-0.6
                  c-1.5,1.4-3,1.9-4.2,2.1l-3.6,0.6c-0.8,0.1-1.6,0.1-2.3-0.2c-1,0.5-2.1,0.9-3.3,1.1l-1.3,0.2c-0.8,0.1-1.5,0.1-2.2-0.2
                  c-0.9,0.5-2,0.9-3.1,1.1l-2,0.3c-0.8,0.1-1.5,0.1-2.2-0.2c-0.9,0.5-2,0.9-3.2,1.1l-1.8,0.3C65.1,84.9,64.9,85,64.6,85z" />
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <g>
                      <path class="st2" d="M116,67.8l-0.1-16.9l-2.9,0.5l0.1,19.8c0,1.1-0.4,2.2-1.2,3.4c-0.8,1.2-1.9,1.9-3.3,2.2l-3.5,0.6l-1-6.3
                      l0.1,0c0.6-0.1,1-0.3,1.3-0.7c0.2-0.4,0.4-0.8,0.4-1.3l-0.2-28.7l-1.3,0.2l0-6.5l5.5-1l0-3.7l7.5-1.3l0,3.7l5.2-0.9l0,6.5
                      l-9.7,1.7l0,5.8l2.9-0.5l3.6-0.6l3.6-0.6l0.2,26.4c0,3.1-1.5,5-4.4,5.5l-4.1,0.7l-1-6.3l1.2-0.2C115.6,69.1,116,68.6,116,67.8
                      z M131.9,34.1c-0.8,1.3-2,2.1-3.7,2.4L125,37l-1.1-5.9l0.8-0.1c0.8-0.1,1.4-0.7,1.6-1.7l0.4-2.5l7.5-1.3l-0.5,1.9l14.4-2.5
                      l0,6.5L131.9,34.1z M125.1,69.2l-0.2-30.4l3.6-0.6l3.7-0.6l6.2-1.1c0.7-0.1,1.1-0.6,1.1-1.4l0-0.8l7.2-1.3l0,3.7
                      c0,3.1-1.5,5-4.4,5.5L132.3,44l0.1,20.9c0,0.8,0.4,1.1,1.1,1l1.3-0.2l0,6.5l-5.4,0.9C126.6,73.6,125.1,72.3,125.1,69.2z
                       M142.4,54.6c0.4-0.1,0.6-0.7,0.6-1.8l0.4-9.2L147,43l-0.5,11.2c0,3.6-0.8,5.6-2.3,5.8l-1,0.2l0.6,2.8c0.2,0.7,0.8,1,1.9,0.8
                      l2.7-0.5l0,6.5l-6.3,1.1c-3,0.5-4.8-0.8-5.4-3.9l-3.6-21.3l7.2-1.3l1.9,10.1L142.4,54.6z" />
                    </g>
                    <g>
                      <path class="st2" d="M165.1,19.8l-3.1,8.7c-1,2.6-2.6,4-4.8,4.4l-4.1,0.7l-1.1-6.3l1.3-0.2c1.4-0.2,2.3-0.9,2.7-1.9l1.6-4.1
                      L165.1,19.8z M163.2,42.2l0.1,24.8l-7.5,1.3l-0.1-23.1l-2.5,0.4l-1.1-6.3l1.5-0.3c1.4-0.2,2.2-0.9,2.7-1.9l1.2-3.7l7.5-1.3
                      l-2.8,8.3c-0.4,0.8-0.7,1.4-1.1,2L163.2,42.2z M192.3,57.2c0,1.1-0.4,2.2-1.2,3.4c-0.8,1.2-1.9,1.9-3.3,2.2L174,65.2l-0.9-5.9
                      l10.1-1.8c0.6-0.1,1-0.3,1.3-0.7c0.2-0.4,0.4-0.8,0.4-1.3l-0.1-17.9l-18.2,3.2l0-6.5l29.7-5.2l0,6.5l-4,0.7L192.3,57.2z
                       M195.2,15.2l0,6.5l-27,4.7l0-6.5L195.2,15.2z" />
                    </g>
                    <g>
                      <path class="st2" d="M205,32.5l0.1,21.3c0,0.8-0.3,1.7-0.8,2.5c-0.6,0.9-1.4,1.4-2.4,1.6l-0.7,0.1l-1-6.3l0.1,0
                      c0.4-0.1,0.7-0.3,1-0.6c0.2-0.3,0.3-0.6,0.3-1l-0.1-17L205,32.5z M200.3,30.8l0-6.5l20.5-3.6l0,6.5l-6.3,1.1l0.2,30.9
                      l-7.5,1.3L207,29.6L200.3,30.8z M219.3,12.1l0,6.1l-17.7,3.1l0-6.1L219.3,12.1z M220.1,29.8l0.1,24l-3.6,0.6l-0.1-24
                      L220.1,29.8z M237.4,48.8l7-1.2l0,6.5l-22.2,3.9l0-6.5l7.6-1.3l-0.2-29.3l-6.5,1.1l0-6.5l6.5-1.1l0-4.7l7.5-1.3l0,4.7l6.1-1.1
                      l0,6.5l-6.1,1.1L237.4,48.8z" />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path class="st0" d="M116,67.8l-0.1-16.9l-2.9,0.5l0.1,19.8c0,1.1-0.4,2.2-1.2,3.4c-0.8,1.2-1.9,1.9-3.3,2.2l-3.5,0.6l-1-6.3
                      l0.1,0c0.6-0.1,1-0.3,1.3-0.7c0.2-0.4,0.4-0.8,0.4-1.3l-0.2-28.7l-1.3,0.2l0-6.5l5.5-1l0-3.7l7.5-1.3l0,3.7l5.2-0.9l0,6.5
                      l-9.7,1.7l0,5.8l2.9-0.5l3.6-0.6l3.6-0.6l0.2,26.4c0,3.1-1.5,5-4.4,5.5l-4.1,0.7l-1-6.3l1.2-0.2C115.6,69.1,116,68.6,116,67.8
                      z M131.9,34.1c-0.8,1.3-2,2.1-3.7,2.4L125,37l-1.1-5.9l0.8-0.1c0.8-0.1,1.4-0.7,1.6-1.7l0.4-2.5l7.5-1.3l-0.5,1.9l14.4-2.5
                      l0,6.5L131.9,34.1z M125.1,69.2l-0.2-30.4l3.6-0.6l3.7-0.6l6.2-1.1c0.7-0.1,1.1-0.6,1.1-1.4l0-0.8l7.2-1.3l0,3.7
                      c0,3.1-1.5,5-4.4,5.5L132.3,44l0.1,20.9c0,0.8,0.4,1.1,1.1,1l1.3-0.2l0,6.5l-5.4,0.9C126.6,73.6,125.1,72.3,125.1,69.2z
                       M142.4,54.6c0.4-0.1,0.6-0.7,0.6-1.8l0.4-9.2L147,43l-0.5,11.2c0,3.6-0.8,5.6-2.3,5.8l-1,0.2l0.6,2.8c0.2,0.7,0.8,1,1.9,0.8
                      l2.7-0.5l0,6.5l-6.3,1.1c-3,0.5-4.8-0.8-5.4-3.9l-3.6-21.3l7.2-1.3l1.9,10.1L142.4,54.6z" />
                    </g>
                    <g>
                      <path class="st0" d="M165.1,19.8l-3.1,8.7c-1,2.6-2.6,4-4.8,4.4l-4.1,0.7l-1.1-6.3l1.3-0.2c1.4-0.2,2.3-0.9,2.7-1.9l1.6-4.1
                      L165.1,19.8z M163.2,42.2l0.1,24.8l-7.5,1.3l-0.1-23.1l-2.5,0.4l-1.1-6.3l1.5-0.3c1.4-0.2,2.2-0.9,2.7-1.9l1.2-3.7l7.5-1.3
                      l-2.8,8.3c-0.4,0.8-0.7,1.4-1.1,2L163.2,42.2z M192.3,57.2c0,1.1-0.4,2.2-1.2,3.4c-0.8,1.2-1.9,1.9-3.3,2.2L174,65.2l-0.9-5.9
                      l10.1-1.8c0.6-0.1,1-0.3,1.3-0.7c0.2-0.4,0.4-0.8,0.4-1.3l-0.1-17.9l-18.2,3.2l0-6.5l29.7-5.2l0,6.5l-4,0.7L192.3,57.2z
                       M195.2,15.2l0,6.5l-27,4.7l0-6.5L195.2,15.2z" />
                    </g>
                    <g>
                      <path class="st0" d="M205,32.5l0.1,21.3c0,0.8-0.3,1.7-0.8,2.5c-0.6,0.9-1.4,1.4-2.4,1.6l-0.7,0.1l-1-6.3l0.1,0
                      c0.4-0.1,0.7-0.3,1-0.6c0.2-0.3,0.3-0.6,0.3-1l-0.1-17L205,32.5z M200.3,30.8l0-6.5l20.5-3.6l0,6.5l-6.3,1.1l0.2,30.9
                      l-7.5,1.3L207,29.6L200.3,30.8z M219.3,12.1l0,6.1l-17.7,3.1l0-6.1L219.3,12.1z M220.1,29.8l0.1,24l-3.6,0.6l-0.1-24
                      L220.1,29.8z M237.4,48.8l7-1.2l0,6.5l-22.2,3.9l0-6.5l7.6-1.3l-0.2-29.3l-6.5,1.1l0-6.5l6.5-1.1l0-4.7l7.5-1.3l0,4.7l6.1-1.1
                      l0,6.5l-6.1,1.1L237.4,48.8z" />
                    </g>
                  </g>
                </g>
                <g>
                  <path class="st0"
                    d="M25.3,70.9L21,96.1c-0.8,3.1-2.5,4.8-5.2,5.2l-4.4,0.7l-1.2-7.8l1.7-0.3c1-0.2,1.6-0.9,1.9-2.1l3.1-19.6
                  L25.3,70.9z M10.3,70.1l0-8l20.5-3.4l0-5.5l-18.7,3.1L11,48.5l37-6.2c0.7-0.1,1.2-0.4,1.5-0.9c0.3-0.4,0.4-0.9,0.4-1.6l0-0.9
                  l8.5-1.4l0,5.1c0,1.4-0.5,2.8-1.3,4.2c-1,1.5-2.3,2.4-3.8,2.6l-13.9,2.3l0,5.5l20.3-3.4l0,8l-20.3,3.4l-0.2,37l-8.5,1.4l0.2-37
                  L10.3,70.1z M56.8,76.1c0.2,1,0.8,1.3,1.8,1.2l1-0.2l0,8L54.1,86c-2.6,0.4-4.3-1.1-5-4.7L45,67.6l8.5-1.4L56.8,76.1z" />
                </g>
                <g>
                  <path class="st0" d="M87.1,56.7l-1.1,3c-0.5,2-1.6,3.1-3.4,3.4l-3.5,0.6l-0.9-3.7l1.4-0.2c0.5-0.1,0.9-0.6,1.1-1.5l0.2-0.6
                  l-2.2,0.4l0-4.7l4-0.7l0.9-2.6l-2.1,0.4c-0.7,0.6-1.7,1-2.9,1.2l-0.2,0l-0.7-3.1l0,25.3c0,2.9-1.3,4.5-3.7,4.9l-2,0.3L71,73.5
                  l0.3-0.1c0.6-0.1,0.9-0.5,0.9-1.3l0-2.6l-2.2,0.4l0,5.4c0,2.9-1.3,4.5-3.7,4.9l-1.8,0.3l-0.8-5.8l0.2,0c0.6-0.1,0.9-0.5,0.9-1.3
                  l0-26l12.6-2.1l0,2.5l0.8-0.1c0.5-0.1,0.9-0.4,1.1-0.9l0.9-2.1L84,44l-0.5,1.7l1.8-0.3l1.1-3l2.9-0.5l0-1.6l-13.9,2.3l0,1.6
                  l-6.3,1.1l0-1.6l-5.2,0.9l0-5.2l5.2-0.9l0-1.8l6.3-1.1l0,1.8L89.3,35l0-1.8l6.3-1V34l5.3-0.9l0,5.2l-5.3,0.9v1.6l-2.8,0.5
                  l-1.1,3l4-0.7l-0.3-1.6l3.6-0.6l0.8,1.9c0.1,0.4,0.5,0.5,1.1,0.4l0.1,0l0,4.2l-0.8,0.1c-1,0.2-1.9,0.1-2.6-0.3L96.7,48l0.7,2.3
                  l3.5-0.6l0,4.7l-2.2,0.4L99,56c0.1,0.5,0.5,0.7,1.2,0.6l0.5-0.1l0,3.8l-3.4,0.6c-2,0.3-3.2-0.3-3.7-2l-0.9-3.1L87.1,56.7z
                   M72.3,55.5l0-3.4l-2.1,0.4l0,3.4L72.3,55.5z M72.3,64.3l0-3.6l-2.1,0.4l0,3.6L72.3,64.3z M85.3,70.9l-1.1,3.7
                  c-0.5,1.8-1.8,2.9-3.8,3.2L79.2,78l-0.9-4.4l0.5-0.1c0.6-0.1,1-0.5,1.2-1.3l0.2-0.6L85.3,70.9z M83.9,64.6
                  c0.1,0.6,0.4,0.8,1,0.7l0.6-0.1l0,3.8l-2.7,0.5C81,69.8,79.9,69,79.4,67l-0.7-1.7l5.1-0.9L83.9,64.6z M93,58l0,13.8
                  c0,0.9-0.3,1.8-0.9,2.8c-0.7,1-1.5,1.6-2.5,1.7L86,76.9l-0.6-4.4l0.1,0c0.4-0.1,0.7-0.3,0.9-0.6c0.2-0.3,0.3-0.7,0.3-1.1l0-11.8
                  L93,58z M90.8,49l-0.9,0.2l-1,2.6l2.6-0.4L90.8,49z M100.7,61.6l-0.9,2.7c-0.5,1.7-1.7,2.7-3.7,3l-1.6,0.3l-0.9-3.7l0.8-0.1
                  c0.5-0.1,0.9-0.5,1.1-1.2l0-0.1L100.7,61.6z M99.5,69.2c0.1,0.6,0.5,0.8,1.1,0.7l0.3,0l0,4.5l-2.4,0.4c-1.8,0.3-3-0.6-3.6-2.7
                  l-0.6-2.8l5.1-0.8L99.5,69.2z" />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav navbar-top">
        <li class="nav-item dropdown" *ngFor="let menu of menuData">
          <a class="nav-link" [routerLink]="['/productCategory', menu.tagID]" data-toggle="dropdown">
            <img [src]="'/api/images/' + menu.image">
            {{menu.text | googleTranslate | async}}
          </a>
          <div class="dropdown-menu dropdown-large" *ngIf="menu.Country.length > 0">
            <div class="w-100 px-3">
              <div class="row justify-content-between">
                <div class="dropdown-section" *ngFor="let c of menu.Country">
                  <h6>{{c.name | googleTranslate | async}}</h6>
                  <ul class="list-unstyled list-inline mr-2">
                    <li class="list-inline-item ml-1" *ngFor="let r of c.Regions"><a
                        [routerLink]="['/productCategory', menu.tagID, r.ID]">{{r.name | googleTranslate | async}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> <!-- dropdown-mega-menu.// -->
        </li>
      </ul>
    </div>

    <div class="right-navbar">
      <ul class="nav navbar-nav float-right user-nav">
        <li *ngIf="!isLogin" class="nav-item text-white dropdown">
          <a class="nav-link" role="button" (click)="login('member')">
            <div><img src="assets/images/menu-user.png"></div>
            <span style="margin-left: 15px;">{{"會員登入" | translate}}</span>
          </a>
        </li>
        <li *ngIf="isLogin" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" role="button" aria-expanded="false">
            <div>
              <span data-toggle="modal" data-target="#modal-login">
                <img src="assets/images/menu-user.png" alt="">
              </span>
            </div>{{"會員專區" | translate}}
          </a>
          <div class="dropdown-menu dropdown-menu-right dropdown-style" role="menu" *ngIf="userInfo">
            <div class="user-info">
              <img src="assets/images/avatar.jpg" class="rounded-circle" *ngIf="!userInfo.picture">
              <img src="{{userInfo.picture}}" class="rounded-circle" *ngIf="userInfo.picture">
              <span class="user-name">{{userInfo.lastName}}{{userInfo.firstName}}</span>
            </div>
            <a class="dropdown-item" [routerLink]="['/member/groupOrder']"
              (click)="memberService.pathSelected = 'groupOrder'">
              <span class="material-icons">flight_takeoff</span>{{"旅遊訂單" | translate}}</a>
            <a class="dropdown-item" [routerLink]="['/member/order']"
              (click)="memberService.pathSelected = 'order'">
              <span class="material-icons">list_alt</span>{{"票卷訂單" | translate}}</a>
            <a class="dropdown-item" [routerLink]="['/member/saved']"
              (click)="memberService.pathSelected = 'saved'"><span class="material-icons">loyalty</span>
              {{"我的收藏" | translate}}</a>
            <a class="dropdown-item" [routerLink]="['/member/coupon']"
              (click)="memberService.pathSelected = 'coupon'"><span
                class="material-icons-outlined">local_activity</span>{{"折扣碼" | translate}}</a>
            <a class="dropdown-item" [routerLink]="['/member/setting']"
              (click)="memberService.pathSelected = 'setting'"><span
                class="material-icons-outlined">settings</span>{{"帳號設定" | translate}}</a>
            <a class="dropdown-item text-gray-m clickable" (click)="loginService.logout()"><span
                class="material-icons">exit_to_app</span>{{"登出" | translate}}</a>
          </div>
        </li>
      </ul>
      <ul *ngIf="!isLogin" class="nav navbar-nav float-right">
        <li class="nav-item text-white">
          <a class="nav-link" role="button" (click)="login('cart')">
            <div class="position-relative">
              <img src="assets/images/menu-cart.png" alt="">
              <span class="badge badge-cart rounded-circle" *ngIf="cartCount > 0">{{cartCount}}</span>
            </div>{{"購物車" | translate}}
          </a>
        </li>
      </ul>
      <ul *ngIf="isLogin" class="nav navbar-nav float-right">
        <li class="nav-item text-white">
          <a class="nav-link" [routerLink]="['/pay/cart']" role="button" aria-expanded="false">
            <div class="position-relative">
              <img src="assets/images/menu-cart.png" alt="">
              <span class="badge badge-cart rounded-circle" *ngIf="cartCount > 0">{{cartCount}}</span>
            </div>{{"購物車" | translate}}
          </a>
        </li>
      </ul>
      <div class="float-right lan-currency-nav">
        <!-- <div class="dropdown mt-2">
          <a class="dropdown-toggle text-white" type="button" id="dropdownMenu2" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            {{selectedLanguage}}
          </a>
          <div class="dropdown-menu dropdown-menu-right dropdown-style" aria-labelledby="dropdownMenu2">
            <a class="dropdown-item" type="button" *ngFor="let lang of languageList"
              (click)="changeLanguage(lang.value); this.selectedLanguage = lang.name">{{lang.name}}</a>
          </div>
        </div> -->
        <div class="dropdown mt-2">
          <a class="dropdown-toggle text-white" id="dropdownMenu3" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">{{selectedCurrency}}</a>
          <div class="dropdown-menu dropdown-menu-right dropdown-style" aria-labelledby="dropdownMenu3">
            <a class="dropdown-item" *ngFor="let currency of currencyList"
              (click)="changeCurrency(currency)">{{currency}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<nav class="navbar navbar-mobile top-header clearfix">
  <a class="navbar-brand" [routerLink]="['/']">
    <div class="navbar-logo">

      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 253 134" xml:space="preserve">
        <style type="text/css">
          .st0 {
            fill: #FFFFFF;
          }

          .st1 {
            fill: #2AABD9;
          }

          .st2 {
            fill: #076BAB;
          }
        </style>
        <g>
          <g>
            <g>
              <g>
                <path class="st0" d="M83.6,113l-0.2,8.8l-23.2,4.8l0.7-35.2l23.2-4.8l-0.2,8.8l-10.9,2.2l-0.1,4.4l7.6-1.6l-0.2,8.8l-7.6,1.6
              l-0.1,4.3L83.6,113z" />
              </g>
              <g>
                <path class="st0"
                  d="M111.9,106.4l-0.2,7.3l-26.1,5.1l11.9-24.1l-10.5,2l0.2-7.3l25.7-5L101,108.5L111.9,106.4z" />
              </g>
              <g>
                <path class="st0"
                  d="M113.4,102.7l0.3-12.9l22-4.3l-0.3,12.9l-5.5,1.1l-0.4,16.3l-11,2.1l0.4-16.3L113.4,102.7z" />
              </g>
              <g>
                <path class="st0" d="M150.5,108c-3.5,0.7-6.5-0.2-8.9-2.6c-2.4-2.4-3.5-5.8-3.4-10c0.1-4.2,1.4-8,3.9-11.4
              c2.5-3.4,5.6-5.4,9.1-6.1c3.5-0.7,6.5,0.2,8.9,2.6c2.4,2.4,3.5,5.7,3.4,9.9c-0.1,4.2-1.4,8-3.9,11.4
              C157.1,105.3,154,107.3,150.5,108z M150.9,89.1c-0.7,0.1-1.3,0.5-1.8,1.2c-0.5,0.7-0.7,1.4-0.7,2.3c0,0.9,0.2,1.6,0.6,2.1
              c0.5,0.5,1,0.7,1.7,0.5c0.7-0.1,1.3-0.5,1.8-1.2c0.5-0.7,0.7-1.4,0.7-2.3c0-0.9-0.2-1.6-0.6-2.1C152.2,89.1,151.6,89,150.9,89.1
              z" />
              </g>
              <g>
                <path class="st0" d="M190.8,79l-5.5,9.8c-2.2-0.8-4.2-1-6.1-0.7c-1.2,0.2-2.2,0.8-3,1.7c-0.8,0.9-1.2,2-1.2,3.2
              c-0.1,2.4,1.2,3.3,3.9,2.8l0.1-4.6l11-2.1l-0.4,15.7c-4.4,1.2-7.4,1.9-9,2.2c-6.1,1.2-10.2,0.5-12.5-2.1
              c-2.3-2.6-3.4-5.9-3.3-9.9c0.1-2.8,0.7-5.4,1.8-8c1.2-2.5,2.8-4.6,4.9-6.3c2.1-1.7,4.6-2.8,7.4-3.3c2-0.4,4-0.4,6.2-0.2
              C187.2,77.5,189.1,78.1,190.8,79z" />
              </g>
              <g>
                <path class="st0" d="M205.9,96.4c-3.5,0.7-6.5-0.2-8.9-2.6c-2.4-2.4-3.5-5.8-3.4-10c0.1-4.2,1.4-8,3.9-11.4
              c2.5-3.4,5.6-5.4,9.1-6.1c3.5-0.7,6.5,0.2,8.9,2.6c2.4,2.4,3.5,5.7,3.4,9.9c-0.1,4.2-1.4,8-3.9,11.4
              C212.5,93.6,209.5,95.7,205.9,96.4z M206.4,77.5c-0.7,0.1-1.3,0.5-1.8,1.2c-0.5,0.7-0.7,1.4-0.7,2.3c0,0.9,0.2,1.6,0.6,2.1
              c0.5,0.5,1,0.7,1.7,0.5c0.7-0.1,1.3-0.5,1.8-1.2c0.5-0.7,0.7-1.4,0.7-2.3c0-0.9-0.2-1.6-0.6-2.1
              C207.7,77.5,207.1,77.3,206.4,77.5z" />
              </g>
            </g>
            <g>
              <g>
                <path class="st1" d="M60.1,130.2c-0.8,0-1.6-0.3-2.2-0.8c-0.8-0.7-1.3-1.7-1.3-2.8l0.7-35.2c0-1.6,1.2-3,2.8-3.4l23.2-4.8
              c1-0.2,2.1,0.1,2.9,0.7c0.8,0.7,1.3,1.7,1.3,2.8l-0.2,8.8c0,1.6-1.2,3-2.8,3.4l-0.9,0.2c0.2,0.5,0.3,1,0.3,1.5l-0.2,8.8
              c0,0,0,0.1,0,0.1c0.7,0.1,1.4,0.3,2,0.8c0.8,0.7,1.3,1.7,1.3,2.8l-0.2,8.8c0,1.6-1.2,3-2.8,3.4l-23.2,4.8
              C60.6,130.1,60.4,130.2,60.1,130.2z M64.3,94.3l-0.6,28.1l16.2-3.3l0-1.7l-6.6,1.4c-1,0.2-2.1-0.1-2.9-0.7
              c-0.8-0.7-1.3-1.7-1.3-2.8l0.1-4.3c0-1.6,1.2-3,2.8-3.4l4.8-1l0-1.7l-3.3,0.7c-1,0.2-2.1-0.1-2.9-0.7s-1.3-1.7-1.3-2.8l0.1-4.4
              c0-1.6,1.2-3,2.8-3.4l8.1-1.7l0-1.7L64.3,94.3z" />
              </g>
              <g>
                <path class="st1" d="M85.6,122.3c-1.1,0-2.1-0.5-2.8-1.4c-0.8-1.1-0.9-2.5-0.4-3.7l8.8-17.7l-3.5,0.7c-1,0.2-2.1-0.1-2.9-0.8
              c-0.8-0.7-1.3-1.7-1.2-2.8l0.2-7.3c0-1.6,1.2-3,2.8-3.4l25.7-5c1.3-0.2,2.6,0.3,3.5,1.3s0.9,2.5,0.4,3.7l-8.8,17.7l3.9-0.8
              c1-0.2,2.1,0.1,2.9,0.8c0.8,0.7,1.3,1.7,1.2,2.8l-0.2,7.3c0,1.6-1.2,3-2.8,3.4l-26.1,5.1C86.1,122.3,85.8,122.3,85.6,122.3z
               M97.5,91.2c1.1,0,2.1,0.5,2.8,1.4c0.8,1.1,0.9,2.5,0.4,3.7L91.9,114l16.4-3.2l0-0.2l-6.5,1.3c-1.3,0.3-2.6-0.3-3.5-1.3
              c-0.8-1.1-0.9-2.5-0.4-3.7l8.8-17.7l-16,3.1l0,0.2l6.2-1.2C97.1,91.2,97.3,91.2,97.5,91.2z" />
              </g>
              <g>
                <path class="st1" d="M118.5,121.3c-0.8,0-1.6-0.3-2.3-0.8c-0.8-0.7-1.3-1.7-1.2-2.8l0.3-11.9l-1.2,0.2c-1,0.2-2.1-0.1-2.9-0.8
              c-0.8-0.7-1.3-1.7-1.2-2.8l0.3-12.9c0-1.6,1.2-3,2.8-3.4l22-4.3c1-0.2,2.1,0.1,2.9,0.8c0.8,0.7,1.3,1.7,1.2,2.8l-0.3,12.9
              c0,1.6-1.2,3-2.8,3.4l-2.8,0.5l-0.3,13.4c0,1.6-1.2,3-2.8,3.4l-11,2.1C118.9,121.3,118.7,121.3,118.5,121.3z M118.9,98.1
              c0.8,0,1.6,0.3,2.3,0.8c0.8,0.7,1.3,1.7,1.2,2.8l-0.3,11.9l3.9-0.8l0.3-13.4c0-1.6,1.2-3,2.8-3.4l2.8-0.5l0.1-5.8l-15,2.9
              l-0.1,5.8l1.2-0.2C118.4,98.1,118.6,98.1,118.9,98.1z" />
              </g>
              <g>
                <path class="st1" d="M148.2,111.8c-3.5,0-6.6-1.3-9.2-3.9c-3.1-3.1-4.6-7.3-4.4-12.5c0.1-4.9,1.7-9.4,4.6-13.4
              c3.1-4.1,6.8-6.6,11.2-7.5c4.7-0.9,8.9,0.3,12.1,3.6c3.1,3.1,4.6,7.3,4.4,12.5c-0.1,4.9-1.7,9.4-4.6,13.4
              c-3.1,4.1-6.8,6.6-11.3,7.5C150.1,111.7,149.2,111.8,148.2,111.8z M153.5,81.2c-0.5,0-1,0.1-1.6,0.2c-2.7,0.5-5,2.1-7,4.8
              c-2.1,2.8-3.2,5.9-3.2,9.4c-0.1,3.3,0.7,5.7,2.4,7.5c1.6,1.6,3.3,2.1,5.7,1.6h0c2.7-0.5,5-2.1,7-4.8c2.1-2.8,3.2-5.9,3.3-9.4
              c0.1-3.2-0.7-5.7-2.4-7.4C156.4,81.8,155.1,81.2,153.5,81.2z M150.3,98.8c-1.5,0-2.8-0.6-3.9-1.7c-0.7-0.8-1.6-2.3-1.6-4.5
              c0-1.6,0.5-3,1.4-4.3c1-1.4,2.4-2.3,4-2.6c0,0,0,0,0,0c1.9-0.4,3.7,0.2,5,1.6c0.7,0.8,1.6,2.3,1.6,4.5c0,1.6-0.5,3-1.4,4.3
              c-1,1.4-2.4,2.3-4,2.6C151.1,98.7,150.7,98.8,150.3,98.8z M150,91.8c0,0.1,0.1,0.1,0.1,0.2c0.4,0.5,1.1,0.6,1.5,0.5l-0.1-0.3
              c-0.4-0.4-1-0.5-1.4-0.4C150,91.8,150,91.8,150,91.8z M151.5,92.2l0.1,0.3c0.1,0,0.1,0,0.2,0C151.7,92.4,151.7,92.4,151.5,92.2
              C151.6,92.3,151.6,92.3,151.5,92.2z" />
              </g>
              <g>
                <path class="st1" d="M175.5,111.1c-4.4,0-7.8-1.3-10-3.8c-2.9-3.2-4.3-7.4-4.2-12.3c0.1-3.2,0.8-6.4,2.1-9.3
              c1.4-3,3.4-5.6,5.9-7.6c2.5-2,5.5-3.4,8.9-4c2.3-0.5,4.8-0.5,7.3-0.2c2.5,0.3,4.8,1,6.9,2.1c0.8,0.4,1.5,1.2,1.7,2.1
              c0.3,0.9,0.2,1.9-0.3,2.7l-2.9,5.1c0.4,0.1,0.9,0.4,1.2,0.7c0.8,0.7,1.3,1.7,1.2,2.8l-0.4,15.7c0,1.6-1.1,2.9-2.6,3.3
              c-4.6,1.2-7.6,1.9-9.2,2.2C179.2,110.9,177.3,111.1,175.5,111.1z M182.5,80.6c-1,0-2,0.1-2.9,0.3c-2.3,0.4-4.2,1.3-5.9,2.6
              c-1.7,1.3-2.9,3-3.9,5c-1,2.1-1.5,4.3-1.5,6.6c-0.1,3.2,0.7,5.6,2.4,7.5c1.7,2,6,1.6,9.2,1c1.2-0.2,3.3-0.7,6.2-1.5l0.2-8.7
              l-3.9,0.8l0,1.8c0,1.6-1.2,3-2.8,3.4c-2.5,0.5-4.5,0.1-6-1.2c-1-0.8-2.1-2.4-2.1-5.1c0-2,0.8-3.9,2-5.4c1.3-1.5,3-2.5,5-2.9
              c1.6-0.3,3.3-0.3,5,0l2.1-3.8c-0.4-0.1-0.7-0.1-1.1-0.2C183.9,80.6,183.2,80.6,182.5,80.6z" />
              </g>
              <g>
                <path class="st1" d="M203.7,100.1c-3.5,0-6.6-1.3-9.2-3.9c-3.1-3.1-4.6-7.3-4.4-12.5c0.1-4.9,1.7-9.4,4.6-13.4
              c3.1-4.1,6.8-6.6,11.2-7.5c4.7-0.9,8.9,0.3,12.1,3.6c3.1,3.1,4.6,7.3,4.4,12.5c-0.1,4.9-1.7,9.4-4.6,13.4
              c-3.1,4.1-6.8,6.6-11.3,7.5C205.6,100,204.6,100.1,203.7,100.1z M208.9,69.5c-0.5,0-1,0.1-1.6,0.2c-2.7,0.5-5,2.1-7,4.8
              c-2.1,2.8-3.2,5.9-3.2,9.4c-0.1,3.3,0.7,5.7,2.4,7.5c1.6,1.6,3.3,2.1,5.7,1.6h0c2.7-0.5,5-2.1,7-4.8c2.1-2.8,3.2-5.9,3.3-9.4
              c0.1-3.2-0.7-5.7-2.4-7.4C211.9,70.1,210.5,69.5,208.9,69.5z M205.8,87.1c-1.5,0-2.9-0.6-3.9-1.7c-0.7-0.8-1.6-2.2-1.6-4.5
              c0-1.6,0.5-3,1.4-4.3c1-1.4,2.4-2.3,4-2.6c1.9-0.4,3.7,0.2,5,1.6c0.7,0.8,1.6,2.2,1.6,4.5c0,1.6-0.5,3-1.4,4.3
              c-1,1.4-2.4,2.3-4,2.6C206.5,87.1,206.2,87.1,205.8,87.1z M205.4,80.2c0,0.1,0.1,0.1,0.1,0.2c0.4,0.5,1.1,0.6,1.5,0.5
              c0.1,0,0.1,0,0.2,0c0-0.1-0.1-0.1-0.1-0.2c-0.4-0.5-1.1-0.6-1.5-0.5C205.5,80.1,205.5,80.2,205.4,80.2z M206.4,77.5L206.4,77.5
              L206.4,77.5z" />
              </g>
            </g>
            <g>
              <g>
                <path class="st0" d="M83.6,113l-0.2,8.8l-23.2,4.8l0.7-35.2l23.2-4.8l-0.2,8.8l-10.9,2.2l-0.1,4.4l7.6-1.6l-0.2,8.8l-7.6,1.6
              l-0.1,4.3L83.6,113z" />
              </g>
              <g>
                <path class="st0"
                  d="M111.9,106.4l-0.2,7.3l-26.1,5.1l11.9-24.1l-10.5,2l0.2-7.3l25.7-5L101,108.5L111.9,106.4z" />
              </g>
              <g>
                <path class="st0"
                  d="M113.4,102.7l0.3-12.9l22-4.3l-0.3,12.9l-5.5,1.1l-0.4,16.3l-11,2.1l0.4-16.3L113.4,102.7z" />
              </g>
              <g>
                <path class="st0" d="M150.5,108c-3.5,0.7-6.5-0.2-8.9-2.6c-2.4-2.4-3.5-5.8-3.4-10c0.1-4.2,1.4-8,3.9-11.4
              c2.5-3.4,5.6-5.4,9.1-6.1c3.5-0.7,6.5,0.2,8.9,2.6c2.4,2.4,3.5,5.7,3.4,9.9c-0.1,4.2-1.4,8-3.9,11.4
              C157.1,105.3,154,107.3,150.5,108z M150.9,89.1c-0.7,0.1-1.3,0.5-1.8,1.2c-0.5,0.7-0.7,1.4-0.7,2.3c0,0.9,0.2,1.6,0.6,2.1
              c0.5,0.5,1,0.7,1.7,0.5c0.7-0.1,1.3-0.5,1.8-1.2c0.5-0.7,0.7-1.4,0.7-2.3c0-0.9-0.2-1.6-0.6-2.1C152.2,89.1,151.6,89,150.9,89.1
              z" />
              </g>
              <g>
                <path class="st0" d="M190.8,79l-5.5,9.8c-2.2-0.8-4.2-1-6.1-0.7c-1.2,0.2-2.2,0.8-3,1.7c-0.8,0.9-1.2,2-1.2,3.2
              c-0.1,2.4,1.2,3.3,3.9,2.8l0.1-4.6l11-2.1l-0.4,15.7c-4.4,1.2-7.4,1.9-9,2.2c-6.1,1.2-10.2,0.5-12.5-2.1
              c-2.3-2.6-3.4-5.9-3.3-9.9c0.1-2.8,0.7-5.4,1.8-8c1.2-2.5,2.8-4.6,4.9-6.3c2.1-1.7,4.6-2.8,7.4-3.3c2-0.4,4-0.4,6.2-0.2
              C187.2,77.5,189.1,78.1,190.8,79z" />
              </g>
              <g>
                <path class="st0" d="M205.9,96.4c-3.5,0.7-6.5-0.2-8.9-2.6c-2.4-2.4-3.5-5.8-3.4-10c0.1-4.2,1.4-8,3.9-11.4
              c2.5-3.4,5.6-5.4,9.1-6.1c3.5-0.7,6.5,0.2,8.9,2.6c2.4,2.4,3.5,5.7,3.4,9.9c-0.1,4.2-1.4,8-3.9,11.4
              C212.5,93.6,209.5,95.7,205.9,96.4z M206.4,77.5c-0.7,0.1-1.3,0.5-1.8,1.2c-0.5,0.7-0.7,1.4-0.7,2.3c0,0.9,0.2,1.6,0.6,2.1
              c0.5,0.5,1,0.7,1.7,0.5c0.7-0.1,1.3-0.5,1.8-1.2c0.5-0.7,0.7-1.4,0.7-2.3c0-0.9-0.2-1.6-0.6-2.1
              C207.7,77.5,207.1,77.3,206.4,77.5z" />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path class="st1" d="M105.2,81.9c-0.9,0-1.8-0.3-2.6-0.8c-1-0.7-1.6-1.8-1.8-2.9l-1-6.3c-0.3-1.7,0.4-3.3,1.7-4.3l-0.1-23.7
                  c-0.8-0.8-1.3-2-1.3-3.2l0-6.5c0-2.2,1.6-4.1,3.7-4.5l1.7-0.3c0-2.2,1.6-4,3.7-4.4l7.5-1.3c1.3-0.2,2.6,0.1,3.7,1
                  c0.6,0.5,1.1,1.1,1.3,1.9l0.3,0c0.1,0,0.3,0,0.4-0.1l0.1-0.5c0.3-1.9,1.8-3.3,3.7-3.7l7.5-1.3c1.4-0.2,2.8,0.2,3.8,1.1
                  l10.1-1.8c1.3-0.2,2.6,0.1,3.7,1c1,0.8,1.6,2.1,1.6,3.4l0,6.5c0,1.3-0.5,2.5-1.4,3.3l0,2.2c0,1.3-0.2,2.6-0.6,3.7
                  c0.5,0.8,0.8,1.8,0.8,2.7L151,54.3c0,1.6-0.2,3.4-0.7,5c0.3,0.2,0.7,0.4,0.9,0.6c1,0.8,1.6,2.1,1.6,3.4l0,6.5
                  c0,2.2-1.6,4.1-3.7,4.5l-6.3,1.1c-1.7,0.3-3.2,0.2-4.6-0.2c-0.7,0.7-1.6,1.3-2.6,1.5l-5.4,0.9c-2.4,0.4-4.2,0-5.5-0.6
                  c-1.3,1.3-3.1,2.2-5.2,2.5l-4.1,0.7c-0.9,0.1-1.8,0-2.6-0.3c-1,0.7-2.2,1.1-3.5,1.4l-3.5,0.6
                  C105.7,81.9,105.4,81.9,105.2,81.9z M129.7,69.8L129.7,69.8L129.7,69.8z" />
                  </g>
                  <g>
                    <path class="st1" d="M155.8,72.8c-1,0-2.1-0.4-2.9-1c-1-0.8-1.6-2.1-1.6-3.4l-0.1-18.6c-1.3-0.6-2.2-1.8-2.5-3.3l-1.1-6.3
                  c-0.2-1.2,0.1-2.4,0.7-3.4c0.2-0.4,0.5-0.7,0.9-0.9c-0.3-0.5-0.5-1-0.6-1.5l-1.1-6.3c-0.2-1.2,0.1-2.4,0.7-3.4
                  s1.7-1.6,2.9-1.9l0.9-0.2l1.2-3.2c0.6-1.5,1.9-2.6,3.4-2.8l7.5-1.3c0.8-0.1,1.7-0.1,2.4,0.2c0.2-0.1,0.4-0.1,0.6-0.2l27-4.7
                  c1.3-0.2,2.6,0.1,3.7,1c1,0.8,1.6,2.1,1.6,3.4l0,6.5c0,1.3-0.6,2.6-1.5,3.4c0.3,0.2,0.6,0.3,0.8,0.6c1,0.8,1.6,2.1,1.6,3.4
                  l0,6.5c0,2.2-1.6,4.1-3.7,4.5l-0.2,0l0.1,17.2c0,0,0,0.1,0,0.1c0,1.9-0.7,3.9-1.9,5.7c0,0,0,0.1-0.1,0.1
                  c-1.5,2.2-3.7,3.6-6.2,4l-13.9,2.4c-1.2,0.2-2.4-0.1-3.4-0.8c-1-0.7-1.6-1.8-1.8-3l-0.9-5.9c-0.4-2.4,1.3-4.7,3.7-5.1l8-1.4
                  l-0.1-10.5l-12.5,2.2l0.1,21.9c0,2.2-1.6,4.1-3.7,4.5l-7.5,1.3C156.3,72.8,156.1,72.8,155.8,72.8z" />
                  </g>
                  <g>
                    <path class="st1" d="M207.2,65c-1,0-2.1-0.4-2.9-1c-0.6-0.5-1-1.1-1.3-1.8c-0.1,0-0.3,0.1-0.4,0.1l-0.7,0.1
                  c-1.2,0.2-2.4-0.1-3.4-0.8c-1-0.7-1.6-1.8-1.8-2.9l-1-6.3c-0.2-1.5,0.3-3,1.3-4l-0.1-14.6c-0.7-0.8-1.1-1.9-1.1-3l0-6.5
                  c0-1.3,0.5-2.5,1.4-3.3l0-5.8c0-2.2,1.6-4.1,3.7-4.5l17.7-3.1c1.3-0.2,2.6,0.1,3.7,1c0.7,0.6,1.2,1.3,1.4,2.2l1.5-0.3l0-0.9
                  c0-2.2,1.6-4.1,3.7-4.5l7.5-1.3c1.3-0.2,2.6,0.1,3.7,1c0.8,0.7,1.4,1.7,1.6,2.8l0.9-0.2c1.3-0.2,2.6,0.1,3.7,1
                  c1,0.8,1.6,2.1,1.6,3.4l0,6.5c0,2.2-1.6,4.1-3.7,4.5l-2.4,0.4l0.1,20.1l1.8-0.3c1.3-0.2,2.6,0.1,3.7,1c1,0.8,1.6,2.1,1.6,3.4
                  l0,6.5c0,2.2-1.6,4.1-3.7,4.5l-22.2,3.9c-1.3,0.2-2.6-0.1-3.7-1c-0.2-0.1-0.3-0.3-0.5-0.5c-0.6,1.4-1.8,2.5-3.4,2.8L208,65
                  C207.7,65,207.4,65,207.2,65z M199.5,47.2C199.5,47.2,199.5,47.2,199.5,47.2L199.5,47.2z M224.6,29.6c0,0.1,0,0.1,0,0.2
                  l0.1,16.6l0.6-0.1l-0.1-18.1C225.1,28.7,224.9,29.2,224.6,29.6z M232.8,17.6c0.1,0.1,0.2,0.2,0.2,0.3c0.2-0.6,0.6-1.1,1-1.5
                  c-0.1-0.1-0.2-0.2-0.2-0.3C233.6,16.6,233.2,17.1,232.8,17.6z" />
                  </g>
                </g>
                <g>
                  <g>
                    <path class="st1" d="M105.2,81.9c-0.9,0-1.8-0.3-2.6-0.8c-1-0.7-1.6-1.8-1.8-2.9l-1-6.3c-0.3-1.7,0.4-3.3,1.7-4.3l-0.1-23.7
                  c-0.8-0.8-1.3-2-1.3-3.2l0-6.5c0-2.2,1.6-4.1,3.7-4.5l1.7-0.3c0-2.2,1.6-4,3.7-4.4l7.5-1.3c1.3-0.2,2.6,0.1,3.7,1
                  c0.6,0.5,1.1,1.1,1.3,1.9l0.3,0c0.1,0,0.3,0,0.4-0.1l0.1-0.5c0.3-1.9,1.8-3.3,3.7-3.7l7.5-1.3c1.4-0.2,2.8,0.2,3.8,1.1
                  l10.1-1.8c1.3-0.2,2.6,0.1,3.7,1c1,0.8,1.6,2.1,1.6,3.4l0,6.5c0,1.3-0.5,2.5-1.4,3.3l0,2.2c0,1.3-0.2,2.6-0.6,3.7
                  c0.5,0.8,0.8,1.8,0.8,2.7L151,54.3c0,1.6-0.2,3.4-0.7,5c0.3,0.2,0.7,0.4,0.9,0.6c1,0.8,1.6,2.1,1.6,3.4l0,6.5
                  c0,2.2-1.6,4.1-3.7,4.5l-6.3,1.1c-1.7,0.3-3.2,0.2-4.6-0.2c-0.7,0.7-1.6,1.3-2.6,1.5l-5.4,0.9c-2.4,0.4-4.2,0-5.5-0.6
                  c-1.3,1.3-3.1,2.2-5.2,2.5l-4.1,0.7c-0.9,0.1-1.8,0-2.6-0.3c-1,0.7-2.2,1.1-3.5,1.4l-3.5,0.6
                  C105.7,81.9,105.4,81.9,105.2,81.9z M129.7,69.8L129.7,69.8L129.7,69.8z" />
                  </g>
                  <g>
                    <path class="st1" d="M155.8,72.8c-1,0-2.1-0.4-2.9-1c-1-0.8-1.6-2.1-1.6-3.4l-0.1-18.6c-1.3-0.6-2.2-1.8-2.5-3.3l-1.1-6.3
                  c-0.2-1.2,0.1-2.4,0.7-3.4c0.2-0.4,0.5-0.7,0.9-0.9c-0.3-0.5-0.5-1-0.6-1.5l-1.1-6.3c-0.2-1.2,0.1-2.4,0.7-3.4
                  s1.7-1.6,2.9-1.9l0.9-0.2l1.2-3.2c0.6-1.5,1.9-2.6,3.4-2.8l7.5-1.3c0.8-0.1,1.7-0.1,2.4,0.2c0.2-0.1,0.4-0.1,0.6-0.2l27-4.7
                  c1.3-0.2,2.6,0.1,3.7,1c1,0.8,1.6,2.1,1.6,3.4l0,6.5c0,1.3-0.6,2.6-1.5,3.4c0.3,0.2,0.6,0.3,0.8,0.6c1,0.8,1.6,2.1,1.6,3.4
                  l0,6.5c0,2.2-1.6,4.1-3.7,4.5l-0.2,0l0.1,17.2c0,0,0,0.1,0,0.1c0,1.9-0.7,3.9-1.9,5.7c0,0,0,0.1-0.1,0.1
                  c-1.5,2.2-3.7,3.6-6.2,4l-13.9,2.4c-1.2,0.2-2.4-0.1-3.4-0.8c-1-0.7-1.6-1.8-1.8-3l-0.9-5.9c-0.4-2.4,1.3-4.7,3.7-5.1l8-1.4
                  l-0.1-10.5l-12.5,2.2l0.1,21.9c0,2.2-1.6,4.1-3.7,4.5l-7.5,1.3C156.3,72.8,156.1,72.8,155.8,72.8z" />
                  </g>
                  <g>
                    <path class="st1" d="M207.2,65c-1,0-2.1-0.4-2.9-1c-0.6-0.5-1-1.1-1.3-1.8c-0.1,0-0.3,0.1-0.4,0.1l-0.7,0.1
                  c-1.2,0.2-2.4-0.1-3.4-0.8c-1-0.7-1.6-1.8-1.8-2.9l-1-6.3c-0.2-1.5,0.3-3,1.3-4l-0.1-14.6c-0.7-0.8-1.1-1.9-1.1-3l0-6.5
                  c0-1.3,0.5-2.5,1.4-3.3l0-5.8c0-2.2,1.6-4.1,3.7-4.5l17.7-3.1c1.3-0.2,2.6,0.1,3.7,1c0.7,0.6,1.2,1.3,1.4,2.2l1.5-0.3l0-0.9
                  c0-2.2,1.6-4.1,3.7-4.5l7.5-1.3c1.3-0.2,2.6,0.1,3.7,1c0.8,0.7,1.4,1.7,1.6,2.8l0.9-0.2c1.3-0.2,2.6,0.1,3.7,1
                  c1,0.8,1.6,2.1,1.6,3.4l0,6.5c0,2.2-1.6,4.1-3.7,4.5l-2.4,0.4l0.1,20.1l1.8-0.3c1.3-0.2,2.6,0.1,3.7,1c1,0.8,1.6,2.1,1.6,3.4
                  l0,6.5c0,2.2-1.6,4.1-3.7,4.5l-22.2,3.9c-1.3,0.2-2.6-0.1-3.7-1c-0.2-0.1-0.3-0.3-0.5-0.5c-0.6,1.4-1.8,2.5-3.4,2.8L208,65
                  C207.7,65,207.4,65,207.2,65z M199.5,47.2C199.5,47.2,199.5,47.2,199.5,47.2L199.5,47.2z M224.6,29.6c0,0.1,0,0.1,0,0.2
                  l0.1,16.6l0.6-0.1l-0.1-18.1C225.1,28.7,224.9,29.2,224.6,29.6z M232.8,17.6c0.1,0.1,0.2,0.2,0.2,0.3c0.2-0.6,0.6-1.1,1-1.5
                  c-0.1-0.1-0.2-0.2-0.2-0.3C233.6,16.6,233.2,17.1,232.8,17.6z" />
                  </g>
                </g>
                <g>
                  <g>
                    <path class="st1" d="M105.2,81.9c-0.9,0-1.8-0.3-2.6-0.8c-1-0.7-1.6-1.8-1.8-2.9l-1-6.3c-0.3-1.7,0.4-3.3,1.7-4.3l-0.1-23.7
                  c-0.8-0.8-1.3-2-1.3-3.2l0-6.5c0-2.2,1.6-4.1,3.7-4.5l1.7-0.3c0-2.2,1.6-4,3.7-4.4l7.5-1.3c1.3-0.2,2.6,0.1,3.7,1
                  c0.6,0.5,1.1,1.1,1.3,1.9l0.3,0c0.1,0,0.3,0,0.4-0.1l0.1-0.5c0.3-1.9,1.8-3.3,3.7-3.7l7.5-1.3c1.4-0.2,2.8,0.2,3.8,1.1
                  l10.1-1.8c1.3-0.2,2.6,0.1,3.7,1c1,0.8,1.6,2.1,1.6,3.4l0,6.5c0,1.3-0.5,2.5-1.4,3.3l0,2.2c0,1.3-0.2,2.6-0.6,3.7
                  c0.5,0.8,0.8,1.8,0.8,2.7L151,54.3c0,1.6-0.2,3.4-0.7,5c0.3,0.2,0.7,0.4,0.9,0.6c1,0.8,1.6,2.1,1.6,3.4l0,6.5
                  c0,2.2-1.6,4.1-3.7,4.5l-6.3,1.1c-1.7,0.3-3.2,0.2-4.6-0.2c-0.7,0.7-1.6,1.3-2.6,1.5l-5.4,0.9c-2.4,0.4-4.2,0-5.5-0.6
                  c-1.3,1.3-3.1,2.2-5.2,2.5l-4.1,0.7c-0.9,0.1-1.8,0-2.6-0.3c-1,0.7-2.2,1.1-3.5,1.4l-3.5,0.6
                  C105.7,81.9,105.4,81.9,105.2,81.9z M129.7,69.8L129.7,69.8L129.7,69.8z" />
                  </g>
                  <g>
                    <path class="st1" d="M155.8,72.8c-1,0-2.1-0.4-2.9-1c-1-0.8-1.6-2.1-1.6-3.4l-0.1-18.6c-1.3-0.6-2.2-1.8-2.5-3.3l-1.1-6.3
                  c-0.2-1.2,0.1-2.4,0.7-3.4c0.2-0.4,0.5-0.7,0.9-0.9c-0.3-0.5-0.5-1-0.6-1.5l-1.1-6.3c-0.2-1.2,0.1-2.4,0.7-3.4
                  s1.7-1.6,2.9-1.9l0.9-0.2l1.2-3.2c0.6-1.5,1.9-2.6,3.4-2.8l7.5-1.3c0.8-0.1,1.7-0.1,2.4,0.2c0.2-0.1,0.4-0.1,0.6-0.2l27-4.7
                  c1.3-0.2,2.6,0.1,3.7,1c1,0.8,1.6,2.1,1.6,3.4l0,6.5c0,1.3-0.6,2.6-1.5,3.4c0.3,0.2,0.6,0.3,0.8,0.6c1,0.8,1.6,2.1,1.6,3.4
                  l0,6.5c0,2.2-1.6,4.1-3.7,4.5l-0.2,0l0.1,17.2c0,0,0,0.1,0,0.1c0,1.9-0.7,3.9-1.9,5.7c0,0,0,0.1-0.1,0.1
                  c-1.5,2.2-3.7,3.6-6.2,4l-13.9,2.4c-1.2,0.2-2.4-0.1-3.4-0.8c-1-0.7-1.6-1.8-1.8-3l-0.9-5.9c-0.4-2.4,1.3-4.7,3.7-5.1l8-1.4
                  l-0.1-10.5l-12.5,2.2l0.1,21.9c0,2.2-1.6,4.1-3.7,4.5l-7.5,1.3C156.3,72.8,156.1,72.8,155.8,72.8z" />
                  </g>
                  <g>
                    <path class="st1" d="M207.2,65c-1,0-2.1-0.4-2.9-1c-0.6-0.5-1-1.1-1.3-1.8c-0.1,0-0.3,0.1-0.4,0.1l-0.7,0.1
                  c-1.2,0.2-2.4-0.1-3.4-0.8c-1-0.7-1.6-1.8-1.8-2.9l-1-6.3c-0.2-1.5,0.3-3,1.3-4l-0.1-14.6c-0.7-0.8-1.1-1.9-1.1-3l0-6.5
                  c0-1.3,0.5-2.5,1.4-3.3l0-5.8c0-2.2,1.6-4.1,3.7-4.5l17.7-3.1c1.3-0.2,2.6,0.1,3.7,1c0.7,0.6,1.2,1.3,1.4,2.2l1.5-0.3l0-0.9
                  c0-2.2,1.6-4.1,3.7-4.5l7.5-1.3c1.3-0.2,2.6,0.1,3.7,1c0.8,0.7,1.4,1.7,1.6,2.8l0.9-0.2c1.3-0.2,2.6,0.1,3.7,1
                  c1,0.8,1.6,2.1,1.6,3.4l0,6.5c0,2.2-1.6,4.1-3.7,4.5l-2.4,0.4l0.1,20.1l1.8-0.3c1.3-0.2,2.6,0.1,3.7,1c1,0.8,1.6,2.1,1.6,3.4
                  l0,6.5c0,2.2-1.6,4.1-3.7,4.5l-22.2,3.9c-1.3,0.2-2.6-0.1-3.7-1c-0.2-0.1-0.3-0.3-0.5-0.5c-0.6,1.4-1.8,2.5-3.4,2.8L208,65
                  C207.7,65,207.4,65,207.2,65z M199.5,47.2C199.5,47.2,199.5,47.2,199.5,47.2L199.5,47.2z M224.6,29.6c0,0.1,0,0.1,0,0.2
                  l0.1,16.6l0.6-0.1l-0.1-18.1C225.1,28.7,224.9,29.2,224.6,29.6z M232.8,17.6c0.1,0.1,0.2,0.2,0.2,0.3c0.2-0.6,0.6-1.1,1-1.5
                  c-0.1-0.1-0.2-0.2-0.2-0.3C233.6,16.6,233.2,17.1,232.8,17.6z" />
                  </g>
                </g>
              </g>
              <g>
                <path class="st1" d="M30.6,108.2c-1.1,0-2.1-0.4-2.9-1.1c-1-0.9-1.6-2.1-1.6-3.5l0.1-11.3l-0.8,4.5c0,0.1-0.1,0.3-0.1,0.4
              c-1.3,4.8-4.4,7.8-8.8,8.5l-4.4,0.7c-1.2,0.2-2.4-0.1-3.4-0.8c-1-0.7-1.6-1.8-1.8-3L5.7,95c-0.4-2.4,1.3-4.7,3.7-5.1l0.1,0
              L12,74.4L11,74.5c-1.3,0.2-2.6-0.1-3.6-1c-1-0.9-1.6-2.1-1.6-3.4l0-8c0-1.6,0.9-3.1,2.2-3.9c-0.2-0.4-0.3-0.8-0.4-1.3l-1.1-7.8
              c-0.3-2.4,1.3-4.7,3.7-5.1l35.1-5.9c0.3-1.9,1.8-3.4,3.7-3.7l8.5-1.4c1.3-0.2,2.6,0.1,3.6,1s1.6,2.1,1.6,3.4l0,5.1
              c0,0,0,0.1,0,0.1c-0.1,2.2-0.7,4.4-2,6.4c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c0.7,0.2,1.3,0.5,1.8,0.9
              c1,0.9,1.6,2.1,1.6,3.4l0,8c0,2.2-1.6,4.1-3.8,4.4l-1.9,0.3l2.1,6.1c0.7,0.1,1.4,0.5,2,1c1,0.9,1.6,2.1,1.6,3.4l0,8
              c0,2.2-1.6,4.1-3.8,4.4l-5.5,0.9c-3.9,0.6-8.6-1.1-10.1-8l-1-3.4l-0.1,23.2c0,2.2-1.6,4.1-3.8,4.4l-8.5,1.4
              C31.1,108.2,30.8,108.2,30.6,108.2z M54,82L54,82L54,82z M52.9,78.6l0.4,1.5c0,0.1,0.1,0.2,0.1,0.3c0.1,0.5,0.2,0.8,0.3,1.1
              l1.3-0.2l0-0.3C54.2,80.4,53.4,79.6,52.9,78.6z M61.2,75.2L61.2,75.2L61.2,75.2z M32.9,62.7c0.3,0.2,0.6,0.3,0.8,0.6
              c0.5,0.4,0.8,0.9,1.1,1.4c0.2-1.4,1.1-2.7,2.4-3.4c-0.3-0.2-0.6-0.3-0.8-0.6c-0.5-0.4-0.8-0.9-1.1-1.4
              C35.1,60.8,34.2,62,32.9,62.7z M33.2,49.4c0.2,0.1,0.4,0.2,0.5,0.4c0.5,0.4,0.8,0.9,1.1,1.4c0.1-0.8,0.5-1.6,1-2.2L33.2,49.4z
               M53.2,43.9c-0.3,0.4-0.6,0.8-0.9,1.1l0.2,0c0.1,0,0.3-0.1,0.8-0.6c0.3-0.5,0.5-1.1,0.6-1.6C53.6,43.2,53.4,43.5,53.2,43.9z
               M47.2,37.9C47.2,37.9,47.2,37.9,47.2,37.9L47.2,37.9z" />
              </g>
              <g>
                <path class="st1"
                  d="M64.6,85c-0.9,0-1.9-0.3-2.7-0.9c-1-0.7-1.6-1.8-1.8-3l-0.8-5.8c-0.2-1.4,0.2-2.7,1.1-3.6l0-24.3
              c0,0,0,0,0,0c-0.7-0.8-1-1.8-1-2.9l0-5.2c0-2.2,1.6-4.1,3.8-4.4l1.9-0.3c0.6-1.2,1.8-2.1,3.2-2.3l6.3-1.1c1.3-0.2,2.6,0.1,3.6,1
              c0.1,0.1,0.1,0.1,0.2,0.2l6.8-1.1c0.6-1.2,1.8-2.1,3.2-2.3l6.3-1c1.3-0.2,2.6,0.2,3.6,1c0.1,0.1,0.1,0.1,0.2,0.2l1.5-0.2
              c1.3-0.2,2.6,0.1,3.6,1c1,0.9,1.6,2.1,1.6,3.4l0,5.2c0,1-0.3,2-0.9,2.7c0.6,0.8,0.9,1.7,0.9,2.7l0,4.2c0,0.3,0,0.6-0.1,0.9
              c0.1,0.3,0.1,0.6,0.1,0.9l0,4.7c0,0.5-0.1,0.9-0.2,1.4c0,0.2,0.1,0.5,0.1,0.7l0,3.8c0,0.2,0,0.4,0,0.6c0.1,0.7,0,1.4-0.2,2.1
              l-0.9,2.6c-0.1,0.3-0.2,0.6-0.3,0.8c1,0.9,1.6,2.1,1.6,3.4l0,4.5c0,2.2-1.6,4.1-3.8,4.4l-2.4,0.4c-1.7,0.3-3.3,0-4.6-0.6
              c-1.5,1.4-3,1.9-4.2,2.1l-3.6,0.6c-0.8,0.1-1.6,0.1-2.3-0.2c-1,0.5-2.1,0.9-3.3,1.1l-1.3,0.2c-0.8,0.1-1.5,0.1-2.2-0.2
              c-0.9,0.5-2,0.9-3.1,1.1l-2,0.3c-0.8,0.1-1.5,0.1-2.2-0.2c-0.9,0.5-2,0.9-3.2,1.1l-1.8,0.3C65.1,84.9,64.9,85,64.6,85z" />
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <path class="st2" d="M116,67.8l-0.1-16.9l-2.9,0.5l0.1,19.8c0,1.1-0.4,2.2-1.2,3.4c-0.8,1.2-1.9,1.9-3.3,2.2l-3.5,0.6l-1-6.3
                  l0.1,0c0.6-0.1,1-0.3,1.3-0.7c0.2-0.4,0.4-0.8,0.4-1.3l-0.2-28.7l-1.3,0.2l0-6.5l5.5-1l0-3.7l7.5-1.3l0,3.7l5.2-0.9l0,6.5
                  l-9.7,1.7l0,5.8l2.9-0.5l3.6-0.6l3.6-0.6l0.2,26.4c0,3.1-1.5,5-4.4,5.5l-4.1,0.7l-1-6.3l1.2-0.2C115.6,69.1,116,68.6,116,67.8
                  z M131.9,34.1c-0.8,1.3-2,2.1-3.7,2.4L125,37l-1.1-5.9l0.8-0.1c0.8-0.1,1.4-0.7,1.6-1.7l0.4-2.5l7.5-1.3l-0.5,1.9l14.4-2.5
                  l0,6.5L131.9,34.1z M125.1,69.2l-0.2-30.4l3.6-0.6l3.7-0.6l6.2-1.1c0.7-0.1,1.1-0.6,1.1-1.4l0-0.8l7.2-1.3l0,3.7
                  c0,3.1-1.5,5-4.4,5.5L132.3,44l0.1,20.9c0,0.8,0.4,1.1,1.1,1l1.3-0.2l0,6.5l-5.4,0.9C126.6,73.6,125.1,72.3,125.1,69.2z
                   M142.4,54.6c0.4-0.1,0.6-0.7,0.6-1.8l0.4-9.2L147,43l-0.5,11.2c0,3.6-0.8,5.6-2.3,5.8l-1,0.2l0.6,2.8c0.2,0.7,0.8,1,1.9,0.8
                  l2.7-0.5l0,6.5l-6.3,1.1c-3,0.5-4.8-0.8-5.4-3.9l-3.6-21.3l7.2-1.3l1.9,10.1L142.4,54.6z" />
                  </g>
                  <g>
                    <path class="st2" d="M165.1,19.8l-3.1,8.7c-1,2.6-2.6,4-4.8,4.4l-4.1,0.7l-1.1-6.3l1.3-0.2c1.4-0.2,2.3-0.9,2.7-1.9l1.6-4.1
                  L165.1,19.8z M163.2,42.2l0.1,24.8l-7.5,1.3l-0.1-23.1l-2.5,0.4l-1.1-6.3l1.5-0.3c1.4-0.2,2.2-0.9,2.7-1.9l1.2-3.7l7.5-1.3
                  l-2.8,8.3c-0.4,0.8-0.7,1.4-1.1,2L163.2,42.2z M192.3,57.2c0,1.1-0.4,2.2-1.2,3.4c-0.8,1.2-1.9,1.9-3.3,2.2L174,65.2l-0.9-5.9
                  l10.1-1.8c0.6-0.1,1-0.3,1.3-0.7c0.2-0.4,0.4-0.8,0.4-1.3l-0.1-17.9l-18.2,3.2l0-6.5l29.7-5.2l0,6.5l-4,0.7L192.3,57.2z
                   M195.2,15.2l0,6.5l-27,4.7l0-6.5L195.2,15.2z" />
                  </g>
                  <g>
                    <path class="st2" d="M205,32.5l0.1,21.3c0,0.8-0.3,1.7-0.8,2.5c-0.6,0.9-1.4,1.4-2.4,1.6l-0.7,0.1l-1-6.3l0.1,0
                  c0.4-0.1,0.7-0.3,1-0.6c0.2-0.3,0.3-0.6,0.3-1l-0.1-17L205,32.5z M200.3,30.8l0-6.5l20.5-3.6l0,6.5l-6.3,1.1l0.2,30.9
                  l-7.5,1.3L207,29.6L200.3,30.8z M219.3,12.1l0,6.1l-17.7,3.1l0-6.1L219.3,12.1z M220.1,29.8l0.1,24l-3.6,0.6l-0.1-24
                  L220.1,29.8z M237.4,48.8l7-1.2l0,6.5l-22.2,3.9l0-6.5l7.6-1.3l-0.2-29.3l-6.5,1.1l0-6.5l6.5-1.1l0-4.7l7.5-1.3l0,4.7l6.1-1.1
                  l0,6.5l-6.1,1.1L237.4,48.8z" />
                  </g>
                </g>
                <g>
                  <g>
                    <path class="st0" d="M116,67.8l-0.1-16.9l-2.9,0.5l0.1,19.8c0,1.1-0.4,2.2-1.2,3.4c-0.8,1.2-1.9,1.9-3.3,2.2l-3.5,0.6l-1-6.3
                  l0.1,0c0.6-0.1,1-0.3,1.3-0.7c0.2-0.4,0.4-0.8,0.4-1.3l-0.2-28.7l-1.3,0.2l0-6.5l5.5-1l0-3.7l7.5-1.3l0,3.7l5.2-0.9l0,6.5
                  l-9.7,1.7l0,5.8l2.9-0.5l3.6-0.6l3.6-0.6l0.2,26.4c0,3.1-1.5,5-4.4,5.5l-4.1,0.7l-1-6.3l1.2-0.2C115.6,69.1,116,68.6,116,67.8
                  z M131.9,34.1c-0.8,1.3-2,2.1-3.7,2.4L125,37l-1.1-5.9l0.8-0.1c0.8-0.1,1.4-0.7,1.6-1.7l0.4-2.5l7.5-1.3l-0.5,1.9l14.4-2.5
                  l0,6.5L131.9,34.1z M125.1,69.2l-0.2-30.4l3.6-0.6l3.7-0.6l6.2-1.1c0.7-0.1,1.1-0.6,1.1-1.4l0-0.8l7.2-1.3l0,3.7
                  c0,3.1-1.5,5-4.4,5.5L132.3,44l0.1,20.9c0,0.8,0.4,1.1,1.1,1l1.3-0.2l0,6.5l-5.4,0.9C126.6,73.6,125.1,72.3,125.1,69.2z
                   M142.4,54.6c0.4-0.1,0.6-0.7,0.6-1.8l0.4-9.2L147,43l-0.5,11.2c0,3.6-0.8,5.6-2.3,5.8l-1,0.2l0.6,2.8c0.2,0.7,0.8,1,1.9,0.8
                  l2.7-0.5l0,6.5l-6.3,1.1c-3,0.5-4.8-0.8-5.4-3.9l-3.6-21.3l7.2-1.3l1.9,10.1L142.4,54.6z" />
                  </g>
                  <g>
                    <path class="st0" d="M165.1,19.8l-3.1,8.7c-1,2.6-2.6,4-4.8,4.4l-4.1,0.7l-1.1-6.3l1.3-0.2c1.4-0.2,2.3-0.9,2.7-1.9l1.6-4.1
                  L165.1,19.8z M163.2,42.2l0.1,24.8l-7.5,1.3l-0.1-23.1l-2.5,0.4l-1.1-6.3l1.5-0.3c1.4-0.2,2.2-0.9,2.7-1.9l1.2-3.7l7.5-1.3
                  l-2.8,8.3c-0.4,0.8-0.7,1.4-1.1,2L163.2,42.2z M192.3,57.2c0,1.1-0.4,2.2-1.2,3.4c-0.8,1.2-1.9,1.9-3.3,2.2L174,65.2l-0.9-5.9
                  l10.1-1.8c0.6-0.1,1-0.3,1.3-0.7c0.2-0.4,0.4-0.8,0.4-1.3l-0.1-17.9l-18.2,3.2l0-6.5l29.7-5.2l0,6.5l-4,0.7L192.3,57.2z
                   M195.2,15.2l0,6.5l-27,4.7l0-6.5L195.2,15.2z" />
                  </g>
                  <g>
                    <path class="st0" d="M205,32.5l0.1,21.3c0,0.8-0.3,1.7-0.8,2.5c-0.6,0.9-1.4,1.4-2.4,1.6l-0.7,0.1l-1-6.3l0.1,0
                  c0.4-0.1,0.7-0.3,1-0.6c0.2-0.3,0.3-0.6,0.3-1l-0.1-17L205,32.5z M200.3,30.8l0-6.5l20.5-3.6l0,6.5l-6.3,1.1l0.2,30.9
                  l-7.5,1.3L207,29.6L200.3,30.8z M219.3,12.1l0,6.1l-17.7,3.1l0-6.1L219.3,12.1z M220.1,29.8l0.1,24l-3.6,0.6l-0.1-24
                  L220.1,29.8z M237.4,48.8l7-1.2l0,6.5l-22.2,3.9l0-6.5l7.6-1.3l-0.2-29.3l-6.5,1.1l0-6.5l6.5-1.1l0-4.7l7.5-1.3l0,4.7l6.1-1.1
                  l0,6.5l-6.1,1.1L237.4,48.8z" />
                  </g>
                </g>
              </g>
              <g>
                <path class="st0"
                  d="M25.3,70.9L21,96.1c-0.8,3.1-2.5,4.8-5.2,5.2l-4.4,0.7l-1.2-7.8l1.7-0.3c1-0.2,1.6-0.9,1.9-2.1l3.1-19.6
              L25.3,70.9z M10.3,70.1l0-8l20.5-3.4l0-5.5l-18.7,3.1L11,48.5l37-6.2c0.7-0.1,1.2-0.4,1.5-0.9c0.3-0.4,0.4-0.9,0.4-1.6l0-0.9
              l8.5-1.4l0,5.1c0,1.4-0.5,2.8-1.3,4.2c-1,1.5-2.3,2.4-3.8,2.6l-13.9,2.3l0,5.5l20.3-3.4l0,8l-20.3,3.4l-0.2,37l-8.5,1.4l0.2-37
              L10.3,70.1z M56.8,76.1c0.2,1,0.8,1.3,1.8,1.2l1-0.2l0,8L54.1,86c-2.6,0.4-4.3-1.1-5-4.7L45,67.6l8.5-1.4L56.8,76.1z" />
              </g>
              <g>
                <path class="st0" d="M87.1,56.7l-1.1,3c-0.5,2-1.6,3.1-3.4,3.4l-3.5,0.6l-0.9-3.7l1.4-0.2c0.5-0.1,0.9-0.6,1.1-1.5l0.2-0.6
              l-2.2,0.4l0-4.7l4-0.7l0.9-2.6l-2.1,0.4c-0.7,0.6-1.7,1-2.9,1.2l-0.2,0l-0.7-3.1l0,25.3c0,2.9-1.3,4.5-3.7,4.9l-2,0.3L71,73.5
              l0.3-0.1c0.6-0.1,0.9-0.5,0.9-1.3l0-2.6l-2.2,0.4l0,5.4c0,2.9-1.3,4.5-3.7,4.9l-1.8,0.3l-0.8-5.8l0.2,0c0.6-0.1,0.9-0.5,0.9-1.3
              l0-26l12.6-2.1l0,2.5l0.8-0.1c0.5-0.1,0.9-0.4,1.1-0.9l0.9-2.1L84,44l-0.5,1.7l1.8-0.3l1.1-3l2.9-0.5l0-1.6l-13.9,2.3l0,1.6
              l-6.3,1.1l0-1.6l-5.2,0.9l0-5.2l5.2-0.9l0-1.8l6.3-1.1l0,1.8L89.3,35l0-1.8l6.3-1V34l5.3-0.9l0,5.2l-5.3,0.9v1.6l-2.8,0.5
              l-1.1,3l4-0.7l-0.3-1.6l3.6-0.6l0.8,1.9c0.1,0.4,0.5,0.5,1.1,0.4l0.1,0l0,4.2l-0.8,0.1c-1,0.2-1.9,0.1-2.6-0.3L96.7,48l0.7,2.3
              l3.5-0.6l0,4.7l-2.2,0.4L99,56c0.1,0.5,0.5,0.7,1.2,0.6l0.5-0.1l0,3.8l-3.4,0.6c-2,0.3-3.2-0.3-3.7-2l-0.9-3.1L87.1,56.7z
               M72.3,55.5l0-3.4l-2.1,0.4l0,3.4L72.3,55.5z M72.3,64.3l0-3.6l-2.1,0.4l0,3.6L72.3,64.3z M85.3,70.9l-1.1,3.7
              c-0.5,1.8-1.8,2.9-3.8,3.2L79.2,78l-0.9-4.4l0.5-0.1c0.6-0.1,1-0.5,1.2-1.3l0.2-0.6L85.3,70.9z M83.9,64.6
              c0.1,0.6,0.4,0.8,1,0.7l0.6-0.1l0,3.8l-2.7,0.5C81,69.8,79.9,69,79.4,67l-0.7-1.7l5.1-0.9L83.9,64.6z M93,58l0,13.8
              c0,0.9-0.3,1.8-0.9,2.8c-0.7,1-1.5,1.6-2.5,1.7L86,76.9l-0.6-4.4l0.1,0c0.4-0.1,0.7-0.3,0.9-0.6c0.2-0.3,0.3-0.7,0.3-1.1l0-11.8
              L93,58z M90.8,49l-0.9,0.2l-1,2.6l2.6-0.4L90.8,49z M100.7,61.6l-0.9,2.7c-0.5,1.7-1.7,2.7-3.7,3l-1.6,0.3l-0.9-3.7l0.8-0.1
              c0.5-0.1,0.9-0.5,1.1-1.2l0-0.1L100.7,61.6z M99.5,69.2c0.1,0.6,0.5,0.8,1.1,0.7l0.3,0l0,4.5l-2.4,0.4c-1.8,0.3-3-0.6-3.6-2.7
              l-0.6-2.8l5.1-0.8L99.5,69.2z" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  </a>

  <div class="ml-auto">
    <ul class="nav user-nav">
      <li class="nav-item">
        <a class="nav-link" (click)="showSearch()">
          <span class="material-icons">search</span>
        </a>
      </li>
      <!-- <li class="nav-item">
        <div class="dropdown mt-3 pl-2 mr-2 pr-2 border rounded">
          <a class="dropdown-toggle text-white" type="button" id="dropdownMenu2" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            {{selectedLanguage}}
          </a>
          <div class="dropdown-menu dropdown-menu-right dropdown-style" aria-labelledby="dropdownMenu2">
            <a class="dropdown-item" type="button" *ngFor="let lang of languageList"
              (click)="changeLanguage(lang.value); this.selectedLanguage = lang.name">{{lang.name}}</a>
          </div>
        </div>
      </li> -->
      <li class="nav-item">
        <div class="dropdown mt-3 pl-2 pr-2 border rounded">
          <a class="dropdown-toggle text-white" id="dropdownMenu3" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            {{selectedCurrency}}
          </a>
          <div class="dropdown-menu dropdown-menu-right dropdown-style" aria-labelledby="dropdownMenu3">
            <a class="dropdown-item" *ngFor="let currency of currencyList"
              (click)="changeCurrency(currency)">{{currency}}</a>
          </div>
        </div>
      </li>
      <li class="nav-item">
        <a id="js-mobile-nav" class="nav-link" href="javascript:void(0)">
          <div id="nav-icon">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
      </li>
    </ul>
  </div>

</nav>

<!-- mobile menu -->
<div class="mobile-menu scroll-container" style="display: none;">
  <div>
    <div *ngIf="!isLogin" class="mb-4">
      <div class="col">
        <a href="#" class="btn btn-warning btn-block" role="button" (click)="login('member')">{{"會員登入" | translate}}</a>
      </div>
    </div>

    <div class="user-nav" *ngIf="isLogin">
      <div class="user-info d-flex align-items-center" *ngIf="userInfo">
        <img src="assets/images/avatar.jpg" class="rounded-circle" width="60" height="60" *ngIf="!userInfo.picture">
        <img src="{{userInfo.picture}}" class="rounded-circle" width="60" height="60" *ngIf="userInfo.picture">
        <span class="user-name">{{userInfo.lastName}}{{userInfo.firstName}}</span>
      </div>
      <a class="dropdown-item" [routerLink]="['/member/groupOrder']"
        (click)="menuToggle(); memberService.pathSelected = 'groupOrder'">
        <span class="material-icons">flight_takeoff</span>{{"旅遊訂單" | translate}}</a>
      <a class="dropdown-item" [routerLink]="['/member/order']"
        (click)="menuToggle(); memberService.pathSelected = 'order'">
        <span class="material-icons">list_alt</span>{{"票卷訂單" | translate}}</a>
      <a class="dropdown-item" [routerLink]="['/member/saved']"
        (click)="menuToggle(); memberService.pathSelected = 'saved'"><span class="material-icons">loyalty</span>
        {{"我的收藏" | translate}}</a>
      <a class="dropdown-item" [routerLink]="['/member/coupon']"
        (click)="menuToggle(); memberService.pathSelected = 'coupon'"><span
          class="material-icons-outlined">local_activity</span>{{"折扣碼" | translate}}</a>
      <a class="dropdown-item" [routerLink]="['/member/setting']"
        (click)="menuToggle(); memberService.pathSelected = 'setting'"><span
          class="material-icons-outlined">settings</span>
        {{"帳號設定" | translate}}</a>
      <a class="dropdown-item text-gray-m clickable" (click)="loginService.logout()"><span
          class="material-icons">exit_to_app</span>{{"登出" | translate}}</a>
    </div>

    <div class="divider my-4"></div>

    <div class="row mx-1 mb-2">
      <div class="col-4" *ngFor="let menu of menuData">
        <a (click)="menuToggle()" [routerLink]="['/productCategory', menu.tagID]" class="mobile-menu-nav">
          <img [src]="'/api/images/' + menu.image" class="rounded-circle">
          <div>{{menu.text | googleTranslate | async}}</div>
        </a>
      </div>
    </div>
    <div class="divider"></div>
    <div class="">
      <ul class="sidebar-social list-unstyled">
        <li>
          <a (click)="menuToggle()" href="https://line.me/ti/p/%40unz8114z" target="_blank">
            <img src="assets/images/sidebar-icon-line.svg">
            {{"加入好友" | translate}}
          </a>
        </li>
        <li>
          <a (click)="menuToggle()" href="https://www.facebook.com/EZTOGOxTRAVEL/" target="_blank">
            <img src="assets/images/sidebar-icon-fb.svg">
            {{"追蹤粉絲團" | translate}}
          </a>
        </li>
        <li>
          <a (click)="menuToggle()" href="https://www.instagram.com/txg.eztogo/" target="_blank">
            <img src="assets/images/sidebar-icon-ig.svg">
            {{"追蹤IG" | translate}}
          </a>
        </li>
        <li>
          <a (click)="menuToggle()" href="https://www.youtube.com/channel/UCLtsAEEDCN0Hbbbzg3w5Jkg/featured"
            target="_blank">
            <img src="assets/images/sidebar-icon-yt.svg">
            {{"旅遊影音" | translate}}
          </a>
        </li>
        <li>
          <a (click)="menuToggle()" href="https://journeydiary2017.blogspot.com/" target="_blank">
            <img src="assets/images/sidebar-icon-blogger.svg">
            {{"旅遊大小事" | translate}}
          </a>
        </li>
      </ul>
    </div>
    <div class="divider"></div>
    <div class="">
      <ul class="list-unstyled sidebar-link pl-4 mt-n1">
        <li class="py-2 pt-4">
          <a (click)="menuToggle()" [routerLink]="['/about']">{{"關於我們" | translate}}</a>
        </li>
        <li class="py-2">
          <a (click)="menuToggle()" [routerLink]="['/contact']">{{"聯絡我們" | translate}}</a>
        </li>
        <li class="py-2">
          <a (click)="menuToggle()" [routerLink]="['/faq']">{{"常見問題" | translate}}</a>
        </li>
      </ul>
    </div>
  </div>

</div>

<!-- mobile fixed bottom tab -->
<div class="mobile-tab">
  <div class="d-flex justify-content-around">
    <a [routerLink]="['/']">
      <img src="assets/images/icon-home.svg" width="28">
      <div>{{"首頁" | translate}}</div>
    </a>
    <a [routerLink]="['/hot']">
      <img src="assets/images/icon-hot.svg" width="28">
      <div>{{"熱門行程" | translate}}</div>
    </a>
    <a [routerLink]="['/productCategory']">
      <img src="assets/images/icon-explore.svg" width="28">
      <div>{{exploreWorldTitle | googleTranslate | async}}</div>
    </a>
    <a data-toggle="modal" (click)="login('cart')" *ngIf="!isLogin">
      <span class="position-relative">
        <img src="assets/images/icon-cart.svg" width="28">
        <span class="badge badge-cart rounded-circle" *ngIf="cartCount > 0">{{cartCount}}</span>
      </span>
      <div>{{"購物車" | translate}}</div>
    </a>
    <a [routerLink]="['/pay/cart']" *ngIf="isLogin">
      <span class="position-relative">
        <img src="assets/images/icon-cart.svg" width="28">
        <span class="badge badge-cart rounded-circle" *ngIf="cartCount > 0">{{cartCount}}</span>
      </span>
      <div>{{"購物車" | translate}}</div>
    </a>
    <a href="https://line.me/ti/p/%40unz8114z" target="_blank">
      <img src="assets/images/icon-contact.svg" width="28">
      <div>{{"線上諮詢" | translate}}</div>
    </a>
  </div>

</div>

<div class="mask-overlay" style="display: none;"></div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GroupTourService {

  constructor(private http: HttpClient) { }

  getTourGroupApplicantsByPlanOptionId(id: number) {
    return this.http.get('/api/site/tourGroupApplicants/byPlanOptionId/' + id);
  }

  getTourGroupControlByPlanOptionId(id: number) {
    return this.http.get('/api/site/tourGroupControl/byPlanOptionId/' + id);
  }

  getTourGroupApplicantsByPlanSpecId(id: number) {
    return this.http.get('/api/site/tourGroupApplicants/byPlanSpecId/' + id);
  }

  getFlightById(id: number) {
    return this.http.get('/api/site/flight/' + id);
  }

  getAirlineById(id: number) {
    return this.http.get('/api/site/airline/' + id);
  }

  getDayOfWeek(dateString: string): string {
    const date = new Date(dateString);
    const dayOfWeekText = ['日', '一', '二', '三', '四', '五', '六'];
    return dayOfWeekText[date.getDay()];
  }
}

import { LayoutService } from './../../modules/layout/layout.service';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'currencyExchange',
})

export class CurrencyExchangePipe implements PipeTransform {
  private rateInfo: any;
  private exchangedPrice: number;

  constructor(private layoutService: LayoutService) { }

  transform(price: number, code?: boolean, dollarSign?: boolean): Observable<any> {

    return this.layoutService.currentRate.pipe(
      map((currency: any) => {
        this.rateInfo = currency;

        if (this.rateInfo && this.rateInfo.currency === 'TWD') {
          this.exchangedPrice = price;
        } else if (this.rateInfo && this.rateInfo.currency) {
          this.exchangedPrice = price * this.rateInfo[this.rateInfo.currency];

          // 大於1:10的匯率顯示金額為小數點兩位
          if (this.rateInfo[this.rateInfo.currency] < 0.1) {
            this.exchangedPrice = parseFloat(this.exchangedPrice.toFixed(2));
          } else {
            this.exchangedPrice = Math.round(this.exchangedPrice);
          }
        } else {
          this.rateInfo.currency = 'TWD';
          this.exchangedPrice = price;
        }

        const codeString = code ? '<small>' + this.rateInfo.currency + '</small> ' : '';
        const dollarSignString = dollarSign ? '$' : '';

        return codeString + dollarSignString + new Intl.NumberFormat('en-US').format(this.exchangedPrice);
      }
      ));
  }
}


<div class="content-cart">
  <div class="container-fluid content-wrapper position-relative" *ngIf="groupControl">
    <h5 class="pl-3">{{'選擇參加人數' | translate}}</h5>
    <div class="group-cart-bg">
      <div class="pay-section">
        <div>
          <h5>{{'產品' | translate}}</h5>
          <div class="bg-white mb-3">
            <div class="row no-gutters cart-item align-items-center">
              <div class="col-3 col-md-2 text-center">
                <div class="product-img img-bg"
                  style="background-image: url(/api/images/{{groupControl.Product.coverImg}});"></div>
              </div>
              <div class="col-9 col-md-9 d-md-flex">
                <div class="flex-grow-1">
                  <div class="product-name">{{groupControl.Product.title | googleTranslate | async}}</div>
                  <div class="text-muted">{{groupControl.PlanOption.ProductPlan.name | googleTranslate | async}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pay-section ">
        <h5>{{'出發日期' | translate}}</h5>
        <div class="bg-white mb-3 p-2">
          <div class="row no-gutters ">
            <div class="col-12 ">
              <div class="row">
                <div class="col-12 col-md-auto my-2">
                  <div class="d-inline-flex badge-pill badge-group-cart badge-primary px-3 py-1">{{'去程' | translate}}
                  </div>
                  <div class="d-inline-flex ml-1">
                    {{groupControl.PlanOption.date}}（{{groupTourService.getDayOfWeek(groupControl.PlanOption.date) |
                    translate}}）
                  </div>
                </div>
                <div class="col-12 col-md-auto my-2">
                  <div class="d-inline-flex badge-pill badge-group-cart badge-primary px-3 py-1">{{'回程' | translate}}
                  </div>
                  <div class="d-inline-flex ml-1">
                    {{endDate | date:'yyyy/MM/dd'}}（{{groupTourService.getDayOfWeek(endDate) | translate}}）
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pay-section"
        *ngIf="groupControl.defaultInfo.businessClassCount > 0 || groupControl.defaultInfo.firstClassCount > 0">
        <h5>{{'艙等' | translate}}</h5>
        <div class="bg-white mb-3 px-4 pt-4 pb-2">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="row">
                <mat-radio-group [(ngModel)]="applicant.cabinClass" (change)="countAmount('cabin')">
                  <mat-radio-button value="經濟艙" class="ml-2 mr-4 h5"
                    *ngIf="groupControl.defaultInfo.economyClassCount > 0">經濟艙</mat-radio-button>
                  <mat-radio-button value="商務艙" class="ml-4 mr-4 h5"
                    *ngIf="groupControl.defaultInfo.businessClassCount > 0">商務艙</mat-radio-button>
                  <mat-radio-button value="頭等艙" class="ml-4 h5"
                    *ngIf="groupControl.defaultInfo.firstClassCount > 0">頭等艙</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="pay-section ">
        <h5>{{'報名人數' | translate}}</h5>
        <div *ngIf="typePrices && typePrices.length > 0">
          <div class="bg-white mb-3 p-2" *ngFor="let type of typePrices; let i = index;">
            <div class="row no-gutters">
              <div class="col-12 bg-light mb-2">
                <h5 class="mb-0 p-2 border-bottom border-secondary">
                  <span *ngIf="type.roomType">{{type.roomType | googleTranslate | async}}</span>
                  <small class="ml-2 d-block d-md-inline-block text-secondary font-weight-normal">
                    (尚餘{{cabinClassRemain.common}}席可報名，超訂席次將轉為候補)
                  </small>
                </h5>
              </div>
              <div class="col-md-3 mb-4 pl-2" *ngFor="let detail of type.detail; let j = index;">
                <h5>{{detail.name | googleTranslate | async}}
                  <span class="product-price">
                    <span [innerHTML]="detail.price | currencyExchange:true:false | async"></span>
                  </span>
                </h5>
                <div class="counter">
                  <button class="counter-btn disabled" data-original-title="總共至少 1 人"
                    (click)="countAmount('minus', i, j);">
                    <span class="material-icons">remove_circle_outline</span>
                  </button>
                  <input type="text" readonly="readonly" class="counter-num" [min]="0" [(ngModel)]="detail.quantity"
                    readonly>
                  <button class="counter-btn" (click)="countAmount('add', i, j);">
                    <span class="material-icons">add_circle_outline</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="pay-section" *ngIf="goFlight || backFlight">
        <h5>{{'預計航班' | translate}}</h5>
        <div class="bg-white mb-3 p-3">
          <div class="row no-gutters mb-5" *ngIf="goFlight">
            <div class="col-12 flight-info-heading">
              <div class="d-inline-flex badge-pill badge-group-cart badge-primary px-3 py-1 ">{{'去程' | translate}}</div>
              <div class="a-to-b-tit">
                {{goFlight.AirportDeparture.name | googleTranslate | async}}{{goFlight.AirportDeparture.code}}
                ➝
                {{goFlight.AirportArrival.name | googleTranslate | async}}{{goFlight.AirportArrival.code}}
              </div>
            </div>
            <div class="col-12">
              <div class="slider-flight-info-list">
                <div class="row">
                  <div class="col-sm-3 col-10 align-items-center d-flex">
                    {{goFlight.airlineName | googleTranslate | async}}({{goFlight.code}})
                  </div>
                  <div class="col-sm-8 col-12">
                    <div class="flight-fromto">
                      <div class="row">
                        <div class="col-4">
                          <div class="station-info">
                            <div class="station-info-date">
                              {{groupControl.PlanOption.date | date:'MM/dd'}}
                              ({{groupTourService.getDayOfWeek(groupControl.PlanOption.date) | translate}})
                            </div>
                            <div class="station-info-time">{{goFlight.departureTime}}</div>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="flight-duration">
                            <div class="flight-duration-time text-center">{{goFlight.timeDifference}}</div>
                            <div class="flight-duration-arrow"></div>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="station-info">
                            <div class="station-info-date">
                              {{goFlight.arrivalDate | date:'MM/dd'}}
                              ({{groupTourService.getDayOfWeek(goFlight.arrivalDate) | translate}})
                            </div>
                            <div class="station-info-time">{{goFlight.arrivalTime}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row no-gutters" *ngIf="backFlight">
            <div class="col-12 flight-info-heading">
              <div class="d-inline-flex badge-pill badge-group-cart badge-primary px-3 py-1 ">{{'回程' | translate}}</div>
              <div class="a-to-b-tit">
                {{backFlight.AirportDeparture.name | googleTranslate | async}}{{backFlight.AirportDeparture.code}}
                ➝
                {{backFlight.AirportArrival.name | googleTranslate | async}}{{backFlight.AirportArrival.code}}
              </div>

            </div>
            <div class="col-12">
              <div class="slider-flight-info-list">
                <div class="row">
                  <div class="col-sm-3 col-10 align-items-center d-flex">
                    {{backFlight.airlineName | googleTranslate | async}}({{backFlight.code}})
                  </div>
                  <div class="col-sm-8 col-12">
                    <div class="flight-fromto">
                      <div class="row">
                        <div class="col-4">
                          <div class="station-info">
                            <div class="station-info-date">
                              {{backFlight.departureDate | date:'yyyy/MM/dd'}}
                              ({{groupTourService.getDayOfWeek(backFlight.departureDate) | translate}})
                            </div>
                            <div class="station-info-time">{{backFlight.departureTime}}</div>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="flight-duration">
                            <div class="flight-duration-time text-center">{{backFlight.timeDifference}}</div>
                            <div class="flight-duration-arrow"></div>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="station-info">
                            <div class="station-info-date">
                              {{endDate | date:'yyyy/MM/dd'}}({{groupTourService.getDayOfWeek(endDate) | translate}})
                            </div>
                            <div class="station-info-time">{{backFlight.arrivalTime}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pay-section">
        <h5>{{'行程計畫' | translate}}</h5>
        <div class="bg-white mb-3 p-2">
          <div class="d-flex justify-content-center my-2">
            <div class="badge badge-pill badge-schedule mx-auto">
              {{'實際行程及順序可能因出發日期、航班、天候、飯店地點等而略異，請以行前說明會資料為準。' | translate}}
            </div>
          </div>

          <div class="row no-gutters my-4" *ngFor="let dayItem of groupControl.Product.itinerary; let i = index;">
            <div class="col-12 d-flex align-items-center mb-3">
              <div class="d-inline-flex badge-pill badge-group-cart badge-primary px-2 py-1">
                {{'第' + (i+1) + '天' | googleTranslate | async}}
              </div>
              <div class="cart-schedule-stroke">{{dayItem.title | googleTranslate | async}}</div>
            </div>
            <div class="col-12">
              <div class="cart-schedule">
                <div class="cart-schedule-right">
                  <div class="cart-schedule-eat-room clearfix">
                    <div class="cart-schedule-eat-room-item"
                      *ngIf="dayItem.breakfast || dayItem.lunch || dayItem.dinner">
                      <div class="cart-schedule-eat-room-tit">
                        <div class="cart-schedule-eat-room-tit-icon">
                          <span class="material-icons">restaurant</span>
                        </div>
                        <div class="cart-schedule-eat-room-tit-text">{{'食' | translate}}</div>
                      </div>
                      <div class="cart-schedule-eat-room-text eat">
                        <ul class="list-unstyled">
                          <li *ngIf="dayItem.breakfast"><span class="text-primary">
                              {{'早餐' | translate}}：</span>{{dayItem.breakfast | googleTranslate | async}}</li>
                          <li *ngIf="dayItem.lunch"><span class="text-primary">
                              {{'中餐' | translate}}：</span>{{dayItem.lunch | googleTranslate | async}}</li>
                          <li *ngIf="dayItem.dinner"><span class="text-primary">
                              {{'晚餐' | translate}}：</span>{{dayItem.dinner | googleTranslate | async}}</li>
                        </ul>
                      </div>
                    </div>
                    <div class="cart-schedule-eat-room-item" *ngIf="dayItem.hotelName">
                      <div class="cart-schedule-eat-room-tit">
                        <div class="cart-schedule-eat-room-tit-icon">
                          <span class="material-icons">bed</span>
                        </div>
                        <div class="cart-schedule-eat-room-tit-text">{{'住' | translate}}</div>
                      </div>
                      <div class="cart-schedule-eat-room-text eat">
                        <a>{{dayItem.hotelName | googleTranslate | async}}</a>　{{'或同級' | translate}}
                        <!-- <a href="">成田GATEWAY</a>／<a href="">MARROAD</a>／<a href="">GARDEN</a>／<a href="">RADISSON</a>／<a
                          href="">MYSTAYS 成田</a>　或同級 -->
                      </div>
                    </div>
                  </div>

                  <div class="cart-schedule-schedule py-2">
                    <div id="collapseExample{{i}}" class="collapse">
                      <div class="p-2">
                        <p [innerHTML]="dayItem.introduction"></p>
                      </div>
                    </div>
                    <div class="collapse-btn">
                      <a class="collapsed" data-toggle="collapse" [attr.data-target]="'#collapseExample' + i"
                        aria-expanded="false" [attr.aria-controls]="'collapseExample' + i"
                        (click)="dayItem.isExpand = !dayItem.isExpand">
                        {{dayItem.isExpand ? '展開詳細行程' : '收起詳細行程' | translate}}
                        <span class="collapse-arrow d-inline-block align-self-start">
                          <svg viewBox="0 0 24 24" width="18" height="18">
                            <polyline fill="none" points="21,8.5 12,17.5 3,8.5 " stroke="#AFAFAF" stroke-miterlimit="10"
                              stroke-width="1.5"></polyline>
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="pay-section" id="contract" *ngIf="groupControl.defaultInfo.specialItem == '國旅'">
        <h5>{{'旅遊契約書' | translate}}</h5>
        <div class="bg-white mb-3 px-3 py-4" id="notice">
          <div class="d-flex justify-content-between">
            <a class="w-80 collapsed btn btn-link" data-toggle="collapse" data-target="#collapseContract"
              aria-expanded="false" aria-controls="collapseContract" (click)="isContractExpand = !isContractExpand">
              {{isContractExpand ? '收起閱讀' : '展開閱讀' | translate}}
              <span class="collapse-arrow d-inline-block align-self-start">
                <svg viewBox="0 0 24 24" width="18" height="18">
                  <polyline fill="none" points="21,8.5 12,17.5 3,8.5 " stroke="#AFAFAF" stroke-miterlimit="10"
                    stroke-width="1.5"></polyline>
                </svg>
              </span>
            </a>
            <a [href]="pdfSrc" class="d-flex btn text-dark" target="_blank">
              <span class="material-icons">file_download</span>
              <span>{{'下載PDF' | translate}}</span>
            </a>
          </div>

          <div class="collapse" id="collapseContract">
            <div class="mt-4">
              <pdf-viewer src="./../../../../assets/國內旅遊合約書.pdf" [render-text]="true" [original-size]="false"
                style="width: 100%; height: 600px"></pdf-viewer>
              <!-- <p>{{'旅遊契約書內容' | translate}}</p> -->
            </div>
          </div>
        </div>
      </div>

      <div class="pay-section ">
        <h5>{{'訂購須知' | translate}}</h5>
        <div class="bg-white mb-3 px-3 py-4">
          <div class="d-flex justify-content-between">
            <a class="w-80 collapsed btn btn-link" data-toggle="collapse" data-target="#collapseNotice"
              aria-expanded="false" aria-controls="collapseNotice" (click)="isPolicyExpand = !isPolicyExpand">
              {{isPolicyExpand ? '收起閱讀' : '展開閱讀' | translate}}
              <span class="collapse-arrow d-inline-block align-self-start">
                <svg viewBox="0 0 24 24" width="18" height="18">
                  <polyline fill="none" points="21,8.5 12,17.5 3,8.5 " stroke="#AFAFAF" stroke-miterlimit="10"
                    stroke-width="1.5"></polyline>
                </svg>
              </span>
            </a>
            <!-- <a href="" class="d-flex btn text-dark">
              <span class="material-icons">file_download</span>
              <span>{{'下載PDF' | translate}}</span>
            </a> -->
          </div>

          <div class="collapse" id="collapseNotice">
            <div class="mt-4">
              <div [innerHTML]="groupControl.Product.policy"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="pay-section  mt-4">
        <h5>{{'訂購明細' | translate}}</h5>
        <div class="bg-white mb-3 p-3">
          <div class="row justify-content-end text-right">
            <ng-container *ngFor="let tp of typePrices">
              <ng-container *ngFor="let detail of tp.detail">
                <div class="col-12 my-1" *ngIf="detail.quantity > 0">
                  <div class="row justify-content-end">
                    <div class="col-6 col-md-3 pb-2 text-left border-bottom">
                      <span *ngIf="applicant.cabinClass" class="mr-2">{{applicant.cabinClass | translate}}</span>
                      <span *ngIf="tp.roomType">{{tp.roomType | googleTranslate | async}} - </span>{{detail.name |
                      googleTranslate | async}}<br>
                      <small class="text-gray-m"><span
                          [innerHTML]="detail.price | currencyExchange:true:false | async"></span>
                        x{{detail.quantity}}</small>
                    </div>
                    <span class="col-6 col-md-2 pb-2 text-price border-bottom"
                      [innerHTML]="detail.price * detail.quantity | currencyExchange:true:false | async">
                    </span>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <!-- <div class="col-12 my-1">
              <div class="row justify-content-end">
                <span class="col-6 col-md-3 pb-2 text-left border-bottom">
                  {{'金額小計' | translate}}
                </span>
                <span class="col-6 col-md-2 pb-2 text-price border-bottom">
                  - NTD 100
                </span>
              </div>
            </div> -->
            <div class="col-12 mt-2">
              <div class="row justify-content-end">
                <span class="col-6 col-md-3 text-left">{{'總價' | translate}}</span>
                <span class="col-6 col-md-2 text-price"
                  [innerHTML]="amount | currencyExchange:true:false | async"></span>
              </div>
              <!-- <div class="row justify-content-end">
                <span class="col-12 col-md-5 text-price">
                  <span class="text-dark pr-2 d-inline-block">應付訂金</span>
                  NTD 20,000
                </span>
              </div> -->
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row justify-content-center justify-content-md-end mt-4">
            <div class="col-12">
              <div class="form-check form-group text-right">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" [(ngModel)]="isAgreed">
                <label class="form-check-label main-nav" for="defaultCheck1">
                  {{'我已閱讀並同意' | translate}} <a class="text-primary">{{'訂購須知' | translate}}</a>
                  <span *ngIf="groupControl.defaultInfo.specialItem == '國旅'"> {{'及' | translate}}<a
                      class="text-primary trigger-contract">
                      {{'旅遊契約書' | translate}}</a></span> ({{'請點選並詳細閱讀' | translate}})
                </label>
              </div>
            </div>
          </div>

          <div class="row justify-content-center justify-content-md-end mt-4">
            <div class="col-12 col-md-4 col-lg-3">
              <button href="pay-confirm.html" class="btn btn-block btn-primary rounded-0"
                [disabled]="amount <= 0 || !isAgreed || !canBuy" (click)="placeOrder()">
                {{'下一步' | translate}}
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>

import { ConfirmDialogComponent } from './../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MemberService } from '../member.service';

@Component({
  selector: 'app-member-setting',
  templateUrl: './member-setting.component.html',
  styleUrls: ['./member-setting.component.scss']
})
export class MemberSettingComponent implements OnInit {
  form: FormGroup;
  countries: any[] = [];
  country: string;
  emailLogin = false;
  isMemberDone = false;
  bsModalRef: BsModalRef;
  id: number;
  actionString: string;
  fields = [];
  commonTourist = [];
  displayName = '';
  memberForm: FormGroup;
  index: number;
  member: any;

  constructor(
    private memberService: MemberService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.loadCountry();
    this.getMember();
  }

  getMember() {
    this.memberService.getMember()
      .subscribe((v: any) => {
        if (v.result) {
          this.member = v.data;
          this.isMemberDone = v.data.status === 1 ? true : false;
          this.commonTourist = this.member.commonTourist ? this.member.commonTourist : [],
          this.emailLogin = v.data.loginMethod === 'email' ? true : false;
          this.country = v.data.memberInfo.country ? v.data.memberInfo.country : '臺灣';
          this.form = this.formBuilder.group({
            firstNameEn: [v.data.firstNameEn, [Validators.required]],
            lastNameEn: [v.data.lastNameEn, [Validators.required]],
            firstName: [v.data.firstName, [Validators.required]],
            lastName: [v.data.lastName, [Validators.required]],
            // gender: [v.data.gender],
            // birthday: [v.data.birthday.slice(0, 1) !== '0' ? v.data.birthday.slice(0, 10) : ''],
            phone: [v.data.phone, [Validators.required]],
            email: [v.data.email, [Validators.required]],
            line: [v.data.line],
            status: [1],
            memberInfo:
              this.formBuilder.group({
                country: [this.country],
              })
          });
        }
      },
        (e) => { console.log(e); },
      );
  }

  loadCountry() {
    this.memberService.getCountry().subscribe(x => {
      this.countries = x;
    });
  }

  get f(): { [key: string]: AbstractControl; } { return this.form.controls; }

  onSubmit() {
    this.memberService.updateMember(this.form.value).subscribe(res => {
      if (res.result) {
        this.toastr.success('已成功更新會員資料', '系統訊息');
        this.memberService.renewUserInfo({
          firstName: this.form.value.firstName,
          lastName: this.form.value.lastName,
        });
        this.getMember();
      }
    },
      (e) => {
        // this.showAlert(e.statusText);
        console.log(e);
      },
    );
  }

  getCommonTouristFields(index = null) {
    this.index = index;
    this.memberService.getCommonTouristFields()
      .subscribe((v: any) => {
        if (v.result && v.data) {
          this.fields = v.data;

          if (this.index === 0 || this.index) {
            this.displayName = this.commonTourist[this.index].displayName;
            this.fields.map(field => {
              if (field.fieldType === 'checkbox') {
                field.answer = [];
                field.fieldData.map(data => {
                  const checkData = { key: data, value: false };
                  field.answer.push(JSON.parse(JSON.stringify(checkData)));
                });
                field.checkValid = false;
              } else {
                field.answer = '';
              }

              this.commonTourist[this.index].setting.map(setting => {
                if (setting.ID === field.ID) {
                  field.answer = setting.answer;
                }
              });
            });
          } else {
            this.displayName = '';
            this.fields.map(field => {
              if (field.fieldType === 'checkbox') {
                field.answer = [];
                field.fieldData.map(data => {
                  const checkData = { key: data, value: false };
                  field.answer.push(JSON.parse(JSON.stringify(checkData)));
                });
                field.checkValid = false;
              } else {
                field.answer = '';
              }
            });
          }
        }
      },
        (e) => { console.log(e); },
      );
  }

  deleteCommonTourist(index: number) {
    const config = {
      initialState: {
        title: '刪除常用旅客',
        content: '確定要刪除此旅客？',
        btnDanger: 'btn btn-danger',
        btnSecondary: 'btn btn-Secondary',
      },
      class: 'modal-danger'
    };
    this.bsModalRef = this.modalService.show(ConfirmDialogComponent, config);
    this.bsModalRef.content.event.subscribe(result => {
      if (result.action) {
        this.member.commonTourist.splice(index, 1);
        this.memberForm = this.formBuilder.group({
          commonTourist: [this.member.commonTourist],
        });
        this.memberService.updateMember(this.memberForm.value)
          .subscribe((v: any) => {
            if (v.result) {
              this.toastr.success('已成功刪除旅客');
              this.getMember();
            }
          },
            (e) => { console.log(e); },
          );
      }
    });
  }

  updateCommonTourist() {
    if (this.index === 0 || this.index) {
      this.commonTourist[this.index] = { displayName: this.displayName, setting: this.fields };
    } else {
      this.commonTourist.push({ displayName: this.displayName, setting: this.fields });
    }
    this.memberForm = this.formBuilder.group({
      commonTourist: [this.commonTourist],
    });
    this.memberService.updateMember(this.memberForm.value)
      .subscribe((v: any) => {
        if (v.result) {
          this.index = null;
          this.toastr.success('已成功' + this.actionString, '常用旅客');
          this.getMember();
        } else {
          this.toastr.success(this.actionString, '常用旅客失敗');
        }
      },
        (e) => { console.log(e); },
      );
  }

}

import { AlertDialogComponent } from './../../../shared/dialogs/alert-dialog/alert-dialog.component';
import { MemberService } from './../member.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { PayService } from '../../pay/pay.service';
import { LayoutService } from '../../layout/layout.service';
declare let $: any;

@Component({
  selector: 'app-member-order',
  templateUrl: './member-order.component.html',
  styleUrls: ['./member-order.component.scss']
})
export class MemberOrderComponent implements OnInit {
  @ViewChild('divForm') divForm: ElementRef;
  orderData: any[] = [];
  selectedOrderId: number;
  form: FormGroup;
  selectedOrder: any;
  tourist = '單人旅遊';
  i = 0;
  photo: string[] = [];
  photoFile: any[] = [];
  bsModalRef: BsModalRef;
  scores = [1, 2, 3, 4, 5];
  score = 0;
  commentSend = false;
  content = '';
  originalOrderData = [];
  selectedTab = 1;

  linePay = false;
  payMethodLoaded = false;
  PayMethod2 = false;
  PayMethod6 = false;
  PayMethod7 = false;
  remitInfo = '';
  today = new Date();
  showPayModal = true;
  // selectedOrder: any;


  constructor(
    private memberService: MemberService,
    private modalService: BsModalService,
    public payService: PayService,
    private layoutService: LayoutService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.getOrders();
    this.switchTab();
  }

  async getOrders(): Promise<any> {
    try {
      const v: any = await this.memberService.getOrders().toPromise();
      if (v.result) {
        console.log('v.data :>> ', v.data);
        v.data = v.data.filter(order => order.tourGroupControlID === 0);
        this.originalOrderData = JSON.parse(JSON.stringify(v.data));
        this.orderData = v.data;

        this.payService.getPayMethod().subscribe((v2: any) => {
          if (v2.result) {
            this.layoutService.translateHTML(v2.remitInfo).subscribe((res: any) => {
              this.remitInfo = res;
            });
            if (v2.data) {
              v2.data.map(method => {
                switch (method) {
                  case 2:
                    this.PayMethod2 = true;
                    break;
                  case 6:
                    this.PayMethod6 = true;
                    break;
                  case 7:
                    this.PayMethod7 = true;
                    break;
                }
              });
            }
            this.payMethodLoaded = true;
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  get f(): { [key: string]: AbstractControl; } { return this.form.controls; }

  resetForm() {
    this.form = this.formBuilder.group({
      productId: [0],
      productPlanId: [0],
      tourist: [''],
      score: [0, [Validators.required]],
      content: [''],
      orderMainId: [0],
      orderDetailId: [0]
    });
    this.photo = [];
    this.photoFile = [];
    this.content = '';
  }

  openModal(orderId, order: any) {
    this.resetForm();
    this.selectedOrderId = orderId;
    this.selectedOrder = order;
    this.tourist = '單人旅遊';
    this.score = 0;
    this.commentSend = false;
  }

  onFileSelect(event, index: number) {
    if (event.target.files[0].size > 500000) {
      alert('圖片大小必須在500k以下。');
    } else {
      if (event.target.files.length > 0) {
        // index = this.id ? this.photo.length : index;
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (res) => {
          this.photo[index] = reader.result as string;
        };
        this.photoFile.push(file);
        this.i++;
      }
    }
  }

  deleteImg(index: number) {
    const photoID = this.photo[index].split('/')[3];
    if (this.photo[index].split('/')[1] !== 'api' && this.photo[index].split('/')[2] !== 'image') {
      this.photo.splice(index, 1);
      this.photoFile.splice(index, 1);
      this.i--;
    }
  }

  onSubmit() {
    this.commentSend = true;
    this.form.value.productId = this.selectedOrder.productData.product.id;
    this.form.value.productPlanId = this.selectedOrder.productData.plan.id;
    this.form.value.tourist = this.tourist;
    this.form.value.score = this.score;
    this.form.value.orderMainId = this.selectedOrderId;
    this.form.value.orderDetailId = this.selectedOrder.ID;
    this.form.value.content = this.content;

    // tslint:disable-next-line:new-parens
    const comment = new FormData;
    this.photoFile.forEach((value, index, files) => {
      comment.append('photo', value);
    });

    Object.keys(this.form.value).map((objectKey, index) => {
      const value = this.form.value[objectKey];
      comment.append(objectKey, value);
    });

    const productData = {
      product: {
        id: this.selectedOrder.productData.product.id, title: this.selectedOrder.productData.product.title,
        coverImg: this.selectedOrder.productData.product.coverImg
      },
      plan: { id: this.selectedOrder.productData.plan.id, name: this.selectedOrder.productData.plan.name }
    };
    comment.append('productData', JSON.stringify(productData));

    this.memberService.createComment(comment).subscribe(async (r: any) => {
      if (r.result) {
        $('#comment' + this.selectedOrder.ID).modal('hide');
        this.toastr.success('已成功建立評論', '系統訊息');
        this.score = 0;
        await this.getOrders();
        this.switchTab();
        this.commentSend = false;
      } else {
        this.showAlert(r.message, 'btn btn-danger', 'modal-danger');
        this.commentSend = false;
      }
    }, e => {
      console.log(e);
      this.showAlert(e.message, 'btn btn-danger', 'modal-danger');
    });
  }

  switchTab() {
    this.orderData = JSON.parse(JSON.stringify(this.originalOrderData));
    if (this.selectedTab === 2) {
      this.orderData = this.orderData.filter(order => order.payStatus === -1 || order.payStatus === 2);
    } else if (this.selectedTab === 3) {
    } else {
      this.orderData = this.orderData.filter(order => order.payStatus === 1 || order.payStatus === 3 || order.payStatus === 5);
    }
  }

  completePayment() {
    // console.log('object :>> ', this.selectedOrder);
    if (this.selectedOrder.payMethod === 2) {
      this.payService.linepayComplete(this.selectedOrder).subscribe(r => {
        if (r.result) {
          location.href = r.url;
          // location.href = r.linkmessage;
        } else {
          this.showAlert(r.message, 'btn btn-danger', 'modal-danger');
        }
      }, e => {
        console.log(e);
        this.showAlert(e.message, 'btn btn-danger', 'modal-danger');
      });
    }

    if (this.selectedOrder.payMethod === 6) {
      this.showAlert('將導到契約第三方付款金流（藍新金流）頁面...請稍候', 'btn btn-danger', 'modal-danger');
      this.payService.newebPayComplete(this.selectedOrder).subscribe(r => {
        if (r.result) {
          const dataForNewebPay = {
            formHtml: r.data.formHtml,
          };
          localStorage.setItem('dataForNewebPay', JSON.stringify(dataForNewebPay));
          const formHtml = r.data;
          this.divForm.nativeElement.innerHTML = `${formHtml}`;
          // tslint:disable-next-line:only-arrow-functions
          $(document).ready(function () {
            $('#newebpaySubmit').click();
            // tslint:disable-next-line:only-arrow-functions
            setTimeout(function () {
              $('#newebpaySubmit').trigger('click');
            }, 500);
          });
        } else {
          $('.modal-dialog').hide();
          if (r.message) {
            this.showAlert(r.message, 'btn btn-danger', 'modal-danger');
          } else {
            if (r.data) {
              this.showAlert(r.data, 'btn btn-danger', 'modal-danger');
            } else {
              this.showAlert('契約第三方付款金流（藍新金流）異常，請聯絡客服人員，謝謝您。', 'btn btn-danger', 'modal-danger');
            }
          }
          // tslint:disable-next-line:only-arrow-functions
          setTimeout(function () {
            // window.location.reload();
          }, 2000);
        }
      }, e => {
        this.showAlert(e.message, 'btn btn-danger', 'modal-danger');
      });
    }

    if (this.selectedOrder.payMethod === 7) {
      this.showPayModal = false;
      this.selectedOrder.payMethod = 7;
      this.payService.updatePayMethod(this.selectedOrder).subscribe(r => {
        if (r.result) {
          this.showAlert('匯款後請聯繫我們確保您的款項成功入帳。', 'btn btn-success', 'modal-success');
        } else {
          this.showAlert(r.message, 'btn btn-danger', 'modal-danger');
        }
      }, e => {
        console.log(e);
        this.showAlert(e.message, 'btn btn-danger', 'modal-danger');
      });
    }
  }

  showAlert(content: string, btnClass: string, theClass: string) {
    const config = {
      initialState: {
        title: '系統訊息',
        content,
        btnClass
      },
      class: theClass
    };
    this.bsModalRef = this.modalService.show(AlertDialogComponent, config);
  }

}

<div class="container-fluid content-wrapper">
  <div class="row">
    <div class="col-12 col-xl-8 mx-auto">
      <h4 class="page-heading text-center my-5">{{"聯絡我們" | translate}}</h4>
      <div class="bg-white p-4 mb-5">
        <div class="row">
          <div class="col-12">
            <form action="" method="post" class="form-horizontal" [formGroup]="form" *ngIf="form">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>{{"問題類別" | translate}} <sub class="text-price">*</sub></label>
                    <select class="form-control select-arrow" formControlName="categoryId">
                      <option value="" selected>{{"請選擇" | translate}}</option>
                      <option [value]="category.ID" *ngFor="let category of categories">{{category.name |
                        googleTranslate | async}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{"連絡人姓名" | translate}} <sub class="text-price">*</sub></label>
                    <input type="text" class="form-control" formControlName="name"
                      [ngClass]="{ 'is-invalid': f.name.errors && f.name.touched }">
                    <span *ngIf="f.name.errors" class="invalid-feedback">{{"姓名" | translate}}{{"為必填" | translate}}</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{"聯絡電話" | translate}} <sub class="text-price">*</sub></label>
                    <input type="text" class="form-control" formControlName="phone"
                      [ngClass]="{ 'is-invalid': f.phone.errors && f.phone.touched }">
                    <span *ngIf="f.phone.errors" class="invalid-feedback">{{"聯絡電話" | translate}}{{"為必填" | translate}}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="first">Email <sub class="text-price">*</sub></label>
                    <input type="email" class="form-control" formControlName="email"
                      [ngClass]="{ 'is-invalid': f.email.errors && f.email.touched }">
                    <span *ngIf="f.email.errors?.required" class="invalid-feedback">Email{{"為必填" | translate}}</span>
                    <span *ngIf="f.email.errors?.email" class="invalid-feedback">{{"請填入正確的Email格式" | translate}}</span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{"旅遊預算" | translate}}</label>
                    <select class="form-control select-arrow" formControlName="budget">
                      <option value="" selected>{{"請選擇" | translate}}</option>
                      <option [value]="budget" *ngFor="let budget of budgetOptions">{{budget | googleTranslate | async}}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{"出發時間" | translate}}</label>
                    <input type="date" class="form-control" formControlName="departureDate">
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{"行程天數" | translate}}</label>
                    <select class="form-control select-arrow" formControlName="travelDay">
                      <option value="" selected>{{"請選擇" | translate}}</option>
                      <option [value]="day" *ngFor="let day of dayOptions">{{day}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{"出發地" | translate}}</label>
                    <select class="form-control select-arrow" formControlName="departurePlace">
                      <option value="" selected>{{"請選擇" | translate}}</option>
                      <option [value]="area" *ngFor="let area of areaOptions">{{area}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>{{"留言" | translate}} <sub class="text-price">*</sub></label>
                    <textarea cols="30" rows="5" class="form-control" formControlName="content"></textarea>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 d-flex justify-content-center my-4">
                  <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (reset)="handleReset()" (expire)="handleExpire()"
                    (load)="handleLoad()" (success)="handleSuccess($event)" [useGlobalDomain]="false" [size]="size"
                    [hl]="lang" [theme]="theme" [type]="type" formControlName="recaptcha">
                  </ngx-recaptcha2>
                </div>
                <div class="col-12 col-md-6 col-lg-5 mx-auto">
                  <input type="button" class="btn btn-primary btn-block btn-lg rounded-0"
                    [value]="'送出' | translate" [disabled]="!form.valid" (click)="onSubmit()">
                </div>
                <div class="col-12 text-center my-4">
                  <small>*{{"按下送出鍵視同(已閱讀並同意)接受個資保護聲明隱私權政策" | translate}}</small>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { LayoutService } from './../layout/layout.service';
import { ProductService } from './../product/product.service';
import { DomSanitizer } from '@angular/platform-browser';
import { VideoService } from './../video/video.service';
import { IndexService } from './index.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
declare let $: any;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  bannerData: any[] = [];
  exploreData: any[] = [];
  popularData: any[] = [];
  featuredData: any[] = [];
  videoData: any[] = [];
  categoryData: any[] = [];
  recentViewedData: any[] = [];
  grabData: any;
  grabProducts: any[] = [];
  selectedVideo: any;
  announcements: any[] = [];
  isMobile = this.deviceService.isMobile();
  isTablet = this.deviceService.isTablet();
  isDesktopDevice = this.deviceService.isDesktop();
  loading = false;
  exploreWorldTitle = '探索世界';
  innerWidth: any;


  dynamicTape: string[] = [];
  areThereUpdates = false;
  duration: string;
  count = 1;

  constructor(
    public sanitizer: DomSanitizer,
    private layoutService: LayoutService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private translateService: TranslateService,
    private indexService: IndexService,
    private videoService: VideoService,
    private productService: ProductService) {
    $('.navbar-mobile').removeClass('d-none');
    this.layoutService.getSystemSetting().subscribe((v: any) => {
      if (v.result && v.data) {
        this.exploreWorldTitle = v.data.ExploreWorldTitle;
      }
    });
    this.innerWidth = window.innerWidth;
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;

    this.indexService.getIndexData()
      .subscribe(async (v: any) => {
        if (v.result) {
          this.bannerData = v.data.banner;
          this.announcements = v.data.announcement;
          this.exploreData = v.data.explore;
          // this.exploreData.map(exp => {
          //   this.layoutService.translateText(exp.buttonText).subscribe((res: any) => {
          //     exp.translatedButtonText = res;
          //   });
          // });
          const exploreDataWithTranslations = await Promise.all(this.exploreData.map(async (explore) => {
            explore.translatedButtonText = await this.layoutService.translateText(explore.buttonText).toPromise();
            return explore;
          }));
          this.exploreData = exploreDataWithTranslations;

          this.featuredData = v.data.featured;

          this.dynamicTape = [];
          if (this.announcements && this.announcements.length > 0) {
            this.announcements.map(ann => {
              this.dynamicTape.push(ann.message);
            });
          }
          this.duration = this.updateDuration();

          let i = 0;
          v.data.popular.map(hot => {
            if (i < 6) {
              this.popularData.push(hot);
            }
            i++;
          });
          if (this.grabData) {
            this.popularData.map(product => {
              product.isSale = false;
              product.deduct = 0;
              this.grabData.GrabTodayPlan.map(sales => {
                if (sales.product.ID === product.Product.ID) {
                  product.isSale = true;
                  product.deduct = sales.product.deduct;
                }
              });
            });
          }

          this.categoryData = v.data.category;
          if (this.grabData) {

            this.categoryData.map(category => {
              if (category.Product) {
                category.Product.map(product => {
                  product.isSale = false;
                  product.deduct = 0;
                  this.grabData.GrabTodayPlan.map(sales => {
                    if (sales.product.ID === product.ID) {
                      product.isSale = true;
                      product.deduct = sales.product.deduct;
                    }
                  });
                });
              }
            });
          }
          this.loadScript('assets/js/deal.js');
          this.loadScript('assets/js/app.js');


          this.videoData = v.data.video;
          this.videoData.map(product => {
            product.isSale = false;
            product.deduct = 0;
            if (this.grabData) {
              this.grabData.GrabTodayPlan.map(sales => {
                if (sales.product.ID === product.Product.ID) {
                  product.isSale = true;
                  product.deduct = sales.product.deduct;
                }
              });
            }
          });
          this.loadScript('assets/js/video.js');

          this.loading = false;
        }
      });

    await this.getGrabToday();
    if (location.hash === '#grabToday') {
      setTimeout(() => {
        const target = document.getElementById('grabToday');
        window.scrollTo({ behavior: 'smooth', top: target.offsetTop });
      }, 1500);
    }

    $(document).on('click', '.popup-modal-dismiss', (e) => {
      e.preventDefault();
      $.magnificPopup.close();
    });

    this.productService.getRecentViewedProducts().subscribe((v: any) => {
      if (v.result) {
        this.recentViewedData = v.data.slice(0, 4);
        if (this.grabData) {
          this.recentViewedData.map(product => {
            product.isSale = false;
            product.deduct = 0;

            this.grabData.GrabTodayPlan.map(sales => {
              if (sales.product.ID === product.ID) {
                product.isSale = true;
                product.deduct = sales.product.deduct;
              }
            });
          });
        }
      }
    });
  }

  updateViewCount(id: number) {
    this.videoService.updateViewCount(id).subscribe((v: any) => { });
    this.videoData.map(video => {
      if (video.ID === id) {
        video.viewCount++;
      }
    });
  }

  setSelectedVideo(v) {
    this.updateViewCount(v.ID);
    this.selectedVideo = v;
  }

  getGrabToday() {
    return new Promise((resolve, reject) => {
      this.indexService.getGrabToday()
        .subscribe((r: any) => {
          if (r.result && r.data) {
            this.grabData = r.data;
            this.grabData.leftTime = (new Date(this.grabData.endDate.split(':00Z')[0]).getTime() - new Date().getTime());

            this.grabData.GrabTodayPlan.map(plan => {
              r.products.map(product => {
                if (plan.product.ID === product.ID) {
                  plan.product.coverImg = product.coverImg;
                  plan.product.highestPrice = product.highestPrice;
                  plan.product.lowestPrice = product.lowestPrice;
                  plan.product.productNumber = product.productNumber;
                  plan.product.title = product.title;
                }
              });
            });
          }
          resolve(true);
        });
    });
  }

  loadScript(url) {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  closeModal() {
    $.magnificPopup.close();
    // this.router.navigate(['/product/' + this.selectedVideo.Product.ID]);
  }

  updateContent = () => {
    // What should we do each time a cycle is completed AND THERE ARE UPDATES to do in the marquee?
    this.dynamicTape.unshift(`Added Item #${this.count++} on the fly`);
  }

  updateDuration = (): string => {
    // Some imaginative way to determine a pleasant time of movement when marquee's content change...
    this.duration = `${String(this.dynamicTape.length * 15)}s`;
    return this.duration;
  }

}

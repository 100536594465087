import { AlertDialogComponent } from './../../../shared/dialogs/alert-dialog/alert-dialog.component';
import { MemberService } from './../member.service';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { GroupTourService } from '../../group-tour/group-tour.service';
declare let $: any;

@Component({
  selector: 'app-member-group-order',
  templateUrl: './member-group-order.component.html',
  styleUrls: ['./member-group-order.component.scss']
})
export class MemberGroupOrderComponent implements OnInit {
  orderData: any[] = [];
  originalOrderData: any[] = [];
  selectedOrderId: number;
  form: FormGroup;
  selectedTab = 1;
  selectedOrder: any;
  tourist = '單人旅遊';
  i = 0;
  photo: string[] = [];
  photoFile: any[] = [];
  bsModalRef: BsModalRef;
  scores = [1, 2, 3, 4, 5];
  score = 0;
  commentSend = false;
  content = '';


  constructor(
    private memberService: MemberService,
    private modalService: BsModalService,
    public groupTourService: GroupTourService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.getOrders();
    this.switchTab();
  }

  async getOrders(): Promise<any> {
    try {
      const v: any = await this.memberService.getOrders().toPromise();
      if (v.result) {
        v.data = v.data.filter(order => order.tourGroupControlID !== 0);
        this.originalOrderData = JSON.parse(JSON.stringify(v.data));
        this.orderData = v.data;
      }
    } catch (error) {
      console.error(error);
    }
  }

  get f(): { [key: string]: AbstractControl; } { return this.form.controls; }

  resetForm() {
    this.form = this.formBuilder.group({
      productId: [0],
      productPlanId: [0],
      tourist: [''],
      score: [0, [Validators.required]],
      content: [''],
      orderMainId: [0],
      orderDetailId: [0]
    });
    this.photo = [];
    this.photoFile = [];
    this.content = '';
  }

  openModal(orderId, order: any) {
    this.resetForm();
    this.selectedOrderId = orderId;
    this.selectedOrder = order;
    this.tourist = '單人旅遊';
    this.score = 0;
    this.commentSend = false;
  }

  onFileSelect(event, index: number) {
    if (event.target.files[0].size > 500000) {
      alert('圖片大小必須在500k以下。');
    } else {
      if (event.target.files.length > 0) {
        // index = this.id ? this.photo.length : index;
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (res) => {
          this.photo[index] = reader.result as string;
        };
        this.photoFile.push(file);
        this.i++;
      }
    }
  }

  deleteImg(index: number) {
    const photoID = this.photo[index].split('/')[3];
    if (this.photo[index].split('/')[1] !== 'api' && this.photo[index].split('/')[2] !== 'image') {
      this.photo.splice(index, 1);
      this.photoFile.splice(index, 1);
      this.i--;
    }
  }

  onSubmit() {
    this.commentSend = true;
    this.form.value.productId = this.selectedOrder.productData.product.id;
    this.form.value.productPlanId = this.selectedOrder.productData.plan.id;
    this.form.value.tourist = this.tourist;
    this.form.value.score = this.score;
    this.form.value.orderMainId = this.selectedOrderId;
    this.form.value.orderDetailId = this.selectedOrder.ID;
    this.form.value.content = this.content;

    // tslint:disable-next-line:new-parens
    const comment = new FormData;
    this.photoFile.forEach((value, index, files) => {
      comment.append('photo', value);
    });

    Object.keys(this.form.value).map((objectKey, index) => {
      const value = this.form.value[objectKey];
      comment.append(objectKey, value);
    });

    const productData = {
      product: {
        id: this.selectedOrder.productData.product.id, title: this.selectedOrder.productData.product.title,
        coverImg: this.selectedOrder.productData.product.coverImg
      },
      plan: { id: this.selectedOrder.productData.plan.id, name: this.selectedOrder.productData.plan.name }
    };
    comment.append('productData', JSON.stringify(productData));

    this.memberService.createComment(comment)
      .subscribe((r: any) => {
        if (r.result) {
          $('#comment' + this.selectedOrder.ID).modal('hide');
          this.toastr.success('已成功建立評論', '系統訊息');
          this.score = 0;
          this.getOrders();
          this.switchTab();
          this.commentSend = false;
        } else {
          this.showAlert(r.message);
          this.commentSend = false;
        }
      },
        e => {
          console.log(e);
          this.showAlert(e.message);
        });
  }

  switchTab() {
    this.orderData = JSON.parse(JSON.stringify(this.originalOrderData));
    if (this.selectedTab === 2) {
      this.orderData = this.orderData.filter(order => order.payStatus === 2);
    } else if (this.selectedTab === 3) {
      // this.orderData = this.orderData.filter(order => order.payStatus === 2);
    } else {
      this.orderData = this.orderData.filter(order => order.payStatus !== 2);
    }
    this.orderData.map(order => {
      order.endDate = new Date(order.TourGroupControl.startDate);
      order.endDate.setDate(order.endDate.getDate() + (order.TourGroupControl.defaultInfo.days - 1));
    });
  }

  showAlert(content: string) {
    const config = {
      initialState: {
        title: '系統錯誤',
        content,
        btnClass: 'btn btn-danger'
      },
      class: 'modal-danger'
    };
    this.bsModalRef = this.modalService.show(AlertDialogComponent, config);
  }

}

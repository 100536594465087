<div class="content-cart">
  <div class="container-fluid content-wrapper position-relative" *ngIf="form">

    <div class="pay-section">
      <h5>{{"訂購人資料" | translate}}</h5>

      <div class="bg-white p-4 mb-4">
        <div class="row">
          <div class="col-lg-9">
            <form action="" method="post" class="form-horizontal" [formGroup]="form">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{"本國名" | translate}} <sub class="text-price">*</sub></label>
                    <input type="text" class="form-control" formControlName="firstName"
                      [ngClass]="{ 'is-invalid': f.firstName.errors && f.firstName.touched }">
                    <span *ngIf="f.firstName.errors" class="invalid-feedback">{{"本國名" | translate}}{{"為必填" | translate}}</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{"本國姓" | translate}} <sub class="text-price">*</sub></label>
                    <input type="text" class="form-control" formControlName="lastName"
                      [ngClass]="{ 'is-invalid': f.lastName.errors && f.lastName.touched }">
                    <span *ngIf="f.lastName.errors" class="invalid-feedback">{{"本國姓" | translate}}{{"為必填" | translate}}</span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{"國家" |translate}} <sub class="text-price">*</sub></label>
                    <select class="form-control select-arrow" formControlName="country" [(ngModel)]="country">
                      <option [value]="country.cn" *ngFor="let country of countries">{{country.cn | googleTranslate |
                        async}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{"聯絡電話" | translate}} <sub class="text-price">*</sub></label>
                    <input type="text" class="form-control" formControlName="phone"
                      [ngClass]="{ 'is-invalid': f.phone.errors && f.phone.touched }">
                    <span *ngIf="f.phone.errors" class="invalid-feedback">{{"聯絡電話" | translate}}{{"為必填" | translate}}</span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>Email <sub class="text-price">*</sub></label>
                    <input type="email" class="form-control" formControlName="email"
                      [ngClass]="{ 'is-invalid': f.email.errors && f.email.touched }">
                    <span *ngIf="f.email.errors" class="invalid-feedback">Email{{"為必填" | translate}}</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="pay-section">
      <h5>{{"旅客資料" | translate}}</h5>

      <div class="bg-white mb-3" *ngFor="let item of cartData; let i = index">
        <div class="" data-toggle="collapse" role="button" (click)="item.isCollapsed = !item.isCollapsed">
          <div class="row no-gutters cart-item px-2">
            <div class="col-4 col-md-3 col-xl-2 d-flex flex-row">
              <div class="collapse-arrow align-self-start">
                <svg viewBox="0 0 24 24" width="24" height="24">
                  <polyline fill="none" points="21,8.5 12,17.5 3,8.5" stroke="#AFAFAF" stroke-miterlimit="10"
                    stroke-width="1.5" />
                </svg>
              </div>
              <div class="align-self-start">
                <img class="productImg" [src]="'/api/images/' + item.productImg">
              </div>
            </div>
            <div class="col-8 col-md-8 d-md-flex">
              <div class="flex-grow-1">
                <div class="product-name">{{item.productTitle | googleTranslate | async}}</div>
                <div class="product-option">{{item.planName | googleTranslate | async}}</div>
                <div>
                  <small class="text-black-50">
                    <ul class="pl-4">
                      <li >{{item.specName | googleTranslate | async}}</li>
                      <li *ngIf="item.extraOptionId !== 0">{{item.extraName | googleTranslate | async}}</li>
                    </ul>
                  </small>
                </div>
                <div class="product-quant d-flex align-items-center">
                  <span class="material-icons px-1">shopping_cart</span>
                  X {{item.quantity}} {{item.productData.planSpecification.unit | googleTranslate | async}}
                  <span class="material-icons px-1 ml-3">calendar_today</span>
                  {{item.date}}
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="collapse show" *ngIf="item.isCollapsed">
          <div class="divider mb-2"></div>
          <div class="pb-2" *ngFor="let tourist of item.touristList; let ti = index;">
            <div class="pay-passenger-card rounded ">
              <h6 class="mb-4" *ngIf="item.fillRequest == 2">{{"旅客" | translate}} {{tourist.count}}</h6>
              <h6 class="mb-4" *ngIf="item.fillRequest == 1">{{"旅客代表人" | translate}}</h6>
              <div class="row">
                <div class="col-md-9">
                  <!-- <div class="row">
                      <div class="col-12">
                        <div class="form-check form-group">
                          <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                          <label class="form-check-label" for="defaultCheck1">同訂購人資料</label>
                        </div>
                      </div>
                    </div> -->

                  <div class="row mb-4" *ngIf="memberData.commonTourist.length > 0">
                    <div class="col-12 col-md-4">
                      <div class="form-group">
                        <label>{{"常用旅客選擇" | translate}}</label>
                        <select class="form-control select-arrow" id="exampleFormControlSelect1"
                          [(ngModel)]="tourist.commonTourist" (change)="setCommonTourist(i, ti)">
                          <option [value]="tourist.displayName" *ngFor="let tourist of memberData.commonTourist">
                            {{tourist.displayName}}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div *ngFor="let req of tourist.requiredFields">

                    <div *ngIf="req.fieldType == 'input'">
                      <div class="form-group">
                        <label>{{req.title | googleTranslate | async}} <sub class="text-price">*</sub></label>
                        <input type="date" class="form-control" [(ngModel)]="req.answer"
                          max="{{today | date:'yyyy-MM-dd'}}" *ngIf="req.title.includes('生日')">
                        <input type="text" class="form-control" [(ngModel)]="req.answer"
                          *ngIf="!req.title.includes('生日')">
                        <div [hidden]="req.answer || !submitClicked" class="alert alert-danger">
                          {{req.title}}{{"為必填" | translate}}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="req.fieldType == 'radio'">
                      <div class="form-group">
                        <label>{{req.title | googleTranslate | async}} <sub class="text-price">*</sub></label><br>
                        <mat-radio-group [(ngModel)]="req.answer" color="primary">
                          <mat-radio-button [value]="data" class="mr-3" *ngFor="let data of req.fieldData">{{data |
                            googleTranslate | async}}
                          </mat-radio-button>
                        </mat-radio-group>
                        <div [hidden]="req.answer || !submitClicked" class="alert alert-danger">
                          {{req.title | googleTranslate | async}}{{"為必填" | translate}}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="req.fieldType == 'textarea'">
                      <div class="form-group">
                        <label>{{req.title | googleTranslate | async}} <sub class="text-price">*</sub></label><br>
                        <textarea rows="5" class="form-control" [(ngModel)]="req.answer"></textarea>
                        <div [hidden]="req.answer || !submitClicked" class="alert alert-danger">
                          {{req.title | googleTranslate | async}}{{"為必填" | translate}}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="req.fieldType == 'readonly'">
                      <div class="form-group">
                        <label>{{req.title | googleTranslate | async}} <sub class="text-price">*</sub></label><br>
                        <textarea rows="5" class="form-control"
                          disabled>{{req.fieldData | googleTranslate:true | async}}</textarea>
                      </div>
                    </div>
                    <div *ngIf="req.fieldType == 'select'">
                      <div class="form-group">
                        <label>{{req.title | googleTranslate | async}} <sub class="text-price">*</sub></label><br>
                        <select class="form-control select-arrow" [(ngModel)]="req.answer">
                          <option [value]="field" *ngFor="let field of req.fieldData">{{field | googleTranslate |
                            async}}</option>
                        </select>
                        <div [hidden]="req.answer || !submitClicked" class="alert alert-danger">
                          {{req.title}}{{"為必填" | translate}}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="req.fieldType == 'checkbox'">
                      <div class="form-group">
                        <label>{{req.title | googleTranslate | async}} <sub class="text-price">*</sub></label><br>
                        <div *ngFor="let data of req.answer">
                          <mat-checkbox color="primary" [(ngModel)]="data.value">{{data.key | googleTranslate |
                            async}}</mat-checkbox><br>
                        </div>
                        <div [hidden]="req.checkValid || !submitClicked" class="alert alert-danger">
                          {{req.title | googleTranslate | async}}{{"為必填" | translate}}
                        </div>
                      </div>
                    </div>
                  </div>


                  <div *ngFor="let opt of tourist.optionalFields">
                    <div *ngIf="opt.fieldType == 'input'">
                      <div class="form-group">
                        <label>{{opt.title | googleTranslate | async}}</label>
                        <input type="date" class="form-control" [(ngModel)]="opt.answer"
                          max="{{today | date:'yyyy-MM-dd'}}" *ngIf="opt.title.includes('生日')">
                        <input type="text" class="form-control" [(ngModel)]="opt.answer"
                          *ngIf="!opt.title.includes('生日')">
                      </div>
                    </div>
                    <div *ngIf="opt.fieldType == 'radio'">
                      <div class="form-group">
                        <label>{{opt.title | googleTranslate | async}}</label><br>
                        <mat-radio-group [(ngModel)]="opt.answer" color="primary">
                          <mat-radio-button [value]="data" class="mr-3" *ngFor="let data of opt.fieldData">{{data |
                            googleTranslate | async}}
                          </mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                    <div *ngIf="opt.fieldType == 'textarea'">
                      <div class="form-group">
                        <label>{{opt.title | googleTranslate | async}}</label><br>
                        <textarea rows="5" class="form-control" [(ngModel)]="opt.answer"></textarea>
                      </div>
                    </div>
                    <div *ngIf="opt.fieldType == 'readonly'">
                      <div class="form-group">
                        <label>{{opt.title | googleTranslate | async}}</label><br>
                        <textarea rows="5" class="form-control"
                          disabled>{{opt.fieldData | googleTranslate:true | async}}</textarea>
                      </div>
                    </div>
                    <div *ngIf="opt.fieldType == 'select'">
                      <div class="form-group">
                        <label>{{opt.title | googleTranslate | async}}</label><br>
                        <select class="form-control select-arrow" [(ngModel)]="opt.answer">
                          <option [value]="field" *ngFor="let field of opt.fieldData">{{field | googleTranslate |
                            async}}</option>
                        </select>
                      </div>
                    </div>
                    <div *ngIf="opt.fieldType == 'checkbox'">
                      <div class="form-group">
                        <label>{{opt.title | googleTranslate | async}}</label>
                        <div *ngFor="let data of opt.answer">
                          <mat-checkbox color="primary" [(ngModel)]="data.value">{{data.key | googleTranslate |
                            async}}</mat-checkbox><br>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pay-section">
      <h5>{{"使用折扣" | translate}}</h5>

      <div class="bg-white p-4 mb-4">
        <!-- <div class="form-check">
          <input class="form-check-input" type="checkbox" name="exampleCheckbox" id="exampleCheckbox1" value="option1">
          <label class="form-check-label" for="exampleCheckbox1">我有折扣卷</label>
        </div> -->
        <div class="form-group mt-4">
          <div class="bg-light p-4">
            <div class="row">
              <div class="col-md-4">
                <div class="input-group mb-3">
                  <input type="text" class="form-control" [placeholder]="'輸入折扣碼' | translate"
                    aria-describedby="button-addon2" [(ngModel)]="code">
                  <div class="input-group-append ml-2">
                    <button class="btn btn-primary rounded-0" type="button" id="button-addon2"
                      (click)="getCoupon()">{{"新增" | translate}}</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-2">
              <a href="#" data-toggle="modal" data-target="#couponModal">{{"選擇我的折扣卷" | translate}}</a>
            </div>

            <!--選取折扣卷 -->
            <div class="row" *ngIf="coupon != null">
              <div class="col-12">
                <div class="coupon-selected">
                  <div class="close" (click)="deleteCoupon()">×</div>
                  <div class="coupon-selected-value">
                    - <span [innerHTML]="discountValue | currencyExchange:true:false | async"></span>
                  </div>
                  <div class="coupon-selected-code" *ngIf="coupon.code != ''">
                    <div class="coupon-code">{{coupon.code}}</div>
                  </div>

                </div>
              </div>
            </div>
            <!--/選取折扣卷 -->
          </div>
        </div>
      </div>
    </div>

    <div class="pay-section" *ngIf="payMethodLoaded">
      <h5>{{"付款方式" | translate}}</h5>
      <div class="bg-white  mb-4">
        <mat-radio-group aria-label="Select an option" [(ngModel)]="payMethod">
          <!-- <div class="form-check ml-4 p-4">
            <mat-radio-button [value]="1">信用卡
              <img src="assets/images/pay-credit.png" class="pl-1">
            </mat-radio-button>
          </div>
          <div class="divider"></div> -->
          <div class="form-check ml-4 p-4" *ngIf="PayMethod2">
            <mat-radio-button [value]="2">
              <img src="assets/images/pay-line.png" class="pl-1" style="margin-top: -3px;">
            </mat-radio-button>
          </div>
          <div *ngIf="PayMethod6">
            <div class="divider"></div>
            <div class="form-check ml-4 p-4">
              <mat-radio-button [value]="6">{{"信用卡付款" | translate}}
                <img src="assets/images/mpg_card_icon.png" class="pl-1" style="margin-top: -3px;">
              </mat-radio-button>
            </div>
          </div>
          <div *ngIf="PayMethod3">
            <div class="divider"></div>
            <div class="form-check ml-4 p-4">
              <mat-radio-button [value]="3">{{"ATM虛擬帳號轉帳" | translate}}</mat-radio-button>
            </div>
          </div>
          <div *ngIf="PayMethod4 && payAmount >= 30">
            <div class="divider"></div>
            <div class="form-check ml-4 p-4">
              <mat-radio-button [value]="4">{{"超商代碼繳款" | translate}}</mat-radio-button>
            </div>
          </div>
          <div *ngIf="PayMethod5 && payAmount >= 30">
            <div class="divider"></div>
            <div class="form-check ml-4 p-4">
              <mat-radio-button [value]="5">{{"超商條碼繳款" | translate}}</mat-radio-button>
            </div>
          </div>
          <div *ngIf="PayMethod7">
            <div class="divider"></div>
            <div class="form-check ml-4 p-4">
              <mat-radio-button [value]="7">
                <div class="d-flex flex-row align-items-center">
                  <span>{{"匯款" | translate}}</span>
                </div>
              </mat-radio-button>

              <div class="card mt-3" *ngIf="payMethod == 7">
                <div class="card-body">
                  <p class="card-text text-muted" [innerHTML]="remitInfo"></p>
                </div>
              </div>
            </div>
          </div>
        </mat-radio-group>
      </div>


      <div class="pay-section">
        <h5>{{"付款明細" | translate}}</h5>

        <div class="bg-white">
          <div class="">
            <div class="row no-gutters cart-item" *ngFor="let item of cartData; let i = index">
              <div class="col-3 col-md-2 text-center">
                <img class="productImg" [src]="'/api/images/' + item.productImg">
              </div>
              <div class="col-9 col-md-10 d-md-flex">
                <div class="flex-grow-1">
                  <div class="product-name">{{item.productTitle | googleTranslate | async}}</div>
                  <div class="product-option">{{item.planName | googleTranslate | async}}</div>
                  <div>
                    <small class="text-black-50">
                      <ul class="pl-4">
                        <li>{{item.specName | googleTranslate | async}}</li>
                        <li *ngIf="item.extraOptionId !== 0">{{item.extraName | googleTranslate | async}}</li>
                      </ul>
                    </small>
                  </div>
                  <div class="product-quant d-flex align-items-center">
                    <span class="material-icons px-1">shopping_cart</span>
                    X {{item.quantity}} {{item.productData.planSpecification.unit | googleTranslate | async}}
                    <span class="material-icons px-1 ml-3">calendar_today</span>
                    {{item.date}}
                  </div>
                </div>
                <div><strong class="text-price"
                    [innerHTML]="item.total - (item.totalDeduct ? item.totalDeduct : 0) | currencyExchange:true:false | async"></strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="bg-white cart-footer mt-4 p-3 ">
        <div class="row justify-content-end text-right">
          <div class="col-12 my-1">
            <div class="row justify-content-end">
              <span class="col-6 col-md-3">{{cartData.length}} {{"件商品合計" | translate}}</span>
              <span class="col-6 col-md-2 text-price"
                [innerHTML]="totalAmount - totalDeduct | currencyExchange:true:false | async"></span>
            </div>
          </div>
          <div class="col-12 my-1">
            <div class="row justify-content-end">
              <span class="col-6 col-md-3">{{"折扣金額" | translate}}</span>
              <span class="col-6 col-md-2 text-price">- <span
                  [innerHTML]="discountValue | currencyExchange:true:false | async"></span></span>
            </div>
          </div>
          <div class="col-12 my-1">
            <div class="row justify-content-end">
              <span class="col-6 col-md-3">{{"付款金額" | translate}}</span>
              <span class="col-6 col-md-2 text-price font-weight-bold"
                [innerHTML]="payAmount | currencyExchange:true:false | async"></span>
            </div>
          </div>
        </div>

        <div class="row justify-content-end mt-4">
          <div class="col-6 col-md-4 col-lg-3 pr-1">
            <a href="product-category.html" class="btn btn-block btn-outline-primary rounded-0">
              {{"繼續購物" | translate}}
            </a>
          </div>
          <div class="col-6 col-md-4 col-lg-3 pl-1">
            <button class="btn btn-block btn-primary rounded-0 text-white" (click)="onSubmit()"
              [disabled]="!form.valid || !payMethod || submitClicked || !canBuy">{{"確認付款" | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>

    <app-footer></app-footer>

  </div>

  <!-- Modal -->
  <div class="modal fade" id="couponModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-custom modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{"選擇折扣卷" | translate}}</h5>
          <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h5 align="center" class="text-danger mt-4" *ngIf="!coupons">{{"目前無可用的折扣券" | translate}}
          </h5>
          <div class="d-flex flex-column" *ngIf="coupons && coupons.length > 0">
            <label class="card-radio-btn" *ngFor="let item of coupons; let i = index">
              <input type="radio" name="demo" class="card-input-element d-none" (click)="selectCoupon(i)">
              <div class="card card-body flex-column flex-md-row justify-content-between">
                <div>
                  <div class="content-head">{{item.title | googleTranslate | async}}</div>
                  <div class="content-code">{{item.code}}</div>
                  <div class="content-sub">{{"有效日期" | translate}}：{{item.startDate | slice:0:10}} ~
                    <span *ngIf="(item.endDate | slice:0:10) == '0001-01-01'">{{"無期限" | translate}}</span>
                    <span *ngIf="(item.endDate | slice:0:10) != '0001-01-01'">{{item.endDate | slice:0:10}}</span>
                  </div>
                  <div class="content-sub">{{"使用規則" | translate}}：{{item.Rule | googleTranslate:true |
                    async}}</div>
                </div>

                <div class="d-flex d-md-block align-items-center">
                  <div class="content-discount-title d-none d-sm-block">
                    <small *ngIf="item.discountType == 1">{{"折扣金額" | translate}}</small>
                    <small *ngIf="item.discountType == 2">{{"折扣" | translate}}</small>
                  </div>
                  <div class="content-discount" *ngIf="item.discountType == 1">
                    <span [innerHTML]="item.discountValue | currencyExchange:true:false | async"></span>
                  </div>
                  <div class="content-discount" *ngIf="item.discountType == 2">
                    <small>{{"折" | translate}}</small>
                    <span>{{item.discountValue}}%</span>
                  </div>
                </div>

              </div>
            </label>
          </div>

        </div>
        <div class="modal-footer border-0">
          <button type="button" class="btn btn-link text-muted" data-dismiss="modal">{{"取消" | translate}}</button>
          <button type="button" class="btn btn-link" data-dismiss="modal" (click)="confirmCoupon()">{{"確定" | translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <div #divForm></div>

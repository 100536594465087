<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <div class="container">
      <!-- <button type="button" class="close" (click)="bsModalRef.hide()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button> -->
      <div class="row my-2">
        <div class="col-12 d-flex align-items-lg-center justify-content-center">
          <div class="w-100">
            <div class="input-group input-group-lg mb-4">
              <input type="text" class="form-control" [placeholder]="'請輸入商品關鍵字' | translate" [(ngModel)]="filter">
              <div class="input-group-append">
                <button type="submit" class="btn btn-primary" (click)="search()">
                  <span><i class="bi bi-search"></i>{{"搜尋產品" | translate}}</span>
                </button>
              </div>
            </div>

            <h6>{{"熱門關鍵字" | translate}}</h6>
            <div class="selection-wrapper">
              <a class="tag" *ngFor="let tag of tags" (click)="useTag(tag)" style="color: #4BBAC7; cursor: pointer;"
                [ngClass]="{ 'active': tag.name === filter }">
                {{tag.name | googleTranslate | async}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="page-groupbuy" *ngIf="initiatorData && !showDashboard">
  <div class="wrapper">
    <header class="d-flex">
      <a [routerLink]="['/groupBuy/initiator', initiatorData.ID]">
        <img [src]="'/api/images/' + initiatorData.logo" alt="logo">
      </a>
      <div class="d-flex align-items-center ml-auto">
        <!-- <ul class="nav user-nav pr-2">
          <li class="nav-item">
            <div class="dropdown pl-2 pr-2 border rounded">
              <a class="dropdown-toggle d-flex align-items-center py-1" id="dropdownMenu3" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <span class="material-icons pr-1">language</span>{{selectedLanguage}}
              </a>
              <div class="dropdown-menu dropdown-menu-lg-right dropdown-style" aria-labelledby="dropdownMenu3">
                <a class="dropdown-item" type="button" *ngFor="let lang of languageList"
                  (click)="changeLanguage(lang.value); this.selectedLanguage = lang.name">{{lang.name}}</a>
              </div>
            </div>
          </li>
        </ul> -->
        <ul class="nav user-nav pr-2">
          <li class="nav-item">
            <div class="dropdown pl-2 pr-2 border rounded">
              <a class="dropdown-toggle d-flex align-items-center py-1" id="dropdownMenu3" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                {{selectedCurrency}}
              </a>
              <div class="dropdown-menu dropdown-menu-right dropdown-style" aria-labelledby="dropdownMenu3"
                *ngIf="initiatorData.currencyList.length > 0">
                <a class="dropdown-item" *ngFor="let currency of initiatorData.currencyList"
                  (click)="changeCurrency(currency)">{{currency}}</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </header>

    <div class="content-main">
      <carousel *ngIf="isDesktop">
        <slide *ngFor="let banner of initiatorData.banner">
          <img [src]="'/api/images/' + banner"
            style="display: block; width: 100%; max-height: 600px !important; object-fit: cover;">
        </slide>
      </carousel>
      <carousel *ngIf="!isDesktop">
        <slide *ngFor="let banner of initiatorData.mobileBanner">
          <img [src]="'/api/images/' + banner" style="display: block; width: 100%; max-height: 400px !important;">
        </slide>
      </carousel>

      <div class="container-fluid content-wrapper content-category">
        <div class="row">
          <div class="col-12">
            <div class="card border-0 shadow-sm">
              <div class="card-body p-2" [innerHTML]="initiatorData.topGraphic">
              </div>
            </div>
          </div>
        </div>

        <section class="section-wrapper">
          <div class="section-title bold">
            <div>{{"相關行程" | translate}}</div>
            <div *ngIf="!groupBuys" class="">
              <h5 class="my-4 text-danger">-{{"目前無團購行程" | translate}}-</h5>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-xl-3 col-sm-6 mb-3" *ngFor="let item of groupBuys">
              <div class="product-card">
                <div class="product-card-image" [routerLink]="['/groupBuy/info', item.ID, initiatorData.ID]"
                  style="display: block; background-image: url(/api/images/{{item.GroupBuyProduct.Product.coverImg}});">
                  <a class="link-spanner"></a>

                  <div class="product-countdown" *ngIf="item.allowLimitedTime == 1 && item.showTimer == 1">
                    <ngx-countdown [time]="item.leftTime" [render]="render" format="HH:mm:ss" [millisecond]="true">
                      <ng-template #render let-data>
                        <div class="countdown">
                          <div><span class="material-icons">schedule</span></div>
                          <div><span>{{ data.fragments[0] }}</span> <small>{{"時" | translate}}</small></div>
                          <div><span>{{ data.fragments[1] }}</span> <small>{{"分" | translate}}</small></div>
                          <div><span>{{ data.fragments[2] }}</span> <small>{{"秒" | translate}}</small></div>
                        </div>
                      </ng-template>
                    </ngx-countdown>
                  </div>
                </div>
                <div class="product-card-body">
                  <div class="product-title text-truncate">
                    <a [routerLink]="['/groupBuy/info', item.ID, initiatorData.ID]">
                      {{item.GroupBuyProduct.Product.title | googleTranslate | async}}</a>
                  </div>
                  <div class="product-des">
                    {{item.GroupBuyProduct.Product.Country.name | googleTranslate | async}}
                    {{item.GroupBuyProduct.Product.Region.name | googleTranslate | async}}
                  </div>
                  <div class="d-flex flex-row justify-content-between">
                    <div class="product-des">
                      {{"觀看次數" | translate}}：{{item.GroupBuyProduct.Product.viewCount}}{{"次" | translate}}
                    </div>
                    <div class="product-price text-sale align-self-end">
                      <span *ngIf="item.GroupBuyProduct.discountType == 0"
                        [innerHTML]="item.GroupBuyProduct.Product.lowestPrice | currencyExchange:true:false | async"></span>
                      <span *ngIf="item.GroupBuyProduct.discountType !== 0"
                        [innerHTML]="item.GroupBuyProduct.Product.lowestPrice - item.GroupBuyProduct.discountValue | currencyExchange:true:false | async"></span>
                      <small>{{"起" | translate}}</small>
                      <small class="text-gray-m ml-auto pr-2 align-self-center pl-1"
                        *ngIf="item.GroupBuyProduct.discountValue > 0">
                        <s>{{ item.GroupBuyProduct.Product.lowestPrice | currencyExchange:false:true | async}}</s>
                      </small>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="footer py-5" [innerHTML]="initiatorData.footer" (click)="showLoginModal()"></div>

    </div>
  </div>
</div>


<app-initiator-dashboard *ngIf="showDashboard" [id]="id" [initiatorData]="initiatorData"
  (closeShowDashboard)="closeShowDashboard($event)"></app-initiator-dashboard>


<div bsModal #myModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h4 class="modal-title">{{"團長登入" | translate}}</h4>
        <button type="button" class="close" (click)="myModal.hide();loginClick = 0;" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="form-group row">
          <label class="col-md-3 col-form-label required-text">{{"請輸入密碼" | translate}}</label>
          <div class="col-md-9">
            <input type="password" name="initiatorPwd" class="form-control" [placeholder]="'請輸入密碼' | translate"
              [(ngModel)]="initiatorPwd">
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="myModal.hide(); loginClick = 0;">
          {{"關閉" | translate}}</button>
        <button type="submit" class="btn btn-primary" [disabled]="!initiatorPwd" (click)="onSubmit()">
          {{"送出" | translate}}</button>
      </div>

    </div>
  </div>
</div>

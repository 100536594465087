<div class="content-cart">
  <div class="container-fluid content-wrapper position-relative">
    <h5>{{"購物車" | translate}}</h5>

    <div class="bg-white" *ngIf="cartDataAble">
      <div class="">
        <div class="row no-gutters cart-item" *ngFor="let item of cartDataAble; let i = index">
          <div class="col-3 col-md-2 text-center">
            <img class="productImg" [src]="'/api/images/' + item.productImg">
          </div>
          <div class="col-9 col-md-9 d-md-flex">
            <div class="flex-grow-1">
              <div class="product-name" [routerLink]="['/product', item.productId]">{{item.productTitle |
                googleTranslate | async}}</div>
              <div class="product-option">{{item.planName | googleTranslate | async}}</div>
              <div>
                <small class="text-black-50">
                  <ul class="pl-4">
                    <li>{{item.specName | googleTranslate | async}}</li>
                    <li *ngIf="item.extraOptionId !== 0">{{item.extraName | googleTranslate | async}}</li>
                  </ul>
                </small>
              </div>
              <div class="product-quant d-flex align-items-center">
                <span class="material-icons px-1">shopping_cart</span>
                X {{item.quantity}} {{item.productData.planSpecification.unit | googleTranslate | async}}
                <span class="material-icons px-1 ml-3">calendar_today</span>
                {{item.date}}
              </div>
            </div>
            <div>
              <strong class="text-price"
                [innerHTML]="item.total - (item.totalDeduct ? item.totalDeduct : 0) | currencyExchange:true:false | async"></strong>
            </div>
          </div>
          <button type="button" class="btn btn-link btn-xs btn-delete">
            <span class="material-icons" (click)="deleteItem(i)">delete_outline</span>
          </button>
        </div>
      </div>
    </div>

    <div class="bg-white cart-footer mt-4 p-3 ">
      <div class="row justify-content-end text-right">
        <div class="col-12 my-1">
          <div class="row justify-content-end">
            <span class="col-5 col-md-3">{{cartDataAble.length}} {{"件商品合計" | translate}}</span>
            <span class="col-5 col-md-3 col-lg-2 text-price font-weight-bold"
              [innerHTML]="totalAmount - totalDeduct | currencyExchange:true:false | async"></span>
          </div>
        </div>
      </div>

      <div class="row justify-content-end mt-2">
        <div class="col-6 col-md-4 col-lg-3 pr-1">
          <a [routerLink]="['/productCategory']" class="btn btn-block btn-outline-primary rounded-0">
            {{"繼續購物" | translate}}
          </a>
        </div>
        <div class="col-6 col-md-4 col-lg-3 pl-1">
          <button [disabled]="cartDataAble.length < 1" (click)="placeOrder()"
            class="btn btn-block btn-primary rounded-0 text-white">
            {{"前往結帳" | translate}}
          </button>
        </div>
      </div>
    </div>

    <h5>{{"已過期的商品" | translate}}</h5>

    <div class="bg-white mb-4" *ngIf="cartDataUnable">
      <div class="">
        <div class="row no-gutters cart-item" *ngFor="let item of cartDataUnable; let i = index">
          <div class="col-3 col-md-2 text-center">
            <img class="productImg" [src]="'/api/images/' + item.productImg">
          </div>
          <div class="col-9 col-md-9 d-md-flex">
            <div class="flex-grow-1">
              <div class="product-name">{{item.productTitle | googleTranslate | async}}</div>
              <div class="product-option">{{item.planName | googleTranslate | async}}</div>
              <div class="product-quant d-flex align-items-center">
                <span class="material-icons px-1">shopping_cart</span>
                X {{item.quantity}} {{item.productData.planSpecification.unit | googleTranslate | async}}
                <span class="material-icons px-1 ml-3">calendar_today</span>
                {{item.date}}
              </div>
            </div>
            <div>
              <strong class="text-price" [innerHTML]="item.total | currencyExchange:true:false | async"></strong>
            </div>
          </div>
          <button type="button" class="btn btn-link btn-xs btn-delete">
            <span class="material-icons" (click)="deleteItem(i)">delete_outline</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>

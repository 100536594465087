import { NavigationEnd, Router } from '@angular/router';
import { LoginService } from './../../../core/services/login.service';
import { Component, OnInit } from '@angular/core';
import { MemberService } from '../member.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-member-layout',
  templateUrl: './member-layout.component.html',
  styleUrls: ['./member-layout.component.scss']
})
export class MemberLayoutComponent implements OnInit {
  firstName: string;
  lastName: string;
  imgSrc: string;
  // tslint:disable-next-line: new-parens
  picture = new FormData;
  userInfo: any;

  constructor(public loginService: LoginService,
    public memberService: MemberService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.getMemberInfo();
    this.memberService.currentUser.subscribe(user => this.userInfo = user);
    if (!this.memberService.pathSelected) {
      this.memberService.pathSelected = window.location.pathname.split('/')[2];
    }
  }

  getMemberInfo() {
    this.memberService.getMemberInfo()
      .subscribe((v: any) => {
        this.imgSrc = v.user_info.picture !== '' && v.user_info.picture.length < 10 ?
          '/api/images/' + v.user_info.picture : v.user_info.picture;
        this.memberService.renewUserInfo({
          firstName: v.user_info.firstName,
          lastName: v.user_info.lastName,
          picture: this.imgSrc,
        });
      },
        (e) => { console.log(e); },
      );
  }

  onFileSelect(event) {
    if (event.target.files[0].size > 500000) {
      alert('圖片大小必須在500k以下。');
    } else {
      const file = event.target.files[0];
      const reader = new FileReader();
      // tslint:disable-next-line:new-parens
      reader.readAsDataURL(file);
      reader.onload = (res) => {
        this.imgSrc = reader.result as string;
      };
      this.picture.append('picture', file);
      this.memberService.updatePicture(this.picture).subscribe(res => {
        if (res.result) {
          this.toastr.success('已成功更新會員資料', '系統訊息');
          this.getMemberInfo();
        }
      },
        (e) => {
          // this.showAlert(e.statusText);
          console.log(e);
        },
      );
    }
  }

}

import { environment } from './../../../environments/environment';
import { AlertDialogComponent } from './../../shared/dialogs/alert-dialog/alert-dialog.component';
import { ContactService } from './contact.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  form: FormGroup;
  budgetOptions = ['1萬元以下', '1~2萬元', '2~4萬元', '4~6萬元', '6~8萬元', '8萬元以上'];
  dayOptions = ['3~5天', '6~10天', '11~15天', '16~20天', '20天以上'];
  areaOptions = ['台北（松山）', '桃園', '台中', '高雄'];
  categories: any;
  bsModalRef: BsModalRef;

  public readonly siteKey = environment.siteKey;
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  constructor(private contactService: ContactService,
              private cdr: ChangeDetectorRef,
              private formBuilder: FormBuilder,
              private router: Router,
              private modalService: BsModalService) { }

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories() {
    this.contactService.getContactCategories()
    .subscribe((v: any) => {
      if (v.result) {
        this.categories = v.data;
        this.form = this.formBuilder.group({
          categoryId: ['', [Validators.required]],
          name: ['', [Validators.required]],
          phone: ['', [Validators.required]],
          email: ['', [Validators.required, Validators.email]],
          budget: [''],
          travelDay: [''],
          departurePlace: [''],
          departureDate: [''],
          content: ['', [Validators.required]],
          recaptcha: ['', Validators.required]
        });
      }
    },
      (e) => { console.log(e); },
    );
  }

  get f(): { [key: string]: AbstractControl; } { return this.form.controls; }

  onSubmit() {
    this.form.value.categoryId = +this.form.value.categoryId;
    this.contactService.createContact(this.form.value).subscribe(res => {
      if (res.result) {
        this.showAlert('您的訊息已送出! 我們將盡快回覆您', 'btn btn-success', 'modal-success');
        this.router.navigate(['/']);
      }
    },
      (e) => {
        this.showAlert('傳送失敗', 'btn btn-danger', 'modal-danger');
        console.log(e);
      },
    );
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }

  showAlert(content: string, btnClass: string, theClass: string) {
    const config = {
      initialState: {
        title: '系統訊息',
        content,
        btnClass
      },
      class: theClass
    };
    this.bsModalRef = this.modalService.show(AlertDialogComponent, config);
  }

}


import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap';
import { LayoutService } from './../layout.service';
import { IndexService } from './../../index/index.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { SearchComponent } from 'src/app/shared/search/search.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  hasSale = false;
  socialLinks: any;
  exploreWorldTitle = '探索世界';
  bsModalRef: BsModalRef;

  constructor(
    private router: Router,
    private indexService: IndexService,
    private titleService: Title,
    private metaService: Meta,
    private modalService: BsModalService,
    private layoutService: LayoutService) { }

  ngOnInit(): void {
    this.indexService.getGrabToday()
      .subscribe((r: any) => {
        if (r.result && r.data) {
          this.hasSale = true;
        }
      });

    this.layoutService.getSystemSetting().subscribe((v: any) => {
      if (v.result && v.data) {
        this.exploreWorldTitle = v.data.ExploreWorldTitle;
        this.socialLinks = v.data.socialLink;
        this.titleService.setTitle(v.data.siteTitle);
        this.metaService.updateTag({ name: 'description', content: v.data.siteDescription });
      }
    });
  }

  showSearch() {
    const config = {
      class: 'ModalSearch',
    };
    this.bsModalRef = this.modalService.show(SearchComponent, config);
  }

  goGrabToday() {
    const target = document.getElementById('grabToday');
    window.scrollTo({ behavior: 'smooth', top: target.offsetTop });
  }

}

import { GroupBuyOrderInfoComponent } from './modules/group-buy/group-buy-order-info/group-buy-order-info.component';
import { GroupBuyInitiatorComponent } from './modules/group-buy/group-buy-initiator/group-buy-initiator.component';
import { GroupBuyInfoComponent } from './modules/group-buy/group-buy-info/group-buy-info.component';
import { ProductSearchComponent } from './modules/product/product-search/product-search.component';
import { RecentViewComponent } from './modules/recent-view/recent-view.component';
import { EditorPagesComponent } from './modules/editor-pages/editor-pages.component';
import { HotComponent } from './modules/hot/hot.component';
import { FeaturedComponent } from './modules/featured/featured.component';
import { VideoComponent } from './modules/video/video.component';
import { IndexComponent } from './modules/index/index.component';
import { AuthGuard } from './core/guards/auth.guard';
import { MemberSavedComponent } from './modules/member/member-saved/member-saved.component';
import { MemberLayoutComponent } from './modules/member/member-layout/member-layout.component';
import { MemberSettingComponent } from './modules/member/member-setting/member-setting.component';
import { PayLayoutComponent } from './modules/pay/pay-layout/pay-layout.component';
import { CartComponent } from './modules/pay/cart/cart.component';
import { ProductInfoComponent } from './modules/product/product-info/product-info.component';
import { ProductCategoryComponent } from './modules/product/product-category/product-category.component';
import { LayoutComponent } from './modules/layout/layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PayInfoComponent } from './modules/pay/pay-info/pay-info.component';
import { MemberCouponComponent } from './modules/member/member-coupon/member-coupon.component';
import { MemberOrderComponent } from './modules/member/member-order/member-order.component';
import { MemberOrderInfoComponent } from './modules/member/member-order-info/member-order-info.component';
import { FaqComponent } from './modules/faq/faq.component';
import { ContactComponent } from './modules/contact/contact.component';
import { ConfirmComponent } from './modules/pay/confirm/confirm.component';
import { FeaturedDetailComponent } from './modules/featured/featured-detail/featured-detail.component';
import { GroupTourProductComponent } from './modules/group-tour/group-tour-product/group-tour-product.component';
import { GroupTourProductSelectComponent } from './modules/group-tour/group-tour-product-select/group-tour-product-select.component';
import { MemberGroupOrderComponent } from './modules/member/member-group-order/member-group-order.component';


export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: IndexComponent,
      },
      {
        path: 'productCategory',
        component: ProductCategoryComponent,
      },
      {
        path: 'productCategory/:tagId',
        component: ProductCategoryComponent,
      },
      {
        path: 'productCategory/:tagId/:regionId',
        component: ProductCategoryComponent,
      },
      {
        path: 'product/:id',
        component: ProductInfoComponent,
      },
      {
        path: 'productSearch/:filter',
        component: ProductSearchComponent,
      },
      {
        path: 'groupTour/product/:id',
        component: GroupTourProductComponent,
      },
      {
        path: 'faq',
        component: FaqComponent,
      },
      {
        path: 'about',
        component: EditorPagesComponent,
      },
      {
        path: 'privacy',
        component: EditorPagesComponent,
      },
      {
        path: 'fraud',
        component: EditorPagesComponent,
      },
      {
        path: 'instruction',
        component: EditorPagesComponent,
      },
      {
        path: 'contact',
        component: ContactComponent,
      },
      {
        path: 'video',
        component: VideoComponent,
      },
      {
        path: 'featured',
        component: FeaturedComponent,
      },
      {
        path: 'featuredDetail/:id',
        component: FeaturedDetailComponent,
      },
      {
        path: 'categoryDetail/:id',
        component: FeaturedDetailComponent,
      },
      {
        path: 'hot',
        component: HotComponent,
      },
      {
        path: 'recentView',
        component: RecentViewComponent,
      },
      // { path: '**', redirectTo: '', component: IndexComponent }
    ]
  },
  {
    path: 'pay',
    component: PayLayoutComponent,
    children: [
      {
        path: 'cart',
        component: CartComponent,
      },
      {
        path: 'info',
        component: PayInfoComponent,
      },
      {
        path: 'confirm',
        component: ConfirmComponent,
      },
      {
        path: 'confirm/:result',
        component: ConfirmComponent,
      },
      {
        path: 'groupTour/:id/:optionId',
        component: GroupTourProductSelectComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'member',
    component: MemberLayoutComponent,
    children: [
      {
        path: 'setting',
        component: MemberSettingComponent,
      },
      {
        path: 'coupon',
        component: MemberCouponComponent,
      },
      {
        path: 'groupOrder',
        component: MemberGroupOrderComponent,
      },
      {
        path: 'order',
        component: MemberOrderComponent,
      },
      {
        path: 'orderInfo/:number',
        component: MemberOrderInfoComponent,
      },
      {
        path: 'saved',
        component: MemberSavedComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  { path: 'groupBuy/info/:id/:initiatorID', component: GroupBuyInfoComponent },
  { path: 'groupBuy/initiator/:id', component: GroupBuyInitiatorComponent },
  { path: 'groupBuy/orderInfo/:number', component: GroupBuyOrderInfoComponent },
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '**', redirectTo: '', component: IndexComponent }
    ]
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

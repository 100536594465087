<div class="page-groupbuy" *ngIf="groupBuyData">
  <div class="wrapper">
    <header class="d-flex">
      <a [routerLink]="['/groupBuy/initiator', initiatorData.ID]">
        <img [src]="'/api/images/' + initiatorData.logo" alt="logo">
      </a>
      <div class="d-flex align-items-center ml-auto">
        <!-- <ul class="nav user-nav pr-2">
          <li class="nav-item">
            <div class="dropdown pl-2 pr-2 border rounded">
              <a class="dropdown-toggle d-flex align-items-center py-1" id="dropdownMenu3" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <span class="material-icons pr-1">language</span>{{selectedLanguage}}
              </a>
              <div class="dropdown-menu dropdown-menu-lg-right dropdown-style" aria-labelledby="dropdownMenu3">
                <a class="dropdown-item" type="button" *ngFor="let lang of languageList"
                  (click)="changeLanguage(lang.value); this.selectedLanguage = lang.name">{{lang.name}}</a>
              </div>
            </div>
          </li>
        </ul> -->
        <ul class="nav user-nav pr-2">
          <li class="nav-item">
            <div class="dropdown pl-2 pr-2 border rounded">
              <a class="dropdown-toggle d-flex align-items-center py-1" id="dropdownMenu3" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                {{selectedCurrency}}
              </a>
              <div class="dropdown-menu dropdown-menu-right dropdown-style" aria-labelledby="dropdownMenu3"
                *ngIf="initiatorData.currencyList.length > 0">
                <a class="dropdown-item" *ngFor="let currency of initiatorData.currencyList"
                  (click)="changeCurrency(currency)">{{currency}}</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </header>

    <div class="content-main">
      <div class="container-fluid content-wrapper">
        <h3 class="d-none d-lg-block mt-3">
          <a [routerLink]="['/groupBuy/initiator', initiatorData.ID]" class="text-dark" data-toggle="tooltip"
            data-placement="bottom" title="回上頁">
            <span class="material-icons">arrow_back_ios_new</span>
          </a>
          {{productData.title | googleTranslate | async}}
        </h3>

        <div class="row mt-2">
          <div class="col-md-8">
            <div class="js-banner">
              <div *ngIf="productData.coverVideo">
                <div class="embed-responsive embed-responsive-16by9" [innerHTML]="productData.coverVideo"></div>
              </div>
              <div>
                <div>
                  <img [src]="'/api/images/' + this.productData.coverImg" class="img-fluid">
                </div>
              </div>
              <div *ngFor="let carousel of productData.carousel">
                <div>
                  <img [src]="'/api/images/' + carousel" class="img-fluid">
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card product-card-selected">
              <div class="card-body">

                <div class="product-card-selected-title">
                  {{productData.title | googleTranslate | async}}
                  <div class="product-price">
                    <span
                      [innerHTML]="groupBuyData.GroupBuyProduct.Product.lowestPrice - lowestDiscountValue | currencyExchange:true:false | async"></span>
                    <small class="ml-1">{{"起" | translate}}</small>
                  </div>
                </div>

                <div class="product-card-selected-option" *ngIf="this.groupBuyData.allowLimitedTime == 1">
                  <div>{{"開始時間" | translate}}： {{groupBuyData.startDate}}</div>
                  <div>{{"結束時間" | translate}}： {{groupBuyData.endDate}}</div>
                  <div class="product-countdown" *ngIf="this.groupBuyData.showTimer == 1">
                    <ngx-countdown [time]="groupBuyData.leftTime" [render]="render" format="HH:mm:ss"
                      [millisecond]="true">
                      <ng-template #render let-data>
                        <div class="countdown">
                          <div><span class="material-icons">schedule</span></div>
                          <div><span>{{ data.fragments[0] }}</span> <small>{{"時" | translate}}</small></div>
                          <div><span>{{ data.fragments[1] }}</span> <small>{{"分" | translate}}</small></div>
                          <div><span>{{ data.fragments[2] }}</span> <small>{{"秒" | translate}}</small></div>
                        </div>
                      </ng-template>
                    </ngx-countdown>
                  </div>

                  <div class="product-card-selected-action">
                    <a class="btn btn-primary btn-block rounded-0 text-white" href="#planOption" pageScroll
                      [pageScrollDuration]="500"> {{"選擇方案" | translate}}</a>
                    <div class="payment-option">
                      <img src="assets/images/product-payment.png" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div id="stickyMenu" class="sticky-tab d-none">
          <div class="content-wrapper mx-auto">
            <nav>
              <ul class="nav product-tab product-tab-bg tab-scrollable" role="navigation" snScrollSpy id="foo">
                <li class="nav-item" *ngIf="productData.detail.changingThisBreaksApplicationSecurity">
                  <a class="nav-link" href="#detail" uniScrollItem="detail-s" pageScroll [pageScrollDuration]="500"
                    [ngClass]="{ 'active': tabSelected == 1 }" (click)="tabSelected = 1">{{"商品明細" | translate}}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#planOption" uniScrollItem="planOption-s" pageScroll
                    [pageScrollDuration]="500" [ngClass]="{ 'active': tabSelected == 2 }"
                    (click)="tabSelected = 2">{{"方案選項" | translate}}</a>
                </li>
                <li class="nav-item" *ngIf="productData.introduction.changingThisBreaksApplicationSecurity">
                  <a class="nav-link" href="#introduction" uniScrollItem="introduction-s" pageScroll
                    [pageScrollDuration]="500" [ngClass]="{ 'active': tabSelected == 3 }"
                    (click)="tabSelected = 3">{{"行程介紹" | translate}}</a>
                </li>
                <li class="nav-item" *ngIf="productData.videoContext.changingThisBreaksApplicationSecurity">
                  <a class="nav-link" href="#videoContext" uniScrollItem="videoContext-s" pageScroll
                    [pageScrollDuration]="500" [ngClass]="{ 'active': tabSelected == 4 }"
                    (click)="tabSelected = 4">{{"圖文影音" | translate}}</a>
                </li>
                <li class="nav-item" *ngIf="productData.description.changingThisBreaksApplicationSecurity">
                  <a class="nav-link" href="#description" uniScrollItem="description-s" pageScroll
                    [pageScrollDuration]="500" [ngClass]="{ 'active': tabSelected == 5 }"
                    (click)="tabSelected = 5">{{"費用說明" | translate}}</a>
                </li>
                <li class="nav-item" *ngIf="productData.instruction.changingThisBreaksApplicationSecurity">
                  <a class="nav-link" href="#instruction" uniScrollItem="instruction-s" pageScroll
                    [pageScrollDuration]="500" [ngClass]="{ 'active': tabSelected == 6 }"
                    (click)="tabSelected = 6">{{"使用方式" | translate}}</a>
                </li>
                <li class="nav-item" *ngIf="productData.map.changingThisBreaksApplicationSecurity">
                  <a class="nav-link" href="#map" uniScrollItem="map-s" pageScroll [pageScrollDuration]="500"
                    [ngClass]="{ 'active': tabSelected == 7 }" (click)="tabSelected = 7">{{"地圖" | translate}}</a>
                </li>
                <li class="nav-item" *ngIf="productData.notice.changingThisBreaksApplicationSecurity">
                  <a class="nav-link" href="#notice" uniScrollItem="notice-s" pageScroll [pageScrollDuration]="500"
                    [ngClass]="{ 'active': tabSelected == 8 }" (click)="tabSelected = 8">{{"注意事項" | translate}}</a>
                </li>
                <li class="nav-item" *ngIf="productData.policy.changingThisBreaksApplicationSecurity">
                  <a class="nav-link" href="#policy" uniScrollItem="policy-s" pageScroll [pageScrollDuration]="500"
                    [ngClass]="{ 'active': tabSelected == 9 }" (click)="tabSelected = 9">{{"取消政策" | translate}}</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <ul id="productTab" class="nav product-tab product-tab-bg tab-scrollable  mx-n3 mx-lg-0">
          <li class="nav-item" *ngIf="productData.detail.changingThisBreaksApplicationSecurity">
            <a class="nav-link" href="#detail" uniScrollItem="detail-s" pageScroll [pageScrollDuration]="500"
              [ngClass]="{ 'active': currentSection == 'detail' }" (click)="currentSection = 'detail'">{{"商品明細" |
              translate}}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#planOption" uniScrollItem="planOption-s" pageScroll [pageScrollDuration]="500"
              [ngClass]="{ 'active': currentSection == 'planOption' }" (click)="currentSection = 'planOption'">{{"方案選項"
              | translate}}</a>
          </li>
          <li class="nav-item" *ngIf="productData.introduction.changingThisBreaksApplicationSecurity">
            <a class="nav-link" href="#introduction" uniScrollItem="introduction-s" pageScroll
              [pageScrollDuration]="500" [ngClass]="{ 'active': currentSection == 'introduction' }"
              (click)="currentSection = 'introduction'">{{"行程介紹" | translate}}</a>
          </li>
          <li class="nav-item" *ngIf="productData.videoContext.changingThisBreaksApplicationSecurity">
            <a class="nav-link" href="#videoContext" uniScrollItem="videoContext-s" pageScroll
              [pageScrollDuration]="500" [ngClass]="{ 'active': currentSection == 'videoContext' }"
              (click)="currentSection = 'videoContext'">{{"圖文影音" | translate}}</a>
          </li>
          <li class="nav-item" *ngIf="productData.description.changingThisBreaksApplicationSecurity">
            <a class="nav-link" href="#description" uniScrollItem="description-s" pageScroll [pageScrollDuration]="500"
              [ngClass]="{ 'active': currentSection == 'description' }"
              (click)="currentSection = 'description'">{{"費用說明" | translate}}</a>
          </li>
          <li class="nav-item" *ngIf="productData.instruction.changingThisBreaksApplicationSecurity">
            <a class="nav-link" href="#instruction" uniScrollItem="instruction-s" pageScroll [pageScrollDuration]="500"
              [ngClass]="{ 'active': currentSection == 'instruction' }"
              (click)="currentSection = 'instruction'">{{"使用方式" | translate}}</a>
          </li>
          <li class="nav-item" *ngIf="productData.map.changingThisBreaksApplicationSecurity">
            <a class="nav-link" href="#map" uniScrollItem="map-s" pageScroll [pageScrollDuration]="500"
              [ngClass]="{ 'active': currentSection == 'map' }" (click)="currentSection = 'map'">{{"地圖" |
              translate}}</a>
          </li>
          <li class="nav-item" *ngIf="productData.notice.changingThisBreaksApplicationSecurity">
            <a class="nav-link" href="#notice" uniScrollItem="notice-s" pageScroll [pageScrollDuration]="500"
              [ngClass]="{ 'active': currentSection == 'notice' }" (click)="currentSection = 'notice'">{{"注意事項" |
              translate}}</a>
          </li>
          <li class="nav-item" *ngIf="productData.policy.changingThisBreaksApplicationSecurity">
            <a class="nav-link" href="#policy" uniScrollItem="policy-s" pageScroll [pageScrollDuration]="500"
              [ngClass]="{ 'active': currentSection == 'policy' }" (click)="currentSection = 'policy'">{{"取消政策" |
              translate}}</a>
          </li>
        </ul>

      </div>


      <div>
        <section uniScrollSpy="detail-s" class="container-fluid content-wrapper">
          <div class="row section-product" *ngIf="productData.detail.changingThisBreaksApplicationSecurity"
            style="position:relative;">
            <div id="detail" class="scrollHere"></div>
            <div class="col">
              <h3 class="product-section-title">{{"商品明細" | translate}}</h3>
              <div class="pl-3" [innerHTML]="productData.detail">
              </div>
            </div>
          </div>
        </section>

        <section uniScrollSpy="planOption-s" class="container-fluid" style="position:relative;">
          <div id="planOption" class="scrollHere"></div>
          <div class="row section-product product-options">
            <div class="col-12 content-wrapper mx-auto">
              <h3 class="product-section-title">{{"方案選項" | translate}}</h3>

              <div class="row">
                <div class="col-md-8">

                  <div *ngFor="let plan of productData.ProductPlans; let i = index">
                    <div class="card product-options-item" *ngIf="plan.show">
                      <div class="product-options-item-title">
                        <h3>{{plan.name | googleTranslate | async}}
                          <span class="product-price float-right" *ngIf="plan.lowestPrice > 0">
                            <span
                              [innerHTML]="plan.lowestPrice - plan.planLowestDiscountValue | currencyExchange:true:false | async"></span>
                            <small class="ml-1">{{"起" | translate}}</small>
                          </span>
                          <!-- <span class="product-price float-right text-black-50 mr-3"
                            *ngIf="plan.planLowestPrice !== '--'">
                            <s>
                              <span [innerHTML]="plan.planLowestPrice - plan.planLowestDiscountValue | currencyExchange:true:false | async"></span>
                              <small class="ml-1">起</small>
                            </s>
                          </span> -->
                        </h3>
                      </div>

                      <div class="product-options-item-action" *ngIf="!plan.selected">
                        <button class="btn btn-outline-primary float-right" (click)="plan.selected = true">
                          {{"選擇" | translate}}</button>
                      </div>

                      <div class="product-options-item-expand" *ngIf="plan.selected">

                        <a href="javascript:void(0)" class="close">
                          <span class="material-icons" (click)="plan.selected = false">close</span>
                        </a>

                        <div class="form-group">
                          <div class="title">{{"票種" | translate}}</div>
                          <div class="d-flex flex-row">
                            <div *ngFor="let spec of plan.PlanSpecification; let si = index">
                              <input class="d-none check-label" type="radio" id="spec{{spec.ID}}"
                                [checked]="spec.checked" />
                              <label class="button-label" for="spec{{spec.ID}}"
                                (click)="setPlanSpec(plan.ID, spec.ID, i, si);">
                                <span>{{spec.name | googleTranslate | async}}</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="form-group" *ngIf="plan.ExtraPurchase">
                          <div class="title">{{"加購" | translate}}</div>
                          <div class="d-flex flex-row">
                            <div *ngFor="let extra of plan.ExtraPurchase">
                              <input class="d-none check-label" type="radio" id="extra{{extra.ID}}"
                                [checked]="extra.checked" />
                              <label class="button-label" for="extra{{extra.ID}}"
                                (click)="setPlanExtra(plan.ID, extra.ID, i);extra.checked=!extra.checked">
                                <span>{{extra.name | googleTranslate | async}}</span>
                              </label>
                            </div>
                          </div>

                        </div>

                        <div class="row px-3 pb-3" *ngIf="plan.specData.id !== 0">
                          <div class="col-md-7">
                            <div class="title">{{"日期" | translate}}</div>

                            <div class="calendar-area">
                              <div id="calendar" class="my-4">
                                <div id="calendar_header">
                                  <div class="title">
                                    {{plan.specData.currentMonth}} - {{plan.specData.currentYear}}
                                  </div>
                                  <button class="arrow material-icons" (click)="goToLastMonth(i)">
                                    navigate_before
                                  </button>
                                  <button class="arrow material-icons" (click)="goToNextMonth(i)">
                                    navigate_next
                                  </button>
                                </div>
                                <div id="calendar_weekdays">
                                  <div *ngFor="let day of dayNames" class="calendar_weekday">{{day}}</div>
                                </div>

                                <div id="calendar_content">
                                  <div *ngFor="let dayItem of plan.specData.daysInLastMonth">
                                    <div class="week-date disabled">
                                      <span class="date-text">{{dayItem.day}}</span>
                                    </div>
                                  </div>
                                  <div *ngFor="let dayItem of plan.specData.daysInThisMonth">
                                    <div class="week-date disabled" *ngIf="(plan.specData.currentDate!=999 && dayItem.day < plan.specData.currentDate) ||
                                      dayItem.quantity < plan.personCount || dayItem.quantity == 0 || dayItem.quantity < plan.personCount || !dayItem.canBuy ||
                                      dayItem.quantity < plan.specData.minimumQuantity">
                                      <span class="date-text">{{dayItem.day}}</span>
                                      <small class="date-content"
                                        *ngIf="dayItem.price > 0 && dayItem.quantity == 0">已額滿</small>
                                    </div>
                                    <div class="week-date enabled clickable"
                                      (click)="setPlanOption(i, dayItem.id, dayItem.price, dayItem.discountValue, dayItem.day, dayItem.extraOptionId)"
                                      [ngClass]="{'selected' : dayItem.selected }" *ngIf="(plan.specData.currentDate == 999 ||
                                        dayItem.day >= plan.specData.currentDate) && dayItem.quantity != 0 && dayItem.quantity >= plan.personCount && dayItem.canBuy &&
                                        dayItem.quantity >= plan.specData.minimumQuantity">
                                      <div *ngIf="dayItem.selected" class="custom-tooltip">
                                        {{ '剩餘可售數量' | translate }} {{ remainQuantity }}
                                      </div>
                                      <span class="date-text"
                                        [ngClass]="{'today' : dayItem.today }">{{dayItem.day}}</span>
                                      <small class="date-content"
                                        [innerHTML]="dayItem.price - dayItem.discountValue | currencyExchange:false:false | async"
                                        (click)="setPlanOption(i, dayItem.id, dayItem.price,  dayItem.discountValue, dayItem.day, dayItem.extraOptionId)"></small>
                                    </div>
                                  </div>
                                  <div *ngFor="let dayItem of plan.specData.daysInNextMonth">
                                    <div class="week-date disabled">
                                      <span class="date-text">{{dayItem.day}}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div class="col-md-5 mt-4 mt-md-0">
                            <div class="title">{{"數量" | translate}}</div>
                            <div class="counter-item">
                              <div class="counter-title">{{plan.specData.unit | googleTranslate | async}}</div>

                              <div class="counter">
                                <button class="counter-btn disabled" data-original-title="總共至少 1 人"
                                  (click)="plan.personCount = plan.personCount > plan.specData.minimumQuantity ? plan.personCount-1 : plan.personCount">
                                  <span class="material-icons">remove_circle_outline</span>
                                </button>
                                <input type="text" readonly="readonly" class="counter-num" [value]="plan.personCount"
                                  [(ngModel)]="plan.personCount">
                                <button class="counter-btn" (click)="plan.personCount = plan.personCount+1">
                                  <span class="material-icons">add_circle_outline</span>
                                </button>
                              </div>
                            </div>
                            <div class="total-price text-right">{{"總金額" | translate}}
                              <span class="text-primary" *ngIf="plan.price == 0">0</span>
                              <span class="text-primary" *ngIf="plan.price !== 0" [innerHTML]="(plan.price - plan.discountValue) * plan.personCount |
                                  currencyExchange:true:false | async"></span>
                            </div>
                            <div class="row no-gutters mt-4">
                              <!-- <div class="col-6 pr-1">
                                <button class="btn btn-primary btn-block rounded-0" (click)="addCart(i)"
                                  [disabled]="plan.specOptionId === 0">加入購物車
                                </button>
                              </div> -->
                              <div class="ml-auto col-6">
                                <button data-toggle="modal" data-target="#ModalGroupbuy" type="button"
                                  class="btn btn-primary btn-block rounded-0 text-white"
                                  [disabled]="plan.specOptionId === 0" (click)="placeOrder(i)">{{"立即訂購" | translate}}
                                </button>
                              </div>
                              <!-- <div class="col-12 my-3" style="font-weight: 400; font-size: 0.8em;"
                                *ngIf="plan.specOptionId !== 0">
                                <span style="border-radius: 30px; border: 1px solid #757575; color: #757575;"
                                  class="py-1 px-3 mr-2">{{"報名資訊" | translate}}</span>
                                <span>{{formattedSelectedDate}} {{"剩餘可售數量" | translate}} {{remainQuantity}}</span>
                              </div> -->
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
                <div class="col-md-4"></div>
              </div>
            </div>
          </div>
        </section>

        <section uniScrollSpy="introduction-s" class="container-fluid content-wrapper">
          <div class="row section-product" *ngIf="productData.introduction.changingThisBreaksApplicationSecurity"
            style="position:relative;">
            <div id="introduction" class="scrollHere"></div>
            <div class="col-md-8">
              <h3 class="product-section-title">{{"行程介紹" | translate}}</h3>
              <div class="pl-3" [innerHTML]="productData.introduction"></div>
            </div>
          </div>
        </section>

        <section uniScrollSpy="videoContext-s" class="container-fluid content-wrapper">
          <div class="row section-product" *ngIf="productData.videoContext.changingThisBreaksApplicationSecurity"
            style="position:relative;">
            <div id="videoContext" class="scrollHere"></div>
            <div class="col-md-8">
              <h3 class="product-section-title">{{"圖文影音" | translate}}</h3>
              <div class="pl-3" [innerHTML]="productData.videoContext"></div>
            </div>
          </div>
        </section>

        <section uniScrollSpy="description-s" class="container-fluid content-wrapper">
          <div class="row section-product" *ngIf="productData.description.changingThisBreaksApplicationSecurity"
            style="position:relative;">
            <div id="description" class="scrollHere"></div>
            <div class="col-md-8">
              <h3 class="product-section-title">{{"費用說明" | translate}}</h3>
              <div class="pl-3" [innerHTML]="productData.description"></div>
            </div>
          </div>
        </section>

        <section uniScrollSpy="instruction-s" class="container-fluid content-wrapper">
          <div class="row section-product" *ngIf="productData.instruction.changingThisBreaksApplicationSecurity"
            style="position:relative;">
            <div id="instruction" class="scrollHere"></div>
            <div class="col-md-8">
              <h3 class="product-section-title">{{"使用方式" | translate}}</h3>
              <div class="pl-3" [innerHTML]="productData.instruction"></div>
            </div>
          </div>
        </section>

        <section uniScrollSpy="map-s" class="container-fluid content-wrapper">
          <div class="row section-product" *ngIf="productData.map.changingThisBreaksApplicationSecurity"
            style="position:relative;">
            <div id="map" class="scrollHere"></div>
            <div class="col-md-8">
              <h3 class="product-section-title">{{"地圖" | translate}}</h3>
              <div class="pl-3" [innerHTML]="productData.map"></div>
            </div>
          </div>
        </section>

        <section uniScrollSpy="notice-s" class="container-fluid content-wrapper">
          <div class="row section-product" *ngIf="productData.notice.changingThisBreaksApplicationSecurity"
            style="position:relative;">
            <div id="notice" class="scrollHere"></div>
            <div class="col-md-8">
              <h3 class="product-section-title">{{"注意事項" | translate}}</h3>
              <div class="pl-3" [innerHTML]="productData.notice"></div>
            </div>
          </div>
        </section>

        <section uniScrollSpy="policy-s" class="container-fluid content-wrapper">
          <div class="row section-product" *ngIf="productData.policy.changingThisBreaksApplicationSecurity"
            style="position:relative;">
            <div id="policy" class="scrollHere"></div>
            <div class="col-md-8">
              <h3 class="product-section-title">{{"取消政策" | translate}}</h3>
              <div class="pl-3" [innerHTML]="productData.policy"></div>
            </div>
          </div>
        </section>
      </div>

      <div class="container-fluid">

        <div class="row section-product product-options">
          <div class="col-12 content-wrapper mx-auto py-5">
            <div class="row">
              <div class="col-12 col-md-4 col-lg-3 mx-auto">
                <a class="btn btn-block btn-primary rounded-0 text-white" href="#planOption" pageScroll
                  [pageScrollDuration]="500">
                  {{"選擇方案" | translate}}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer py-5" [innerHTML]="initiatorData.footer"></div>

    </div>
  </div>
  <div #divForm></div>
  <!-- /wrapper -->

  <!-- Modal Groupbuy-->
  <div class="ModalGroupbuy modal fade modal-fullscreen" id="ModalGroupbuy" aria-hidden="true" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content border-0 bg-light">
        <div class="modal-header p-4 text-center border-0">
          <h3 class="text-center w-100 ml-5">{{"行程付款" | translate}}</h3>
          <button type="button" class="close" data-dismiss="modal" #closebutton aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="container-fluid" *ngIf="order.length > 0">
          <div class="row">
            <div class="col-xl-9 mx-auto">
              <div class="bg-white p-3">
                <div class="" role="button">
                  <div class="row no-gutters cart-item px-2">
                    <div class="col-4 col-md-3 col-xl-2 d-flex flex-row">
                      <img class="productImg img-bg align-self-start" style="max-height: 150px;"
                        [src]="'/api/images/' + order[0].productData.product.coverImg">
                    </div>
                    <div class="col-8 col-md-9 col-xl-10 d-md-flex">
                      <div class="flex-grow-1">
                        <div class="product-name">{{order[0].productData.product.title | googleTranslate | async}}</div>
                        <div class="product-option">{{order[0].productData.plan.name | googleTranslate | async}}</div>
                        <div>
                          <small class="text-black-50">
                            <ul class="pl-4">
                              <li>{{order[0].productData.planSpecification.name | googleTranslate | async}}</li>
                              <li *ngIf="order[0].productData.extraOption.id !== 0">
                                {{order[0].productData.extraPurchase.name | googleTranslate | async}}</li>
                            </ul>
                          </small>
                        </div>
                        <div class="product-quant d-flex align-items-center">
                          <span class="material-icons px-1">person_outline</span>
                          X {{order[0].quantity}}
                          <span class="material-icons px-1 ml-3">calendar_today</span>
                          {{order[0].date}}
                        </div>
                      </div>
                      <div>
                        <strong class="text-price"
                          [innerHTML]="order[0].total | currencyExchange:true:false | async"></strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="divider mb-2"></div>

                  <mat-horizontal-stepper linear #stepper>
                    <mat-step [stepControl]="firstFormGroup">

                      <ng-template matStepLabel>{{"訂購人資料" | translate}}</ng-template>

                      <div class="pay-passenger-card rounded">
                        <h6 class="mb-4">{{"訂購人資料" | translate}}</h6>

                        <form action="" method="post" class="form-horizontal" [formGroup]="firstFormGroup">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label>{{"本國名" | translate}} <sub class="text-price">*</sub></label>
                                <input type="text" class="form-control" formControlName="firstName"
                                  [ngClass]="{ 'is-invalid': f.firstName.errors && f.firstName.touched }">
                                <span *ngIf="f.firstName.errors" class="invalid-feedback">{{"本國名" | translate}}{{"為必填" |
                                  translate}}</span>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label>{{"本國姓" | translate}} <sub class="text-price">*</sub></label>
                                <input type="text" class="form-control" formControlName="lastName"
                                  [ngClass]="{ 'is-invalid': f.lastName.errors && f.lastName.touched }">
                                <span *ngIf="f.lastName.errors" class="invalid-feedback">{{"本國姓" | translate}}{{"為必填" |
                                  translate}}</span>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label>{{"國家" | translate}} <sub class="text-price">*</sub></label>
                                <select class="form-control select-arrow" formControlName="country"
                                  [(ngModel)]="country">
                                  <option [value]="country.cn" *ngFor="let country of countries">{{country.cn |
                                    googleTranslate | async}}</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label>{{"聯絡電話" | translate}} <sub class="text-price">*</sub></label>
                                <input type="text" class="form-control" formControlName="phone"
                                  [ngClass]="{ 'is-invalid': f.phone.errors && f.phone.touched }">
                                <span *ngIf="f.phone.errors" class="invalid-feedback">{{"聯絡電話" | translate}}{{"為必填" |
                                  translate}}</span>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-12">
                              <div class="form-group">
                                <label>Email <sub class="text-price">*</sub></label>
                                <input type="email" class="form-control" formControlName="email"
                                  [ngClass]="{ 'is-invalid': f.email.errors && f.email.touched }">
                                <span *ngIf="f.email.errors?.required" class="invalid-feedback">Email{{"為必填" |
                                  translate}}</span>
                                <span *ngIf="f.email.errors?.email" class="invalid-feedback">{{"請填入正確的Email格式" |
                                  translate}}</span>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>

                      <div class="row justify-content-center mt-4">
                        <div class="col-6 col-md-4 col-lg-3 pl-1">
                          <button class="btn btn-block btn-primary rounded-0 text-white" matStepperNext
                            [disabled]="!firstFormGroup.valid">{{"下一步" | translate}}
                          </button>
                        </div>
                      </div>
                    </mat-step>

                    <mat-step>
                      <ng-template matStepLabel>{{"旅客資料" | translate}}</ng-template>
                      <div class="pb-2" *ngFor="let tourist of order[0].touristList; let ti = index;">
                        <div class="pay-passenger-card rounded ">
                          <h6 class="mb-4" *ngIf="order[0].fillRequest == 2">{{"旅客" | translate}} {{tourist.count}}</h6>
                          <h6 class="mb-4" *ngIf="order[0].fillRequest == 1">{{"旅客代表人" | translate}}</h6>
                          <div class="row">
                            <div class="col-md-9">

                              <div *ngFor="let req of tourist.requiredFields">
                                <div *ngIf="req.fieldType == 'input'">
                                  <div class="form-group">
                                    <label>{{req.title | googleTranslate | async}} <sub
                                        class="text-price">*</sub></label>
                                    <input type="text" class="form-control" [(ngModel)]="req.answer">
                                    <div [hidden]="req.answer || !submitClicked" class="alert alert-danger">
                                      {{req.title}}{{"為必填" | translate}}
                                    </div>
                                  </div>
                                </div>
                                <div *ngIf="req.fieldType == 'radio'">
                                  <div class="form-group">
                                    <label>{{req.title | googleTranslate | async}} <sub
                                        class="text-price">*</sub></label><br>
                                    <mat-radio-group [(ngModel)]="req.answer" color="primary">
                                      <mat-radio-button [value]="data" class="mr-3" *ngFor="let data of req.fieldData">
                                        {{data | googleTranslate | async}}
                                      </mat-radio-button>
                                    </mat-radio-group>
                                    <div [hidden]="req.answer || !submitClicked" class="alert alert-danger">
                                      {{req.title}}{{"為必填" | translate}}
                                    </div>
                                  </div>
                                </div>
                                <div *ngIf="req.fieldType == 'textarea'">
                                  <div class="form-group">
                                    <label>{{req.title | googleTranslate | async}} <sub
                                        class="text-price">*</sub></label><br>
                                    <textarea rows="5" class="form-control" [(ngModel)]="req.answer"></textarea>
                                    <div [hidden]="req.answer || !submitClicked" class="alert alert-danger">
                                      {{req.title}}{{"為必填" | translate}}
                                    </div>
                                  </div>
                                </div>
                                <div *ngIf="req.fieldType == 'readonly'">
                                  <div class="form-group">
                                    <label>{{req.title | googleTranslate | async}} <sub
                                        class="text-price">*</sub></label><br>
                                    <textarea rows="5" class="form-control"
                                      disabled>{{req.fieldData | googleTranslate | async}}</textarea>
                                  </div>
                                </div>
                                <div *ngIf="req.fieldType == 'select'">
                                  <div class="form-group">
                                    <label>{{req.title | googleTranslate | async}} <sub
                                        class="text-price">*</sub></label><br>
                                    <select class="form-control select-arrow" [(ngModel)]="req.answer">
                                      <option [value]="field" *ngFor="let field of req.fieldData">{{field |
                                        googleTranslate | async}}</option>
                                    </select>
                                    <div [hidden]="req.answer || !submitClicked" class="alert alert-danger">
                                      {{req.title}}{{"為必填" | translate}}
                                    </div>
                                  </div>
                                </div>
                                <div *ngIf="req.fieldType == 'checkbox'">
                                  <div class="form-group">
                                    <label>{{req.title | googleTranslate | async}} <sub
                                        class="text-price">*</sub></label><br>
                                    <div *ngFor="let data of req.answer">
                                      <mat-checkbox color="primary" [(ngModel)]="data.value">{{data.key |
                                        googleTranslate | async}}
                                      </mat-checkbox><br>
                                    </div>
                                    <div [hidden]="req.checkValid || !submitClicked" class="alert alert-danger">
                                      {{req.title}}{{"為必填" | translate}}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div *ngFor="let opt of tourist.optionalFields">
                                <div *ngIf="opt.fieldType == 'input'">
                                  <div class="form-group">
                                    <label>{{opt.title | googleTranslate | async}}</label>
                                    <input type="text" class="form-control" [(ngModel)]="opt.answer">
                                  </div>
                                </div>
                                <div *ngIf="opt.fieldType == 'radio'">
                                  <div class="form-group">
                                    <label>{{opt.title | googleTranslate | async}}</label><br>
                                    <mat-radio-group [(ngModel)]="opt.answer" color="primary">
                                      <mat-radio-button [value]="data" class="mr-3" *ngFor="let data of opt.fieldData">
                                        {{data | googleTranslate | async}}
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </div>
                                </div>
                                <div *ngIf="opt.fieldType == 'textarea'">
                                  <div class="form-group">
                                    <label>{{opt.title | googleTranslate | async}}</label><br>
                                    <textarea rows="5" class="form-control" [(ngModel)]="opt.answer"></textarea>
                                  </div>
                                </div>
                                <div *ngIf="opt.fieldType == 'readonly'">
                                  <div class="form-group">
                                    <label>{{opt.title | googleTranslate | async}}</label><br>
                                    <textarea rows="5" class="form-control"
                                      disabled>{{opt.fieldData | googleTranslate | async}}</textarea>
                                  </div>
                                </div>
                                <div *ngIf="opt.fieldType == 'select'">
                                  <div class="form-group">
                                    <label>{{opt.title | googleTranslate | async}}</label><br>
                                    <select class="form-control select-arrow" [(ngModel)]="opt.answer">
                                      <option [value]="field" *ngFor="let field of opt.fieldData">{{field |
                                        googleTranslate | async}}</option>
                                    </select>
                                  </div>
                                </div>
                                <div *ngIf="opt.fieldType == 'checkbox'">
                                  <div class="form-group">
                                    <label>{{opt.title | googleTranslate | async}}</label>
                                    <div *ngFor="let data of opt.answer">
                                      <mat-checkbox color="primary" [(ngModel)]="data.value">{{data.key |
                                        googleTranslate | async}}
                                      </mat-checkbox><br>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row justify-content-center">
                        <div class="col-6 col-md-4 col-lg-3 pr-1">
                          <button class="btn btn-block btn-outline-primary rounded-0" matStepperPrevious>
                            {{"上一步" | translate}}
                          </button>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3 pl-1">
                          <button class="btn btn-block btn-primary rounded-0 text-white" (click)="checkTouristForm()">
                            {{"下一步" | translate}}
                          </button>
                        </div>
                      </div>
                    </mat-step>

                    <mat-step>
                      <ng-template matStepLabel>{{"確認付款" | translate}}</ng-template>
                      <div class="pay-passenger-card rounded ">
                        <h6 class="mb-4">{{"使用折扣碼" | translate}}</h6>
                        <div class="form-group mt-4">
                          <div class="bg-light p-4">
                            <div class="row">
                              <div class="col-md-4">
                                <div class="input-group mb-3">
                                  <input type="text" class="form-control" placeholder="輸入折扣碼"
                                    aria-label="Recipient's username" aria-describedby="button-addon2"
                                    [(ngModel)]="code">
                                  <div class="input-group-append ml-2">
                                    <button class="btn btn-primary rounded-0" type="button" id="button-addon2"
                                      (click)="getCoupon()">{{"新增" | translate}}</button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="mt-2">
                              <a href="#" data-toggle="modal" data-target="#couponModal">{{"選擇折扣卷" | translate}}</a>
                            </div>

                            <!--選取折扣卷 -->
                            <div class="row" *ngIf="coupon != null">
                              <div class="col-12">
                                <div class="coupon-selected">
                                  <div class="close" (click)="deleteCoupon()">×</div>
                                  <div class="coupon-selected-value">
                                    - <span [innerHTML]="discountValue | currencyExchange:true:false | async"></span>
                                  </div>
                                  <div class="coupon-selected-code" *ngIf="coupon.code != ''">
                                    <div class="coupon-code">{{coupon.code}}</div>
                                  </div>

                                </div>
                              </div>
                            </div>
                            <!--/選取折扣卷 -->
                          </div>

                        </div>

                        <h6 class="my-4">{{"付款方式" | translate}}</h6>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row mb-4">
                              <div class="col-12">
                                <div class="border">
                                  <mat-radio-group aria-label="Select an option" [(ngModel)]="payMethod">
                                    <div class="form-check ml-4 p-4" *ngIf="PayMethod2">
                                      <mat-radio-button [value]="2">
                                        <img src="assets/images/pay-line.png" class="pl-1" style="margin-top: -3px;">
                                      </mat-radio-button>
                                    </div>
                                    <div *ngIf="PayMethod6">
                                      <div class="divider"></div>
                                      <div class="form-check ml-4 p-4">
                                        <mat-radio-button [value]="6">{{"信用卡付款" | translate}}
                                          <img src="assets/images/mpg_card_icon.png" class="pl-1"
                                            style="margin-top: -3px;">
                                        </mat-radio-button>
                                      </div>
                                    </div>
                                    <div *ngIf="PayMethod3">
                                      <div class="divider"></div>
                                      <div class="form-check ml-4 p-4">
                                        <mat-radio-button [value]="3">{{"ATM虛擬帳號轉帳" | translate}}</mat-radio-button>
                                      </div>
                                    </div>
                                    <div *ngIf="PayMethod4 && payAmount >= 30">
                                      <div class="divider"></div>
                                      <div class="form-check ml-4 p-4">
                                        <mat-radio-button [value]="4">{{"超商代碼繳款" | translate}}</mat-radio-button>
                                      </div>
                                    </div>
                                    <div *ngIf="PayMethod5 && payAmount >= 30">
                                      <div class="divider"></div>
                                      <div class="form-check ml-4 p-4">
                                        <mat-radio-button [value]="5">{{"超商條碼繳款" | translate}}</mat-radio-button>
                                      </div>
                                    </div>
                                    <div *ngIf="PayMethod7">
                                      <div class="divider"></div>
                                      <div class="form-check ml-4 p-4">
                                        <mat-radio-button [value]="7">
                                          <div class="d-flex flex-row align-items-center">
                                            <span>{{"匯款" | translate}}</span>
                                          </div>
                                        </mat-radio-button>

                                        <div class="card mt-3" *ngIf="payMethod == 7">
                                          <div class="card-body">
                                            <p class="card-text text-muted" [innerHTML]="remitInfo"></p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </mat-radio-group>
                                </div>

                                <div class="bg-white cart-footer mb-1 mt-4 p-3">
                                  <div class="row justify-content-end text-right">
                                    <div class="col-12 my-1">
                                      <div class="row justify-content-end">
                                        <span class="col-6 col-md-3">1 {{"件商品合計" | translate}}</span>
                                        <span class="col-6 col-md-2 text-price"
                                          [innerHTML]="totalAmount - totalDeduct | currencyExchange:true:false | async"></span>
                                      </div>
                                    </div>
                                    <div class="col-12 my-1">
                                      <div class="row justify-content-end">
                                        <span class="col-6 col-md-3">{{"折扣金額" | translate}}</span>
                                        <span class="col-6 col-md-2 text-price">- <span
                                            [innerHTML]="discountValue | currencyExchange:true:false | async"></span></span>
                                      </div>
                                    </div>
                                    <div class="col-12 my-1">
                                      <div class="row justify-content-end">
                                        <span class="col-6 col-md-3">{{"付款金額" | translate}}</span>
                                        <span class="col-6 col-md-2 text-price font-weight-bold"
                                          [innerHTML]="payAmount | currencyExchange:true:false | async"></span>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row justify-content-center">
                        <div class="col-6 col-md-4 col-lg-3 pr-1">
                          <button class="btn btn-block btn-outline-primary rounded-0" matStepperPrevious>上一步</button>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3 pl-1">
                          <button class="btn btn-block btn-primary rounded-0 text-white" [disabled]="submitClicked"
                            (click)="onSubmit()">
                            {{"確認付款" | translate}}
                          </button>
                        </div>
                      </div>
                    </mat-step>

                  </mat-horizontal-stepper>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="couponModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-custom modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{"選擇折扣卷" | translate}}</h5>
        <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h5 align="center" class="text-danger mt-4" *ngIf="!coupons">{{"目前無可用的折扣券" | translate}}</h5>
        <div class="d-flex flex-column" *ngIf="coupons && coupons.length > 0">
          <label class="card-radio-btn" *ngFor="let item of coupons; let i = index">
            <input type="radio" name="demo" class="card-input-element d-none" (click)="selectCoupon(i)">
            <div class="card card-body flex-column flex-md-row justify-content-between">
              <div>
                <div class="content-head">{{item.title | googleTranslate | async}}</div>
                <div class="content-code">{{item.code}}</div>
                <div class="content-sub">{{"有效日期" | translate}}：{{item.startDate | slice:0:10}} ~
                  <span *ngIf="(item.endDate | slice:0:10) == '0001-01-01'">{{"無期限" | translate}}</span>
                  <span *ngIf="(item.endDate | slice:0:10) != '0001-01-01'">{{item.endDate | slice:0:10}}</span>
                </div>
                <div class="content-sub">{{"使用規則" | translate}}：{{item.Rule | googleTranslate | async}}</div>
              </div>

              <div class="d-flex d-md-block align-items-center">
                <div class="content-discount-title d-none d-sm-block">
                  <small *ngIf="item.discountType == 1">{{"折扣金額" | translate}}</small>
                  <small *ngIf="item.discountType == 2">{{"折扣" | translate}}</small>
                </div>
                <div class="content-discount" *ngIf="item.discountType == 1">
                  <span [innerHTML]="item.discountValue | currencyExchange:true:false | async"></span>
                </div>
                <div class="content-discount" *ngIf="item.discountType == 2">
                  <small>{{"折" | translate}}</small>
                  <span>{{item.discountValue}}%</span>
                </div>
              </div>

            </div>
          </label>
        </div>

      </div>
      <div class="modal-footer border-0">
        <button type="button" class="btn btn-link text-muted" data-dismiss="modal" data-target="#couponModal">{{"取消" |
          translate}}</button>
        <button type="button" class="btn btn-link" data-dismiss="modal" data-target="#couponModal"
          (click)="confirmCoupon()">{{"確定" | translate}}</button>
      </div>
    </div>
  </div>
</div>

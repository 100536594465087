import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.loadScript('assets/js/vendor/slick/slick.js');
    this.loadScript('assets/js/vendor/magnific-popup/jquery.magnific-popup.min.js');
    this.loadScript('assets/js/vendor/perfect-scrollbar/perfect-scrollbar.js');
    // this.loadScript('assets/js/vendor/jquery.countdown.min.js');
    // this.loadScript('assets/js/common.js');
    // this.loadScript('assets/js/app.js');
  }

  loadScript(url) {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

}

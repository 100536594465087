import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  title: '';
  content: '';
  btnDanger: 'btn btn-danger';
  btnSecondary: 'btn btn-secondary';
  public event: EventEmitter<any> = new EventEmitter();
  constructor(public bsModalRef: BsModalRef) {
  }

  ngOnInit() {
  }

  ok() {
    this.event.emit({ action: true });
  }

}

import { LayoutService } from './../layout.service';
import { PayService } from './../../pay/pay.service';
import { MemberService } from './../../member/member.service';
import { LoginService } from './../../../core/services/login.service';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { LoginComponent } from 'src/app/shared/login/login.component';
import { Router } from '@angular/router';
import { SearchComponent } from 'src/app/shared/search/search.component';
import { TranslateService } from '@ngx-translate/core';
declare let $: any;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  bsModalRef: BsModalRef;
  isLogin = false;
  firstName: string;
  lastName: string;
  imgSrc: string;
  userInfo: any;
  cartCount: number;
  menuData: any[] = [];
  currencyList = [];
  selectedCurrency = 'TWD';
  languageList = [{ name: '繁體中文', value: 'zh-TW' }, { name: '简体中文', value: 'zh-CN' }, { name: 'English', value: 'en' },
  { name: '日本語', value: 'ja' }];
  selectedLanguage: string;
  exploreWorldTitle = '探索世界';

  constructor(
    private router: Router,
    private modalService: BsModalService,
    public memberService: MemberService,
    private layoutService: LayoutService,
    public payService: PayService,
    private translateService: TranslateService,
    public loginService: LoginService) {
    this.layoutService.getSystemSetting().subscribe((v: any) => {
      if (v.result && v.data) {
        this.exploreWorldTitle = v.data.ExploreWorldTitle;
      }
    });
  }

  ngOnInit(): void {
    this.memberService.currentUser.subscribe(user => this.userInfo = user);
    this.payService.currentItemCount.subscribe(count => this.cartCount = count);
    if (this.loginService.userInfo === undefined) {
      this.loginService.isLogin().subscribe(resp => {
        this.isLogin = resp.result;
        if (resp.result) {
          this.loginService.userInfo = resp.user_info;
          this.getUserInfo();
        }
      }, err => {
        // console.log(err);
        this.isLogin = false;
      }, () => { });
    } else {
      this.isLogin = true;
      this.getUserInfo();
    }
    this.payService.renewCartCount(this.payService.getItems('CartItems').length);

    this.layoutService.getMenu().subscribe((v: any) => {
      if (v.result && v.data) {
        this.menuData = v.data;
        v.data.map(menu => {
          menu.Country.map(country => {
            country.Regions = [];
            menu.Region.map(region => {
              if (country.ID === region.countryID) {
                country.Regions.push(region);
              }
            });
            // tslint:disable-next-line:only-arrow-functions
            menu.Region.sort(function (a, b) {
              return a.sort - b.sort;
            });
          });
        });
      }
      this.loadScript('assets/js/common.js');
    });

    this.getCurrencies();
    this.translateService.use(this.layoutService.language.value);
    switch (this.layoutService.language.value) {
      case 'zh-TW':
        this.selectedLanguage = '繁體中文';
        break;
      case 'zh-CN':
        this.selectedLanguage = '简体中文';
        break;
      case 'en':
        this.selectedLanguage = 'English';
        break;
      case 'ja':
        this.selectedLanguage = '日本語';
        break;
    }
  }

  getUserInfo() {
    this.memberService.getMemberInfo().subscribe((v: any) => {
      this.imgSrc = v.user_info.picture !== '' && v.user_info.picture.length < 10 ?
        '/api/images/' + v.user_info.picture : v.user_info.picture;
      this.memberService.renewUserInfo({
        firstName: v.user_info.firstName,
        lastName: v.user_info.lastName,
        picture: this.imgSrc,
      });
    },
      (e) => { console.log(e); },
    );
  }

  getCurrencies() {
    this.layoutService.getCurrencies().subscribe((v: any) => {
      if (v.result) {
        v.data.map(data => {
          this.currencyList.push(data.code);
        });
      }
    },
      (e) => { console.log(e); },
    );
  }

  changeCurrency(currency: string) {
    this.selectedCurrency = currency;
    if (this.selectedCurrency === 'TWD') {
      this.layoutService.renewCurrency({
        currency,
      });
    }
    if (this.selectedCurrency !== 'TWD') {
      this.layoutService.getCurrencyRate().subscribe((v: any) => {

        this.layoutService.renewCurrency({
          currency,
          USD: v.data.USD.Exrate / v.data.USDTWD.Exrate,
          AUD: v.data.USDAUD.Exrate / v.data.USDTWD.Exrate,
          CAD: v.data.USDCAD.Exrate / v.data.USDTWD.Exrate,
          CNY: v.data.USDCNY.Exrate / v.data.USDTWD.Exrate,
          EUR: v.data.USDEUR.Exrate / v.data.USDTWD.Exrate,
          GBP: v.data.USDGBP.Exrate / v.data.USDTWD.Exrate,
          HKD: v.data.USDHKD.Exrate / v.data.USDTWD.Exrate,
          IDR: v.data.USDIDR.Exrate / v.data.USDTWD.Exrate,
          INR: v.data.USDINR.Exrate / v.data.USDTWD.Exrate,
          JPY: v.data.USDJPY.Exrate / v.data.USDTWD.Exrate,
          KHR: v.data.USDKHR.Exrate / v.data.USDTWD.Exrate,
          KRW: v.data.USDKRW.Exrate / v.data.USDTWD.Exrate,
          MAD: v.data.USDMAD.Exrate / v.data.USDTWD.Exrate,
          MMK: v.data.USDMMK.Exrate / v.data.USDTWD.Exrate,
          MOP: v.data.USDMOP.Exrate / v.data.USDTWD.Exrate,
          MYR: v.data.USDMYR.Exrate / v.data.USDTWD.Exrate,
          MXN: v.data.USDMXN.Exrate / v.data.USDTWD.Exrate,
          NPR: v.data.USDNPR.Exrate / v.data.USDTWD.Exrate,
          NZD: v.data.USDNZD.Exrate / v.data.USDTWD.Exrate,
          PHP: v.data.USDPHP.Exrate / v.data.USDTWD.Exrate,
          RUB: v.data.USDRUB.Exrate / v.data.USDTWD.Exrate,
          SGD: v.data.USDSGD.Exrate / v.data.USDTWD.Exrate,
          THB: v.data.USDTHB.Exrate / v.data.USDTWD.Exrate,
          TRY: v.data.USDTRY.Exrate / v.data.USDTWD.Exrate,
          VND: v.data.USDVND.Exrate / v.data.USDTWD.Exrate,
          ZAR: v.data.USDZAR.Exrate / v.data.USDTWD.Exrate,
        });
      },
        (e) => { console.log(e); },
      );
    }
  }

  changeLanguage(lang: string) {
    this.layoutService.renewLanguage(lang);
    this.translateService.use(lang);

    location.reload();
  }

  login(headto: string) {
    const config = {
      initialState: {
        headto: headto === 'member' ? window.location.href : headto,
      },
    };
    this.bsModalRef = this.modalService.show(LoginComponent, config);
  }

  loadScript(url) {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  showSearch() {
    const config = {
      class: 'ModalSearch',
    };
    this.bsModalRef = this.modalService.show(SearchComponent, config);
  }

  menuToggle() {
    function disablePageScroll() {
      $('body').toggleClass('overflow-hidden');
    }
    const $savedScrollTop = $(document).scrollTop();
    function savedScroll() {
      $(document).scrollTop($savedScrollTop || 0);
    }

    disablePageScroll();
    $('#nav-icon').toggleClass('open');
    $('.mobile-menu').fadeToggle();

    $('.navbar-mobile').toggleClass('position-fixed');
    savedScroll();
  }
}

import { IndexService } from './../../index/index.service';
import { AlertDialogComponent } from './../../../shared/dialogs/alert-dialog/alert-dialog.component';
import { PayService } from './../pay.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LayoutService } from '../../layout/layout.service';
@Component({
  selector: 'app-pay-info',
  templateUrl: './pay-info.component.html',
  styleUrls: ['./pay-info.component.scss']
})
export class PayInfoComponent implements OnInit {
  @ViewChild('divForm') divForm: ElementRef;
  cartData: Array<any> = [];
  totalAmount: number;
  bsModalRef: BsModalRef;
  form: FormGroup;
  countries: any[] = [];
  country: string;
  submitClicked = false;
  memberId: number;
  coupons: any[] = [];
  queryCoupon: any = {
    code: '',
    order: []
  };
  coupon: any;
  code = '';
  onSelectCoupon: any;
  discountValue = 0;
  payAmount: number;
  payMethod: number;
  linePay = false;
  totalDeduct = 0;
  payMethodLoaded = false;
  PayMethod2 = false;
  PayMethod3 = false;
  PayMethod4 = false;
  PayMethod5 = false;
  PayMethod6 = false;
  PayMethod7 = false;
  remitInfo = '';
  memberData: any;
  today = new Date();
  currencyRate: number;
  currency: string;
  canBuy = true;

  constructor(
    private router: Router,
    private indexService: IndexService,
    private payService: PayService,
    private toastr: ToastrService,
    private layoutService: LayoutService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.getOrderItems();
    this.layoutService.currentRate.subscribe((rateInfo: any) => {
      this.currency = rateInfo.currency;
      if (this.currency === 'TWD') {
        this.currencyRate = 1.0000;
      } else {
        this.currencyRate = 1 / rateInfo[this.currency];
      }
    });

    this.payService.getPayMethod().subscribe((v: any) => {
      if (v.result) {
        this.layoutService.translateHTML(v.remitInfo).subscribe((res: any) => {
          this.remitInfo = res;
        });

        if (v.data) {
          v.data.map(method => {
            switch (method) {
              case 2:
                this.PayMethod2 = true;
                break;
              case 3:
                this.PayMethod3 = true;
                break;
              case 4:
                this.PayMethod4 = true;
                break;
              case 5:
                this.PayMethod5 = true;
                break;
              case 6:
                this.PayMethod6 = true;
                break;
              case 7:
                this.PayMethod7 = true;
                break;
            }
          });
        }

        this.payMethodLoaded = true;
      }
    });

    this.loadCountry();
    this.payService.getMember().subscribe((v: any) => {
      if (v.result) {
        this.memberData = v.data;
        this.country = v.data.memberInfo.country ? v.data.memberInfo.country : '臺灣';
        this.memberId = v.data.ID;
        this.form = this.formBuilder.group({
          firstName: [v.data.firstName, [Validators.required]],
          lastName: [v.data.lastName, [Validators.required]],
          country: ['', [Validators.required]],
          gender: [v.data.gender],
          birthday: [v.data.birthday.slice(0, 1) !== '0' ? v.data.birthday.slice(0, 10) : ''],
          phone: [v.data.phone, [Validators.required]],
          address: [v.data.memberInfo.address],
          email: [v.data.email, [Validators.required]],
          line: [v.data.line],
        });
      }
    },
      (e) => { console.log(e); },
    );
  }

  getOrderItems() {
    this.totalAmount = 0;
    this.cartData = this.payService.getItems('OrderItems');
    if (this.cartData.length < 1) {
      this.router.navigate(['/']);
      this.toastr.warning('訂單已失效，請重新訂購', '系統通知');
    }

    console.log('this.cartData :>> ', this.cartData);

    this.cartData.map(item => {
      if (item.sellingTimeBeforeHours % 24 === 0) {
        item.sellingTimeBeforeHours += 24;
      }
      const tt = new Date(item.date + 'T24:00');
      tt.setHours(tt.getHours() - item.sellingTimeBeforeHours);
      item.canBuy = item.sellingTimeType === 1 ? tt.getTime() > new Date().getTime() : tt.getTime() >= new Date().getTime();
      if (!item.canBuy) {
        this.canBuy = false;
      }

      item.isSale = false;
      item.deduct = 0;
      item.totalDeduct = 0;
      item.productData.grabToday = { id: 0, deadline: '', deduct: 0 };
      this.queryCoupon.order.push(JSON.parse(JSON.stringify({
        productId: item.productId, total: item.total -
          (item.productData.grabToday.deduct * item.quantity)
      })));

      item.isCollapsed = true;
      this.totalAmount += item.total;
      this.payAmount = this.totalAmount;

      this.indexService.getGrabToday()
        .subscribe((r: any) => {
          if (r.result && r.data) {
            this.totalDeduct = 0;
            this.cartData.map(product => {
              r.data.GrabTodayPlan.map(sales => {
                if (sales.product.ID === product.productId) {
                  product.isSale = true;
                  product.deduct = sales.product.deduct;
                  product.totalDeduct = sales.product.deduct * product.quantity;
                  product.productData.grabToday = { id: r.data.ID, deadline: r.data.deadline, deduct: sales.product.deduct };
                }
              });
              this.totalDeduct += product.totalDeduct;
              this.payAmount = this.totalAmount - this.totalDeduct;
            });
          }
        });

      item.touristList = [];
      item.requiredFields.map(req => {
        if (req.fieldType === 'checkbox') {
          req.answer = [];
          req.fieldData.map(data => {
            const checkData = { key: data, value: false };
            req.answer.push(JSON.parse(JSON.stringify(checkData)));
          });
          req.checkValid = false;
        } else {
          req.answer = '';
        }
      });

      item.optionalFields.map(opt => {
        if (opt.fieldType === 'checkbox') {
          opt.answer = [];
          opt.fieldData.map(data => {
            const checkData = { key: data, value: false };
            opt.answer.push(JSON.parse(JSON.stringify(checkData)));
          });
        } else {
          opt.answer = '';
        }
      });

      if (item.fillRequest === 1) {
        const touristLis = {
          count: 1, requiredFields: item.requiredFields,
          optionalFields: item.optionalFields, commonTourist: null,
        };
        item.touristList.push(JSON.parse(JSON.stringify(touristLis)));
      } else {
        for (let i = 0; i < item.quantity; i++) {
          const touristLis = {
            count: i + 1, requiredFields: item.requiredFields,
            optionalFields: item.optionalFields, commonTourist: null,
          };
          item.touristList.push(JSON.parse(JSON.stringify(touristLis)));
        }
      }
    });
    if (!this.canBuy) {
      this.showAlert('此產品已無法訂購，請回產品頁重新選擇日期，謝謝～', '系統提示');
      return;
    }
    this.payService.getCoupons(this.queryCoupon, this.code).subscribe((v: any) => {
      if (v.result) {
        this.coupons = v.coupons;
      }
    },
      (e) => { console.log(e); },
    );
  }

  getCoupon() {
    if (this.code === '') {
      this.showAlert('請輸入折扣碼', '系統提示');
      return;
    }
    this.payService.getCoupons(this.queryCoupon, this.code).subscribe(v => {
      if (v.coupons.length > 0) {
        this.code = '';
        this.onSelectCoupon = v.coupons[0];
        this.confirmCoupon();
      } else {
        this.showAlert('找不到符合的折扣碼', '系統提示');
      }
    });
  }

  loadCountry() {
    this.payService.getCountry().subscribe(x => {
      this.countries = x;
    });
  }

  get f(): { [key: string]: AbstractControl; } { return this.form.controls; }

  setCommonTourist(index: number, touristIndex: number) {
    const selectedTourist =
      this.memberData.commonTourist.filter(t => t.displayName === this.cartData[index].touristList[touristIndex].commonTourist);

    selectedTourist[0].setting.map(common => {
      this.cartData[index].touristList[touristIndex].requiredFields.map(require => {
        if (require.ID === common.ID) {
          require.answer = common.answer;
        }
      });
      this.cartData[index].touristList[touristIndex].optionalFields.map(optional => {
        if (optional.ID === common.ID) {
          optional.answer = common.answer;
        }
      });
    });
  }

  selectCoupon(i: number) {
    this.onSelectCoupon = this.coupons[i];
  }

  confirmCoupon() {
    const tmpCoupon: any = this.onSelectCoupon;
    let tmpTotal = 0;
    this.coupon = tmpCoupon;

    if (tmpCoupon.discountType === 1) {
      this.discountValue = tmpCoupon.discountValue;
      this.payAmount -= tmpCoupon.discountValue;
      return;
    }
    if (tmpCoupon.productId > 0) {
      this.cartData.map(item => {
        if (item.productId === tmpCoupon.productId) {
          tmpTotal += item.total;
        }
      });
      this.discountValue = Math.round((tmpTotal - this.totalDeduct) * (tmpCoupon.discountValue / 100));
      this.payAmount -= this.discountValue;
      return;
    }

    this.discountValue = Math.round((this.totalAmount - this.totalDeduct) * (tmpCoupon.discountValue / 100));
    this.payAmount -= this.discountValue;
  }

  deleteCoupon() {
    this.onSelectCoupon = {};
    this.coupon = null;
    this.payAmount = this.totalAmount - this.totalDeduct;
    this.discountValue = 0;
  }

  onSubmit() {
    let formValid = true;
    // this.submitClicked = true;
    const orderDetail = [];
    let errMsg = '請檢查欄位是否正確填寫';

    this.cartData.map(product => {
      let discount = 0;
      let couponID = 0;

      if (this.coupon && this.coupon.productId === product.productId) {
        couponID = this.coupon.ID;
        if (this.coupon.discountType === 1) { // 折價
          let tmpA = [];
          let tmpATotal = 0;
          tmpA = this.cartData.filter(p => p.productId === this.coupon.productId);
          if (tmpA.length > 1) {
            tmpA.map(a => {
              tmpATotal += a.total - a.totalDeduct;
            });
            const tmpTotal = product.total - product.totalDeduct;
            discount = Math.round(tmpTotal / tmpATotal * this.coupon.discountValue);
          } else {
            discount = this.coupon.discountValue;
          }
        }
        if (this.coupon.discountType === 2) {
          discount = Math.round((product.total - product.totalDeduct) * (this.coupon.discountValue / 100));
        }
      }

      if (this.coupon && this.coupon.conditionType !== 3) {
        couponID = this.coupon.ID;
        if (this.coupon.discountType === 1) {
          const tmpTotal = product.total - product.totalDeduct;
          discount = Math.round(tmpTotal / (this.totalAmount - this.totalDeduct) * this.coupon.discountValue);
        }
        if (this.coupon.discountType === 2) {
          discount = Math.round((product.total - product.totalDeduct) * (this.coupon.discountValue / 100));
        }
      }

      const touristInfo = [];
      product.touristList.map(tourist => {
        const fieldData = [];
        tourist.requiredFields.map(req => {
          const checkAns = [];
          if (req.fieldType === 'checkbox') {
            req.answer.map(ans => {
              if (ans.value) {
                req.checkValid = true;
                checkAns.push(ans.key);
              }
            });
            if (checkAns.length < 1) {
              formValid = false;
            }
            fieldData.push({ id: req.ID, title: req.title, value: checkAns });
          } else {
            if (req.fieldType !== 'readonly') {
              if (!req.answer) {
                formValid = false;
              } else if (req.title === '身分證字號' || req.title === '身份證字號') {
                const idCheck = this.verifyIdentityNumber(req.answer);
                if (!idCheck) {
                  formValid = false;
                  errMsg = '請填寫有效的身份證字號';
                }
              }
              fieldData.push({ id: req.ID, title: req.title, value: req.answer });
            }
          }
        });
        tourist.optionalFields.map(opt => {
          const checkAns = [];
          if (opt.fieldType === 'checkbox') {
            opt.answer.map(ans => {
              if (ans.value) {
                checkAns.push(ans.key);
              }
            });
            fieldData.push({ id: opt.ID, title: opt.title, value: checkAns });
          } else {
            if (opt.fieldType !== 'readonly') {
              if ((opt.title === '身分證字號' || opt.title === '身份證字號') && opt.answer) {
                const idCheck = this.verifyIdentityNumber(opt.answer);
                if (!idCheck) {
                  formValid = false;
                  errMsg = '請填寫有效的身份證字號';
                }
              } else {
                fieldData.push({ id: opt.ID, title: opt.title, value: opt.answer });
              }
            }
          }
        });
        touristInfo.push(fieldData);

      });

      if (this.payMethod !== 7) {
        this.currency = 'TWD';
        this.currencyRate = 1.0000;
      }

      orderDetail.push({
        tourGroupControlId: product.tourGroupControlID,
        tourGroupType: product.tourGroupType,
        productId: product.productId,
        productPlanId: product.planId,
        planSpecificationId: product.specId,
        extraPurchaseId: product.extraId,
        specOptionId: product.specOptionId,
        extraOptionId: product.extraOptionId,
        price: product.price - (product.deduct ? product.deduct : 0),
        quantity: product.quantity,
        payStatus: -1,
        couponID,
        discount,
        touristInfo,
        productData: product.productData,
        groupBuyInitiatorId: 0,
        groupBuyId: 0,
        groupBuyProductId: 0,
        currency: this.currency,
        rate: this.currencyRate,
        cabinClass: product.productData.cabinClass
      });
    });

    const orderData = this.formBuilder.group({
      ordererInfo: [this.form.value],
      orderDetails: [orderDetail],
      payStatus: -1,
      orderStatus: -1,
      memberId: +this.memberId,
      amount: this.totalAmount - this.totalDeduct,
      payMethod: this.payMethod,
      orderByAdmin: 0,
      couponID: this.coupon && this.coupon.ID ? this.coupon.ID : 0,
      discount: this.discountValue,
      discountStatus: this.coupon && this.coupon.ID ? 1 : 0,
      groupBuyInitiatorId: 0,
      groupBuyId: 0,
      groupBuyProductId: 0,
      tourGroupControlId: this.cartData[0].tourGroupControlID ? this.cartData[0].tourGroupControlID : 0,
      tourGroupType: this.cartData[0].tourGroupControlID ? this.cartData[0].tourGroupType : '',
      currency: this.currency,
      rate: this.currencyRate
    });
    console.log('orderData.value :>> ', orderData.value);
    if (!formValid) {
      this.showAlert(errMsg);
      this.submitClicked = false;
    } else {
      if (this.payMethod === 2) {
        this.payService.linepay(orderData.value).subscribe(
          r => {
            if (r.result) {
              location.href = r.url;
              // location.href = r.linkmessage;
            } else {
              this.showAlert(r.message);
            }
          },
          e => {
            console.log(e);
            this.showAlert(e.message);
          });
      }

      if (this.payMethod === 3 || this.payMethod === 4 || this.payMethod === 5 || this.payMethod === 6) {
        this.showAlert('將導到契約第三方付款金流（藍新金流）頁面...請稍候', '系統提示');
        this.payService.newebPayWithNumberSystem(orderData.value).subscribe(
          r => {
            if (r.result) {
              const dataForNewebPay = {
                formHtml: r.data.formHtml,
              };
              localStorage.setItem('dataForNewebPay', JSON.stringify(dataForNewebPay));
              const formHtml = r.data;
              this.divForm.nativeElement.innerHTML = `${formHtml}`;
              // tslint:disable-next-line:only-arrow-functions
              $(document).ready(function() {
                $('#newebpaySubmit').click();
                // tslint:disable-next-line:only-arrow-functions
                setTimeout(function() {
                  $('#newebpaySubmit').trigger('click');
                }, 500);
              });
            } else {
              $('.modal-dialog').hide();
              if (r.message) {
                this.showAlert(r.message);
              } else {
                if (r.data) {
                  this.showAlert(r.data);
                } else {
                  this.showAlert('契約第三方付款金流（藍新金流）異常，請聯絡客服人員，謝謝您。', '系統錯誤');
                }
              }
              // tslint:disable-next-line:only-arrow-functions
              setTimeout(function() {
                // window.location.reload();
              }, 2000);
            }
          },
          e => {
            this.showAlert(e.message);
          });
      }

      if (this.payMethod === 7) {
        this.payService.createOrder(orderData.value).subscribe(
          r => {
            if (r.result) {
              location.href = '/pay/confirm/success?number=' + r.order.orderNumber;
            } else {
              this.showAlert(r.message);
            }
          },
          e => {
            console.log(e);
            this.showAlert(e.message);
          });
      }

    }
  }

  showAlert(content: string, title: string = '系統錯誤') {
    const config = {
      initialState: {
        title,
        content,
        btnClass: 'btn btn-danger'
      },
      class: 'modal-danger'
    };
    this.bsModalRef = this.modalService.show(AlertDialogComponent, config);
  }

  verifyIdentityNumber(identityNumber: string) {
    if (identityNumber && identityNumber.trim() !== '') {
      identityNumber = identityNumber.trim().toLocaleUpperCase();
      let id = identityNumber;
      const verification = id.match('^[A-Z][12]\\d{8}$');
      if (!verification) {
        return false;
      }

      const conver = 'ABCDEFGHJKLMNPQRSTUVXYWZIO';
      const weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1];

      id = String(conver.indexOf(id[0]) + 10) + id.slice(1);

      let checkSum = 0;
      for (let i = 0; i < id.length; i++) {
        const c = +id[i];
        const w = weights[i];
        checkSum += c * w;
      }
      return checkSum % 10 === 0;
    } else {
      return true;
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MemberService } from '../member.service';

@Component({
  selector: 'app-member-coupon',
  templateUrl: './member-coupon.component.html',
  styleUrls: ['./member-coupon.component.scss']
})
export class MemberCouponComponent implements OnInit {
  myCode = '';
  coupons = [];
  unCoupons = [];
  pageIndex = 0;
  PageSize = 5;
  itemLength = 0;
  numPages = 0;

  constructor(
              public memberService: MemberService,
              private toastr: ToastrService,
              private router: Router
  ) { }

  ngOnInit(): void {
    this.getMemberCoupon();
  }

  getMemberCoupon(pageNumber = this.pageIndex) {
    this.memberService.getCoupon(pageNumber, this.myCode, this.PageSize)
      .subscribe((v: any) => {
        this.coupons = v.coupons;
        this.unCoupons = v.unCoupons;
        this.itemLength = v.enTotal;
      },
        (e) => { console.log(e); },
      );
  }


}

<div class="page-groupbuy" *ngIf="initiatorData">
  <div class="wrapper">
    <header class="d-flex">
      <a [routerLink]="['/groupBuy/initiator', initiatorData.ID]">
        <img [src]="'/api/images/' + initiatorData.logo" alt="logo">
      </a>
      <div class="d-flex align-items-center ml-auto">
        <!-- <ul class="nav user-nav pr-2">
          <li class="nav-item">
            <div class="dropdown pl-2 pr-2 border rounded">
              <a class="dropdown-toggle d-flex align-items-center py-1" id="dropdownMenu3" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <span class="material-icons pr-1">language</span>{{selectedLanguage}}
              </a>
              <div class="dropdown-menu dropdown-menu-lg-right dropdown-style" aria-labelledby="dropdownMenu3">
                <a class="dropdown-item" type="button" *ngFor="let lang of languageList"
                  (click)="changeLanguage(lang.value); this.selectedLanguage = lang.name">{{lang.name}}</a>
              </div>
            </div>
          </li>
        </ul> -->
        <ul class="nav user-nav pr-2">
          <li class="nav-item">
            <div class="dropdown pl-2 pr-2 border rounded">
              <a class="dropdown-toggle d-flex align-items-center py-1" id="dropdownMenu3" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                {{selectedCurrency}}
              </a>
              <div class="dropdown-menu dropdown-menu-right dropdown-style" aria-labelledby="dropdownMenu3"
                *ngIf="initiatorData.currencyList.length > 0">
                <a class="dropdown-item" *ngFor="let currency of initiatorData.currencyList"
                  (click)="changeCurrency(currency)">{{currency}}</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </header>

    <div class="content-main">
      <div class="container mt-4 p-4">
        <div class="bg-white panel">

          <div class="panel-content">
            <div class="row" *ngIf="orderData">
              <div class="col-12">
                <div class="mb-4 d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="h5">{{"訂單編號" | translate}}
                      <span class="text-primary px-2">{{orderData.orderNumber}}</span>
                    </div>
                    <div class="d-flex align-items-center ml-2 mb-2">
                      <span class="badge badge-pill badge-danger" style="font-size: 1em;"
                        *ngIf="orderData.orderStatus === 2">{{"訂單作廢" | translate}}</span>
                    </div>
                  </div>
                  <a [routerLink]="['/groupBuy/info', orderData.groupBuyId, initiatorData.ID]"
                    class="float-right text-muted"><u>{{"回商品頁" | translate}}</u></a>
                </div>

                <div>
                  <div class="my-3">{{"訂購人資料" | translate}}</div>
                  <div class="row mb-5">
                    <div class="col-md-9">
                      <div class="row mb-4">
                        <div class="col-6 col-md-5 my-1">
                          <div class="row">
                            <div class="col-12 col-lg-4 form-label">{{"本國名" | translate}}</div>
                            <div class="col-12 col-lg-8">{{orderData.ordererInfo.firstName}}</div>
                          </div>
                        </div>
                        <div class="col-6 col-md-5 my-1">
                          <div class="row">
                            <div class="col-12 col-lg-4 form-label">{{"本國姓" | translate}}</div>
                            <div class="col-12 col-lg-8">{{orderData.ordererInfo.lastName}}</div>
                          </div>
                        </div>
                        <div class="col-6 col-md-5 my-1">
                          <div class="row">
                            <div class="col-12 col-lg-4 form-label">{{"國家" | translate}}</div>
                            <div class="col-12 col-lg-8">{{orderData.ordererInfo.country}}</div>
                          </div>
                        </div>
                        <div class="col-6 col-md-5 my-1">
                          <div class="row">
                            <div class="col-12 col-lg-4 form-label">{{"聯絡電話" | translate}}</div>
                            <div class="col-12 col-lg-8">{{orderData.ordererInfo.phone}}</div>
                          </div>
                        </div>
                        <div class="col-6 col-md-10 my-1">
                          <div class="row no-gutters">
                            <div class="col-12 col-md-2 form-label">Email</div>
                            <div class="col-12 col-md-8 pl-md-2">{{orderData.ordererInfo.email}}</div>
                          </div>
                        </div>
                        <div class="col-12 col-md-10 my-1">
                          <div class="row no-gutters">
                            <div class="col-12 col-md-2 form-label">{{"付款方式" | translate}}</div>
                            <div class="col-12 col-md-8 pl-md-2" *ngIf="orderData.payMethod === 2">
                              <img src="assets/images/pay-line.png" class="pl-1" style="margin-top: -3px;">
                            </div>
                            <div class="col-12 col-md-8 pl-md-2" *ngIf="orderData.payMethod === 3">
                              ATM
                              <div class="divider"></div>
                              <div>{{"金流交易序號" | translate}}： {{tradeInfo.Result.PaymentType}}</div>
                              <div>{{"您選擇轉入銀行" | translate}}： {{tradeInfo.Result.BankCode}}</div>
                              <div>{{"ATM轉帳帳號" | translate}}： {{tradeInfo.Result.CodeNo}}</div>
                              <div>{{"繳費截止日期" | translate}}： {{tradeInfo.Result.ExpireDate}}
                                {{tradeInfo.Result.ExpireTime}}</div>
                            </div>
                            <div class="col-12 col-md-8 pl-md-2" *ngIf="orderData.payMethod === 4">
                              {{"超商代碼繳費" | translate}}
                              <div class="divider"></div>
                              <div>{{"金流交易序號" | translate}}： {{tradeInfo.Result.PaymentType}}</div>
                              <div>{{"超商代碼" | translate}}： {{tradeInfo.Result.CodeNo}}</div>
                              <div>{{"繳費截止日期" | translate}}： {{tradeInfo.Result.ExpireDate}}
                                {{tradeInfo.Result.ExpireTime}}</div>
                            </div>
                            <div class="col-12 col-md-8 pl-md-2" *ngIf="orderData.payMethod === 5">
                              {{"超商條碼繳費" | translate}}
                              <div class="divider"></div>
                              <div>{{"繳費截止日期" | translate}}： {{tradeInfo.Result.ExpireDate}}
                                {{tradeInfo.Result.ExpireTime}}</div><br>
                              {{"若您交易完成後，未即時列印條碼繳費單，可以點擊" | translate}}<a
                                href="https://ccore.newebpay.com/barcode?MerID={{tradeInfo.Result.MerchantID}}&MerTradeNo={{tradeInfo.Result.MerchantOrderNo}}">此連結</a>
                            </div>
                            <div class="col-12 col-md-8 pl-md-2" *ngIf="orderData.payMethod === 6">
                              {{"信用卡" | translate}}
                              <img src="assets/images/mpg_card_icon.png" class="pl-1">
                            </div>
                            <div class="col-12 col-md-8 pl-md-2"
                              *ngIf="orderData.payMethod === 1 || orderData.payMethod === 7 || orderData.payMethod === 8">
                              <div *ngIf="orderData.payRecord.length < 1 && orderData.payMethod === 7">
                                {{"匯款資訊" | translate}}
                                <div *ngIf="orderData.payMethod == 7" [innerHTML]="initiatorData.remitInfo"
                                  class="text-muted mt-2"
                                  style="border: 1px solid #757575; border-radius: 10px; padding: 15px 30px 0;">
                                </div>

                              </div>
                              <div *ngIf="orderData.payRecord.length == 1">
                                <div *ngIf="orderData.payMethod === 1">{{"現金" | translate}}</div>
                                <div *ngIf="orderData.payMethod === 7">{{"匯款" | translate}}</div>
                                <div *ngIf="orderData.payMethod === 8">{{"刷卡" | translate}}</div>
                              </div>
                              <div *ngIf="orderData.payRecord.length > 1">
                                {{"多筆付款" | translate}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-2 col-md-3">
                      <a href="https://line.me/ti/p/%40unz8114z" target="_blank"
                        class="btn btn-outline-secondary btn-block rounded-0">
                        {{"聯繫客服" | translate}}
                      </a>
                    </div>
                  </div>

                  <div class="h6 my-3">{{"訂單明細" | translate}}</div>

                  <div class="mb-3 member-order-history">
                    <div class="card rounded-0 shadow-sm mb-3">
                      <div class="member-order-history-item" *ngFor="let item of orderData.OrderDetail">
                        <div>
                          <div class="row no-gutters cart-item px-2">
                            <div class="col-3 col-md-3 col-xl-2 d-flex flex-row">
                              <div class="collapse-arrow align-self-start collapsed" data-toggle="collapse"
                                [attr.data-target]="'#collapse' + item.ID" role="button">
                                <svg viewBox="0 0 24 24" width="24" height="24">
                                  <polyline fill="none" points="21,8.5 12,17.5 3,8.5 " stroke="#AFAFAF"
                                    stroke-miterlimit="10" stroke-width="1.5" />
                                </svg>
                              </div>
                              <div class="product-img img-bg align-self-start"
                                style="background-image: url(/api/images/{{item.productData.product.coverImg}});"></div>
                            </div>

                            <div class="col-9 col-md-9 col-xl-10 d-md-flex">
                              <div class="flex-grow-1">
                                <div class="product-name">{{item.productData.product.title | googleTranslate | async}}</div>
                                <div class="product-option">{{item.productData.plan.name | googleTranslate | async}}</div>
                                <div>
                                  <small class="text-black-50">
                                    <ul class="pl-4">
                                      <li>{{item.productData.planSpecification.name | googleTranslate | async}}</li>
                                      <li *ngIf="item.productData.extraOption.id !== 0">
                                        {{item.productData.extraPurchase.name | googleTranslate | async}}
                                      </li>
                                    </ul>
                                  </small>
                                </div>
                                <div class="product-quant d-flex align-items-center">
                                  <span class="material-icons px-1">shopping_cart</span>
                                  X {{item.quantity}} {{item.productData.planSpecification.unit | googleTranslate | async}}
                                  <span class="material-icons px-1 ml-3">calendar_today</span>
                                  {{item.productData.specOption.date | date: 'yyyy/MM/dd'}}
                                </div>

                                <div class="d-flex align-items-center">
                                  <div class="mb-2"
                                    [innerHTML]="item.price * item.quantity | currencyTransform:orderData.currency:orderData.rate">
                                  </div>
                                  <div class="mb-2 ml-3 text-danger bg-warning" *ngIf="item.refund > 0">
                                    {{"退款" | translate}}：
                                    <span
                                      [innerHTML]="item.refund | currencyTransform:orderData.currency:orderData.rate"></span>
                                  </div>
                                </div>
                              </div>
                              <div class="d-flex flex-column justify-content-md-between cart-action"
                                *ngIf="orderData.orderStatus !== 2">
                                <div class="d-flex justify-content-md-end">
                                  <small class="text-primary">
                                    <span *ngIf="item.payStatus == 1">{{"可使用" | translate}}</span>
                                  </small>
                                  <small class="text-warning">
                                    <span *ngIf="item.payStatus == -1">{{"未付款" | translate}}</span>
                                    <span *ngIf="item.payStatus == 2">{{"未付完" | translate}}</span>
                                    <span *ngIf="item.payStatus == 3 || item.payStatus == 4">
                                      {{"退款處理中" | translate}}</span>
                                    <span *ngIf="item.payStatus == 5">{{"部分退款已完成" | translate}}</span>
                                    <span *ngIf="item.payStatus == 6">{{"全額退款已完成" | translate}}</span>
                                    <span *ngIf="item.payStatus == 7">{{"退款申請中" | translate}}</span>
                                  </small>
                                </div>


                                <div class="d-flex flex-row justify-content-end align-self-md-end"
                                  *ngIf="item.payStatus !== -1 && orderData.orderStatus !== 2">
                                  <!-- <a href="#" data-toggle="modal" [attr.data-target]="'#comment'+item.ID"
                                    *ngIf="item.Comment.ID == 0" class="btn btn-outline-primary btn-sm rounded-0"
                                    (click)="openModal(item, 'comment')">給予評論
                                  </a> -->

                                  <div class="d-flex flex-column" *ngIf="item.Comment.ID !== 0">
                                    <div class="user-rating small d-flex flex-row">
                                      <div class="d-flex flex-row" *ngFor="let s of scores; let i = index;">
                                        <span class="material-icons checked" *ngIf="i < item.Comment.score">star</span>
                                        <span class="material-icons unchecked"
                                          *ngIf="i >= item.Comment.score">star</span>
                                      </div>
                                    </div>
                                    <small class="text-muted align-self-md-center">{{item.Comment.CreatedAt | date:
                                      'yyyy/MM/dd HH:mm'}}</small>
                                  </div>

                                  <!-- <a href="/api/site/auth/orderDetail/voucher/{{item.ID}}" target="_blank"
                                    class="btn btn-primary btn-sm btn-dl rounded-0 ml-2"
                                    *ngIf="item.voucherInfo && item.voucherInfo.qrCode">
                                    <i class="material-icons">get_app</i>下載憑證
                                  </a> -->

                                  <a class="btn btn-primary btn-sm btn-dl rounded-0 ml-2 dropdown-toggle text-white"
                                    data-toggle="dropdown" *ngIf="item.voucherFile.length >= 1">
                                    <i class="material-icons">get_app</i>{{"下載憑證" | translate}}
                                  </a>
                                  <div class="dropdown-menu dropdown-menu-right dropdown-style" role="menu">
                                    <a class="dropdown-item" *ngFor="let file of item.voucherFile" target="_blank"
                                      href="/api/site/auth/orderDetail/voucherFile/{{file.file}}?detailId={{item.ID}}">{{file.fileName}}</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="collapse" id="collapse{{item.ID}}">
                          <div class="pb-2" *ngFor="let tourist of item.touristInfo; let i = index;">
                            <div class="pay-passenger-card rounded">
                              <h6 class="mb-4" *ngIf="item.productData.product.fillRequest == 2">{{"旅客" | translate}}
                                {{i + 1}}</h6>
                              <h6 class="mb-4" *ngIf="item.productData.product.fillRequest == 1">{{"旅客代表人" | translate}}
                              </h6>
                              <div class="row">
                                <div class="col-md-9">
                                  <form>
                                    <div class="row">
                                      <div class="col-12 col-md-6" *ngFor="let info of tourist">
                                        <div class="form-group">
                                          <div class="form-label">{{info.title | googleTranslate | async}}</div>
                                          <div>{{info.value | googleTranslate | async}}</div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                            <a href="#" data-toggle="modal" [attr.data-target]="'#refund'+item.ID" data-show="true"
                              *ngIf="item.refundable"
                              class="mx-3 mb-3 mt-0 float-right btn btn-link text-muted pull-right"
                              (click)="openModal(item, 'refund')">
                              <u>{{"申請退款" | translate}}</u>
                            </a>
                            <a href="#" class="mx-3 mb-3 mt-0 float-right btn btn-link text-danger pull-right disabled"
                              *ngIf="item.payStatus == 3 || item.payStatus == 4 || item.payStatus == 7">
                              <span *ngIf="item.payStatus == 3 || item.payStatus == 4">{{"退款處理中" | translate}}</span>
                              <span *ngIf="item.payStatus == 7">{{"退款申請中" | translate}}</span>
                            </a>
                          </div>
                        </div>
                      </div>

                      <div class="row p-4 justify-content-end text-right">
                        <div class="col-12 my-1">
                          <div class="row justify-content-end">
                            <span class="col-6 col-md-3">
                              {{orderData.OrderDetail.length}}{{"件商品合計" |translate}}</span>
                            <span class="col-6 col-md-2"
                              [innerHTML]="orderData.amount | currencyTransform:orderData.currency:orderData.rate"></span>
                          </div>
                        </div>
                        <div class="col-12 my-1" *ngIf="orderData.couponID !== 0">
                          <div class="row justify-content-end">
                            <span class="col-6 col-md-3">{{"折扣金額" | translate}}</span>
                            <span class="col-6 col-md-2">-
                              <span
                                [innerHTML]="orderData.discount | currencyTransform:orderData.currency:orderData.rate"></span>
                            </span>
                          </div>
                        </div>
                        <div class="col-12 my-1">
                          <div class="row justify-content-end">
                            <span class="col-6 col-md-3">{{"付款金額" | translate}}</span>
                            <span class="col-6 col-md-2 font-weight-bold"
                              [innerHTML]="orderData.amount - orderData.discount | currencyTransform:orderData.currency:orderData.rate"></span>
                          </div>
                        </div>
                        <div class="col-12 my-1 text-danger" *ngIf="orderData.refund > 0">
                          <div class="row justify-content-end">
                            <span class="col-6 col-md-3">{{"退款金額" | translate}}</span>
                            <span class="col-6 col-md-2 font-weight-bold"
                              [innerHTML]="orderData.refund | currencyTransform:orderData.currency:orderData.rate"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

      <div class="footer py-5" [innerHTML]="initiatorData.footer"></div>

    </div>
  </div>
</div>


<!-- commentModal -->
<div class="modal fade" id="comment{{selectedOrder.ID}}" tabindex="-1" aria-labelledby="exampleModalLabel"
  aria-hidden="true" *ngIf="selectedOrder">
  <div class="modal-dialog modal-lg modal-custom modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">

      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{"給予評論" | translate}}</h5>
        <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <form action="" method="post" class="form-horizontal" [formGroup]="form" *ngIf="form">
          <div class="row no-gutters cart-item">
            <div class="col-4 col-md-3 text-center">
              <div class="product-img img-bg"
                style="background-image: url(/api/images/{{selectedOrder.productData.product.coverImg}});"></div>
            </div>
            <div class="col-8 col-md-9 d-md-flex">
              <div class="flex-grow-1">
                <div class="product-name">{{selectedOrder.productData.product.title | googleTranslate | async}}</div>
                <div class="product-option">{{selectedOrder.productData.plan.name | googleTranslate | async}}</div>
              </div>
            </div>
          </div>
          <div class="row mt-3 mb-4">
            <div class="col-12">{{"旅遊人" | translate}}</div>

            <div class="col-12 mt-2">
              <button type="button" class="btn btn-sm rounded-0 mr-2 my-1" (click)="tourist = '單人旅遊'"
                [ngClass]="{'btn-primary': tourist === '單人旅遊', 'btn-outline-secondary': tourist !== '單人旅遊'}">
                {{"單人旅遊" | translate}}</button>
              <button type="button" class="btn btn-sm rounded-0 mr-2 my-1" (click)="tourist = '好友'"
                [ngClass]="{'btn-primary': tourist === '好友', 'btn-outline-secondary': tourist !== '好友'}">
                {{"好友" | translate}}</button>
              <button type="button" class="btn btn-sm rounded-0 mr-2 my-1" (click)="tourist = '家人'"
                [ngClass]="{'btn-primary': tourist === '家人', 'btn-outline-secondary': tourist !== '家人'}">
                {{"家人" | translate}}</button>
              <button type="button" class="btn btn-sm rounded-0 mr-2 my-1" (click)="tourist = '情侶夫妻'"
                [ngClass]="{'btn-primary': tourist === '情侶夫妻', 'btn-outline-secondary': tourist !== ''}">
                {{"情侶夫妻" | translate}}</button>
              <button type="button" class="btn btn-sm rounded-0 mr-2 my-1" (click)="tourist = '其他'"
                [ngClass]="{'btn-primary': tourist === '其他', 'btn-outline-secondary': tourist !== '其他'}">
                {{"其他" | translate}}</button>
            </div>

          </div>
          <div class="row mb-2">
            <div class="col-12">{{"評分" | translate}}</div>
            <div class="rate mt-2">
              <ngx-input-star-rating [(ngModel)]="score" [ngModelOptions]="{standalone: true}">
              </ngx-input-star-rating>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-2">
              {{"評論" | translate}}
              <textarea name="" id="" cols="30" rows="3" class="form-control" [(ngModel)]="content"
                [ngModelOptions]="{standalone: true}"></textarea>
            </div>
            <div class="col-12">
              <label class="input input--file">
                <label class="input__support" [for]="'input-file-id'+i">
                  <span class="input__helper">{{"上傳檔案" | translate}}</span>
                </label>
                <input class="ng-hide" [id]="'input-file-id'+i" type="file" accept="image/jpeg, image/png"
                  (change)="onFileSelect($event, i)" name="image" />
              </label>
            </div>

            <div class="col-12" *ngIf="photo.length > 0">
              <div class="d-flex flex-wrap review-img-wrapper no-gutters mx-n1">
                <div class="col-3 col-md-2 p-1" *ngFor="let pic of photo;let i = index">
                  <img [src]="photo[i]" class="img-thumbnail">
                  <a class="delete">
                    <span class="material-icons" (click)="deleteImg(i)">
                      close
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer border-0">
        <button type="button" class="btn btn-link text-muted" data-dismiss="modal">{{"取消" | translate}}</button>
        <button type="button" class="btn btn-primary rounded-0" [disabled]="score <= 0 || commentSend"
          (click)="onSubmit()">{{"確定" | translate}}</button>
      </div>
    </div>
  </div>
</div>

<!-- refundModal -->
<div class="modal fade" id="refund{{selectedOrder.ID}}" aria-labelledby="refundModalLabel" aria-hidden="true"
  *ngIf="selectedOrder">
  <div class="modal-dialog modal-lg modal-custom modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <form action="" method="post" class="form-horizontal" [formGroup]="refundForm" (ngSubmit)="refundRequest()"
        *ngIf="refundForm">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{"申請退款" | translate}}</h5>
          <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row no-gutters cart-item">
            <div class="col-4 col-md-3 text-center">
              <div class="product-img img-bg"
                style="background-image: url(/api/images/{{selectedOrder.productData.product.coverImg}});"></div>
            </div>
            <div class="col-8 col-md-9 d-md-flex">
              <div class="flex-grow-1">
                <div class="product-name">{{selectedOrder.productData.product.title | googleTranslate | async}}</div>
                <div class="product-option">{{selectedOrder.productData.plan.name | googleTranslate | async}}</div>
                <div>
                  <small class="text-black-50">
                    <ul class="pl-4">
                      <li>{{selectedOrder.productData.planSpecification.name | googleTranslate | async}}</li>
                      <li *ngIf="selectedOrder.productData.extraOption.id !== 0">
                        {{selectedOrder.productData.extraPurchase.name | googleTranslate | async}}</li>
                    </ul>
                  </small>
                </div>
                <div class="product-quant d-flex align-items-center">
                  <span class="material-icons px-1">shopping_cart</span>
                  X {{selectedOrder.quantity}} {{selectedOrder.productData.planSpecification.unit | googleTranslate | async}}
                  <span class="material-icons px-1 ml-3">calendar_today</span>
                  {{selectedOrder.productData.specOption.date | date: 'yyyy/MM/dd'}}
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3 mb-4">
            <div class="col-12 mb-1">{{"退款原因" | translate}}</div>
            <div class="col-12 col-md-6">
              <select class="form-control select-arrow" formControlName="reason">
                <option value="" selected>{{"請選擇" | translate}}</option>
                <option value="日期時間選擇錯誤">{{"日期時間選擇錯誤" | translate}}</option>
                <option value="行程計劃變更">{{"行程計劃變更" | translate}}</option>
                <option value="其他">{{"其他" | translate}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-2">
              <div class="mb-1">{{"備註" | translate}}</div>
              <textarea name="note" cols="30" rows="3" class="form-control" formControlName="note"></textarea>
            </div>
          </div>

        </div>
        <div class="modal-footer border-0">
          <button type="button" class="btn btn-link text-muted" data-dismiss="modal">{{"取消" | translate}}</button>
          <button type="submit" class="btn btn-primary rounded-0">{{"確定" | translate}}</button>
        </div>
      </form>
    </div>
  </div>
</div>

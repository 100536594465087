import { IndexService } from './../index/index.service';
import { ProductService } from './../product/product.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recent-view',
  templateUrl: './recent-view.component.html',
  styleUrls: ['./recent-view.component.scss']
})
export class RecentViewComponent implements OnInit {

  recentViewedData: any[] = [];

  constructor(private productService: ProductService, private indexService: IndexService) { }

  ngOnInit(): void {
    this.productService.getRecentViewedProducts().subscribe((v: any) => {
      if (v.result) {
        this.recentViewedData = v.data;
        if (this.recentViewedData) {
          this.indexService.getGrabToday()
            .subscribe((r: any) => {
              if (r.result && r.data) {
                this.recentViewedData.map(product => {
                  product.isSale = false;
                  product.deduct = 0;

                  r.data.GrabTodayPlan.map(sales => {
                    if (sales.product.ID === product.ID) {
                      product.isSale = true;
                      product.deduct = sales.product.deduct;
                    }
                  });
                });
              }
            });
        }
      }
    });
  }
}

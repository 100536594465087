import { LayoutService } from './../../layout/layout.service';
import { IndexService } from './../../index/index.service';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from './../product.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.scss']
})
export class ProductCategoryComponent implements OnInit {
  pageIndex = 0;
  productData: any = [];
  regionData: any = [];
  tagData: any = [];
  itemLength = 0;
  numPages = 0;
  regionSelected = 0;
  tagSelected = 0;
  coverImg = 0;

  constructor(
    private route: ActivatedRoute,
    private layoutService: LayoutService,
    private indexService: IndexService,
    private productService: ProductService) {
    $('.navbar-mobile').removeClass('d-none');
    this.getRegions();
    this.getTags();
    this.route.url.subscribe(url => {
      if (url.length === 2) {
        this.tagSelected = +url[1].path;
      }
      if (url.length === 3) {
        this.tagSelected = +url[1].path;
        this.regionSelected = + url[2].path;
        setTimeout(() => {
          this.regionData.map(region => {
            if (region.ID === this.regionSelected && region.banner !== 0) {
              this.coverImg = region.banner;
            }
          });
          this.tagData.map(tag => {
            if (tag.ID === this.tagSelected && tag.image !== 0) {
              this.coverImg = tag.image;
            }
          });
        }, 1);
      }
      this.getTags();
      this.getProducts();
    });

    this.layoutService.getSystemSetting().subscribe((v: any) => {
      if (v.result && v.data) {
        this.coverImg = v.data.exploreWorldBanner;
      }
    });
  }

  ngOnInit(): void {
  }

  getProducts(pageNumber = this.pageIndex, region = this.regionSelected, tag = this.tagSelected) {
    this.coverImg = 0;
    if (this.tagSelected !== 0) {
      // tslint:disable-next-line:no-shadowed-variable
      this.tagData.map(tag => {
        if (tag.ID === this.tagSelected && tag.image !== 0) {
          this.coverImg = tag.image;
        }
      });
    }

    this.productService.getProducts(pageNumber, region, tag)
      .subscribe((v: any) => {
        if (v.result) {
          this.productData = v.data;
          this.itemLength = v.total;

          this.indexService.getGrabToday()
            .subscribe((r: any) => {
              if (r.result && r.data) {
                this.productData.map(product => {
                  product.isSale = false;
                  product.deduct = 0;

                  r.data.GrabTodayPlan.map(sales => {
                    if (sales.product.ID === product.ID) {
                      product.isSale = true;
                      product.deduct = sales.product.deduct;
                    }
                  });
                });
              }
            });
        }
      },
        (e) => { console.log(e); },
      );
  }

  getRegions() {
    this.productService.getRegions()
      .subscribe((v: any) => {
        if (v.result) {
          this.regionData = v.data;
        }
      },
        (e) => { console.log(e); },
      );
  }

  getTags() {
    this.tagData = [];
    this.productService.getTags(this.regionSelected)
      .subscribe((v: any) => {
        if (v.result) {
          this.tagData = v.data;
          setTimeout(() => {
            this.tagData.map(tag => {
              if (tag.ID === this.tagSelected && tag.image !== 0) {
                this.coverImg = tag.image;
              }
            });
          }, 1);
        }
      },
        (e) => { console.log(e); },
      );
  }

  selectRegion(id) {
    this.regionSelected = id;
    this.tagSelected = 0;
    setTimeout(() => {
      this.regionData.map(region => {
        if (region.ID === id && region.banner !== 0) {
          this.coverImg = region.banner;
        }
      });
    }, 1);
  }

}

<div class="bg-white panel">
  <h5 class="panel-title">{{"折扣卷" | translate}}</h5>
  <div class="panel-content">
    <div class="row">
      <!-- <div class="col-12 mb-2">
        <div class="bg-light p-4">
          <div class="form-group mb-0">

            <div class="row justify-content-center">
              <div class="col-md-6">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="輸入折扣碼" aria-label="Recipient's username"
                    aria-describedby="button-addon2" [(ngModel)]="myCode">
                  <div class="input-group-append ml-2">
                    <button class="btn btn-primary rounded-0" type="button" id="button-addon2">新增</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div> -->
      <div class="col-12">
        <div>
          <ul class="nav nav-tabs-custom mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link active" id="coupon-available" data-toggle="tab" href="#couupon-available" role="tab"
                aria-controls="couupon-available" aria-selected="true">{{"可使用" | translate}}</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-profile-tab" data-toggle="tab" href="#coupon-unavailable" role="tab"
                aria-controls="coupon-unavailable" aria-selected="false">{{"未付款" | translate}}</a>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="couupon-available" role="tabpanel"
              aria-labelledby="coupon-available">
              <div class="card-radio-btn mb-3" *ngFor="let item of coupons; let i = index">
                <div class="card card-body flex-column flex-md-row justify-content-between">
                  <div>
                    <div class="coupon-head">{{item.title | googleTranslate | async}}</div>
                    <div class="coupon-code" *ngIf="item.code">{{item.code}}</div>
                    <div class="coupon-sub">{{"有效日期" | translate}}：{{item.startDate | slice:0:10}} ~
                      <span *ngIf="(item.endDate | slice:0:10) == '0001-01-01'">{{"無期限" | translate}}</span>
                      <span *ngIf="(item.endDate | slice:0:10) != '0001-01-01'">{{item.endDate | slice:0:10}}</span>
                    </div>
                    <div class="coupon-sub">{{"使用規則" | translate}}：{{item.Rule | googleTranslate:true | async}}</div>
                  </div>

                  <div class="d-flex d-md-block align-items-center">
                    <div class="content-discount-title d-none d-sm-block">
                      <small *ngIf="item.discountType == 1">{{"折扣金額" | translate}}</small>
                      <small *ngIf="item.discountType == 2">{{"折扣" | translate}}</small>
                    </div>
                    <div class="coupon-discount" *ngIf="item.discountType == 1">
                      <span [innerHTML]="item.discountValue | currencyExchange:true:false | async"></span>
                    </div>
                    <div class="coupon-discount" *ngIf="item.discountType == 2">
                      <small>{{"折" | translate}}</small>
                      <span>{{item.discountValue}}%</span>
                    </div>
                  </div>

                </div>
              </div>

              <div class="mt-5 pagination d-flex justify-content-center">
                <pagination [totalItems]="itemLength" [maxSize]="5" previousText="&lsaquo;" nextText="&rsaquo;"
                  [itemsPerPage]="PageSize" firstText="&laquo;" lastText="&raquo;" [boundaryLinks]="true" (numPages)="numPages = $event"
                  (pageChanged)="pageIndex = $event.page-1;getMemberCoupon()"></pagination>
              </div>
            </div>
            <!-- unavailable -->
            <div class="tab-pane fade" id="coupon-unavailable" role="tabpanel" aria-labelledby="coupon-unavailable-tab">
              <div class="card-radio-btn mb-3" *ngFor="let item of unCoupons; let i = index">
                <div class="card card-body flex-column flex-md-row justify-content-between">
                  <div>
                    <div class="coupon-head">{{item.title | googleTranslate | async}}</div>
                    <div class="coupon-code" *ngIf="item.code">{{item.code}}</div>
                    <div class="coupon-sub">{{"有效日期" | translate}}：{{item.startDate | slice:0:10}} ~
                      <span *ngIf="(item.endDate | slice:0:10) == '0001-01-01'">{{"無期限" | translate}}</span>
                      <span *ngIf="(item.endDate | slice:0:10) != '0001-01-01'">{{item.endDate | slice:0:10}}</span>
                    </div>
                    <div class="coupon-sub">{{"使用規則" | translate}}：{{item.Rule | googleTranslate:true | async}}</div>
                  </div>

                  <div class="d-flex d-md-block align-items-center">
                    <div class="coupon-discount-title d-none d-sm-block">
                      <small *ngIf="item.discountType == 1">{{"折扣金額" | translate}}</small>
                      <small *ngIf="item.discountType == 2">{{"折扣" | translate}}</small>
                    </div>
                    <div class="coupon-discount" *ngIf="item.discountType == 1">
                      <span [innerHTML]="item.discountValue | currencyExchange:true:false | async"></span>
                    </div>
                    <div class="coupon-discount" *ngIf="item.discountType == 2">
                      <small>{{"折" | translate}}</small>
                      <span>{{item.discountValue}}%</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <!-- /unavailavle -->
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-web-storage';
import { Observable, of, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';
// import Country from '../../shared/resources/country.json';


@Injectable({
  providedIn: 'root'
})
export class PayService {
  // orderItems: any[] = [];
  private cartItemCount = new Subject<number>();
  currentItemCount = this.cartItemCount.asObservable();

  constructor(private http: HttpClient, private local: LocalStorageService) { }

  getItems(itemType: string) {
    const data = this.local.get(itemType);
    return data ? data : [];
  }

  addCart(orderItem: any) {
    const cartData = this.getItems('CartItems');
    cartData.push(orderItem);
    // 6小時後expire
    this.local.set('CartItems', cartData, 6, 'h');
    this.renewCartCount(cartData.length);
  }

  placeOrder(orderItem: any) {
    this.local.remove('OrderItems');
    this.local.set('OrderItems', orderItem, 6, 'h');
  }

  updateCart(cartItem: any) {
    this.removeAllItems('CartItems');
    this.local.set('CartItems', cartItem, 6, 'h');
    const cartData = this.getItems('CartItems');
    this.renewCartCount(cartData.length);
  }

  deleteCartItem(index: number) {
    const cartData = this.getItems('CartItems');
    cartData.splice(index, 1);
    this.removeAllItems('CartItems');
    this.local.set('CartItems', cartData, 6, 'h');
    this.renewCartCount(cartData.length);
  }

  orderFromCart(rsl: string) {
    this.local.set('orderFromCart', rsl, 6, 'h');
  }

  removeAllItems(itemType: string) {
    this.local.remove(itemType);
    const cartData = this.getItems('CartItems');
    this.renewCartCount(cartData.length);
  }

  renewCartCount(count: number) {
    this.cartItemCount.next(count);
  }

  getCountry(term: string = null): Observable<any[]> {
    // let items = Country;
    let items = require('../../shared/resources/country.json');
    if (term) {
      items = items.filter(x => x.cn.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
    }
    return of(items).pipe(delay(500));
  }

  getPayMethod() {
    return this.http.get('/api/site/payMethod/systemSetting');
  }

  getMember() {
    return this.http.get('/api/site/auth/member');
  }

  createOrder(order: any) {
    return this.http.post<any>('/api/site/auth/orderMain', order);
  }

  updatePayMethod(order: any) {
    return this.http.post<any>('/api/site/auth/orderMain/payMethod', order);
  }

  finishOrder(order: any) {
    return this.http.post<any>('/api/site/auth/finish/orderMain', order);
  }

  createGroupBuyOrder(order: any) {
    return this.http.post<any>('/api/site/orderMain', order);
  }

  getCoupons(order: any, code: string) {
    order.code = code;
    return this.http.post<any>('/api/site/coupons', order);
  }

  getCouponsForGroupBuy(order: any, code: string, id: number) {
    order.code = code;
    return this.http.post<any>('/api/site/groupBuy/coupons/' + id, order);
  }

  getGroupBuyCoupon(order: any, code: string, groupBuyId: number) {
    order.code = code;
    order.groupBuyId = groupBuyId;
    return this.http.post<any>('/api/site/groupBuyCoupon', order);
  }

  linepay(order: any) {
    return this.http.post<any>('/api/site/auth/linePay/request', order);
  }

  linepayForGroupBuy(order: any) {
    return this.http.post<any>('/api/site/linePay/request', order);
  }

  linepayComplete(order: any) {
    return this.http.post<any>('/api/site/auth/linePay/complete', order);
  }

  newebPayWithNumberSystem(order: any) {
    return this.http.post<any>('/api/site/auth/newebPay/orderProcessWithNewebPayNumberSystem', order);
  }

  newebPayWithNumberSystemForGroupBuy(order: any) {
    return this.http.post<any>('/api/site/newebPay/orderProcessWithNewebPayNumberSystem', order);
  }

  newebPayComplete(order: any) {
    return this.http.post<any>('/api/site/auth/newebPay/completePaymentWithNewebPay', order);
  }

  getOrderDetailsBySpecOptionId(id: number) {
    return this.http.get('/api/site/orderDetail/bySpecOptionId/' + id);
  }

  isWithin24Hours(order: any): boolean {
    const createdAt = new Date(order.CreatedAt);
    const currentTime = new Date();
    const timeDifference = currentTime.getTime() - createdAt.getTime();
    return timeDifference < (24 * 60 * 60 * 1000);
  }
}

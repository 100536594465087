import { MemberService } from './../../member/member.service';
import { DomSanitizer } from '@angular/platform-browser';
import { GroupBuyService } from './../group-buy.service';
import { PayService } from './../../pay/pay.service';
import { AlertDialogComponent } from './../../../shared/dialogs/alert-dialog/alert-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LayoutService } from '../../layout/layout.service';
import { TranslateService } from '@ngx-translate/core';
declare let $: any;

@Component({
  selector: 'app-group-buy-order-info',
  templateUrl: './group-buy-order-info.component.html',
  styleUrls: ['./group-buy-order-info.component.scss']
})
export class GroupBuyOrderInfoComponent implements OnInit {
  initiatorData: any;
  orderNumber: string;
  orderData: any;
  form: FormGroup;
  refundForm: FormGroup;
  selectedOrder: any;
  tourist = '單人旅遊';
  i = 0;
  photo: string[] = [];
  photoFile: any[] = [];
  bsModalRef: BsModalRef;
  scores = [1, 2, 3, 4, 5];
  score = 0;
  newebPayLogs: any;
  tradeInfo: any;
  commentSend = false;
  content = '';
  selectedCurrency: string;
  languageList = [{ name: '繁體中文', value: 'zh-TW' }, { name: '简体中文', value: 'zh-CN' }, { name: 'English', value: 'en' },
  { name: '日本語', value: 'ja' }];
  selectedLanguage: string;

  constructor(
    private route: ActivatedRoute,
    private groupBuyService: GroupBuyService,
    private payService: PayService,
    private memberService: MemberService,
    private modalService: BsModalService,
    public sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private layoutService: LayoutService,
    private translateService: TranslateService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.orderNumber = this.route.snapshot.params.number;
    this.getOrder();
    // this.getNewebPayLogs(this.orderNumber);
  }

  getOrder() {
    this.groupBuyService.getGroupBuyOrder(this.orderNumber).subscribe((v: any) => {
      console.log('v :>> ', v);
      if (v.result) {
        this.orderData = v.data;

        this.groupBuyService.getGroupBuyByInitiatorID(this.orderData.groupBuyInitiatorId).subscribe((v2: any) => {
          console.log('v2 :>> ', v2);
          if (v2.result) {
            this.initiatorData = v2.initiator;
            this.initiatorData.topGraphic = this.initiatorData.topGraphic === 'null' || this.initiatorData.topGraphic === '' ?
              '' : this.sanitizer.bypassSecurityTrustHtml(this.initiatorData.topGraphic);
            this.layoutService.translateHTML(this.initiatorData.footer).subscribe((res: any) => {
              this.initiatorData.footer = res;
            });
            const storedCurrency = localStorage.getItem('selectedCurrency' + this.initiatorData.ID);
            if (!storedCurrency) {
              this.selectedCurrency = this.initiatorData.currencyList.length > 0 ? this.initiatorData.currencyList[0] : 'TWD';
            } else {
              this.selectedCurrency = storedCurrency;
            }
            this.changeCurrency(this.selectedCurrency);
            this.translateService.use(this.layoutService.language.value);
            switch (this.layoutService.language.value) {
              case 'zh-TW':
                this.selectedLanguage = '繁體中文';
                break;
              case 'zh-CN':
                this.selectedLanguage = '简体中文';
                break;
              case 'en':
                this.selectedLanguage = 'English';
                break;
              case 'ja':
                this.selectedLanguage = '日本語';
                break;
            }

            // this.groupBuys = v2.data;
            // if (this.groupBuys) {
            //   this.groupBuys.map(item => {
            //     if (item.allowLimitedTime === 1) {
            //       item.leftTime = (new Date(item.endDate.split(':00Z')[0]).getTime() - new Date().getTime());
            //     }
            //     if (item.GroupBuyProduct.discountType === 2) {
            //       item.GroupBuyProduct.discountValue =
            //         Math.round(item.GroupBuyProduct.Product.lowestPrice * (item.GroupBuyProduct.discountValue / 100));
            //     }
            //   });
            // }
          }
        },
          (e) => {
            console.log(e);
          },
        );

        this.orderData.OrderDetail.map(detail => {
          detail.refundable = true;
          if (detail.payStatus !== 1) {
            detail.refundable = false;
          }
          const createdAt = new Date(this.orderData.CreatedAt.slice(0, 10));
          createdAt.setDate(createdAt.getDate() + 10);

          if (new Date().getTime() > createdAt.getTime() || new Date().getTime() > new Date(detail.PlanOption.date).getTime()) {
            detail.refundable = false;
          }
        });
      }
    },
      (e) => { console.log(e); },
    );
  }

  downloadVoucher(id: number) {
    const pdfUrl = '/api/site/orderDetail/voucher/' + id;
    const pdfName = 'voucher';
    saveAs(pdfUrl, pdfName);
  }

  resetCommentForm() {
    this.form = this.formBuilder.group({
      productId: [0],
      productPlanId: [0],
      tourist: [''],
      score: [0, [Validators.required]],
      content: [''],
      orderMainId: [0],
      orderDetailId: [0]
    });
    this.photo = [];
    this.photoFile = [];
    this.content = '';
  }

  resetRefundForm() {
    this.refundForm = this.formBuilder.group({
      reason: [''],
      note: [''],
    });
  }

  openModal(order: any, type: string) {
    if (type === 'comment') {
      this.resetCommentForm();
      this.tourist = '單人旅遊';
      this.score = 0;
    } else {
      this.resetRefundForm();
    }
    this.selectedOrder = order;
  }

  onFileSelect(event, index: number) {
    if (event.target.files[0].size > 500000) {
      alert('圖片大小必須在500k以下。');
    } else {
      if (event.target.files.length > 0) {
        // index = this.id ? this.photo.length : index;
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (res) => {
          this.photo[index] = reader.result as string;
        };
        this.photoFile.push(file);
        this.i++;
      }
    }
  }

  deleteImg(index: number) {
    const photoID = this.photo[index].split('/')[3];
    if (this.photo[index].split('/')[1] !== 'api' && this.photo[index].split('/')[2] !== 'image') {
      this.photo.splice(index, 1);
      this.photoFile.splice(index, 1);
      this.i--;
    }
  }

  onSubmit() {
    this.commentSend = true;
    this.form.value.productId = this.selectedOrder.productData.product.id;
    this.form.value.productPlanId = this.selectedOrder.productData.plan.id;
    this.form.value.tourist = this.tourist;
    this.form.value.score = this.score;
    this.form.value.orderMainId = this.orderData.ID;
    this.form.value.orderDetailId = this.selectedOrder.ID;
    this.form.value.content = this.content;

    // tslint:disable-next-line:new-parens
    const comment = new FormData;
    this.photoFile.forEach((value, index, files) => {
      comment.append('photo', value);
    });

    Object.keys(this.form.value).map((objectKey, index) => {
      const value = this.form.value[objectKey];
      comment.append(objectKey, value);
    });

    const productData = {
      product: {
        id: this.selectedOrder.productData.product.id, title: this.selectedOrder.productData.product.title,
        coverImg: this.selectedOrder.productData.product.coverImg
      },
      plan: { id: this.selectedOrder.productData.plan.id, name: this.selectedOrder.productData.plan.name }
    };
    comment.append('productData', JSON.stringify(productData));

    this.memberService.createComment(comment)
      .subscribe((r: any) => {
        if (r.result) {
          $('#comment' + this.selectedOrder.ID).modal('hide');
          this.toastr.success('已成功建立評論', '系統訊息');
          this.score = 0;
          this.getOrder();
          this.commentSend = false;
        } else {
          this.showAlert(r.message);
          this.commentSend = false;
        }
      },
        e => {
          console.log(e);
          this.showAlert(e.message);
        });
  }

  refundRequest() {
    this.memberService.refundRequest(this.selectedOrder.ID, { refundData: this.refundForm.value })
      .subscribe((r: any) => {
        if (r.result) {
          $('#refund' + this.selectedOrder.ID).modal('hide');
          this.toastr.success('已送出退款請求，請等候人員處理', '系統訊息');
          this.getOrder();
        } else {
          this.showAlert(r.message);
        }
      },
        e => {
          console.log(e);
          this.showAlert(e.message);
        });
  }

  showAlert(content: string) {
    const config = {
      initialState: {
        title: '系統錯誤',
        content,
        btnClass: 'btn btn-danger'
      },
      class: 'modal-danger'
    };
    this.bsModalRef = this.modalService.show(AlertDialogComponent, config);
  }

  getNewebPayLogs(orderNumber: string) {
    this.memberService.getNewebPayLogsByOrderNumber(orderNumber)
      .subscribe((v: any) => {
        if (v.result) {
          this.tradeInfo = JSON.parse(v.data.ThirdPartyPaymentFeedbackTradeInfo);
          console.log(this.tradeInfo);
        }
      },
        (e) => { console.log(e); },
      );
  }

  changeCurrency(currency: string) {
    this.selectedCurrency = currency;
    localStorage.setItem('selectedCurrency' + this.initiatorData.ID, currency);
    if (this.selectedCurrency === 'TWD') {
      this.layoutService.renewCurrency({
        currency,
      });
    }
    if (this.selectedCurrency !== 'TWD') {
      this.layoutService.getCurrencyRate().subscribe((v: any) => {

        this.layoutService.renewCurrency({
          currency,
          USD: v.data.USD.Exrate / v.data.USDTWD.Exrate,
          AUD: v.data.USDAUD.Exrate / v.data.USDTWD.Exrate,
          CAD: v.data.USDCAD.Exrate / v.data.USDTWD.Exrate,
          CNY: v.data.USDCNY.Exrate / v.data.USDTWD.Exrate,
          EUR: v.data.USDEUR.Exrate / v.data.USDTWD.Exrate,
          GBP: v.data.USDGBP.Exrate / v.data.USDTWD.Exrate,
          HKD: v.data.USDHKD.Exrate / v.data.USDTWD.Exrate,
          IDR: v.data.USDIDR.Exrate / v.data.USDTWD.Exrate,
          INR: v.data.USDINR.Exrate / v.data.USDTWD.Exrate,
          JPY: v.data.USDJPY.Exrate / v.data.USDTWD.Exrate,
          KHR: v.data.USDKHR.Exrate / v.data.USDTWD.Exrate,
          KRW: v.data.USDKRW.Exrate / v.data.USDTWD.Exrate,
          MAD: v.data.USDMAD.Exrate / v.data.USDTWD.Exrate,
          MMK: v.data.USDMMK.Exrate / v.data.USDTWD.Exrate,
          MOP: v.data.USDMOP.Exrate / v.data.USDTWD.Exrate,
          MYR: v.data.USDMYR.Exrate / v.data.USDTWD.Exrate,
          MXN: v.data.USDMXN.Exrate / v.data.USDTWD.Exrate,
          NPR: v.data.USDNPR.Exrate / v.data.USDTWD.Exrate,
          NZD: v.data.USDNZD.Exrate / v.data.USDTWD.Exrate,
          PHP: v.data.USDPHP.Exrate / v.data.USDTWD.Exrate,
          RUB: v.data.USDRUB.Exrate / v.data.USDTWD.Exrate,
          SGD: v.data.USDSGD.Exrate / v.data.USDTWD.Exrate,
          THB: v.data.USDTHB.Exrate / v.data.USDTWD.Exrate,
          TRY: v.data.USDTRY.Exrate / v.data.USDTWD.Exrate,
          VND: v.data.USDVND.Exrate / v.data.USDTWD.Exrate,
          ZAR: v.data.USDZAR.Exrate / v.data.USDTWD.Exrate,
        });
      },
        (e) => { console.log(e); },
      );
    }
  }

  changeLanguage(lang: string) {
    this.layoutService.renewLanguage(lang);
    this.translateService.use(lang);

    location.reload();
  }

}

import { PayService } from './../../pay/pay.service';
import { AlertDialogComponent } from './../../../shared/dialogs/alert-dialog/alert-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { MemberService } from './../member.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { saveAs } from 'file-saver';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LayoutService } from '../../layout/layout.service';
import { GroupTourService } from '../../group-tour/group-tour.service';
import { formatDate } from '@angular/common';
declare let $: any;

@Component({
  selector: 'app-member-order-info',
  templateUrl: './member-order-info.component.html',
  styleUrls: ['./member-order-info.component.scss']
})
export class MemberOrderInfoComponent implements OnInit {
  @ViewChild('divForm') divForm: ElementRef;
  orderNumber: string;
  orderData: any;
  form: FormGroup;
  refundForm: FormGroup;
  selectedOrder: any;
  tourist = '單人旅遊';
  i = 0;
  photo: string[] = [];
  photoFile: any[] = [];
  bsModalRef: BsModalRef;
  scores = [1, 2, 3, 4, 5];
  score = 0;
  newebPayLogs: any;
  tradeInfo: any;
  remitInfo = '';
  commentSend = false;
  content = '';
  endDate: any;
  goFlight: any;
  backFlight: any;

  linePay = false;
  payMethodLoaded = false;
  PayMethod2 = false;
  PayMethod6 = false;
  PayMethod7 = false;
  today = new Date();
  showPayModal = true;

  constructor(
    private route: ActivatedRoute,
    private memberService: MemberService,
    public payService: PayService,
    private modalService: BsModalService,
    private layoutService: LayoutService,
    public groupTourService: GroupTourService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.orderNumber = this.route.snapshot.params.number;
    this.getOrder();
    this.getNewebPayLogs(this.orderNumber);
  }

  getOrder() {
    this.memberService.getOrder(this.orderNumber).subscribe((v: any) => {
      if (v.result) {
        this.orderData = v.data;
        console.log('this.orderData :>> ', this.orderData);
        this.orderData.OrderDetail.map(detail => {
          detail.refundable = true;
          if (detail.payStatus !== 1) {
            detail.refundable = false;
          }
          const createdAt = new Date(this.orderData.CreatedAt.slice(0, 10));
          createdAt.setDate(createdAt.getDate() + 10);

          if (new Date().getTime() > createdAt.getTime() || new Date().getTime() > new Date(detail.PlanOption.date).getTime()) {
            detail.refundable = false;
          }
        });
        if (this.orderData.payMethod === 7) {
          this.payService.getPayMethod().subscribe((r: any) => {
            if (r.result) {
              this.layoutService.translateHTML(r.remitInfo).subscribe((res: any) => {
                this.remitInfo = res;
              });
            }
          });
        }
        if (this.orderData.payStatus === -1 || this.orderData.payStatus === 2) {
          this.payService.getPayMethod().subscribe((v2: any) => {
            if (v2.result) {
              this.layoutService.translateHTML(v2.remitInfo).subscribe((res: any) => {
                this.remitInfo = res;
              });
              if (v2.data) {
                v2.data.map(method => {
                  switch (method) {
                    case 2:
                      this.PayMethod2 = true;
                      break;
                    case 6:
                      this.PayMethod6 = true;
                      break;
                    case 7:
                      this.PayMethod7 = true;
                      break;
                  }
                });
              }
              this.payMethodLoaded = true;
            }
          });
        }

        if (this.orderData.tourGroupControlID !== 0) {
          this.endDate = new Date(this.orderData.TourGroupControl.startDate);
          this.endDate.setDate(this.endDate.getDate() + (this.orderData.TourGroupControl.defaultInfo.days - 1));
          if (this.orderData.TourGroupControl.flightInfo.length > 0) {
            this.orderData.TourGroupControl.flightInfo.map(flight => {
              if (flight.type === '出發') {
                this.groupTourService.getFlightById(flight.flightID).subscribe((v2: any) => {
                  if (v2.result && v2.data) {
                    console.log('v2.data :>> ', v2.data);
                    this.goFlight = v2.data;
                    this.goFlight.Flight = flight;

                    const { timeDiff, addOneDay } = this.countTimeDifference(flight.departureTime, flight.arrivalTime);
                    this.goFlight.timeDifference = timeDiff;
                    this.goFlight.arrivalDate = addOneDay ?
                      this.addOneDate(this.orderData.TourGroupControl.startDate) : this.orderData.TourGroupControl.startDate;

                    this.groupTourService.getAirlineById(flight.airlineID).subscribe((v3: any) => {
                      if (v3.result) {
                        this.goFlight.airlineName = v3.data.name;
                      }
                    },
                      (e) => { console.log(e); },
                    );
                  }
                });

              }

              if (flight.type === '回程') {
                this.groupTourService.getFlightById(flight.flightID).subscribe((v2: any) => {
                  if (v2.result && v2.data) {
                    this.backFlight = v2.data;
                    this.backFlight.Flight = flight;
                    console.log('this.backFlight :>> ', this.backFlight);
                    // this.backFlight.timeDifference = this.countTimeDifference(flight.departureTime, flight.arrivalTime);
                    const { timeDiff, addOneDay } = this.countTimeDifference(flight.departureTime, flight.arrivalTime);
                    this.backFlight.timeDifference = timeDiff;
                    this.backFlight.departureDate = addOneDay ? this.minusOneDate(this.endDate) : this.endDate;

                    this.groupTourService.getAirlineById(flight.airlineID).subscribe((v3: any) => {
                      if (v3.result) {
                        this.backFlight.airlineName = v3.data.name;
                      }
                    },
                      (e) => { console.log(e); },
                    );
                  }
                });
              }
            });
          }

        }
      }
    },
      (e) => { console.log(e); },
    );
  }

  downloadVoucher(id: number) {
    const pdfUrl = '/api/site/auth/orderDetail/voucher/' + id;
    const pdfName = 'voucher';
    saveAs(pdfUrl, pdfName);
  }

  resetCommentForm() {
    this.form = this.formBuilder.group({
      productId: [0],
      productPlanId: [0],
      tourist: [''],
      score: [0, [Validators.required]],
      content: [''],
      orderMainId: [0],
      orderDetailId: [0]
    });
    this.photo = [];
    this.photoFile = [];
    this.content = '';
  }

  resetRefundForm() {
    this.refundForm = this.formBuilder.group({
      reason: [''],
      note: [''],
    });
  }

  openModal(order: any, type: string) {
    if (type === 'comment') {
      this.resetCommentForm();
      this.tourist = '單人旅遊';
      this.score = 0;
    } else {
      this.resetRefundForm();
    }
    this.selectedOrder = order;
  }

  onFileSelect(event, index: number) {
    if (event.target.files[0].size > 500000) {
      alert('圖片大小必須在500k以下。');
    } else {
      if (event.target.files.length > 0) {
        // index = this.id ? this.photo.length : index;
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (res) => {
          this.photo[index] = reader.result as string;
        };
        this.photoFile.push(file);
        this.i++;
      }
    }
  }

  deleteImg(index: number) {
    const photoID = this.photo[index].split('/')[3];
    if (this.photo[index].split('/')[1] !== 'api' && this.photo[index].split('/')[2] !== 'image') {
      this.photo.splice(index, 1);
      this.photoFile.splice(index, 1);
      this.i--;
    }
  }

  onSubmit() {
    this.commentSend = true;
    this.form.value.productId = this.selectedOrder.productData.product.id;
    this.form.value.productPlanId = this.selectedOrder.productData.plan.id;
    this.form.value.tourist = this.tourist;
    this.form.value.score = this.score;
    this.form.value.orderMainId = this.orderData.ID;
    this.form.value.orderDetailId = this.selectedOrder.ID;
    this.form.value.content = this.content;

    // tslint:disable-next-line:new-parens
    const comment = new FormData;
    this.photoFile.forEach((value, index, files) => {
      comment.append('photo', value);
    });

    Object.keys(this.form.value).map((objectKey, index) => {
      const value = this.form.value[objectKey];
      comment.append(objectKey, value);
    });

    const productData = {
      product: {
        id: this.selectedOrder.productData.product.id, title: this.selectedOrder.productData.product.title,
        coverImg: this.selectedOrder.productData.product.coverImg
      },
      plan: { id: this.selectedOrder.productData.plan.id, name: this.selectedOrder.productData.plan.name }
    };
    comment.append('productData', JSON.stringify(productData));

    this.memberService.createComment(comment)
      .subscribe((r: any) => {
        if (r.result) {
          $('#comment' + this.selectedOrder.ID).modal('hide');
          this.toastr.success('已成功建立評論', '系統訊息');
          this.score = 0;
          this.getOrder();
          this.commentSend = false;
        } else {
          this.showAlert(r.message, 'btn btn-danger', 'modal-danger');
          this.commentSend = false;
        }
      },
        e => {
          console.log(e);
          this.showAlert(e.message, 'btn btn-danger', 'modal-danger');
        });
  }

  refundRequest() {
    this.memberService.refundRequest(this.selectedOrder.ID, { refundData: this.refundForm.value })
      .subscribe((r: any) => {
        if (r.result) {
          $('#refund' + this.selectedOrder.ID).modal('hide');
          this.toastr.success('已送出退款請求，請等候人員處理', '系統訊息');
          this.getOrder();
        } else {
          this.showAlert(r.message, 'btn btn-danger', 'modal-danger');
        }
      },
        e => {
          console.log(e);
          this.showAlert(e.message, 'btn btn-danger', 'modal-danger');
        });
  }

  // showAlert(content: string) {
  //   const config = {
  //     initialState: {
  //       title: '系統錯誤',
  //       content,
  //       btnClass: 'btn btn-danger'
  //     },
  //     class: 'modal-danger'
  //   };
  //   this.bsModalRef = this.modalService.show(AlertDialogComponent, config);
  // }

  getNewebPayLogs(orderNumber: string) {
    this.memberService.getNewebPayLogsByOrderNumber(orderNumber)
      .subscribe((v: any) => {
        if (v.result) {
          this.tradeInfo = JSON.parse(v.data.ThirdPartyPaymentFeedbackTradeInfo);
          console.log(this.tradeInfo);
        }
      },
        (e) => { console.log(e); },
      );
  }

  countTimeDifference(start: string, end: string): { timeDiff: string, addOneDay: boolean } {
    const startTime = this.parseTime(start);
    const endTime = this.parseTime(end);
    const timeDiff = this.calculateTimeDifference(startTime, endTime);

    let addOneDay = false;
    let hours = timeDiff.hours;
    const minutes = timeDiff.minutes;

    if (hours < 0) {
      hours += 24; // 跨越一天，加上24小时
      addOneDay = true;
    }

    const hoursStr = hours > 0 ? `${hours}h` : '';
    const minutesStr = minutes > 0 ? `${minutes}m` : '';

    return { timeDiff: `${hoursStr} ${minutesStr}`, addOneDay };
  }

  parseTime(timeStr: string) {
    const [hoursStr, minutesStr] = timeStr.split(':');
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);
    return { hours, minutes };
  }

  calculateTimeDifference(startTime, endTime) {
    let hours = endTime.hours - startTime.hours;
    let minutes = endTime.minutes - startTime.minutes;
    if (minutes < 0) {
      hours -= 1;
      minutes += 60;
    }
    return { hours, minutes };
  }

  addOneDate(dateStr: string): string {
    const currentDate = new Date(dateStr);
    currentDate.setDate(currentDate.getDate() + 1);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  minusOneDate(dateStr: string): string {
    const currentDate = new Date(dateStr);
    currentDate.setDate(currentDate.getDate() - 1);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() - 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  completePayment() {
    if (this.selectedOrder.payMethod === 2) {
      this.payService.linepayComplete(this.selectedOrder).subscribe(r => {
        if (r.result) {
          location.href = r.url;
          // location.href = r.linkmessage;
        } else {
          this.showAlert(r.message, 'btn btn-danger', 'modal-danger');
        }
      }, e => {
        console.log(e);
        this.showAlert(e.message, 'btn btn-danger', 'modal-danger');
      });
    }

    if (this.selectedOrder.payMethod === 6) {
      this.showAlert('將導到契約第三方付款金流（藍新金流）頁面...請稍候', 'btn btn-danger', 'modal-danger');
      this.payService.newebPayComplete(this.selectedOrder).subscribe(r => {
        if (r.result) {
          const dataForNewebPay = {
            formHtml: r.data.formHtml,
          };
          localStorage.setItem('dataForNewebPay', JSON.stringify(dataForNewebPay));
          const formHtml = r.data;
          this.divForm.nativeElement.innerHTML = `${formHtml}`;
          // tslint:disable-next-line:only-arrow-functions
          $(document).ready(function () {
            $('#newebpaySubmit').click();
            // tslint:disable-next-line:only-arrow-functions
            setTimeout(function () {
              $('#newebpaySubmit').trigger('click');
            }, 500);
          });
        } else {
          $('.modal-dialog').hide();
          if (r.message) {
            this.showAlert(r.message, 'btn btn-danger', 'modal-danger');
          } else {
            if (r.data) {
              this.showAlert(r.data, 'btn btn-danger', 'modal-danger');
            } else {
              this.showAlert('契約第三方付款金流（藍新金流）異常，請聯絡客服人員，謝謝您。', 'btn btn-danger', 'modal-danger');
            }
          }
          // tslint:disable-next-line:only-arrow-functions
          setTimeout(function () {
            // window.location.reload();
          }, 2000);
        }
      }, e => {
        this.showAlert(e.message, 'btn btn-danger', 'modal-danger');
      });
    }

    if (this.selectedOrder.payMethod === 2) {
      this.showPayModal = false;
      this.selectedOrder.payMethod = 7;
      this.payService.updatePayMethod(this.selectedOrder).subscribe(r => {
        if (r.result) {
          this.showAlert('匯款後請聯繫我們確保您的款項成功入帳。', 'btn btn-success', 'modal-success');
        } else {
          this.showAlert(r.message, 'btn btn-danger', 'modal-danger');
        }
      }, e => {
        console.log(e);
        this.showAlert(e.message, 'btn btn-danger', 'modal-danger');
      });
    }
  }

  showAlert(content: string, btnClass: string, theClass: string) {
    const config = {
      initialState: {
        title: '系統訊息',
        content,
        btnClass
      },
      class: theClass
    };
    this.bsModalRef = this.modalService.show(AlertDialogComponent, config);
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LayoutService } from 'src/app/modules/layout/layout.service';

@Pipe({
  name: 'googleTranslate'
})
export class GoogleTranslatePipe implements PipeTransform {

  constructor(private layoutService: LayoutService) { }

  transform(text: string, isArticle?: boolean): Observable<string> {
    if (text === '' || text === undefined) {
      return of(text);
    } else {
      return this.layoutService.currentLanguage.pipe(
        switchMap((lang: any) => {
          if (this.layoutService.language.value === 'zh-TW') {
            return of(text);
          } else {
            return this.layoutService.translateText(text).pipe(
              map((translatedText: string) => {
                if (!isArticle && this.layoutService.language.value === 'en') {
                  return this.capitalizeFirstWord(translatedText);
                } else {
                  return translatedText;
                }
              })
            );
          }
        })
      );
    }
  }

  private capitalizeFirstWord(text: string): string {
    const wordsToLowerCase = ['a', 'an', 'the', 'in', 'on', 'at', 'by', 'for', 'of', 'with', 'to', 'and', 'as', 'but', 'or'];
    return text.split(' ').map((word, index) => {
      if (index === 0 || !wordsToLowerCase.includes(word.toLowerCase())) {
        return this.capitalizeFirstLetter(word);
      } else {
        return word.toLowerCase();
      }
    }).join(' ');
  }

  private capitalizeFirstLetter(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

}

import { FeaturedService } from './featured.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-featured',
  templateUrl: './featured.component.html',
  styleUrls: ['./featured.component.scss']
})
export class FeaturedComponent implements OnInit {
  featuredData: any[] = [];
  banner: number;
  showMore = false;

  constructor(private featuredService: FeaturedService) { }

  ngOnInit(): void {
    this.featuredService.getFeaturedTopics()
      .subscribe((v: any) => {
        if (v.result && v.data) {
          this.featuredData = v.data;
        }
      });

    this.featuredService.getFeaturedTopicBanner()
      .subscribe((v: any) => {
        if (v.result && v.data) {
          this.banner = v.data.image;
        }
      });
  }

}

<div class="container my-4">
  <div class="row">
    <div class="col-12 d-flex justify-content-end">
      <button type="button" class="btn btn-dark d-flex align-items-center"
        (click)="closeShowDashboard.emit(false);"><span class="material-icons mr-1">exit_to_app</span>退出</button>
    </div>

    <div class="input-group my-3 col-12">
      <input type="month" class="form-control" style="height: 38px;" [(ngModel)]="searchMonth">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary pb-0" style="height: 38px;" type="button"
          (click)="getGroupBuyOrders()">
          <span class="material-icons text-muted">search</span>
        </button>
      </div>
    </div>
    <!-- <div class="col-sm-6 col-lg-3">
      <div class="card mb-4 text-muted">
        <div class="card-body pb-2">
          <div class="p-0 float-right">
            <i class="icon-docs icon font-2xl big-icon"></i>
          </div>
          <h6>商品數</h6>
          <div class="text-value">5</div>
        </div>
      </div>
    </div> -->
    <div class="col-sm-6 col-lg-4">
      <div class="card mb-4 text-muted">
        <div class="card-body pb-2">
          <div class="p-0 float-right">
            <span class="material-icons big-icon">receipt_long</span>
          </div>
          <h6>訂單數</h6>
          <div class="text-value" *ngIf="orderData">{{orderData.length}}</div>
          <div class="text-value" *ngIf="!orderData">0</div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg-4">
      <a [routerLink]="['/admin','contact']">
        <div class="card mb-4 text-muted">
          <div class="card-body pb-2">
            <div class="p-0 float-right">
              <span class="material-icons big-icon">paid</span>
            </div>
            <h6>訂單總額</h6>
            <div class="text-value" *ngIf="orderData">{{orderAmount | currency:'USD':'symbol':'1.0'}}</div>
            <div class="text-value" *ngIf="!orderData">{{0 | currency:'USD':'symbol':'1.0'}}</div>
          </div>
        </div>
      </a>
    </div>
    <div class="col-sm-6 col-lg-4">
      <div class="card mb-4 text-muted">
        <div class="card-body pb-2">
          <div class="p-0 float-right">
            <span class="material-icons big-icon">payments</span>
          </div>
          <h6>總分潤</h6>
          <div class="text-value" *ngIf="orderData">{{totalProfit | currency:'USD':'symbol':'1.0'}}</div>
          <div class="text-value" *ngIf="!orderData">{{0 | currency:'USD':'symbol':'1.0'}}</div>
        </div>
      </div>
    </div>

    <div class="col-12" *ngIf="initiatorData">
      <div class="card mb-4 text-muted">
        <div class="card-body pb-2 d-flex flex-column">
          <div class="d-flex flex-row mb-2">
            <h6 class="mr-2">姓名：</h6>{{initiatorData.name}}
          </div>
          <div class="d-flex flex-row mb-2">
            <h6 class="mr-2">電話：</h6>{{initiatorData.phone}}
          </div>
          <div class="d-flex flex-row">
            <h6 class="mr-2">信箱：</h6>
            <span *ngFor="let email of initiatorData.emails; let i = index;">{{email}} <span
                *ngIf="i < initiatorData.emails.length - 1">、</span></span>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="card mb-4">
    <div class="card-header">
      <div>訂單詳細<small class="text-muted" *ngIf="isMobile">（表單向右滑可瀏覽更多資訊）</small></div>
    </div>
    <div class="card-body row">
      <div class="mat-elevation-z8 w-100" [ngClass]="{'example-container': isMobile}">
        <mat-table [dataSource]="dataSource" matSort matSortDirection="asc" [ngClass]="{'mobileTable': isMobile}">
          <ng-container matColumnDef="order_number">
            <mat-header-cell *matHeaderCellDef mat-sort-header> 訂單編號 </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span class="text-primary">{{row.orderNumber}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="schedule">
            <mat-header-cell *matHeaderCellDef> 行程 </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div class="d-flex flex-column">
                <span *ngFor="let item of row.OrderDetail" class="my-2">
                  <span *ngIf="item.productData.product">{{item.productData.product.title}}</span>
                </span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="departureDay">
            <mat-header-cell *matHeaderCellDef> 出發日 </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div class="d-flex flex-column">
                <span *ngFor="let item of row.OrderDetail" class="my-2">
                  <span *ngIf="item.productData.product">{{item.productData.specOption.date}}</span>
                </span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> 客戶 </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{row.ordererInfo.lastName}}{{row.ordererInfo.firstName}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="amount">
            <mat-header-cell *matHeaderCellDef mat-sort-header> 應付款總額 </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span *ngIf="row.discountStatus === -1">{{row.amount - row.refund | currency:'USD':'symbol':'1.0'}}</span>
              <span *ngIf="row.discountStatus !== -1">{{row.amount - row.discount - row.refund |
                currency:'USD':'symbol':'1.0'}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="pay_status">
            <mat-header-cell *matHeaderCellDef mat-sort-header> 付款狀態 </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div class="d-flex flex-column">
                <span *ngFor="let item of row.OrderDetail" class="my-1">
                  <h5><span *ngIf="item.payStatus === -1" class="badge badge-pill badge-primary">未付款</span></h5>
                  <h5><span *ngIf="item.payStatus === 1" class="badge badge-pill badge-success">已付款</span></h5>
                  <h5><span *ngIf="item.payStatus === 2" class="badge badge-pill badge-warning">未付完</span></h5>
                  <h5><span *ngIf="item.payStatus === 3" class="badge badge-pill badge-warning">部分退款處理中</span></h5>
                  <h5><span *ngIf="item.payStatus === 4" class="badge badge-pill badge-warning">全額退款處理中</span></h5>
                  <h5><span *ngIf="item.payStatus === 5" class="badge badge-pill badge-warning">部分退款已完成</span></h5>
                  <h5><span *ngIf="item.payStatus === 6" class="badge badge-pill badge-warning">全額退款已完成</span></h5>
                  <h5><span *ngIf="item.payStatus === 7" class="badge badge-pill badge-warning">退款申請中</span></h5>
                </span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="created_at">
            <mat-header-cell *matHeaderCellDef mat-sort-header> 下單日 </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.CreatedAt| date: 'yyyy-MM-dd HH:mm'}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="profit">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="pl-4"> 分潤金額 </mat-header-cell>
            <mat-cell *matCellDef="let row" class="ml-4">
              <span>{{row.profitAmount | currency:'USD':'symbol':'1.0'}}</span>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
      </div>

    </div>

  </div>

</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyTransform'
})
export class CurrencyTransformPipe implements PipeTransform {
  private exchangedValue: number;

  transform(value: number, currency: string, rate: number): unknown {
    this.exchangedValue = value / rate;

    if (rate > 10) {
      this.exchangedValue = parseFloat(this.exchangedValue.toFixed(2));
    } else {
      this.exchangedValue = Math.round(this.exchangedValue);
    }

    const codeString = '<small>' + currency + '</small> ';
    // const dollarSignString = dollarSign ? '$' : '';

    return codeString + new Intl.NumberFormat('en-US').format(this.exchangedValue);
  }

}

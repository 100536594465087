<div class="container-fluid content-wrapper">

  <!-- 相關行程 -->
  <section class="section-wrapper">
    <div class="section-title bold mb-4">
      <img src="assets/images/index-section-icon_01.png" alt="">
      <div>{{"熱門行程" | translate}}</div>
    </div>
    <div class="row no-gutters">
      <div class="col-xl-4 col-sm-6 mb-3" *ngFor="let item of popularData">
        <div class="product-card">
          <div class="product-sale" *ngIf="item.isSale"></div>
          <div class="product-card-image"
            style="display: block; background-image: url(/api/images/{{item.Product.coverImg}});">
            <a [routerLink]="['/product', item.Product.ID]" class="link-spanner">
            </a>
          </div>
          <div class="product-card-body">
            <div class="product-title text-truncate">
              <a [routerLink]="['/product', item.Product.ID]">{{item.Product.title | googleTranslate | async}}</a>
            </div>
            <div class="product-des">{{item.Product.Country.name | googleTranslate | async}} {{item.Product.Region.name
              | googleTranslate | async}}</div>
            <div class="d-flex justify-content-between" [ngClass]="{ 'flex-column': item.isSale }">
              <div class="product-des">{{"觀看次數" | translate}}：{{item.Product.viewCount}}{{"次" |
                translate}}</div>
              <div class="product-price" *ngIf="!item.isSale">
                <span [innerHTML]="item.Product.lowestPrice | currencyExchange:true:false | async"></span>
                <small>{{"起" | translate}}</small>
              </div>
              <div class="product-price text-sale align-self-end" *ngIf="item.isSale">
                <small class="text-gray-m ml-auto pr-2 align-self-center">
                  <s>
                    <span [innerHTML]="item.Product.lowestPrice | currencyExchange:true:false | async"></span>
                    <small>{{"起" | translate}}</small>
                  </s>
                </small>
                <span [innerHTML]="item.Product.lowestPrice - item.deduct | currencyExchange:true:false | async"></span>
                <small>{{"起" | translate}}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="mt-5">
      <ul class="pagination justify-content-center">
        <li class="page-item active"><a class="page-link " href="#">1</a></li>
        <li class="page-item"><a class="page-link" href="#">2</a></li>
        <li class="page-item"><a class="page-link" href="#">3</a></li>
      </ul>
    </div> -->
  </section>

  <app-others-viewed></app-others-viewed>

</div>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionStorageService, LocalStorageService } from 'angular-web-storage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient, private session: SessionStorageService, private local: LocalStorageService) { }

  getProductById(id: number) {
    return this.http.get('/api/site/product/' + id);
  }

  getProductInfoById(id: number) {
    return this.http.get('/api/site/productInfo/' + id);
  }

  getOptionDetailById(id: number, filter: string) {
    return this.http.get('/api/site/optionDetail/product/' + id + '/' + filter);
  }

  getPlanOptionById(id: number) {
    return this.http.get('/api/site/planOption/' + id);
  }

  getProductByCode(orderNumber: string) {
    return this.http.get('/api/site/code/product/' + orderNumber);
  }

  getOrderDetailsBySpecId(id: number) {
    return this.http.get('/api/site/orderDetail/bySpecId/' + id);
  }

  getOrderDetailsByExtraId(id: number) {
    return this.http.get('/api/site/orderDetail/byExtraId/' + id);
  }

  getCommentsByPId(pid = 0, filter = '', score = 0, pageNumber = 0,
                   pageSize = 10, filterCategory = '', startDate = '', endDate = ''): Observable<any[]> {
    return this.http.get<any[]>('/api/site/comments/byScore', {
      params: new HttpParams()
        .set('pid', pid.toString())
        .set('filter', filter)
        .set('score', score.toString())
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
        .set('filterCategory', filterCategory)
        .set('startDate', startDate)
        .set('endDate', endDate)
    });
  }

  updateViewCount(id: number) {
    return this.http.post<any>('/api/site/product/viewCount/' + id, null);
  }

  getCartItems() {
    const data = JSON.parse(this.session.get('OrderItems'));
    return data ? data : [];
  }

  getCountries() {
    return this.http.get('/api/site/countries');
  }

  getRegions() {
    return this.http.get('/api/site/regions');
  }

  getTags(id: number) {
    return this.http.get('/api/site/tags/' + id);
  }

  getHotTags() {
    return this.http.get('/api/site/hot/tags');
  }

  getHotelById(id: number) {
    return this.http.get('/api/site/hotel/' + id);
  }

  getProducts(pageNumber = 0, region = 0, tag = 0): Observable<any[]> {
    return this.http.get<any[]>('/api/site/products/all', {
      params: new HttpParams()
        .set('pageNumber', pageNumber.toString())
        .set('region', region.toString())
        .set('tag', tag.toString())
    });
  }

  getProductsBySearch(filter: string): Observable<any[]> {
    return this.http.get<any[]>('/api/site/products/search', {
      params: new HttpParams()
        .set('filter', filter)
    });
  }

  getOthersViewedProducts(region = 0, tag = 0): Observable<any[]> {
    return this.http.get<any[]>('/api/site/products/othersViewed', {
      params: new HttpParams()
        .set('region', region.toString())
        .set('tag', tag.toString())
    });
  }

  getRecentViewedProducts() {
    const ids = this.local.get('recentView') ? this.local.get('recentView') : [];
    return this.http.post<any>('/api/site/products/byIdSet', ids);
  }

  addRecentViewedProduct(id: number) {
    const ids = this.local.get('recentView') ? this.local.get('recentView') : [];
    ids.unshift(id);
    const unique = ids.filter((v, i, a) => a.indexOf(v) === i);
    if (unique.length > 16) {
      unique.pop();
    }
    this.local.set('recentView', unique);
  }
}

<div class="container-fluid content-wrapper ">

  <section class="section-wrapper">
    <div class="section-title mb-4">
      <img src="assets/images/index-section-icon_07.png" alt="">
      <div>{{"影音介紹" | translate}}</div>
    </div>
    <div class="row no-gutters">
      <div class="gtrans" id="google_translate_element"></div>

      <div class="col-xl-3 col-sm-6 mb-3" *ngFor="let video of videoData; let i = index;"
        (click)="selectedVideo = video; updateViewCount(video.ID);">
        <div class="product-card" *ngIf="i < 16">
          <div class="product-card-image" style="background-image: url(/api/images/{{video.image}});">
            <div class="product-card-video-icon">
              <svg viewBox="0 0 24 24">
                <path fill="currentColor"
                  d="M10,16.5V7.5L16,12M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
              </svg>
            </div>
          </div>
          <div class="product-card-body">
            <div class="product-title text-truncate"><a>{{video.title | googleTranslate | async}}</a></div>
            <div class="product-des">{{video.Country.name | googleTranslate | async}} {{video.Region.name |
              googleTranslate | async}}</div>
            <div class="d-flex justify-content-between" [ngClass]="{ 'flex-column': video.isSale }">
              <div class="product-des">{{"觀看次數" | translate}}：{{video.viewCount}}{{"次" | translate}}</div>
              <div class="product-price" *ngIf="!video.isSale">
                <span [innerHTML]="video.Product.lowestPrice | currencyExchange:true:false | async"></span>
                <small>{{"起" | translate}}</small>
              </div>
              <div class="product-price text-sale align-self-end" *ngIf="video.isSale">
                <small class="text-gray-m ml-auto pr-2 align-self-center">
                  <s>
                    <span [innerHTML]="video.Product.lowestPrice | currencyExchange:true:false | async"></span>
                    <small>{{"起" | translate}}</small>
                  </s>
                </small>
                <span
                  [innerHTML]="video.Product.lowestPrice - video.deduct | currencyExchange:true:false | async"></span>
                <small>{{"起" | translate}}</small>
              </div>
            </div>
          </div>
          <a href="#video-modal" class="link-spanner popup-modal"></a>
        </div>

        <div *ngIf="showMore">
          <div class="product-card" *ngIf="i >= 16">
            <div class="product-card-image" style="background-image: url(/api/images/{{video.image}});">
              <div class="product-card-video-icon">
                <svg viewBox="0 0 24 24">
                  <path fill="currentColor"
                    d="M10,16.5V7.5L16,12M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                </svg>
              </div>
            </div>
            <div class="product-card-body">
              <div class="product-title text-truncate"><a>{{video.title | googleTranslate | async}}</a></div>
              <div class="product-des">{{video.Country.name | googleTranslate | async}} {{video.Region.name |
                googleTranslate | async}}</div>
              <div class="d-flex justify-content-between">
                <div class="product-des">{{"觀看次數" | translate}}：{{video.viewCount}}{{"次" | translate}}
                </div>
                <div class="product-price">
                  <span [innerHTML]="video.Product.lowestPrice | currencyExchange:true:false | async"></span>
                  <small>{{"起" | translate}}</small>
                </div>
              </div>
            </div>
            <a href="#video-modal" class="link-spanner popup-modal"></a>
          </div>
        </div>
      </div>

    </div>

    <div class="row mt-5" *ngIf="videoData.length > 16 && !showMore">
      <div class="col-12 col-md-4 col-lg-3 mx-auto">
        <a type="button" id="showmore" class="btn btn-outline-primary btn-lg btn-block rounded-0"
          (click)="showMore = true;loadScript('assets/js/video.js');">{{"看更多" | translate}}</a>
      </div>
    </div>
  </section>
</div>


<div id="video-modal" class="mfp-hide white-popup-block">
  <a class="popup-modal-dismiss" href="#">
    <span aria-hidden="true">&times;</span>
  </a>
  <div class=" embed-responsive embed-responsive-16by9" *ngIf="selectedVideo">
    <iframe [src]="selectedVideo.youtube | safeUrl" class="embed-responsive-item" frameborder="0"
      allowfullscreen></iframe>
  </div>

  <a [routerLink]="['/product', selectedVideo.Product.ID]" class="card mt-4 shadow-sm" *ngIf="selectedVideo">
    <div class="row no-gutters">
      <div class="col-4 p-2">
        <img [src]="'/api/images/' + selectedVideo.image" class="card-img rounded-0">
      </div>
      <div class="col-8">
        <div class="card-body">
          <div class="product-card-body">
            <div class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center"
              (click)="closeModal()">
              <div>
                <div class="product-title text-truncate"><a>{{selectedVideo.title | googleTranslate | async}}</a></div>
                <div class="product-des">{{selectedVideo.Country.name | googleTranslate | async}}
                  {{selectedVideo.Region.name | googleTranslate | async}}</div>
              </div>
              <div class="text-primary d-flex">{{"看詳細介紹" | translate}} ></div>
            </div>
            <!-- <a [routerLink]="['/product', selectedVideo.Product.ID]" class="link-spanner"></a> -->
          </div>
        </div>
      </div>
    </div>
  </a>
</div>

import { IndexService } from './../../index/index.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ProductService } from './../product.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss']
})
export class ProductSearchComponent implements OnInit {

  filter: string;
  productData: any[] = [];
  itemLength: number;
  pageIndex = 0;
  numPages = 0;
  pageProducts: any[] = [];
  itemsPerPage = 9;

  constructor(
    private productService: ProductService,
    private indexService: IndexService,
    private route: ActivatedRoute,
    private router: Router) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.filter = this.route.snapshot.params.filter;
        this.getProducts();
      }
    });
  }

  ngOnInit(): void {
  }

  getProducts() {
    this.pageProducts = [];
    this.productService.getProductsBySearch(this.filter)
      .subscribe((v: any) => {
        if (v.result && v.data) {
          this.productData = v.data;
          this.itemLength = this.productData.length;
          let i = 0;
          this.productData.map(item => {
            if (i < this.itemsPerPage) {
              this.pageProducts.push(item);
            }
            i++;
          });

          this.indexService.getGrabToday()
            .subscribe((r: any) => {
              if (r.result && r.data) {
                this.productData.map(product => {
                  product.isSale = false;
                  product.deduct = 0;

                  r.data.GrabTodayPlan.map(sales => {
                    if (sales.product.ID === product.ID) {
                      product.isSale = true;
                      product.deduct = sales.product.deduct;
                    }
                  });
                });
              }
            });
        }
      });
  }

  getPageProducts(pageIndex: number) {
    this.pageProducts = [];
    let i = 0;
    this.productData.map(item => {
      if (i >= pageIndex * this.itemsPerPage && i < (pageIndex + 1) * this.itemsPerPage) {
        this.pageProducts.push(item);
      }
      i++;
    });
  }

}

import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

  title: '';
  content: '';
  btnClass: 'btn btn-primary';

  public event: EventEmitter<any> = new EventEmitter();
  constructor(public bsModalRef: BsModalRef) {
  }

  ngOnInit() {
  }

  ok() {
    this.event.emit({ action: true });
  }
}

<div class="wrapper">
  <app-nav></app-nav>
  <div class="content-member">
    <div class="container-fluid content-wrapper position-relative">
      <div class="row">
        <div class="col-md-3 d-none d-xl-block">
          <div class="bg-white">
            <div class="user-nav" role="menu">
              <div class="user-info text-center" *ngIf="userInfo">
                <div class="position-relative">
                  <img src="assets/images/avatar.jpg" class="user-img rounded-circle" *ngIf="!imgSrc">
                  <img src="{{imgSrc}}" class="user-img rounded-circle" width="82px" height="82px" *ngIf="imgSrc">
                  <label class="btn-edit " for="input-file">
                    <span class="material-icons">create</span>
                  </label>
                  <input class="ng-hide" id="input-file" type="file" accept="image/jpeg, image/png"
                    (change)="onFileSelect($event)" name="image" />
                </div>
                <div class="user-name">{{userInfo.lastName}}{{userInfo.firstName}}</div>
              </div>
              <a class="dropdown-item" [ngClass]="{ 'active': memberService.pathSelected == 'groupOrder' }"
                [routerLink]="['/member/groupOrder']" (click)="memberService.pathSelected = 'groupOrder'">
                <span class="material-icons">flight_takeoff</span>{{"旅遊訂單" | translate}}</a>
              <a class="dropdown-item" [ngClass]="{ 'active': memberService.pathSelected == 'order' }"
                [routerLink]="['/member/order']" (click)="memberService.pathSelected = 'order'">
                <span class="material-icons">list_alt</span>{{"票卷訂單" | translate}}</a>
              <a class="dropdown-item" [ngClass]="{ 'active': memberService.pathSelected == 'saved' }"
                (click)="memberService.pathSelected = 'saved'" [routerLink]="['/member/saved']"><span
                  class="material-icons">loyalty</span>{{"我的收藏" | translate}}</a>
              <a class="dropdown-item" [ngClass]="{ 'active': memberService.pathSelected == 'coupon' }"
                (click)="memberService.pathSelected = 'coupon'" [routerLink]="['/member/coupon']"><span
                  class="material-icons-outlined">local_activity</span>{{"折扣碼" | translate}}</a>
              <a class="dropdown-item" [ngClass]="{ 'active': memberService.pathSelected == 'setting' }"
                (click)="memberService.pathSelected = 'setting'" [routerLink]="['/member/setting']"><span
                  class="material-icons-outlined">settings</span>{{"帳號設定" | translate}}</a>
              <a class="dropdown-item clickable" (click)="loginService.logout()"><span
                  class="material-icons">exit_to_app</span>{{"登出" | translate}}</a>
            </div>
          </div>
        </div>
        <div class="col-xl-9 px-1 px-md-2">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>

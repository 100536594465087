<!-- 其他人也看過 -->
<section class="section-viewed px-2" *ngIf="viewedData.length > 3">
  <div class="section-title">
    <div>{{"其他人也看過" | translate}}</div>
  </div>
  <div class="scroll-box d-flex flex-row">
    <div class="col-xl-3 col-lg-6 col-sm-5 col-10" *ngFor="let product of viewedData">
      <div class="product-card cards">
        <div class="product-sale" *ngIf="product.isSale"></div>
        <div class="product-card-image"
          style="display: block; background-image: url(/api/images/{{product.coverImg}});">
          <!-- <a [routerLink]="['/product', product.ID]" class="link-spanner"></a> -->
        </div>
        <div class="product-card-body">
          <div class="product-title text-truncate">
            <a [routerLink]="['/product', product.ID]">{{product.title | googleTranslate | async}}</a>
          </div>
          <div class="product-des">{{product.Country.name | googleTranslate | async}} {{product.Region.name |
            googleTranslate | async}}</div>
          <div class="d-flex justify-content-between">
            <div class="product-des">{{"觀看次數" | translate}}：{{product.viewCount}}{{"次" | translate}}</div>
            <div class="product-price" *ngIf="!product.isSale">
              <span [innerHTML]="product.lowestPrice | currencyExchange:true:false | async"></span>
              <small>{{"起" | translate}}</small>
            </div>
            <div class="product-price text-sale align-self-end" *ngIf="product.isSale">
              <small class="text-gray-m ml-auto pr-2 align-self-center">
                <s>
                  <span [innerHTML]="product.lowestPrice | currencyExchange:true:false | async"></span>
                  <small>{{"起" | translate}}</small>
                </s>
              </small>
              <span [innerHTML]="product.lowestPrice - product.deduct | currencyExchange:true:false | async"></span>
              <small>{{"起" | translate}}</small>
            </div>
          </div>
        </div>
        <a [routerLink]="['/product', product.ID]" class="link-spanner"></a>
      </div>
    </div>
  </div>
</section>

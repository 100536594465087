import { IndexService } from './../../index/index.service';
import { MemberService } from './../../member/member.service';
import { LoginService } from './../../../core/services/login.service';
import { PayService } from './../../pay/pay.service';
import { ProductService } from './../product.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { LoginComponent } from 'src/app/shared/login/login.component';
import { ScrollSpyService } from '@uniprank/ngx-scrollspy';
import { Location } from '@angular/common';
import { LayoutService } from '../../layout/layout.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
declare let $: any;

@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss']
})
export class ProductInfoComponent implements OnInit {
  loading = false;
  id: number;
  productData: any;
  commentData: any[] = [];
  planData: any[] = [];
  pageIndex = 0;
  avgScore = 0;
  totalComments = 0;
  numPages = 0;
  isLogin = false;
  bsModalRef: BsModalRef;
  tabSelected = 1;
  memberSaved = false;
  memberSavedId: number;
  isSale = false;
  deduct = 0;
  pageUrl = location.href;
  grabTodayData = { id: 0, deadline: '', deduct: 0 };
  currentSection = '';
  formattedSelectedDate = '';
  remainQuantity = 0;
  isMemberDone = false;
  hasProduct = true;

  monthNames: string[] = ['一月', '二月', '三月', '四月', '五月', '六月', '七月',
    '八月', '九月', '十月', '十一月', '十二月'];
  monthNumbers: string[] = ['01', '02', '03', '04', '05', '06', '07',
    '08', '09', '10', '11', '12'];
  dayNames = ['一', '二', '三', '四', '五', '六', '日'];

  constructor(
    private route: ActivatedRoute,
    private scrollSpyService: ScrollSpyService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    public sanitizer: DomSanitizer,
    private productService: ProductService,
    private payService: PayService,
    private memberService: MemberService,
    private indexService: IndexService,
    public locations: Location,
    private layoutService: LayoutService,
    public loginService: LoginService,
    private modalService: BsModalService,
  ) {
    this.id = this.route.snapshot.params.id;
    this.route.url.subscribe(url => {
      if (+url[1].path !== this.id) {
        this.id = +url[1].path;
        this.getProductById();
        this.productService.updateViewCount(this.id)
          .subscribe((v: any) => { });
      }
    });
  }

  ngOnInit(): void {
    if (this.loginService.userInfo === undefined) {
      this.loginService.isLogin().subscribe(resp => {
        this.isLogin = resp.result;
        if (resp.result) {
          this.loginService.userInfo = resp.user_info;
        }
      }, err => {
        // console.log(err);
        this.isLogin = false;
      }, () => { });
    } else {
      this.isLogin = true;
    }
    this.memberService.getMember().subscribe((v: any) => {
      if (v.result) {
        this.isMemberDone = v.data.status === 1 ? true : false;
      }
    },
      (e) => { console.log(e); },
    );
    this.scrollSpyService.setOffset('window', 100);
  }

  getProductById() {
    this.getMemberSavedById();

    this.productService.getProductInfoById(this.id).subscribe((v: any) => {
      this.loading = true;
      console.log('v :>> ', v);
      if (v.result && v.data && v.data.tourControl === 0) {
        this.avgScore = v.avg;
        this.productData = v.data;
        this.changeDetectorRef.detectChanges();

        this.getGrabToday();

        this.productService.addRecentViewedProduct(this.id);

        this.layoutService.translateHTML(this.productData.map).subscribe((res: any) => {
          this.productData.map = res;
        });
        this.layoutService.translateHTML(this.productData.videoContext).subscribe((res: any) => {
          this.productData.videoContext = res;
        });
        this.layoutService.translateHTML(this.productData.detail).subscribe((res: any) => {
          this.productData.detail = res;
        });
        this.layoutService.translateHTML(this.productData.introduction).subscribe((res: any) => {
          this.productData.introduction = res;
        });
        this.layoutService.translateHTML(this.productData.description).subscribe((res: any) => {
          this.productData.description = res;
        });
        this.layoutService.translateHTML(this.productData.instruction).subscribe((res: any) => {
          this.productData.instruction = res;
        });
        this.layoutService.translateHTML(this.productData.notice).subscribe((res: any) => {
          this.productData.notice = res;
        });
        this.layoutService.translateHTML(this.productData.policy).subscribe((res: any) => {
          this.productData.policy = res;
        });

        this.productData.coverVideo = this.productData.coverVideo === 'null' || this.productData.coverVideo === '' ?
          '' : this.sanitizer.bypassSecurityTrustHtml(this.productData.coverVideo);


        if (this.productData.ProductPlans) {
          this.productData.ProductPlans.sort((a, b) => (a.sort < b.sort ? -1 : 1));
          this.productData.ProductPlans = this.productData.ProductPlans.filter(plan => plan.status === 1);
          this.productData.ProductPlans.map(plan => {
            plan.show = false;
            plan.selected = false;
            plan.personCount = 1;

            plan.productPlanId = 0;
            plan.specOptionId = 0;
            plan.extraOptionId = 0;
            plan.price = 0;
            plan.optionSelected = false;
            plan.optionDate = '';

            plan.specData = {
              id: 0, name: '', unit: '',
              splitMethod: 0,
              splitValue: 0,
              fullPayment: 1,
              deposit: 0,
              productCosts: [],
              planOptData: [],
              daysInThisMonth: [],
              daysInLastMonth: [],
              daysInNextMonth: [],
              date: new Date(),
              currentDate: 0,
              currentMonth: '',
              currentMonthNum: '',
              currentYear: 0
            };

            plan.extraData = {
              id: 0, name: '', unit: '',
              splitMethod: 0,
              splitValue: 0,
              productCosts: [],
              planOptData: [],
              daysInThisMonth: [],
              daysInLastMonth: [],
              daysInNextMonth: [],
              date: new Date(),
              currentDate: 0,
              currentMonth: '',
              currentMonthNum: '',
              currentYear: 0
            };

            if (this.loading) {
              setTimeout(() => {
                if (plan.PlanSpecification && plan.PlanSpecification.length > 0) {
                  plan.PlanSpecification.forEach((spec) => {
                    if (spec.fullPayment === 1) {
                      plan.show = true;
                    }
                    spec.checked = false;
                    this.getOptionDetailById(spec.ID, 'plan_specification_id').subscribe((options: any[]) => {
                      spec.PlanOptions = options;
                      if (spec.PlanOptions && spec.PlanOptions.length > 0) {
                        spec.PlanOptions.forEach((option) => {
                          option.sold = 0;
                          option.date = option.date.length === 10 ?
                            option.date : option.date.split('-')[0] + '-' + option.date.split('-')[1] + '-0' + option.date.split('-')[2];
                        });
                      }
                    });
                  });
                }
                if (plan.ExtraPurchase && plan.ExtraPurchase.length > 0) {
                  plan.ExtraPurchase.forEach((extra) => {
                    extra.checked = false;
                    this.getOptionDetailById(extra.ID, 'extra_purchase_id').subscribe((options: any[]) => {
                      extra.PlanOptions = options;
                      if (extra.PlanOptions && extra.PlanOptions.length > 0) {
                        extra.PlanOptions.forEach((option) => {
                          option.sold = 0;
                          option.date = option.date.length === 10 ?
                            option.date : option.date.split('-')[0] + '-' + option.date.split('-')[1] + '-0' + option.date.split('-')[2];
                        });
                      }
                    });
                  });
                }
              }, 100);
            }
          });
        }
        this.getComments();
      } else {
        this.loading = false;
        this.hasProduct = false;
      }
    },
      (e) => { this.loading = false; console.log(e); },
    );
  }

  getGrabToday() {
    return new Promise((resolve, reject) => {
      this.indexService.getGrabToday()
        .subscribe((r: any) => {
          if (r.result && r.data) {
            r.data.GrabTodayPlan.map(item => {
              if (item.product.ID === +this.id) {
                this.isSale = true;
                this.deduct = item.product.deduct;
                this.grabTodayData = { id: r.data.ID, deadline: r.data.deadline, deduct: item.product.deduct };
              }
            });
          }
          resolve(true);
        });
    });
  }

  getOptionDetailById(id: number, filter: string): Observable<any[]> {
    return this.productService.getOptionDetailById(id, filter).pipe(
      map((v: any) => {
        if (v.result && v.data) {
          return v.data;
        } else {
          return [];
        }
      })
    );
  }

  getMemberSavedById() {
    if (this.isLogin) {
      this.memberService.getMemberSavedById(this.id)
        .subscribe((v: any) => {
          if (v.result && v.data) {
            this.memberSaved = true;
            this.memberSavedId = v.data.ID;
          }
        });
    }
  }

  getComments(
    filter = '', score = 0, pageNumber = this.pageIndex, pageSize = 5,
    filterCategory = '', startDate = '', endDate = '') {
    if (this.id) {
      this.productService.getCommentsByPId(this.id, filter, score, pageNumber, pageSize, filterCategory, startDate, endDate)
        .subscribe((v: any) => {
          if (v.result) {
            this.commentData = v.data;
            this.totalComments = v.itemLength;
          }
          this.loadScript('assets/js/app.js');
          this.loadScript('assets/js/gallery.js');
          this.loading = false;
        },
          (e) => { this.loading = false; console.log(e); },
        );
      return;
    }
  }

  memberSavedChecked() {
    if (!this.isLogin) {
      this.login(window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/product/' + this.id);
    } else {
      if (this.memberSaved) {
        this.memberService.deleteMemberSaved(this.memberSavedId)
          .subscribe((v: any) => {
            if (v.result) {
              this.memberSaved = false;
              this.memberSavedId = null;
            }
          },
            (e) => { console.log(e); },
          );
      } else {
        const data = { productID: +this.id, countryID: this.productData.countryID, regionID: this.productData.regionID };
        this.memberService.createMemberSaved(data)
          .subscribe((v: any) => {
            if (v.result) {
              this.getMemberSavedById();
            }
          },
            (e) => { console.log(e); },
          );
      }
    }
  }

  addCart(i: number) {
    if (this.isMemberDone && this.isLogin) {
      const date = this.productData.ProductPlans[i].optionDate.split('-')[2].length > 1 ? this.productData.ProductPlans[i].optionDate
        : this.productData.ProductPlans[i].optionDate.split('-')[0] + '-' + this.productData.ProductPlans[i].optionDate.split('-')[1]
        + '-0' + this.productData.ProductPlans[i].optionDate.split('-')[2];

      let quantityCheck = true;
      if (this.productData.ProductPlans[i].extraData.id !== 0) {
        this.productData.ProductPlans[i].extraData.planOptData.map(opt => {
          if (opt.ID === this.productData.ProductPlans[i].extraOptionId) {
            if (this.productData.ProductPlans[i].personCount > opt.quantity) {
              quantityCheck = false;
            }
          }
        });
      } else {
        this.productData.ProductPlans[i].specData.planOptData.map(opt => {
          if (opt.ID === this.productData.ProductPlans[i].specOptionId) {
            if (this.productData.ProductPlans[i].personCount > opt.quantity - opt.sold) {
              quantityCheck = false;
            }
          }
        });
      }

      if (quantityCheck) {
        let checked = false;
        const cartData = this.payService.getItems('CartItems');
        cartData.map(item => {
          if (item.specOptionId === this.productData.ProductPlans[i].specOptionId &&
            item.extraOptionId === this.productData.ProductPlans[i].extraOptionId) {
            checked = true;
            item.quantity = this.productData.ProductPlans[i].personCount;
            item.total = this.productData.ProductPlans[i].personCount * this.productData.ProductPlans[i].price;
            this.payService.updateCart(cartData);
          }
        });

        const required = [];
        const optional = [];
        this.productData.requiredField.map(rr => {
          this.productData.required_fields.map(req => {
            if (rr === req.ID) {
              required.push(req);
            }
          });
        });
        this.productData.optionalField.map(oo => {
          this.productData.optional_fields.map(opt => {
            if (oo === opt.ID) {
              optional.push(opt);
            }
          });
        });

        if (!checked) {
          const data = {
            tourGroupControlID: 0,
            tourGroupType: '',
            productId: this.productData.ID,
            productImg: this.productData.coverImg,
            productTitle: this.productData.title,
            planId: this.productData.ProductPlans[i].ID,
            planName: this.productData.ProductPlans[i].name,
            specId: this.productData.ProductPlans[i].specData.id,
            specName: this.productData.ProductPlans[i].specData.name,
            specOptionId: this.productData.ProductPlans[i].specOptionId,
            extraId: this.productData.ProductPlans[i].extraData.id,
            extraName: this.productData.ProductPlans[i].extraOptionId !== 0 ? this.productData.ProductPlans[i].extraData.name : '',
            extraOptionId: this.productData.ProductPlans[i].extraOptionId,
            date,
            price: this.productData.ProductPlans[i].price,
            quantity: this.productData.ProductPlans[i].personCount,
            total: this.productData.ProductPlans[i].personCount * this.productData.ProductPlans[i].price,
            fillRequest: this.productData.fillRequest,
            requiredFields: required,
            optionalFields: optional,
            sellingTimeType: this.productData.ProductPlans[i].sellingTimeType,
            sellingTimeBeforeHours: this.productData.ProductPlans[i].sellingTimeBeforeHours,
            productData: {
              product: {
                id: this.productData.ID, title: this.productData.title, coverImg: this.productData.coverImg,
                countryId: this.productData.Country.ID, countryName: this.productData.Country.name,
                regionId: this.productData.Region.ID, regionName: this.productData.Region.name,
                fillRequest: this.productData.fillRequest, productNumber: this.productData.productNumber,
              },
              productCategory: {
                id: this.productData.ProductCategory.ID, name: this.productData.ProductCategory.name
              },
              plan: { id: this.productData.ProductPlans[i].ID, name: this.productData.ProductPlans[i].name },
              planSpecification: {
                id: this.productData.ProductPlans[i].specData.id, name: this.productData.ProductPlans[i].specData.name,
                unit: this.productData.ProductPlans[i].specData.unit,
                splitMethod: this.productData.ProductPlans[i].specData.splitMethod,
                splitValue: this.productData.ProductPlans[i].specData.splitValue,
                fullPayment: this.productData.ProductPlans[i].specData.fullPayment,
                deposit: this.productData.ProductPlans[i].specData.deposit,
                productCosts: this.productData.ProductPlans[i].specData.productCosts
              },
              extraPurchase: {
                id: this.productData.ProductPlans[i].extraData.id, name: this.productData.ProductPlans[i].extraData.name,
                unit: this.productData.ProductPlans[i].extraData.unit,
                splitMethod: this.productData.ProductPlans[i].extraData.splitMethod,
                splitValue: this.productData.ProductPlans[i].extraData.splitValue,
                productCosts: this.productData.ProductPlans[i].extraData.productCosts
              },
              specOption: this.productData.ProductPlans[i].specOptionData,
              extraOption: this.productData.ProductPlans[i].extraOptionData,
              grabToday: this.grabTodayData
            }
          };
          this.payService.addCart(data);
        }
      }
    } else {
      if (this.isLogin) {
        alert('需填寫完會員基本資料才能使用會員功能喔！');
        this.router.navigate(['/member/setting']);
      } else {
        this.login(window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/product/' + this.id);
      }
    }
  }

  placeOrder(i: number) {
    if (this.isMemberDone && this.isLogin) {
      const date = this.productData.ProductPlans[i].optionDate.split('-')[2].length > 1 ? this.productData.ProductPlans[i].optionDate
        : this.productData.ProductPlans[i].optionDate.split('-')[0] + '-' + this.productData.ProductPlans[i].optionDate.split('-')[1]
        + '-0' + this.productData.ProductPlans[i].optionDate.split('-')[2];

      let quantityCheck = true;
      if (this.productData.ProductPlans[i].extraData.id !== 0) {
        this.productData.ProductPlans[i].extraData.planOptData.map(opt => {
          if (opt.ID === this.productData.ProductPlans[i].extraOptionId) {
            if (this.productData.ProductPlans[i].personCount > opt.quantity - opt.sold) {
              quantityCheck = false;
            }
          }
        });
      } else {
        this.productData.ProductPlans[i].specData.planOptData.map(opt => {
          if (opt.ID === this.productData.ProductPlans[i].specOptionId) {
            if (this.productData.ProductPlans[i].personCount > opt.quantity - opt.sold) {
              quantityCheck = false;
            }
          }
        });
      }

      const required = [];
      const optional = [];
      this.productData.requiredField.map(rr => {
        this.productData.required_fields.map(req => {
          if (rr === req.ID) {
            required.push(req);
          }
        });
      });
      this.productData.optionalField.map(oo => {
        this.productData.optional_fields.map(opt => {
          if (oo === opt.ID) {
            optional.push(opt);
          }
        });
      });

      if (quantityCheck) {
        const data = [{
          tourGroupControlID: 0,
          tourGroupType: '',
          productId: this.productData.ID,
          productImg: this.productData.coverImg,
          productTitle: this.productData.title,
          planId: this.productData.ProductPlans[i].ID,
          planName: this.productData.ProductPlans[i].name,
          specId: this.productData.ProductPlans[i].specData.id,
          specName: this.productData.ProductPlans[i].specData.name,
          specOptionId: this.productData.ProductPlans[i].specOptionId,
          extraId: this.productData.ProductPlans[i].extraData.id,
          extraOptionId: this.productData.ProductPlans[i].extraOptionId,
          extraName: this.productData.ProductPlans[i].extraOptionId !== 0 ? this.productData.ProductPlans[i].extraData.name : '',
          date,
          price: this.productData.ProductPlans[i].price,
          quantity: this.productData.ProductPlans[i].personCount,
          total: this.productData.ProductPlans[i].personCount * this.productData.ProductPlans[i].price,
          fillRequest: this.productData.fillRequest,
          requiredFields: required,
          optionalFields: optional,
          sellingTimeType: this.productData.ProductPlans[i].sellingTimeType,
          sellingTimeBeforeHours: this.productData.ProductPlans[i].sellingTimeBeforeHours,
          productData: {
            product: {
              id: this.productData.ID, title: this.productData.title, coverImg: this.productData.coverImg,
              countryId: this.productData.Country.ID, countryName: this.productData.Country.name,
              regionId: this.productData.Region.ID, regionName: this.productData.Region.name,
              fillRequest: this.productData.fillRequest, productNumber: this.productData.productNumber,
            },
            productCategory: {
              id: this.productData.ProductCategory.ID, name: this.productData.ProductCategory.name
            },
            plan: { id: this.productData.ProductPlans[i].ID, name: this.productData.ProductPlans[i].name },
            planSpecification: {
              id: this.productData.ProductPlans[i].specData.id, name: this.productData.ProductPlans[i].specData.name,
              unit: this.productData.ProductPlans[i].specData.unit,
              splitMethod: this.productData.ProductPlans[i].specData.splitMethod,
              splitValue: this.productData.ProductPlans[i].specData.splitValue,
              fullPayment: this.productData.ProductPlans[i].specData.fullPayment,
              deposit: this.productData.ProductPlans[i].specData.deposit,
              productCosts: this.productData.ProductPlans[i].specData.productCosts
            },
            extraPurchase: {
              id: this.productData.ProductPlans[i].extraData.id, name: this.productData.ProductPlans[i].extraData.name,
              unit: this.productData.ProductPlans[i].extraData.unit,
              splitMethod: this.productData.ProductPlans[i].extraData.splitMethod,
              splitValue: this.productData.ProductPlans[i].extraData.splitValue,
              productCosts: this.productData.ProductPlans[i].extraData.productCosts
            },
            specOption: this.productData.ProductPlans[i].specOptionData,
            extraOption: this.productData.ProductPlans[i].extraOptionData,
            tourGroupControlID: 0,
            cabinClass: '',
            grabToday: this.grabTodayData
          }
        }];
        this.payService.placeOrder(data);
        this.payService.orderFromCart('false');

        this.router.navigate(['/pay/info/']);

      }
    } else {
      if (this.isLogin) {
        alert('需填寫完會員基本資料才能使用會員功能喔！');
        this.router.navigate(['/member/setting']);
      } else {
        this.login(window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/product/' + this.id);
      }
    }
  }

  login(headto: string) {
    const config = {
      initialState: {
        headto: headto === 'member' ? window.location.href : headto,
      },
    };
    this.bsModalRef = this.modalService.show(LoginComponent, config);
  }

  loadScript(url) {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);

    // tslint:disable-next-line:only-arrow-functions
    // node.onload = () => {
    //   $(document).ready(() => {
    //     // tslint:disable-next-line:only-arrow-functions
    //     $(() => {
    //       $('.gallery').each(() => { // the containers for all your galleries
    //         $(this).magnificPopup({
    //           delegate: 'a', // the selector for gallery item
    //           type: 'image',
    //           gallery: {
    //             enabled: true
    //           }
    //         });
    //       });
    //     });
    //   });
    // };

  }

  async getOrderDetailsForSpec(specId: number, planIndex: number) {
    let defaultDateSetted = false;
    await this.productService.getOrderDetailsBySpecId(specId).subscribe((v: any) => {
      if (v.result && v.data) {
        this.productData.ProductPlans[planIndex].specData.planOptData.map(opt => {
          opt.sold = 0;
          v.data.map(detail => {
            if (opt.ID === detail.specOptionId) {
              opt.sold += detail.quantity;
              // opt.quantity -= detail.quantity;
            }
          });

          if (opt.quantity - opt.sold >= this.productData.ProductPlans[planIndex].specData.minimumQuantity && !defaultDateSetted) {
            this.productData.ProductPlans[planIndex].specData.defaultDate = new Date(opt.date);
            defaultDateSetted = true;
          }
        });

        if (!defaultDateSetted) {
          this.productData.ProductPlans[planIndex].specData.defaultDate = new Date();
        }
        this.getSpecDaysOfMonth(planIndex);
      }
    });
  }

  getOrderDetailsForExtra(extraId: number, planIndex: number) {
    this.productService.getOrderDetailsByExtraId(extraId).subscribe((v: any) => {
      if (v.result && v.data) {
        this.productData.ProductPlans[planIndex].extraData.planOptData.map(opt => {
          v.data.map(detail => {
            opt.sold = 0;
            if (opt.ID === detail.extraOptionId) {
              opt.sold += detail.quantity;
              // opt.quantity -= detail.quantity;
            }
          });
        });
        this.getSpecDaysOfMonth(planIndex);
      }
    });
  }

  async setPlanSpec(planId: number, specId: number, planIndex: number, specIndex?: number) {
    this.productData.ProductPlans.map(plan => {
      if (plan.ID === planId) {
        plan.specData.id = 0;
        plan.specData.name = '';
        plan.specData.unit = '';
        plan.specData.planOptData = [];

        plan.optionSelected = false;
        plan.specOptionId = 0;
        plan.extraOptionId = 0;
        plan.price = 0;
        plan.optionDate = '';

        if (specId > 0) {
          let defaultDateSetted = false;
          this.productData.ProductPlans[planIndex].PlanSpecification[specIndex].PlanOptions.map(opt => {
            let sellingTime = this.productData.ProductPlans[planIndex].sellingTimeBeforeHours;
            if (sellingTime % 24 === 0) {
              sellingTime += 24;
            }
            const tt = new Date(opt.date + 'T24:00');
            tt.setHours(tt.getHours() - sellingTime);
            const canBuy = this.productData.ProductPlans[planIndex].sellingTimeType === 1 ?
              tt.getTime() > new Date().getTime() : tt.getTime() >= new Date().getTime();

            if (opt.quantity >= this.productData.ProductPlans[planIndex].PlanSpecification[specIndex].minimumQuantity && canBuy &&
              !defaultDateSetted) {
              plan.specData.defaultDate = new Date(opt.date);
              defaultDateSetted = true;
            }
          });
          if (!defaultDateSetted) {
            plan.specData.defaultDate = new Date();
          }
          // this.getOrderDetailsForSpec(specId, planIndex);
        }

        plan.PlanSpecification.map(spec => {
          spec.checked = false;
          if (spec.ID === specId) {
            spec.checked = true;
            // if (spec.checked) {
            //   // extra.checked = false;
            // } else {
            // plan.specData.defaultDate = spec.PlanOptions[0] ? new Date(spec.PlanOptions[0].date) : new Date();
            plan.specData.id = specId;
            plan.specData.name = spec.name;
            plan.specData.unit = spec.unit;
            plan.specData.planOptData = spec.PlanOptions;
            plan.specData.splitMethod = spec.splitMethod;
            plan.specData.splitValue = spec.splitValue;
            plan.specData.fullPayment = spec.fullPayment;
            plan.specData.deposit = spec.deposit;
            plan.specData.productCosts = spec.ProductCosts;
            plan.specData.minimumQuantity = spec.minimumQuantity;
            plan.personCount = spec.minimumQuantity;
            // }
          }
        });
      }
    });
    this.getSpecDaysOfMonth(planIndex);
  }

  setPlanExtra(planId: number, extraId: number, planIndex: number) {
    this.productData.ProductPlans.map(plan => {
      if (plan.ID === planId) {
        plan.extraData.id = 0;
        plan.extraData.name = '';
        plan.extraData.unit = '';
        plan.extraData.planOptData = [];

        plan.optionSelected = false;
        plan.specOptionId = 0;
        plan.extraOptionId = 0;
        plan.price = 0;
        plan.optionDate = '';

        if (extraId > 0) {
          this.getOrderDetailsForExtra(extraId, planIndex);
        }

        plan.ExtraPurchase.map(extra => {
          if (extra.ID === extraId) {
            if (extra.checked) {
              // extra.checked = false;
            } else {
              // extra.checked = true;
              plan.extraData.id = extraId;
              plan.extraData.name = extra.name;
              plan.extraData.unit = extra.unit;
              plan.extraData.planOptData = extra.PlanOptions;
              plan.extraData.splitMethod = extra.splitMethod;
              plan.extraData.splitValue = extra.splitValue;
              plan.extraData.productCosts = extra.ProductCosts;
            }
          } else {
            extra.checked = false;
          }
        });
      }
    });
    this.getSpecDaysOfMonth(planIndex);
  }

  setPlanOption(index: number, specOptionId: number, price: number, date: string, extraOptionId: number = 0) {
    if (this.productData.ProductPlans[index].optionSelected) {
      if (this.productData.ProductPlans[index].specOptionId === specOptionId) {
        this.productData.ProductPlans[index].optionSelected = false;
        this.productData.ProductPlans[index].specOptionId = 0;
        this.productData.ProductPlans[index].extraOptionId = 0;
        this.productData.ProductPlans[index].price = 0;
        this.productData.ProductPlans[index].optionDate = '';
        this.productData.ProductPlans[index].specOptionData = { id: 0, date: '', price: 0, quantity: 0, sold: 0 };
        this.productData.ProductPlans[index].extraOptionData = { id: 0, date: '', price: 0, quantity: 0, sold: 0 };
      } else {
        this.productData.ProductPlans[index].specOptionId = specOptionId;
        this.productData.ProductPlans[index].extraOptionId = extraOptionId;
        this.productData.ProductPlans[index].price = price;

        this.productData.ProductPlans[index].specData.planOptData.map(option => {
          if (option.ID === specOptionId) {
            this.productData.ProductPlans[index].specOptionData = {
              id: option.ID, date: option.date, price: option.price,
              quantity: option.quantity, sold: option.sold
            };
          }
        });
        if (extraOptionId > 0) {
          this.productData.ProductPlans[index].extraData.planOptData.map(option => {
            if (option.ID === extraOptionId) {
              this.productData.ProductPlans[index].extraOptionData = {
                id: option.ID, date: option.date,
                price: option.price, quantity: option.quantity, sold: option.sold
              };
            }
          });
        }
      }
    } else {
      this.productData.ProductPlans[index].optionSelected = true;
      this.productData.ProductPlans[index].specOptionId = specOptionId;
      this.productData.ProductPlans[index].extraOptionId = extraOptionId;
      this.productData.ProductPlans[index].price = price;

      this.productData.ProductPlans[index].specData.planOptData.map(option => {
        if (option.ID === specOptionId) {
          this.productData.ProductPlans[index].specOptionData = {
            id: option.ID, date: option.date, price: option.price,
            quantity: option.quantity, sold: option.sold
          };
        }
      });
      if (extraOptionId > 0) {
        this.productData.ProductPlans[index].extraData.planOptData.map(option => {
          if (option.ID === extraOptionId) {
            this.productData.ProductPlans[index].extraOptionData = {
              id: option.ID, date: option.date,
              price: option.price, quantity: option.quantity, sold: option.sold
            };
          }
        });
      } else {
        this.productData.ProductPlans[index].extraOptionData = { id: 0, date: '', price: 0, quantity: 0, sold: 0 };
      }
    }
    this.getSpecDaysOfMonth(index);
  }


  getSpecDaysOfMonth(index: number) {
    let sellingTime = this.productData.ProductPlans[index].sellingTimeBeforeHours;
    if (sellingTime % 24 === 0) {
      sellingTime += 24;
    }
    this.productData.ProductPlans[index].specData.daysInThisMonth = new Array();
    this.productData.ProductPlans[index].specData.daysInLastMonth = new Array();
    this.productData.ProductPlans[index].specData.daysInNextMonth = new Array();

    this.productData.ProductPlans[index].specData.currentMonth =
      this.monthNames[this.productData.ProductPlans[index].specData.defaultDate.getMonth()];
    this.productData.ProductPlans[index].specData.currentYear =
      this.productData.ProductPlans[index].specData.defaultDate.getFullYear();
    this.productData.ProductPlans[index].specData.currentMonthNum =
      this.monthNumbers[this.productData.ProductPlans[index].specData.defaultDate.getMonth()];

    // this.productData.ProductPlans[index].specData.currentMonth =
    //   this.monthNames[this.productData.ProductPlans[index].specData.date.getMonth()];
    // this.productData.ProductPlans[index].specData.currentYear =
    //   this.productData.ProductPlans[index].specData.date.getFullYear();
    // this.productData.ProductPlans[index].specData.currentMonthNum =
    //   this.monthNumbers[this.productData.ProductPlans[index].specData.date.getMonth()];


    if (this.productData.ProductPlans[index].specData.defaultDate.getMonth() === new Date().getMonth()) {
      this.productData.ProductPlans[index].specData.currentDate = new Date().getDate();
    } else {
      if (this.productData.ProductPlans[index].specData.defaultDate.getTime() < new Date().getTime()) {
        this.productData.ProductPlans[index].specData.currentDate = 888;
      } else {
        this.productData.ProductPlans[index].specData.currentDate = 999;
      }
    }

    const firstDayThisMonth = new Date(this.productData.ProductPlans[index].specData.defaultDate.getFullYear(),
      this.productData.ProductPlans[index].specData.defaultDate.getMonth(), 0).getDay();
    const prevNumOfDays = new Date(this.productData.ProductPlans[index].specData.defaultDate.getFullYear(),
      this.productData.ProductPlans[index].specData.defaultDate.getMonth(), 0).getDate();
    let thisMonthDays = 0;
    let lastMonthDays = 0;
    let nextMonthDays = 0;

    for (let i = prevNumOfDays - (firstDayThisMonth - 1); i <= prevNumOfDays; i++) {
      this.productData.ProductPlans[index].specData.daysInLastMonth.
        push({ id: 0, day: i, quantity: 0, price: 0, today: false, selected: false });
      lastMonthDays = this.productData.ProductPlans[index].specData.daysInLastMonth.length;
    }
    const thisNumOfDays = new Date(this.productData.ProductPlans[index].specData.defaultDate.getFullYear(),
      this.productData.ProductPlans[index].specData.defaultDate.getMonth() + 1, 0).getDate();

    for (let i = 0; i < thisNumOfDays; i++) {
      let today = false;
      i === this.productData.ProductPlans[index].specData.currentDate - 1 ? today = true : today = false;

      this.productData.ProductPlans[index].specData.daysInThisMonth.
        push({ id: 0, day: i + 1, quantity: 0, price: 0, today, selected: false });
      thisMonthDays = this.productData.ProductPlans[index].specData.daysInThisMonth.length;

      if (this.productData.ProductPlans[index].extraData.id !== 0) {
        this.productData.ProductPlans[index].extraData.planOptData.map(opt => {
          if (opt.date.split('-')[0] === this.productData.ProductPlans[index].specData.defaultDate.getFullYear().toString() &&
            opt.date.split('-')[1] === this.productData.ProductPlans[index].specData.currentMonthNum) {

            const formatedDay = this.productData.ProductPlans[index].specData.daysInThisMonth[i].day > 9 ?
              this.productData.ProductPlans[index].specData.daysInThisMonth[i].day.toString() :
              '0' + this.productData.ProductPlans[index].specData.daysInThisMonth[i].day.toString();

            if (formatedDay === opt.date.split('-')[2]) {
              const sold = opt.sold ? opt.sold : 0;
              this.productData.ProductPlans[index].specData.daysInThisMonth[i].extraQuantity = opt.quantity - sold;
              this.productData.ProductPlans[index].specData.daysInThisMonth[i].extraPrice = opt.price;
              this.productData.ProductPlans[index].specData.daysInThisMonth[i].extraOptionId = opt.ID;
            }
          }
        });
      }

      this.productData.ProductPlans[index].specData.planOptData.map(opt => {
        if (opt.date.split('-')[0] === this.productData.ProductPlans[index].specData.defaultDate.getFullYear().toString() &&
          opt.date.split('-')[1] === this.productData.ProductPlans[index].specData.currentMonthNum) {

          const formatedDay = this.productData.ProductPlans[index].specData.daysInThisMonth[i].day > 9 ?
            this.productData.ProductPlans[index].specData.daysInThisMonth[i].day.toString() :
            '0' + this.productData.ProductPlans[index].specData.daysInThisMonth[i].day.toString();

          if (formatedDay === opt.date.split('-')[2]) {
            this.productData.ProductPlans[index].specData.daysInThisMonth[i].id = opt.ID;
            const tt = new Date(opt.date + 'T24:00');
            tt.setHours(tt.getHours() - sellingTime);

            this.productData.ProductPlans[index].specData.daysInThisMonth[i].canBuy =
              this.productData.ProductPlans[index].sellingTimeType === 1 ?
                tt.getTime() > new Date().getTime() : tt.getTime() >= new Date().getTime();

            if (this.productData.ProductPlans[index].extraData.id !== 0) {
              if (this.productData.ProductPlans[index].specData.daysInThisMonth[i].extraQuantity > 0) {
                this.productData.ProductPlans[index].specData.daysInThisMonth[i].quantity =
                  opt.quantity - opt.sold > this.productData.ProductPlans[index].specData.daysInThisMonth[i].extraQuantity ?
                    this.productData.ProductPlans[index].specData.daysInThisMonth[i].extraQuantity : opt.quantity - opt.sold;
                this.productData.ProductPlans[index].specData.daysInThisMonth[i].price =
                  this.productData.ProductPlans[index].specData.daysInThisMonth[i].extraPrice + opt.price;
              }
            } else {
              this.productData.ProductPlans[index].specData.daysInThisMonth[i].quantity = opt.quantity - opt.sold;
              this.productData.ProductPlans[index].specData.daysInThisMonth[i].price = opt.price;
            }
            if (this.productData.ProductPlans[index].specOptionId === opt.ID) {
              this.productData.ProductPlans[index].specData.daysInThisMonth[i].selected = true;
              this.productData.ProductPlans[index].optionDate = opt.date;

              // const dayNames2 = ['日', '一', '二', '三', '四', '五', '六'];
              // this.formattedSelectedDate = opt.date.split('-')[0] + '/' + opt.date.split('-')[1] + '/' +
              //   opt.date.split('-')[2] + '(' + dayNames2[new Date(opt.date).getDay()] + ')';
              this.remainQuantity = this.productData.ProductPlans[index].specData.daysInThisMonth[i].quantity;
            }
          }
        }
      });
    }

    const lastDayThisMonth = new Date(this.productData.ProductPlans[index].specData.defaultDate.getFullYear(),
      this.productData.ProductPlans[index].specData.defaultDate.getMonth() + 1, 0).getDay();
    const nextNumOfDays = new Date(this.productData.ProductPlans[index].specData.defaultDate.getFullYear(),
      this.productData.ProductPlans[index].specData.defaultDate.getMonth() + 2, 0).getDate();

    for (let i = 0; i < (7 - lastDayThisMonth); i++) {
      this.productData.ProductPlans[index].specData.daysInNextMonth.
        push({ id: 0, day: i + 1, quantity: 0, price: 0, today: false, selected: false });
      nextMonthDays = this.productData.ProductPlans[index].specData.daysInNextMonth.length;
    }

    const totalDays = lastMonthDays + thisMonthDays + nextMonthDays;
    if (totalDays < 36) {
      for (let i = (7 - lastDayThisMonth); i < ((7 - lastDayThisMonth) + 7); i++) {
        this.productData.ProductPlans[index].specData.daysInNextMonth.
          push({ id: 0, day: i, quantity: 0, price: 0, today: false, selected: false });
      }
    }

  }

  goToLastMonth(index: number) {
    this.productData.ProductPlans[index].specData.defaultDate =
      new Date(this.productData.ProductPlans[index].specData.defaultDate.getFullYear(),
        this.productData.ProductPlans[index].specData.defaultDate.getMonth(), 0);

    this.productData.ProductPlans[index].specData.date =
      new Date(this.productData.ProductPlans[index].specData.date.getFullYear(),
        this.productData.ProductPlans[index].specData.date.getMonth(), 0);
    this.getSpecDaysOfMonth(index);
  }

  goToNextMonth(index: number) {
    this.productData.ProductPlans[index].specData.defaultDate =
      new Date(this.productData.ProductPlans[index].specData.defaultDate.getFullYear(),
        this.productData.ProductPlans[index].specData.defaultDate.getMonth() + 2, 0);

    this.productData.ProductPlans[index].specData.date =
      new Date(this.productData.ProductPlans[index].specData.date.getFullYear(),
        this.productData.ProductPlans[index].specData.date.getMonth() + 2, 0);
    this.getSpecDaysOfMonth(index);
  }

}

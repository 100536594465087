<div class="sidebar-left">
  <div class="scroll-container">
    <ul class="sidebar-left-main list-unstyled">
      <li>
        <a [routerLink]="['/productCategory']">{{exploreWorldTitle | googleTranslate | async}}</a>
      </li>
      <li>
        <a [routerLink]="['/hot']">{{"熱門行程" | translate}}</a>
      </li>
      <li *ngIf="hasSale">
        <a routerLink="/" fragment="grabToday" (click)="goGrabToday()">{{"今日必搶" | translate}}</a>
      </li>
      <li>
        <a [routerLink]="['/featured']">{{"精選主題" | translate}}</a>
      </li>
      <li>
        <a [routerLink]="['/video']">{{"影音介紹" | translate}}</a>
      </li>
      <li>
        <a [routerLink]="['/recentView']">{{"最近瀏覽" | translate}}</a>
      </li>
    </ul>

    <div class="sidebar-search-btn">
      <a (click)="showSearch()" style="color: #597e9d; cursor:pointer;">
        {{"行程搜尋" | translate}}
        <svg viewBox="0 0 24 24" class="sidebar-icon-search">
          <path fill-rule="evenodd"
            d="M11 3.3a7.7 7.7 0 0 1 5.813 12.75l3.295 3.406a.7.7 0 0 1-.92 1.049l-.086-.075-3.3-3.41A7.7 7.7 0 1 1 11 3.3zm0 1.4a6.3 6.3 0 1 0 0 12.6 6.3 6.3 0 0 0 0-12.6z">
          </path>
        </svg>
      </a>
    </div>

    <ul class="sidebar-elements sidebar-social list-unstyled" *ngIf="socialLinks">
      <li>
        <a [href]="socialLinks.line" target="_blank">
          <img src="assets/images/sidebar-icon-line.svg">
          {{"加入好友" | translate}}
        </a>
      </li>
      <li>
        <a [href]="socialLinks.facebook" target="_blank">
          <img src="assets/images/sidebar-icon-fb.svg">
          {{"追蹤粉絲團" | translate}}
        </a>
      </li>
      <li>
        <a [href]="socialLinks.instagram" target="_blank">
          <img src="assets/images/sidebar-icon-ig.svg">
          {{"追蹤IG" | translate}}
        </a>
      </li>
      <li>
        <a [href]="socialLinks.youtube" target="_blank">
          <img src="assets/images/sidebar-icon-yt.svg">
          {{"旅遊影音" | translate}}
        </a>
      </li>
      <li>
        <a [href]="socialLinks.blog" target="_blank">
          <img src="assets/images/sidebar-icon-blogger.svg" alt="">
          {{"旅遊大小事" | translate}}
        </a>
      </li>
    </ul>
    <div class="divider"></div>
    <ul class="list-unstyled sidebar-link pl-4">
      <li class="py-2 pt-4">
        <a [routerLink]="['/about']">{{"關於我們" | translate}}</a>
      </li>
      <li class="py-2">
        <a [routerLink]="['/contact']">{{"聯絡我們" | translate}}</a>
      </li>
      <li class="py-2">
        <a [routerLink]="['/faq']">{{"常見問題" | translate}}</a>
      </li>
    </ul>
  </div>
</div>

import { DomSanitizer } from '@angular/platform-browser';
import { FaqService } from './faq.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  faqData: any;
  category: any[] = [];

  constructor(private faqService: FaqService, public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.faqService.getQuestions()
      .subscribe((v: any) => {
        if (v.result) {
          this.faqData = v.data;
          for (let i = 0; i < v.data.length; i++) {
            if (i < 1) {
              this.category.push({ id: v.data[i].categoryID, name: v.data[i].name });
            } else {
              if (v.data[i].categoryID !== v.data[i - 1].categoryID) {
                this.category.push({ id: v.data[i].categoryID, name: v.data[i].name });
              }
            }
          }
        }
      },
        (e) => {
          console.log(e);
        },
      );
  }

}

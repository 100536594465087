<div class="container-fluid content-wrapper">
  <h4 class="mt-5">{{'以下是與' | googleTranslate:true | async}}「{{filter}}」{{'相關的行程' | googleTranslate:true | async}}</h4>

  <h5 class="my-4">{{'共' | googleTranslate | async}} {{itemLength}} {{'個行程' | translate}}</h5>

  <div class="row no-gutters">
    <div class="col-xl-4 col-sm-6 my-3" *ngFor="let item of pageProducts; let i = index;">

      <div class="product-card">
        <div class="product-sale" *ngIf="item.isSale"></div>
        <div class="product-card-image" style="display: block; background-image: url(/api/images/{{item.coverImg}});">
          <a [routerLink]="['/product', item.ID]" class="link-spanner">
          </a>
        </div>
        <div class="product-card-body">
          <div class="product-title text-truncate">
            <a [routerLink]="['/product', item.ID]">{{item.title | googleTranslate | async}}</a>
          </div>
          <div class="product-des">{{item.Country.name | googleTranslate | async}} {{item.Region.name | googleTranslate
            | async}}</div>
          <div class="d-flex justify-content-between" [ngClass]="{ 'flex-column': item.isSale }">
            <div class="product-des">{{'觀看次數' | translate}}：{{item.viewCount}}{{"次" | translate}}
            </div>
            <div class="product-price" *ngIf="!item.isSale">
              <span [innerHTML]="item.lowestPrice | currencyExchange:true:false | async"></span>
              <small>{{"起" | translate}}</small>
            </div>
            <div class="product-price text-sale align-self-end" *ngIf="item.isSale">
              <small class="text-gray-m ml-auto pr-2 align-self-center">
                <s>
                  <span [innerHTML]="item.lowestPrice | currencyExchange:true:false | async"></span>
                  <small>{{"起" | translate}}</small>
                </s>
              </small>
              <span [innerHTML]="item.lowestPrice - item.deduct | currencyExchange:true:false | async"></span>
              <small>{{"起" | translate}}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-5">
    <pagination [totalItems]="itemLength" [maxSize]="itemsPerPage" previousText="&lsaquo;" nextText="&rsaquo;"
      [itemsPerPage]="itemsPerPage" firstText="&laquo;" lastText="&raquo;" [boundaryLinks]="true"
      (numPages)="numPages = $event" (pageChanged)="pageIndex = $event.page-1;getPageProducts(pageIndex)"></pagination>
  </div>

</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface GetLoginURLReponse {
  result: boolean;
  url: string;
}

export interface UserInfo {
  user_id: string;
  name: string;
  picture: string;
  email: string;
}

export interface GetUserInfoReponse {
  result: boolean;
  user_info: UserInfo;
}

export interface IsLoginResponse extends GetUserInfoReponse {
  message?: string;
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public userInfo: UserInfo;

  constructor(private http: HttpClient) { }

  line() {
    return this.http.get<GetLoginURLReponse>('/api/auth/line/getLoginURL');
  }

  getLINEUserInfo() {
    return this.http.get<GetUserInfoReponse>('/api/auth/line/getUserInfo');
  }

  facebook(returnURL: string) {
    return this.http.get<GetLoginURLReponse>('/api/auth/facebook/getLoginURL?returnURL=' + returnURL);
  }

  getFacebookUserInfo() {
    return this.http.get<GetUserInfoReponse>('/api/auth/facebook/getUserInfo');
  }

  google(returnURL: string) {
    return this.http.get<GetLoginURLReponse>('/api/auth/google/getLoginURL?returnURL=' + returnURL);
  }

  isLogin() {
    return this.http.get<IsLoginResponse>('/api/site/isLogin');
  }

  register(data: any) {
    return this.http.post<any>('/api/site/register', data);
  }

  accountLogin(data: any, returnURL: string) {
    return this.http.post<any>('/api/site/accountLogin?returnURL=' + returnURL, data);
  }

  resetPswd(data: any) {
    return this.http.post<any>('/api/site/resetPswd', data);
  }

  logout() {
    location.href = '/api/site/logout';
  }
}

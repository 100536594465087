import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HotService {

  constructor(private http: HttpClient) { }

  getPopulars() {
    return this.http.get('/api/site/populars');
  }


}

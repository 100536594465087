import { LayoutService } from './../layout.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  companyInfo = '';
  features = [];

  constructor(private layoutService: LayoutService) { }

  ngOnInit(): void {
    this.layoutService.getSystemSetting().subscribe((v: any) => {
      if (v.result && v.data) {
        this.features = v.data.feature;
        this.layoutService.translateHTML(v.data.companyInfo).subscribe((res: any) => {
          this.companyInfo = res;
        });
      }
    });
  }

}

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AngularMaterialModule } from './shared/styles/angular-material.module';
import { SearchComponent } from './shared/search/search.component';
import { FaqComponent } from './modules/faq/faq.component';
import { LayoutComponent } from './modules/layout/layout.component';
import { NavComponent } from './modules/layout/nav/nav.component';
import { ProductInfoComponent } from './modules/product/product-info/product-info.component';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './modules/layout/footer/footer.component';
import { SidebarComponent } from './modules/layout/sidebar/sidebar.component';
import { ProductCategoryComponent } from './modules/product/product-category/product-category.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AlertModule } from 'ngx-bootstrap/alert';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxInputStarRatingModule } from 'ngx-input-star-rating';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CartComponent } from './modules/pay/cart/cart.component';
import { PayLayoutComponent } from './modules/pay/pay-layout/pay-layout.component';
import { AngularWebStorageModule } from 'angular-web-storage';
import { ConfirmDialogComponent } from './shared/dialogs/confirm-dialog/confirm-dialog.component';
import { PayInfoComponent } from './modules/pay/pay-info/pay-info.component';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AlertDialogComponent } from './shared/dialogs/alert-dialog/alert-dialog.component';
import { ToastrModule } from 'ngx-toastr';
import { LoginComponent } from './shared/login/login.component';
import { MemberSettingComponent } from './modules/member/member-setting/member-setting.component';
import { MemberOrderComponent } from './modules/member/member-order/member-order.component';
import { MemberCouponComponent } from './modules/member/member-coupon/member-coupon.component';
import { MemberLayoutComponent } from './modules/member/member-layout/member-layout.component';
import { MemberSavedComponent } from './modules/member/member-saved/member-saved.component';
import { MemberOrderInfoComponent } from './modules/member/member-order-info/member-order-info.component';
import { FeaturedComponent } from './modules/featured/featured.component';
import { IndexComponent } from './modules/index/index.component';
import { VideoComponent } from './modules/video/video.component';
import { ContactComponent } from './modules/contact/contact.component';
import { HotComponent } from './modules/hot/hot.component';
import { ConfirmComponent } from './modules/pay/confirm/confirm.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { OthersViewedComponent } from './modules/others-viewed/others-viewed.component';
import { FeaturedDetailComponent } from './modules/featured/featured-detail/featured-detail.component';
import { CurrencyExchangePipe } from './shared/pipes/currency-exchange.pipe';
import { EditorPagesComponent } from './modules/editor-pages/editor-pages.component';
import { RecentViewComponent } from './modules/recent-view/recent-view.component';
import { ProductSearchComponent } from './modules/product/product-search/product-search.component';
import { CountdownModule } from '@ciri/ngx-countdown';
import { SafeUrlPipe } from './shared/pipes/safe-url.pipe';
import { NgxScrollspyModule } from '@uniprank/ngx-scrollspy';
import { NgxMarqueeModule } from 'ngx-marquee';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { GroupBuyInitiatorComponent } from './modules/group-buy/group-buy-initiator/group-buy-initiator.component';
import { GroupBuyInfoComponent } from './modules/group-buy/group-buy-info/group-buy-info.component';
import { GroupBuyOrderInfoComponent } from './modules/group-buy/group-buy-order-info/group-buy-order-info.component';
import { InitiatorDashboardComponent } from './modules/group-buy/initiator-dashboard/initiator-dashboard.component';
import { GoogleTranslatePipe } from './shared/pipes/google-translate.pipe';
import { CurrencyTransformPipe } from './shared/pipes/currency-transform.pipe';
import { GroupTourProductComponent } from './modules/group-tour/group-tour-product/group-tour-product.component';
import { GroupTourProductSelectComponent } from './modules/group-tour/group-tour-product-select/group-tour-product-select.component';
import { MemberGroupOrderComponent } from './modules/member/member-group-order/member-group-order.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    FooterComponent,
    SidebarComponent,
    NavComponent,
    ProductCategoryComponent,
    ProductInfoComponent,
    CartComponent,
    PayLayoutComponent,
    ConfirmDialogComponent,
    PayInfoComponent,
    AlertDialogComponent,
    LoginComponent,
    MemberSettingComponent,
    MemberOrderComponent,
    MemberGroupOrderComponent,
    MemberCouponComponent,
    MemberLayoutComponent,
    MemberSavedComponent,
    MemberOrderInfoComponent,
    FaqComponent,
    FeaturedComponent,
    IndexComponent,
    VideoComponent,
    ContactComponent,
    HotComponent,
    ConfirmComponent,
    OthersViewedComponent,
    FeaturedDetailComponent,
    CurrencyExchangePipe,
    EditorPagesComponent,
    RecentViewComponent,
    ProductSearchComponent,
    SafeUrlPipe,
    SearchComponent,
    GroupBuyInitiatorComponent,
    GroupBuyInfoComponent,
    GroupBuyOrderInfoComponent,
    InitiatorDashboardComponent,
    GroupTourProductComponent,
    GroupTourProductSelectComponent,
    GoogleTranslatePipe,
    CurrencyTransformPipe,
  ],
  imports: [
    // BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AlertModule.forRoot(),
    AppRoutingModule,
    NgxInputStarRatingModule,
    AngularWebStorageModule,
    NgxPageScrollCoreModule.forRoot({ /* custom settings here */ }),
    NgxPageScrollModule,
    PaginationModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    NgxScrollspyModule.forRoot(),
    CountdownModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    NgxCaptchaModule,
    AngularMaterialModule,
    NgxMarqueeModule,
    PdfViewerModule
  ],
  entryComponents: [
    LoginComponent,
    SearchComponent,
    ConfirmDialogComponent,
    AlertDialogComponent
  ],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div class="theme-banner">
  <div *ngIf="featuredData">
    <div class="banner-static" style="background-image: url(/api/images/{{featuredData.banner}});"></div>
    <div class="title">{{featuredData.title | googleTranslate | async}}</div>
  </div>
  <div *ngIf="categoryData">
    <div class="banner-static" style="background-image: url(/api/images/{{categoryData.Tag.image}});"></div>
    <div class="title">{{categoryData.text | googleTranslate | async}}</div>
  </div>
</div>

<div class="container-fluid content-wrapper">
  <div class="row product-category-selection">
    <div class="col-12">
      <div class="card border-0 shadow-sm">
        <div class="card-body">
          <ul class="nav product-tab tab-scrollable has-arrow mx-n3">
            <li class="nav-item">
              <a class="nav-link active" (click)="regionSelected = 0; tagSelected = 0; getTags(0); getProducts()"
                [ngClass]="{'active': regionSelected===0}">{{"全部" | translate}}</a>
            </li>
            <li class="nav-item" *ngFor="let region of regionData">
              <a class="nav-link"
                (click)="regionSelected = region.ID; tagSelected = 0; getTags(region.ID); getProducts()"
                [ngClass]="{'active': regionSelected==region.ID}">{{region.name | googleTranslate | async}}</a>
            </li>
          </ul>

          <div class="selection-wrapper scroll-x-container" style="height: 32;">
            <a class="btn btn-outline-tag btn-sm btn-pill mr-1" *ngFor="let tag of tagData"
              (click)="tagSelected = tag.ID; getProducts()" [ngClass]="{'active text-white': tagSelected==tag.ID}">
              {{tag.name | googleTranslate | async}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section class="section-wrapper">
    <div class="section-title bold">
      <div>{{"相關行程" | translate}}</div>
    </div>
    <div class="row no-gutters" *ngIf="products">
      <div class="col-xl-3 col-sm-6 mb-3" *ngFor="let product of products">
        <div class="product-card">
          <div class="product-sale" *ngIf="product.isSale"></div>
          <div class="product-card-image"
            style="display: block; background-image: url(/api/images/{{product.coverImg}});">
            <a [routerLink]="['/product', product.ID]" class="link-spanner"></a>
          </div>
          <div class="product-card-body">
            <div class="product-title text-truncate">
              <a [routerLink]="['/product', product.ID]">{{product.title | googleTranslate | async}}</a>
            </div>
            <div class="product-des">{{product.Country.name | googleTranslate | async}} {{product.Region.name |
              googleTranslate | async}}</div>
            <div class="d-flex justify-content-between" [ngClass]="{ 'flex-column': product.isSale }">
              <div class="product-des">{{"觀看次數" | translate}}：{{product.viewCount}}{{"次" | translate}}
              </div>
              <div class="product-price" *ngIf="!product.isSale">
                <span [innerHTML]="product.lowestPrice | currencyExchange:true:false | async"></span>
                <small>{{"起" | translate}}</small>
              </div>
              <div class="product-price text-sale align-self-end" *ngIf="product.isSale">
                <small class="text-gray-m ml-auto pr-2 align-self-center">
                  <s>
                    <span [innerHTML]="product.lowestPrice | currencyExchange:true:false | async"></span>
                    <small>{{"起" | translate}}</small>
                  </s>
                </small>
                <span [innerHTML]="product.lowestPrice - product.deduct | currencyExchange:true:false | async"></span>
                <small>{{"起" | translate}}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5 pagination d-flex justify-content-center">
      <pagination [totalItems]="itemLength" [maxSize]="5" previousText="&lsaquo;" nextText="&rsaquo;"
        [itemsPerPage]="12" firstText="&laquo;" lastText="&raquo;" [boundaryLinks]="true" (numPages)="numPages = $event"
        (pageChanged)="pageIndex = $event.page-1;getProducts()"></pagination>
    </div>
  </section>

  <!-- 其他人也看過 -->
  <app-others-viewed></app-others-viewed>

</div>

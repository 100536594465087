import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../../product/product.service';
import { GroupTourService } from '../group-tour.service';
import { MemberService } from '../../member/member.service';
import { LayoutService } from '../../layout/layout.service';
import { LoginService } from 'src/app/core/services/login.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PayService } from '../../pay/pay.service';
import { AlertDialogComponent } from 'src/app/shared/dialogs/alert-dialog/alert-dialog.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-group-tour-product-select',
  templateUrl: './group-tour-product-select.component.html',
  styleUrls: ['./group-tour-product-select.component.scss']
})
export class GroupTourProductSelectComponent implements OnInit {
  isLogin = false;
  bsModalRef: BsModalRef;
  id: number;
  optionId: number;
  groupControl: any;
  endDate: any;
  goFlight: any;
  backFlight: any;
  typePrices = [];
  headCount = [];
  amount = 0;
  headcountDetail = [];
  totalRoomQuantity = 0;
  totalQuantity = 0;
  form: FormGroup;
  // roomTypes = [];
  originalCabinClassRemain = { economy: 0, business: 0, first: 0, common: 0, waitlistSize: 0 };
  cabinClassRemain = { economy: 0, business: 0, first: 0, common: 0, waitlistSize: 0 };
  applicant: any;
  isAgreed = false;
  canBuy = true;
  isPolicyExpand = false;
  isContractExpand = false;
  pdfSrc = '../assets/國內旅遊合約書.pdf';

  constructor(
    private route: ActivatedRoute,
    // private scrollSpyService: ScrollSpyService,
    private router: Router,
    // public sanitizer: DomSanitizer,
    private productService: ProductService,
    public groupTourService: GroupTourService,
    private payService: PayService,
    private memberService: MemberService,
    // public locations: Location,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private layoutService: LayoutService,
    public loginService: LoginService
  ) {
    // this.id = this.route.snapshot.params.id;
    this.optionId = this.route.snapshot.params.optionId;
  }

  async ngOnInit() {
    if (this.loginService.userInfo === undefined) {
      try {
        const resp = await this.loginService.isLogin().toPromise();
        this.isLogin = resp.result;
        if (resp.result) {
          this.loginService.userInfo = resp.user_info;
        }
      } catch (err) {
        this.isLogin = false;
        // console.log(err);
      }
    } else {
      this.isLogin = true;

    }

    if (!this.isLogin) {
      alert('需填寫完會員基本資料才能使用會員功能喔！');
      this.router.navigate(['/member/setting']);
    } else {
      this.getTourGroupControlByPlanOptionID();
    }
  }

  getTourGroupControlByPlanOptionID() {
    this.groupTourService.getTourGroupControlByPlanOptionId(this.optionId).subscribe((v: any) => {
      if (v.result) {
        console.log('==v== :>> ', v);
        this.groupControl = v.data;

        this.layoutService.translateHTML(this.groupControl.Product.policy).subscribe((res: any) => {
          this.groupControl.Product.policy = res;
        });

        if (this.groupControl.PlanOption.ProductPlan.sellingTimeBeforeHours % 24 === 0) {
          this.groupControl.PlanOption.ProductPlan.sellingTimeBeforeHours += 24;
        }
        const tt = new Date(this.groupControl.startDate + 'T24:00');
        tt.setHours(tt.getHours() - this.groupControl.PlanOption.ProductPlan.sellingTimeBeforeHours);
        this.canBuy = this.groupControl.PlanOption.ProductPlan.sellingTimeType === 1 ?
          tt.getTime() > new Date().getTime() : tt.getTime() >= new Date().getTime();
        if (!this.canBuy) {
          this.showAlert('此產品已無法報名，請回產品頁重新選擇日期，謝謝～', '系統提示');
        }

        this.endDate = new Date(this.groupControl.PlanOption.date);
        this.endDate.setDate(this.endDate.getDate() + (this.groupControl.defaultInfo.days - 1));

        if (this.groupControl.defaultInfo.economyClassCount > 0) {
          this.cabinClassRemain.economy = this.groupControl.defaultInfo.economyClassCount;
        }
        if (this.groupControl.defaultInfo.businessClassCount > 0) {
          this.cabinClassRemain.business = this.groupControl.defaultInfo.businessClassCount;
        }
        if (this.groupControl.defaultInfo.firstClassCount > 0) {
          this.cabinClassRemain.first = this.groupControl.defaultInfo.firstClassCount;
        }
        this.cabinClassRemain.common = this.groupControl.defaultInfo.bookingSize;
        this.cabinClassRemain.waitlistSize = this.groupControl.defaultInfo.waitlistSize;

        this.typePrices = this.generateTypePrices(this.groupControl.defaultPrices, this.groupControl.defaultInfo.roomTypes);

        console.log('this.typePrices :>> ', this.typePrices);

        if (this.groupControl.flightInfo.length > 0) {
          this.groupControl.flightInfo.map(flight => {
            if (flight.type === '出發') {
              this.groupTourService.getFlightById(flight.flightID).subscribe((v2: any) => {
                if (v2.result && v2.data) {
                  this.goFlight = v2.data;
                  this.goFlight.Flight = flight;
                  const { timeDiff, addOneDay } = this.countTimeDifference(flight.departureTime, flight.arrivalTime);
                  this.goFlight.timeDifference = timeDiff;
                  this.goFlight.arrivalDate = addOneDay ?
                    this.addOneDate(this.groupControl.PlanOption.date) : this.groupControl.PlanOption.date;

                  this.groupTourService.getAirlineById(flight.airlineID).subscribe((v3: any) => {
                    if (v3.result) {
                      this.goFlight.airlineName = v3.data.name;
                    }
                  },
                    (e) => { console.log(e); },
                  );
                }
              });

            }

            if (flight.type === '回程') {
              this.groupTourService.getFlightById(flight.flightID).subscribe((v2: any) => {
                if (v2.result && v2.data) {
                  this.backFlight = v2.data;
                  this.backFlight.Flight = flight;
                  // this.backFlight.timeDifference = this.countTimeDifference(flight.departureTime, flight.arrivalTime);
                  const { timeDiff, addOneDay } = this.countTimeDifference(flight.departureTime, flight.arrivalTime);
                  this.backFlight.timeDifference = timeDiff;
                  this.backFlight.departureDate = addOneDay ? this.minusOneDate(this.endDate) : this.endDate;

                  this.groupTourService.getAirlineById(flight.airlineID).subscribe((v3: any) => {
                    if (v3.result) {
                      this.backFlight.airlineName = v3.data.name;
                    }
                  },
                    (e) => { console.log(e); },
                  );
                }
              });
            }
          });
        }

        this.applicant = {
          tourGroupControlID: this.groupControl.ID,
          productID: this.groupControl.productID,
          planSpecificationID: this.groupControl.PlanOption.planSpecificationID,
          planOptionID: this.groupControl.planOptionID,
          adminUserID: 0,
          applicantType: '會員',
          memberID: 0,
          vendorID: 0,
          category: '一般',
          price: 0,
          quantity: 0,
          amount: 0,
          headCount: null,
          cabinClass: this.groupControl.defaultInfo.economyClassCount > 0 ? '經濟艙' : '',
          note: '',
          startDate: this.groupControl.startDate,
          specialItem: this.groupControl.defaultInfo.specialItem,
        };

        // this.form = this.formBuilder.group({
        //   tourGroupControlID: [this.groupControl.ID],
        //   productID: [this.groupControl.productID],
        //   planSpecificationID: [this.groupControl.PlanOption.planSpecificationID],
        //   planOptionID: [this.groupControl.planOptionID],
        //   adminUserID: [0],
        //   applicantType: ['', [Validators.required]],
        //   memberID: [0],
        //   vendorID: [0],
        //   category: ['一般'],
        //   price: [0],
        //   quantity: [0],
        //   amount: [0],
        //   headCount: [],
        //   cabinClass: [''],
        //   note: [''],
        //   startDate: [this.groupControl.startDate],
        //   specialItem: [this.groupControl.defaultInfo.specialItem]
        // });
        this.getOrderDetailsBySpecOptionID();
      }
    });
    // this.getApplicants();

  }

  generateTypePrices(defaultPrices, roomTypes = null) {
    this.typePrices = [];
    if (roomTypes && roomTypes.length > 0) {
      return roomTypes.map(room => {
        const detail = [];
        defaultPrices.forEach(price => {
          const roomDiff = price.roomDiffs.find(rd => rd.name === room.name);
          if (roomDiff) {
            detail.push({
              businessClassDiff: price.businessClassDiff,
              firstClassDiff: price.firstClassDiff,
              name: price.name,
              bedOccupy: price.bedOccupy,
              sellPrice: price.sellPrice + roomDiff.priceDiff,
              quantity: 0,
              price: price.sellPrice + roomDiff.priceDiff,
              touristList: []
            });
          }
        });
        return {
          capacity: room.capacity,
          roomType: room.name,
          realCapacity: 0,
          quantity: 0,
          detail
        };
      });
    } else {
      const detail = [];
      defaultPrices.map(price => {
        detail.push({
          businessClassDiff: price.businessClassDiff,
          firstClassDiff: price.firstClassDiff,
          name: price.name,
          bedOccupy: price.bedOccupy,
          sellPrice: price.sellPrice,
          quantity: 0,
          price: price.sellPrice,
          touristList: []
        });
      });
      return [{ detail }];
    }
  }

  getOrderDetailsBySpecOptionID() {
    if (this.groupControl.defaultInfo.presetLeader === 1) {
      if (this.groupControl.defaultInfo.economyClassCount > 0) {
        this.cabinClassRemain.economy -= 1;
      }
      this.cabinClassRemain.common -= 1;
    }
    this.payService.getOrderDetailsBySpecOptionId(this.groupControl.planOptionID).subscribe((v: any) => {
      if (v.result) {
        v.data.map(dd => {
          if (dd.cabinClass === '經濟艙') {
            this.cabinClassRemain.economy -= dd.quantity;
          } else if (dd.cabinClass === '商務艙') {
            this.cabinClassRemain.business -= dd.quantity;
          } else if (dd.cabinClass === '頭等艙') {
            this.cabinClassRemain.first -= dd.quantity;
          }
          this.cabinClassRemain.common -= dd.quantity;
        });
        this.originalCabinClassRemain = JSON.parse(JSON.stringify(this.cabinClassRemain));

        console.log('this.ccabinClassRemain:>> ', this.cabinClassRemain);
      }
    },
      // tslint:disable-next-line:no-shadowed-variable
      (e) => { console.log(e); },
    );
  }

  countTimeDifference(start: string, end: string): { timeDiff: string, addOneDay: boolean } {
    const startTime = this.parseTime(start);
    const endTime = this.parseTime(end);
    const timeDiff = this.calculateTimeDifference(startTime, endTime);

    let addOneDay = false;
    let hours = timeDiff.hours;
    const minutes = timeDiff.minutes;

    if (hours < 0) {
      hours += 24; // 跨越一天，加上24小时
      addOneDay = true;
    }

    const hoursStr = hours > 0 ? `${hours}h` : '';
    const minutesStr = minutes > 0 ? `${minutes}m` : '';

    return { timeDiff: `${hoursStr} ${minutesStr}`, addOneDay };
  }

  parseTime(timeStr: string) {
    const [hoursStr, minutesStr] = timeStr.split(':');
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);
    return { hours, minutes };
  }

  calculateTimeDifference(startTime, endTime) {
    let hours = endTime.hours - startTime.hours;
    let minutes = endTime.minutes - startTime.minutes;
    if (minutes < 0) {
      hours -= 1;
      minutes += 60;
    }
    return { hours, minutes };
  }

  addOneDate(dateStr: string): string {
    const currentDate = new Date(dateStr);
    currentDate.setDate(currentDate.getDate() + 1);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  minusOneDate(dateStr: string): string {
    const currentDate = new Date(dateStr);
    currentDate.setDate(currentDate.getDate() - 1);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() - 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  countAmount(action?: string, i?: number, j?: number) {
    if (action === 'cabin') {
      this.typePrices.map(tp => {
        tp.detail.map(detail => {
          detail.quantity = 0;
          detail.realCapacity = 0;
        });
      });
      this.cabinClassRemain = JSON.parse(JSON.stringify(this.originalCabinClassRemain));
      this.totalQuantity = 0;
    }
    console.log('this.typePrices :>> ', this.typePrices);
    if (action === 'add' && this.cabinClassRemain.common + this.cabinClassRemain.waitlistSize > 0) {
      if (this.applicant.cabinClass === '經濟艙') {
        if (this.cabinClassRemain.economy + this.cabinClassRemain.waitlistSize > 0) {
          this.cabinClassRemain.economy--;

        } else {
          this.showAlert('經濟艙已無剩餘座位', '系統提示');
          return;
        }
      } else if (this.applicant.cabinClass === '商務艙') {
        if (this.cabinClassRemain.business > 0) {
          this.cabinClassRemain.business--;
        } else {
          this.showAlert('商務艙已無剩餘座位', '系統提示');
          return;
        }
      } else if (this.applicant.cabinClass === '頭等艙') {
        if (this.cabinClassRemain.first > 0) {
          this.cabinClassRemain.first--;
        } else {
          this.showAlert('頭等艙已無剩餘座位', '系統提示');
          return;
        }
      }
      if (this.cabinClassRemain.common > 0) {
        this.cabinClassRemain.common--;
      } else {
        this.cabinClassRemain.waitlistSize--;
      }
      if (this.typePrices[i].detail[j].bedOccupy) {
        this.typePrices[i].realCapacity++;
      }
      if (this.typePrices[i].quantity >= 0) {
        this.typePrices[i].quantity++;
      }
      this.typePrices[i].detail[j].quantity++;
      this.totalQuantity++;
    }

    if (action === 'minus' && this.typePrices[i].detail[j].quantity > 0) {
      if (this.applicant.cabinClass === '經濟艙') {
        this.cabinClassRemain.economy++;
      } else if (this.applicant.cabinClass === '商務艙') {
        this.cabinClassRemain.business++;
      } else if (this.applicant.cabinClass === '頭等艙') {
        this.cabinClassRemain.first++;
      }
      if (this.typePrices[i].detail[j].bedOccupy) {
        this.typePrices[i].realCapacity--;
      }
      this.cabinClassRemain.common++;
      // this.typePrices[i].quantity--;
      if (this.typePrices[i].quantity >= 0) {
        this.typePrices[i].quantity--;
      }
      this.typePrices[i].detail[j].quantity--;
      this.totalQuantity--;
    }

    this.amount = 0;
    this.typePrices.map(tp => {
      tp.detail.map(detail => {
        if (this.applicant.cabinClass === '商務艙') {
          detail.price = detail.sellPrice + detail.businessClassDiff;
        } else if (this.applicant.cabinClass === '頭等艙') {
          detail.price = detail.sellPrice + detail.firstClassDiff;
        } else {
          detail.price = detail.sellPrice;
        }
        this.amount += detail.price * detail.quantity;
      });
    });
  }

  placeOrder() {
    this.applicant.amount = this.amount;
    this.applicant.quantity = this.totalQuantity;

    const required = [];
    const optional = [];
    this.groupControl.Product.requiredField.map(rr => {
      this.groupControl.Product.required_fields.map(req => {
        if (rr === req.ID) {
          required.push(req);
        }
      });
    });
    this.groupControl.Product.optionalField.map(oo => {
      this.groupControl.Product.optional_fields.map(opt => {
        if (oo === opt.ID) {
          optional.push(opt);
        }
      });
    });
    const detailData = [];

    let checked = true;

    this.typePrices.map(tp => {
      if ((tp.quantity > 0 && tp.realCapacity % tp.capacity !== 0) || (tp.quantity > 0 && tp.realCapacity === 0) ) {
        checked = false;
      }

      tp.detail.map(detail => {
        if (detail.quantity > 0) {
          const cabinClassString = this.applicant.cabinClass ? this.applicant.cabinClass + ' ' : '';
          const roomTypeString = tp.roomType ? tp.roomType + ' - ' : '';
          // headCount.push(tp);
          detailData.push({
            tourGroupControlID: this.groupControl.ID,
            tourGroupType: this.groupControl.TourGroup.specialItem,
            productId: this.groupControl.productID,
            productImg: this.groupControl.Product.coverImg,
            productTitle: this.groupControl.Product.title,
            planId: this.groupControl.PlanOption.productPlanId,
            planName: this.groupControl.PlanOption.ProductPlan.name,
            specId: this.groupControl.PlanOption.planSpecificationID,
            specName: cabinClassString + roomTypeString + detail.name,
            specOptionId: this.groupControl.planOptionID,
            extraId: 0,
            extraOptionId: 0,
            extraName: '',
            date: this.groupControl.startDate,
            price: detail.price,
            quantity: detail.quantity,
            total: detail.quantity * detail.price,
            fillRequest: this.groupControl.Product.fillRequest,
            requiredFields: required,
            optionalFields: optional,
            sellingTimeType: this.groupControl.PlanOption.ProductPlan.sellingTimeType,
            sellingTimeBeforeHours: this.groupControl.PlanOption.ProductPlan.sellingTimeBeforeHours,
            productData: {
              product: {
                id: this.groupControl.productID, title: this.groupControl.Product.title, coverImg: this.groupControl.Product.coverImg,
                countryId: this.groupControl.Product.Country.ID, countryName: this.groupControl.Product.Country.name,
                regionId: this.groupControl.Product.regionID,
                regionName: this.groupControl.Product.regionID ? this.groupControl.Product.Region.name : '',
                fillRequest: this.groupControl.Product.fillRequest, productNumber: this.groupControl.Product.productNumber,
              },
              productCategory: { id: 0, name: '' },
              plan: { id: this.groupControl.PlanOption.productPlanId, name: this.groupControl.PlanOption.ProductPlan.name },
              planSpecification: {
                id: this.groupControl.PlanOption.planSpecificationID, name: cabinClassString + roomTypeString + detail.name,
                unit: '',
                splitMethod: 0,
                splitValue: 0,
                fullPayment: 1,
                deposit: 0,
                productCosts: this.groupControl.PlanOption.PlanSpecification.ProductCosts
              },
              extraPurchase: {
                id: 0, name: '', unit: '', splitMethod: '', splitValue: '', productCosts: null
              },
              specOption: {
                date: this.groupControl.PlanOption.date,
                id: this.groupControl.PlanOption.ID,
                status: this.groupControl.PlanOption.status,
                price: this.groupControl.PlanOption.price,
                quantity: this.groupControl.PlanOption.quantity,
                sold: 0,
              },
              tourGroupControlData: {
                id: this.groupControl.ID,
                tourGroupName: this.groupControl.tourGroupName,
                tourControl: this.groupControl.tourControl,
                tourGroupCategoryID: this.groupControl.tourGroupCategoryID,
                tourGroupID: this.groupControl.tourGroupID,
                groupNumber: this.groupControl.groupNumber,
                status: this.groupControl.status,
                defaultInfo: this.groupControl.defaultInfo,
                defaultPrices: this.groupControl.defaultPrices,
                headCount: tp,
              },
              extraOption: null,
              grabToday: null,
              cabinClass: this.applicant.cabinClass,
              roomType: tp.roomType,
              roomDetail: detail
              // applicant: this.applicant,
            },
          });
        }
      });
    });

    console.log('detailData :>> ', detailData);

    if (checked) {
    this.payService.placeOrder(detailData);
    this.payService.orderFromCart('false');

    this.router.navigate(['/pay/info/']);
    } else {
      this.showAlert('房型與人數不符', '系統提示');
    }

  }

  showAlert(content: string, title: string = '系統錯誤') {
    const config = {
      initialState: {
        title,
        content,
        btnClass: 'btn btn-danger'
      },
      class: 'modal-danger'
    };
    this.bsModalRef = this.modalService.show(AlertDialogComponent, config);
  }

}

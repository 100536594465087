import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  constructor(private http: HttpClient) { }

  getVideos() {
    return this.http.get('/api/site/videos');
  }

  updateViewCount(id: number) {
    return this.http.post<any>('/api/site/video/viewCount/' + id, null);
  }
}

import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GroupBuyService {

  constructor(private http: HttpClient) { }

  getGroupBuyByInitiatorID(id: number) {
    return this.http.get('/api/site/byInitiatorId/groupBuys/' + id);
  }

  getGroupBuyByID(id: number) {
    return this.http.get('/api/site/groupBuy/' + id);
  }

  verifyInitiatorPwd(data: any) {
    return this.http.post<any>('/api/site/initiator/verifyInitiator', data);
  }

  getGroupBuyOrder(orderNumber: string) {
    return this.http.get('/api/site/orderMain/byOrderNumber/' + orderNumber);
  }

  getInitiatorOrders(id: number, startDate = '', endDate = ''): Observable<any> {
    return this.http.get<any>('/api/site/initiator/orders/' + id, {
      params: new HttpParams()
        .set('startDate', startDate)
        .set('endDate', endDate)
    });
  }

}

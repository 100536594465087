import { delay } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';

export interface SimpleInfo {
  firstName: string;
  lastName: string;
  picture: string;
}

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  pathSelected: string;
  private userInfo = new Subject<SimpleInfo>();
  currentUser = this.userInfo.asObservable();

  constructor(private http: HttpClient) { }

  getMemberInfo() {
    return this.http.get('/api/site/auth/memberInfo');
  }

  getMember() {
    return this.http.get('/api/site/auth/member');
  }

  renewUserInfo(data: any) {
    this.userInfo.next(data);
  }

  getCountry(term: string = null): Observable<any[]> {
    let items = require('../../shared/resources/country.json');
    if (term) {
      items = items.filter(x => x.cn.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
    }
    return of(items).pipe(delay(500));
  }

  updateMember(data: any) {
    return this.http.patch<any>('/api/site/auth/member', data);
  }

  updatePicture(data: any) {
    return this.http.patch<any>('/api/site/auth/memberPicture', data);
  }

  getMemberSavedAll() {
    return this.http.get('/api/site/auth/memberSaved');
  }

  createMemberSaved(data: any) {
    return this.http.post<any>('/api/site/auth/memberSaved', data);
  }

  getMemberSavedById(id: number) {
    return this.http.get('/api/site/auth/memberSaved/' + id);
  }

  deleteMemberSaved(id: number) {
    return this.http.delete<any>('/api/site/auth/memberSaved/' + id);
  }

  getOrders() {
    return this.http.get('/api/site/auth/orderMain/byMemberId');
  }

  getOrder(orderNumber: string) {
    return this.http.get('/api/site/auth/orderMain/byOrderNumber/' + orderNumber);
  }

  createComment(comment: any) {
    return this.http.post<any>('/api/site/auth/comment', comment);
  }

  refundRequest(id: number, data: any) {
    return this.http.post<any>('/api/site/auth/orderDetail/refund/' + id, data);
  }

  getCoupon(pageNumber: number, code: any, PageSize: number) {
    return this.http.get<any>('/api/site/auth/memberCoupon', {
      params: new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('PageSize', PageSize.toString())
      .set('code', code.toString())
    });
  }

  getNewebPayLogsByOrderNumber(orderNumber: string) {
    return this.http.get<any>('/api/site/auth/newebPay/getNewebPayLogsByOrderNumber/' + orderNumber);
  }

  getCommonTouristFields() {
    return this.http.get('/api/site/commonTouristFields');
  }
}

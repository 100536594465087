import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EditorPagesService {

  constructor(private http: HttpClient) { }

  getEditorData(subject: string) {
    return this.http.get('/api/site/editorData/' + subject);
  }
}

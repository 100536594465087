<div class="container-fluid content-wrapper">
  <h4 class="page-heading my-5">{{"常見問題" | translate}}</h4>

  <div class="row ">
    <div class="col-md-9 col-xl-10 border-right">
      <div class="mb-5" *ngFor="let cate of category">
        <h4 class="font-weight-normal mb-4" id="cate{{cate.id}}">{{cate.name | googleTranslate | async}}</h4>
        <div class="accordion faq-accordion" id="accordionExample" *ngFor="let faq of faqData">
          <div class="card" *ngIf="faq.categoryID === cate.id">
            <div class="card-header collapsed">
              <div data-toggle="collapse" [attr.data-target]="'#collapse' + faq.ID" aria-expanded="false" class="collapsed">
                <span class="title">{{faq.question | googleTranslate:true | async}}</span>
                <span class="accicon"></span>
              </div>
            </div>
            <div id="collapse{{faq.ID}}" class="collapse" data-parent="#accordionExample">
              <div class="card-body">
                <p [innerHTML]="sanitizer.bypassSecurityTrustHtml(faq.answer | googleTranslate:true | async)"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <nav class="d-none d-md-block col-md-3 col-xl-2 bd-toc" aria-label="Secondary navigation">
      <h4 class="mb-3">{{"問題類別" | translate}}</h4>
      <ul class="section-nav list-unstyled">
        <li class="py-2" *ngFor="let cate of category">
          <a href="#cate{{cate.id}}" pageScroll [pageScrollDuration]="500">{{cate.name | googleTranslate | async}}</a>
        </li>
      </ul>
    </nav>
  </div>
</div>


import { DeviceDetectorService } from 'ngx-device-detector';
import { ActivatedRoute } from '@angular/router';
import { GroupBuyService } from './../group-buy.service';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-initiator-dashboard',
  templateUrl: './initiator-dashboard.component.html',
  styleUrls: ['./initiator-dashboard.component.scss']
})
export class InitiatorDashboardComponent implements OnInit {
  @Input() id: number;
  @Input() initiatorData: any;
  @Output() closeShowDashboard: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  datePipe = new DatePipe('en-US');
  searchMonth: any;
  today = new Date();
  displayedColumns: string[] = ['order_number', 'schedule', 'departureDay', 'name', 'amount', 'pay_status', 'created_at', 'profit'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  orderData: any[];
  orderAmount = 0;
  totalProfit = 0;
  isMobile = this.deviceService.isMobile();
  isTablet = this.deviceService.isTablet();
  isDesktopDevice = this.deviceService.isDesktop();

  constructor(
    private deviceService: DeviceDetectorService,
    private groupBuyService: GroupBuyService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.searchMonth = (this.today.getMonth() + 1) < 10 ? this.today.getFullYear() + '-0' + (this.today.getMonth() + 1) :
      this.today.getFullYear() + '-' + (this.today.getMonth() + 1);
    this.getGroupBuyOrders();
  }

  getGroupBuyOrders() {
    const startTemp = new Date(this.searchMonth + '-01');
    const lastDayOfMonth = new Date(startTemp.getFullYear(), startTemp.getMonth() + 1, 0);
    const endDate = formatDate(lastDayOfMonth, 'yyyy-MM-dd', 'en');

    this.orderAmount = 0;
    this.totalProfit = 0;
    this.groupBuyService.getInitiatorOrders(+this.id, this.searchMonth + '-01', endDate).subscribe(v => {
      if (v.result) {
        console.log('=res= :>> ', v);
        this.orderData = v.data;
        if (v.data) {
          this.orderData.map(data => {
            data.quantity = 0;
            if (data.GroupBuyProduct.profitSet && data.payStatus === 1) {
              data.OrderDetail.map(od => {
                data.quantity += od.quantity;
              });
              data.GroupBuyProduct.profitSet.map(pp => {
                if (data.quantity >= pp.from && data.quantity <= pp.to) {
                  if (data.GroupBuyProduct.splitType === 1) {
                    data.profitPercentage = pp.percentage;
                    data.profitAmount = Math.round((data.amount - data.discount - data.refund) * (pp.percentage / 100));
                  } else {
                    data.profitValue = pp.value;
                    data.profitAmount = data.quantity * pp.value;
                  }
                }
              });
            }

            // v.sumData.map(dd => {
            //   if (data.groupBuyProductId === dd.groupBuyProductId) {
            //     if (data.GroupBuyProduct.profitSet && data.payStatus === 1) {
            //       data.profitAmount = 0;
            //       data.GroupBuyProduct.profitSet.map(pp => {
            //         if (dd.sum >= pp.from && dd.sum <= pp.to ) {
            //           if (data.GroupBuyProduct.splitType === 1) {
            //             data.profitPercentage = pp.percentage;
            //             data.profitAmount = Math.round((data.amount - data.discount - data.refund) * (pp.percentage / 100));
            //           } else {
            //             console.log('data.profitValue :>> ', data.profitValue);
            //             data.profitValue = pp.value;
            //             data.profitAmount = dd.sum * pp.value;
            //           }
            //         }
            //       });
            //     }
            //   }
            // });
            this.totalProfit = data.profitAmount ? this.totalProfit + data.profitAmount : this.totalProfit;
            this.orderAmount += (data.amount - data.discount - data.refund);
          });
        }
        this.dataSource = new MatTableDataSource(this.orderData ? this.orderData : []);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        // this.showAlert('密碼錯誤');
      }
    },
      (e) => {
        console.log(e);
      },
    );
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FeaturedService {

  constructor(private http: HttpClient) { }

  getFeaturedTopics() {
    return this.http.get('/api/site/featuredTopics');
  }

  getFeaturedTopic(id: number) {
    return this.http.get('/api/site/featuredTopic/' + id);
  }

  getFeaturedTopicBanner() {
    return this.http.get('/api/site/featuredTopicBanner');
  }

  getHomeCategory(id: number) {
    return this.http.get('/api/site/homeCategory/' + id);
  }
}

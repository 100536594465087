import { ToastrService } from 'ngx-toastr';
import { AlertDialogComponent } from './../../../shared/dialogs/alert-dialog/alert-dialog.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ScrollSpyService } from '@uniprank/ngx-scrollspy';
import { DomSanitizer } from '@angular/platform-browser';
import { PayService } from './../../pay/pay.service';
import { GroupBuyService } from './../group-buy.service';
import { ProductService } from './../../product/product.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { LayoutService } from '../../layout/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-group-buy-info',
  templateUrl: './group-buy-info.component.html',
  styleUrls: ['./group-buy-info.component.scss']
})
export class GroupBuyInfoComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  @ViewChild('divForm') divForm: ElementRef;
  isMobile = this.deviceService.isMobile();
  isTablet = this.deviceService.isTablet();
  isDesktopDevice = this.deviceService.isDesktop();
  id: number;
  initiatorID: number;
  initiatorData: any;
  groupBuyData: any;
  productData: any;
  tabSelected = 1;
  lowestDiscountValue = 0;
  currentSection = '';
  submitClicked = false;
  firstFormGroup: FormGroup;
  countries: any[] = [];
  country = '臺灣';
  order: any[] = [];
  coupons: any[] = [];
  queryCoupon: any = {
    code: '',
    order: []
  };
  coupon: any;
  code = '';
  onSelectCoupon: any;
  discountValue = 0;
  totalAmount: number;
  payAmount: number;
  payMethod: number;
  linePay = false;
  totalDeduct = 0;
  PayMethod2 = false;
  PayMethod3 = false;
  PayMethod4 = false;
  PayMethod5 = false;
  PayMethod6 = false;
  PayMethod7 = false;
  remitInfo = '';
  bsModalRef: BsModalRef;
  touristInfo: any[] = [];
  selectedCurrency: string;
  currencyRate: number;
  languageList = [{ name: '繁體中文', value: 'zh-TW' }, { name: '简体中文', value: 'zh-CN' }, { name: 'English', value: 'en' },
  { name: '日本語', value: 'ja' }];
  selectedLanguage: string;
  isLoading = false;

  formattedSelectedDate = '';
  remainQuantity = 0;
  monthNames: string[] = ['一月', '二月', '三月', '四月', '五月', '六月', '七月',
    '八月', '九月', '十月', '十一月', '十二月'];
  monthNumbers: string[] = ['01', '02', '03', '04', '05', '06', '07',
    '08', '09', '10', '11', '12'];
  dayNames = ['一', '二', '三', '四', '五', '六', '日'];

  constructor(
    private router: Router,
    private deviceService: DeviceDetectorService,
    private productService: ProductService,
    private groupBuyService: GroupBuyService,
    private changeDetectorRef: ChangeDetectorRef,
    public sanitizer: DomSanitizer,
    private payService: PayService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private layoutService: LayoutService,
    private scrollSpyService: ScrollSpyService,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    // const storedCurrency = localStorage.getItem('selectedCurrency');
    // this.selectedCurrency = storedCurrency || 'TWD';
    // this.changeCurrency(this.selectedCurrency);

    this.id = this.route.snapshot.params.id;
    this.initiatorID = this.route.snapshot.params.initiatorID;
    this.submitClicked = false;
    this.groupBuyService.getGroupBuyByID(this.id).subscribe((v: any) => {
      this.isLoading = true;
      if (v.result && v.data) {
        this.groupBuyData = v.data;
        this.initiatorData = v.data.GroupBuyInitiator;
        this.layoutService.translateHTML(this.initiatorData.remitInfo).subscribe((res: any) => {
          this.remitInfo = res;
        });
        if (this.initiatorData.payMethod) {
          this.initiatorData.payMethod.map(method => {
            switch (method) {
              case 2:
                this.PayMethod2 = true;
                break;
              case 3:
                this.PayMethod3 = true;
                break;
              case 4:
                this.PayMethod4 = true;
                break;
              case 5:
                this.PayMethod5 = true;
                break;
              case 6:
                this.PayMethod6 = true;
                break;
              case 7:
                this.PayMethod7 = true;
                break;
            }
          });
        }

        const storedCurrency = localStorage.getItem('selectedCurrency' + this.initiatorData.ID);
        if (!storedCurrency) {
          this.selectedCurrency = this.initiatorData.currencyList.length > 0 ? this.initiatorData.currencyList[0] : 'TWD';
        } else {
          this.selectedCurrency = storedCurrency;
        }
        this.changeCurrency(this.selectedCurrency);
        this.translateService.use(this.layoutService.language.value);
        switch (this.layoutService.language.value) {
          case 'zh-TW':
            this.selectedLanguage = '繁體中文';
            break;
          case 'zh-CN':
            this.selectedLanguage = '简体中文';
            break;
          case 'en':
            this.selectedLanguage = 'English';
            break;
          case 'ja':
            this.selectedLanguage = '日本語';
            break;
        }

        this.productData = v.data.GroupBuyProduct.Product;
        if (this.groupBuyData.allowLimitedTime === 1) {
          this.groupBuyData.leftTime = (new Date(this.groupBuyData.endDate.split('Z')[0]).getTime() - new Date().getTime());
          this.groupBuyData.startDate = this.groupBuyData.startDate.split('T')[0] + ' ' +
            this.groupBuyData.startDate.split('T')[1].substring(0, 8);
          this.groupBuyData.endDate = this.groupBuyData.endDate.split('T')[0] + ' ' +
            this.groupBuyData.endDate.split('T')[1].substring(0, 8);
        }
        if (this.groupBuyData.GroupBuyProduct.discountType !== 0) {
          if (this.groupBuyData.GroupBuyProduct.discountType === 1) {
            this.lowestDiscountValue = this.groupBuyData.GroupBuyProduct.discountValue;
          } else {
            this.lowestDiscountValue =
              Math.round(this.groupBuyData.GroupBuyProduct.Product.lowestPrice * (this.groupBuyData.GroupBuyProduct.discountValue / 100));
          }
        }
        this.initiatorData.topGraphic = this.initiatorData.topGraphic === 'null' || this.initiatorData.topGraphic === '' ?
          '' : this.sanitizer.bypassSecurityTrustHtml(this.initiatorData.topGraphic);

        this.layoutService.translateHTML(this.initiatorData.footer).subscribe((res: any) => {
          this.initiatorData.footer = res;
        });

        this.changeDetectorRef.detectChanges();

        if (this.productData) {
          this.productData.coverVideo = this.productData.coverVideo === 'null' || this.productData.coverVideo === '' ?
            '' : this.sanitizer.bypassSecurityTrustHtml(this.productData.coverVideo);

          this.layoutService.translateHTML(this.productData.map).subscribe((res: any) => {
            this.productData.map = res;
          });
          this.layoutService.translateHTML(this.productData.videoContext).subscribe((res: any) => {
            this.productData.videoContext = res;
          });
          this.layoutService.translateHTML(this.productData.detail).subscribe((res: any) => {
            this.productData.detail = res;
          });
          this.layoutService.translateHTML(this.productData.introduction).subscribe((res: any) => {
            this.productData.introduction = res;
          });
          this.layoutService.translateHTML(this.productData.description).subscribe((res: any) => {
            this.productData.description = res;
          });
          this.layoutService.translateHTML(this.productData.instruction).subscribe((res: any) => {
            this.productData.instruction = res;
          });
          this.layoutService.translateHTML(this.productData.notice).subscribe((res: any) => {
            this.productData.notice = res;
          });
          this.layoutService.translateHTML(this.productData.policy).subscribe((res: any) => {
            this.productData.policy = res;
          });


          if (this.productData.ProductPlans) {
            this.productData.ProductPlans.sort((a, b) => (a.sort < b.sort ? -1 : 1));
            this.productData.ProductPlans = this.productData.ProductPlans.filter(plan => plan.status === 1);
            this.productData.ProductPlans.map(plan => {
              plan.show = false;
              plan.selected = false;
              plan.personCount = 1;
              plan.planLowestDiscountValue = 0;
              plan.productPlanId = 0;
              plan.specOptionId = 0;
              plan.extraOptionId = 0;
              plan.price = 0;
              plan.optionSelected = false;
              plan.optionDate = '';

              plan.specData = {
                id: 0, name: '', unit: '',
                splitMethod: 0,
                splitValue: 0,
                fullPayment: 1,
                deposit: 0,
                productCosts: [],
                planOptData: [],
                daysInThisMonth: [],
                daysInLastMonth: [],
                daysInNextMonth: [],
                date: new Date(),
                currentDate: 0,
                currentMonth: '',
                currentMonthNum: '',
                currentYear: 0
              };

              plan.extraData = {
                id: 0, name: '', unit: '',
                splitMethod: 0,
                splitValue: 0,
                productCosts: [],
                planOptData: [],
                daysInThisMonth: [],
                daysInLastMonth: [],
                daysInNextMonth: [],
                date: new Date(),
                currentDate: 0,
                currentMonth: '',
                currentMonthNum: '',
                currentYear: 0
              };

              if (this.isLoading) {
                setTimeout(() => {
                  if (this.groupBuyData.GroupBuyProduct.discountType !== 0) {
                    if (this.groupBuyData.GroupBuyProduct.discountType === 1) {
                      plan.planLowestDiscountValue = this.groupBuyData.GroupBuyProduct.discountValue;
                    } else {
                      plan.planLowestDiscountValue =
                        Math.round(plan.lowestPrice * (this.groupBuyData.GroupBuyProduct.discountValue / 100));
                    }
                  }
                  if (plan.PlanSpecification && plan.PlanSpecification.length > 0) {
                    plan.PlanSpecification.map(spec => {
                      if (spec.fullPayment === 1) {
                        plan.show = true;
                      }
                      spec.checked = false;
                      this.getOptionDetailById(spec.ID, 'plan_specification_id').subscribe((options: any[]) => {
                        spec.PlanOptions = options;
                        if (spec.PlanOptions && spec.PlanOptions.length > 0) {
                          spec.PlanOptions.map(option => {
                            option.sold = 0;
                            option.date = option.date.length === 10 ?
                              option.date : option.date.split('-')[0] + '-' + option.date.split('-')[1] + '-0' + option.date.split('-')[2];
                          });
                        }
                      });
                    });
                  }

                  if (plan.ExtraPurchase && plan.ExtraPurchase.length > 0) {
                    plan.ExtraPurchase.map(extra => {
                      extra.checked = false;
                      this.getOptionDetailById(extra.ID, 'extra_purchase_id').subscribe((options: any[]) => {
                        extra.PlanOptions = options;
                        if (extra.PlanOptions && extra.PlanOptions.length > 0) {
                          extra.PlanOptions.map(option => {
                            option.sold = 0;
                            option.date = option.date.length === 10 ?
                              option.date : option.date.split('-')[0] + '-' + option.date.split('-')[1] + '-0' + option.date.split('-')[2];
                          });
                        }
                      });
                    });
                  }
                }, 100);
              }
            });
          }

        }
        console.log('this.productData :>> ', this.productData);

        if (this.groupBuyData.GroupBuyProduct.Product.ID !== 0) {
          this.productService.updateViewCount(this.groupBuyData.GroupBuyProduct.Product.ID)
            .subscribe((res: any) => { });
        }

        this.loadScript('assets/js/vendor/perfect-scrollbar/perfect-scrollbar.js');
        this.loadScript('assets/js/vendor/slick/slick.js');
        this.loadScript('assets/js/app.js');
      } else {
        const config = {
          initialState: {
            title: '系統通知',
            content: '此團購專案已結束銷售',
            btnClass: 'btn btn-primary'
          },
          class: 'modal-primary'
        };
        this.bsModalRef = this.modalService.show(AlertDialogComponent, config);
        this.bsModalRef.content.event.subscribe(result => {
          if (result.action) {
            this.router.navigate(['/groupBuy/initiator/', this.initiatorID]);
          }
        });
      }
    });

    this.scrollSpyService.setOffset('window', 100);

    this.loadCountry();

    this.firstFormGroup = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      country: ['臺灣'],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
    });

  }

  get f(): { [key: string]: AbstractControl; } { return this.firstFormGroup.controls; }

  loadCountry() {
    this.payService.getCountry().subscribe(x => {
      this.countries = x;
    });
  }

  getOptionDetailById(id: number, filter: string): Observable<any[]> {
    return this.productService.getOptionDetailById(id, filter).pipe(
      map((v: any) => {
        if (v.result && v.data) {
          return v.data;
        } else {
          return [];
        }
      })
    );
  }

  placeOrder(i: number) {
    const date = this.productData.ProductPlans[i].optionDate.split('-')[2].length > 1 ?
      this.productData.ProductPlans[i].optionDate
      : this.productData.ProductPlans[i].optionDate.split('-')[0] + '-' + this.productData.ProductPlans[i].optionDate.split('-')[1]
      + '-0' + this.productData.ProductPlans[i].optionDate.split('-')[2];

    let quantityCheck = true;
    if (this.productData.ProductPlans[i].extraData.id !== 0) {
      this.productData.ProductPlans[i].extraData.planOptData.map(opt => {
        if (opt.ID === this.productData.ProductPlans[i].extraOptionId) {
          if (this.productData.ProductPlans[i].personCount > opt.quantity - opt.sold) {
            quantityCheck = false;
          }
        }
      });
    } else {
      this.productData.ProductPlans[i].specData.planOptData.map(opt => {
        if (opt.ID === this.productData.ProductPlans[i].specOptionId) {
          if (this.productData.ProductPlans[i].personCount > opt.quantity - opt.sold) {
            quantityCheck = false;
          }
        }
      });
    }

    if (quantityCheck) {
      this.order = [{
        productId: this.productData.ID,
        productImg: this.productData.coverImg,
        productTitle: this.productData.title,
        planId: this.productData.ProductPlans[i].ID,
        planName: this.productData.ProductPlans[i].name,
        specId: this.productData.ProductPlans[i].specData.id,
        specName: this.productData.ProductPlans[i].specData.name,
        specOptionId: this.productData.ProductPlans[i].specOptionId,
        extraId: this.productData.ProductPlans[i].extraData.id,
        extraOptionId: this.productData.ProductPlans[i].extraOptionId,
        extraName: this.productData.ProductPlans[i].extraOptionId !== 0 ?
          this.productData.ProductPlans[i].extraData.name : '',
        date,
        price: this.productData.ProductPlans[i].price,
        quantity: this.productData.ProductPlans[i].personCount,
        total: this.productData.ProductPlans[i].personCount *
          (this.productData.ProductPlans[i].price - this.productData.ProductPlans[i].specOptionData.discountValue),
        fillRequest: this.productData.fillRequest,
        requiredFields: this.productData.required_fields,
        optionalFields: this.productData.optional_fields,
        sellingTimeType: this.productData.ProductPlans[i].sellingTimeType,
        sellingTimeBeforeHours: this.productData.ProductPlans[i].sellingTimeBeforeHours,
        groupBuy: {
          discountType: this.groupBuyData.GroupBuyProduct.discountType,
          discountValue: this.groupBuyData.GroupBuyProduct.discountValue,
          deductValue: this.productData.ProductPlans[i].specOptionData.discountValue * this.productData.ProductPlans[i].personCount
        },
        productData: {
          product: {
            id: this.productData.ID, title: this.productData.title, coverImg: this.productData.coverImg,
            countryId: this.productData.Country.ID, countryName: this.productData.Country.name,
            regionId: this.productData.Region.ID, regionName: this.productData.Region.name,
            fillRequest: this.productData.fillRequest, productNumber: this.productData.productNumber,
          },
          productCategory: {
            id: this.productData.ProductCategory.ID, name: this.productData.ProductCategory.name
          },
          plan: {
            id: this.productData.ProductPlans[i].ID,
            name: this.productData.ProductPlans[i].name
          },
          planSpecification: {
            id: this.productData.ProductPlans[i].specData.id, name: this.productData.ProductPlans[i].specData.name,
            unit: this.productData.ProductPlans[i].specData.unit,
            splitMethod: this.productData.ProductPlans[i].specData.splitMethod,
            splitValue: this.productData.ProductPlans[i].specData.splitValue,
            fullPayment: this.productData.ProductPlans[i].specData.fullPayment,
            deposit: this.productData.ProductPlans[i].specData.deposit,
            productCosts: this.productData.ProductPlans[i].specData.productCosts
          },
          extraPurchase: {
            id: this.productData.ProductPlans[i].extraData.id,
            name: this.productData.ProductPlans[i].extraData.name,
            unit: this.productData.ProductPlans[i].extraData.unit,
            splitMethod: this.productData.ProductPlans[i].extraData.splitMethod,
            splitValue: this.productData.ProductPlans[i].extraData.splitValue,
            productCosts: this.productData.ProductPlans[i].extraData.productCosts
          },
          specOption: this.productData.ProductPlans[i].specOptionData,
          extraOption: this.productData.ProductPlans[i].extraOptionData,
          groupBuyInitiator: {
            id: this.groupBuyData.GroupBuyInitiator.ID,
            name: this.groupBuyData.GroupBuyInitiator.name,
            email: this.groupBuyData.GroupBuyInitiator.email,
            phone: this.groupBuyData.GroupBuyInitiator.phone,
            payMethod: this.groupBuyData.GroupBuyInitiator.payMethod,
            remitInfo: this.groupBuyData.GroupBuyInitiator.remitInfo,
          },
          groupBuy: {
            id: this.groupBuyData.ID,
            allowLimitedTime: this.groupBuyData.allowLimitedTime,
            startDate: this.groupBuyData.startDate,
            endDate: this.groupBuyData.endDate,
          },
          groupBuyProduct: {
            id: this.groupBuyData.GroupBuyProduct.ID,
            discountType: this.groupBuyData.GroupBuyProduct.discountType,
            discountValue: this.groupBuyData.GroupBuyProduct.discountValue,
            profitSet: this.groupBuyData.GroupBuyProduct.profitSet,
            deductValue: this.productData.ProductPlans[i].specOptionData.discountValue * this.productData.ProductPlans[i].personCount
          },
        },
      }];


      this.totalAmount = 0;
      this.payAmount = 0;

      this.order.map(item => {
        item.productData.grabToday = { id: 0, deadline: '', deduct: 0 };
        this.queryCoupon.order.push(JSON.parse(JSON.stringify({
          productId: item.productId, total: item.total
        })));
        this.totalAmount += item.total;
        this.payAmount = this.totalAmount;

        item.touristList = [];
        item.requiredFields.map(req => {
          if (req.fieldType === 'checkbox') {
            req.answer = [];
            req.fieldData.map(data => {
              const checkData = { key: data, value: false };
              req.answer.push(JSON.parse(JSON.stringify(checkData)));
            });
            req.checkValid = false;
          } else {
            req.answer = '';
          }
        });

        item.optionalFields.map(opt => {
          if (opt.fieldType === 'checkbox') {
            opt.answer = [];
            opt.fieldData.map(data => {
              const checkData = { key: data, value: false };
              opt.answer.push(JSON.parse(JSON.stringify(checkData)));
            });
          } else {
            opt.answer = '';
          }
        });

        if (item.fillRequest === 1) {
          const touristLis = {
            count: 1, requiredFields: item.requiredFields,
            optionalFields: item.optionalFields, commonTourist: null,
          };
          item.touristList.push(JSON.parse(JSON.stringify(touristLis)));
        } else {
          for (let j = 0; j < item.quantity; j++) {
            const touristLis = {
              count: j + 1, requiredFields: item.requiredFields,
              optionalFields: item.optionalFields, commonTourist: null,
            };
            item.touristList.push(JSON.parse(JSON.stringify(touristLis)));
          }
        }
      });

      this.queryCoupon.order.push(JSON.parse(JSON.stringify({
        productId: this.productData.ID, total: this.order[0].total
      })));
      this.payService.getCouponsForGroupBuy(this.queryCoupon, this.code, this.id).subscribe((v3: any) => {
        if (v3.result) {
          this.coupons = v3.coupons;
        }
      },
        (e) => { console.log(e); },
      );
      console.log('this.order :>> ', this.order);
    }

  }

  async getOrderDetailsForSpec(specId: number, planIndex: number) {
    let defaultDateSetted = false;
    await this.productService.getOrderDetailsBySpecId(specId).subscribe((v: any) => {
      if (v.result && v.data) {
        this.productData.ProductPlans[planIndex].specData.planOptData.map(opt => {
          opt.sold = 0;
          v.data.map(detail => {
            if (opt.ID === detail.specOptionId) {
              opt.sold += detail.quantity;
              // opt.quantity -= detail.quantity;
            }
          });

          if (opt.quantity - opt.sold >= this.productData.ProductPlans[planIndex].specData.minimumQuantity && !defaultDateSetted) {
            this.productData.ProductPlans[planIndex].specData.defaultDate = new Date(opt.date);
            defaultDateSetted = true;
          }
        });

        if (!defaultDateSetted) {
          this.productData.ProductPlans[planIndex].specData.defaultDate = new Date();
        }
        this.getSpecDaysOfMonth(planIndex);
      }
    });
  }

  getOrderDetailsForExtra(extraId: number, planIndex: number) {
    this.productService.getOrderDetailsByExtraId(extraId).subscribe((v: any) => {
      if (v.result && v.data) {
        this.productData.ProductPlans[planIndex].extraData.planOptData.map(opt => {
          v.data.map(detail => {
            opt.sold = 0;
            if (opt.ID === detail.extraOptionId) {
              opt.sold += detail.quantity;
              // opt.quantity -= detail.quantity;
            }
          });
        });
        this.getSpecDaysOfMonth(planIndex);
      }
    });
  }

  async setPlanSpec(planId: number, specId: number, planIndex: number, specIndex?: number) {
    this.productData.ProductPlans.map(plan => {
      if (plan.ID === planId) {
        plan.specData.id = 0;
        plan.specData.name = '';
        plan.specData.unit = '';
        plan.specData.planOptData = [];

        plan.optionSelected = false;
        plan.specOptionId = 0;
        plan.extraOptionId = 0;
        plan.price = 0;
        plan.optionDate = '';

        if (specId > 0) {
          let defaultDateSetted = false;
          this.productData.ProductPlans[planIndex].PlanSpecification[specIndex].PlanOptions.map(opt => {
            if (opt.quantity >= this.productData.ProductPlans[planIndex].PlanSpecification[specIndex].minimumQuantity &&
              !defaultDateSetted) {
              plan.specData.defaultDate = new Date(opt.date);
              defaultDateSetted = true;
            }
          });
          if (!defaultDateSetted) {
            plan.specData.defaultDate = new Date();
          }
          // this.getOrderDetailsForSpec(specId, planIndex);
        }

        plan.PlanSpecification.map(spec => {
          spec.checked = false;
          if (spec.ID === specId) {
            spec.checked = true;
            // if (spec.checked) {
            //   // extra.checked = false;
            // } else {
            // plan.specData.defaultDate = spec.PlanOptions[0] ? new Date(spec.PlanOptions[0].date) : new Date();
            plan.specData.id = specId;
            plan.specData.name = spec.name;
            plan.specData.unit = spec.unit;
            plan.specData.planOptData = spec.PlanOptions;
            plan.specData.splitMethod = spec.splitMethod;
            plan.specData.splitValue = spec.splitValue;
            plan.specData.fullPayment = spec.fullPayment;
            plan.specData.deposit = spec.deposit;
            plan.specData.productCosts = spec.ProductCosts;
            plan.specData.minimumQuantity = spec.minimumQuantity;
            plan.personCount = spec.minimumQuantity;
            // }
          }
        });
      }
    });
    this.getSpecDaysOfMonth(planIndex);
  }

  setPlanExtra(planId: number, extraId: number, planIndex: number) {
    this.productData.ProductPlans.map(plan => {
      if (plan.ID === planId) {
        plan.extraData.id = 0;
        plan.extraData.name = '';
        plan.extraData.unit = '';
        plan.extraData.planOptData = [];

        plan.optionSelected = false;
        plan.specOptionId = 0;
        plan.extraOptionId = 0;
        plan.price = 0;
        plan.optionDate = '';

        if (extraId > 0) {
          this.getOrderDetailsForExtra(extraId, planIndex);
        }

        plan.ExtraPurchase.map(extra => {
          if (extra.ID === extraId) {
            if (extra.checked) {
              // extra.checked = false;
            } else {
              // extra.checked = true;
              plan.extraData.id = extraId;
              plan.extraData.name = extra.name;
              plan.extraData.unit = extra.unit;
              plan.extraData.planOptData = extra.PlanOptions;
              plan.extraData.splitMethod = extra.splitMethod;
              plan.extraData.splitValue = extra.splitValue;
              plan.extraData.productCosts = extra.ProductCosts;
            }
          } else {
            extra.checked = false;
          }
        });
      }
    });
    this.getSpecDaysOfMonth(planIndex);
  }

  setPlanOption(index: number, specOptionId: number, price: number, discountValue: number, date: string, extraOptionId: number = 0) {
    if (this.productData.ProductPlans[index].optionSelected) {
      if (this.productData.ProductPlans[index].specOptionId === specOptionId) {
        this.productData.ProductPlans[index].optionSelected = false;
        this.productData.ProductPlans[index].specOptionId = 0;
        this.productData.ProductPlans[index].extraOptionId = 0;
        this.productData.ProductPlans[index].price = 0;
        this.productData.ProductPlans[index].optionDate = '';
        this.productData.ProductPlans[index].specOptionData = { id: 0, date: '', price: 0, discountValue: 0, quantity: 0, sold: 0 };
        this.productData.ProductPlans[index].extraOptionData = { id: 0, date: '', price: 0, quantity: 0, sold: 0 };
      } else {
        this.productData.ProductPlans[index].specOptionId = specOptionId;
        this.productData.ProductPlans[index].extraOptionId = extraOptionId;
        this.productData.ProductPlans[index].price = price;
        this.productData.ProductPlans[index].discountValue = discountValue;

        this.productData.ProductPlans[index].specData.planOptData.map(option => {
          if (option.ID === specOptionId) {
            this.productData.ProductPlans[index].specOptionData = {
              id: option.ID, date: option.date, price: option.price, discountValue,
              quantity: option.quantity, sold: option.sold
            };
          }
        });
        if (extraOptionId > 0) {
          this.productData.ProductPlans[index].extraData.planOptData.map(option => {
            if (option.ID === extraOptionId) {
              this.productData.ProductPlans[index].extraOptionData = {
                id: option.ID, date: option.date,
                price: option.price, quantity: option.quantity, sold: option.sold
              };
            }
          });
        }
      }
    } else {
      this.productData.ProductPlans[index].optionSelected = true;
      this.productData.ProductPlans[index].specOptionId = specOptionId;
      this.productData.ProductPlans[index].extraOptionId = extraOptionId;
      this.productData.ProductPlans[index].price = price;
      this.productData.ProductPlans[index].discountValue = discountValue;

      this.productData.ProductPlans[index].specData.planOptData.map(option => {
        if (option.ID === specOptionId) {
          this.productData.ProductPlans[index].specOptionData = {
            id: option.ID, date: option.date, price: option.price, discountValue,
            quantity: option.quantity, sold: option.sold
          };
        }
      });
      if (extraOptionId > 0) {
        this.productData.ProductPlans[index].extraData.planOptData.map(option => {
          if (option.ID === extraOptionId) {
            this.productData.ProductPlans[index].extraOptionData = {
              id: option.ID, date: option.date,
              price: option.price, quantity: option.quantity, sold: option.sold
            };
          }
        });
      } else {
        this.productData.ProductPlans[index].extraOptionData = { id: 0, date: '', price: 0, quantity: 0, sold: 0 };
      }
    }
    this.getSpecDaysOfMonth(index);
  }


  getSpecDaysOfMonth(index: number) {
    let sellingTime = this.productData.ProductPlans[index].sellingTimeBeforeHours;
    if (sellingTime % 24 === 0) {
      sellingTime += 24;
    }
    this.productData.ProductPlans[index].specData.daysInThisMonth = new Array();
    this.productData.ProductPlans[index].specData.daysInLastMonth = new Array();
    this.productData.ProductPlans[index].specData.daysInNextMonth = new Array();

    this.productData.ProductPlans[index].specData.currentMonth =
      this.monthNames[this.productData.ProductPlans[index].specData.defaultDate.getMonth()];
    this.productData.ProductPlans[index].specData.currentYear =
      this.productData.ProductPlans[index].specData.defaultDate.getFullYear();
    this.productData.ProductPlans[index].specData.currentMonthNum =
      this.monthNumbers[this.productData.ProductPlans[index].specData.defaultDate.getMonth()];

    // this.productData.ProductPlans[index].specData.currentMonth =
    //   this.monthNames[this.productData.ProductPlans[index].specData.date.getMonth()];
    // this.productData.ProductPlans[index].specData.currentYear =
    //   this.productData.ProductPlans[index].specData.date.getFullYear();
    // this.productData.ProductPlans[index].specData.currentMonthNum =
    //   this.monthNumbers[this.productData.ProductPlans[index].specData.date.getMonth()];

    if (this.productData.ProductPlans[index].specData.defaultDate.getMonth() === new Date().getMonth()) {
      this.productData.ProductPlans[index].specData.currentDate = new Date().getDate();
    } else {
      if (this.productData.ProductPlans[index].specData.defaultDate.getTime() < new Date().getTime()) {
        this.productData.ProductPlans[index].specData.currentDate = 888;
      } else {
        this.productData.ProductPlans[index].specData.currentDate = 999;
      }
    }

    const firstDayThisMonth = new Date(this.productData.ProductPlans[index].specData.defaultDate.getFullYear(),
      this.productData.ProductPlans[index].specData.defaultDate.getMonth(), 0).getDay();
    const prevNumOfDays = new Date(this.productData.ProductPlans[index].specData.defaultDate.getFullYear(),
      this.productData.ProductPlans[index].specData.defaultDate.getMonth(), 0).getDate();
    let thisMonthDays = 0;
    let lastMonthDays = 0;
    let nextMonthDays = 0;

    for (let i = prevNumOfDays - (firstDayThisMonth - 1); i <= prevNumOfDays; i++) {
      this.productData.ProductPlans[index].specData.daysInLastMonth.
        push({ id: 0, day: i, quantity: 0, price: 0, today: false, selected: false });
      lastMonthDays = this.productData.ProductPlans[index].specData.daysInLastMonth.length;
    }
    const thisNumOfDays = new Date(this.productData.ProductPlans[index].specData.defaultDate.getFullYear(),
      this.productData.ProductPlans[index].specData.defaultDate.getMonth() + 1, 0).getDate();

    for (let i = 0; i < thisNumOfDays; i++) {
      let today = false;
      i === this.productData.ProductPlans[index].specData.currentDate - 1 ? today = true : today = false;

      this.productData.ProductPlans[index].specData.daysInThisMonth.
        push({ id: 0, day: i + 1, quantity: 0, price: 0, today, selected: false });
      thisMonthDays = this.productData.ProductPlans[index].specData.daysInThisMonth.length;

      if (this.productData.ProductPlans[index].extraData.id !== 0) {
        this.productData.ProductPlans[index].extraData.planOptData.map(opt => {
          if (opt.date.split('-')[0] === this.productData.ProductPlans[index].specData.defaultDate.getFullYear().toString() &&
            opt.date.split('-')[1] === this.productData.ProductPlans[index].specData.currentMonthNum) {

            const formatedDay = this.productData.ProductPlans[index].specData.daysInThisMonth[i].day > 9 ?
              this.productData.ProductPlans[index].specData.daysInThisMonth[i].day.toString() :
              '0' + this.productData.ProductPlans[index].specData.daysInThisMonth[i].day.toString();

            if (formatedDay === opt.date.split('-')[2]) {
              const sold = opt.sold ? opt.sold : 0;
              this.productData.ProductPlans[index].specData.daysInThisMonth[i].extraQuantity = opt.quantity - sold;
              this.productData.ProductPlans[index].specData.daysInThisMonth[i].extraPrice = opt.price;
              this.productData.ProductPlans[index].specData.daysInThisMonth[i].extraOptionId = opt.ID;
            }
          }
        });
      }

      this.productData.ProductPlans[index].specData.planOptData.map(opt => {
        if (opt.date.split('-')[0] === this.productData.ProductPlans[index].specData.defaultDate.getFullYear().toString() &&
          opt.date.split('-')[1] === this.productData.ProductPlans[index].specData.currentMonthNum) {

          const formatedDay = this.productData.ProductPlans[index].specData.daysInThisMonth[i].day > 9 ?
            this.productData.ProductPlans[index].specData.daysInThisMonth[i].day.toString() :
            '0' + this.productData.ProductPlans[index].specData.daysInThisMonth[i].day.toString();

          if (formatedDay === opt.date.split('-')[2]) {
            this.productData.ProductPlans[index].specData.daysInThisMonth[i].id = opt.ID;
            const tt = new Date(opt.date + 'T24:00');
            tt.setHours(tt.getHours() - sellingTime);

            this.productData.ProductPlans[index].specData.daysInThisMonth[i].discountValue = 0;
            if (this.groupBuyData.GroupBuyProduct.discountType !== 0) {
              if (this.groupBuyData.GroupBuyProduct.discountType === 1) {
                this.productData.ProductPlans[index].specData.daysInThisMonth[i].discountValue =
                  this.groupBuyData.GroupBuyProduct.discountValue;
              } else {
                this.productData.ProductPlans[index].specData.daysInThisMonth[i].discountValue =
                  Math.round(opt.price * (this.groupBuyData.GroupBuyProduct.discountValue / 100));
              }
            }
            this.productData.ProductPlans[index].specData.daysInThisMonth[i].canBuy =
              this.productData.ProductPlans[index].sellingTimeType === 1 ?
                tt.getTime() > new Date().getTime() : tt.getTime() >= new Date().getTime();

            if (this.productData.ProductPlans[index].extraData.id !== 0) {
              if (this.productData.ProductPlans[index].specData.daysInThisMonth[i].extraQuantity > 0) {
                this.productData.ProductPlans[index].specData.daysInThisMonth[i].quantity =
                  opt.quantity - opt.sold > this.productData.ProductPlans[index].specData.daysInThisMonth[i].extraQuantity ?
                    this.productData.ProductPlans[index].specData.daysInThisMonth[i].extraQuantity : opt.quantity - opt.sold;
                this.productData.ProductPlans[index].specData.daysInThisMonth[i].price =
                  this.productData.ProductPlans[index].specData.daysInThisMonth[i].extraPrice + opt.price;
              }
            } else {
              this.productData.ProductPlans[index].specData.daysInThisMonth[i].quantity = opt.quantity - opt.sold;
              this.productData.ProductPlans[index].specData.daysInThisMonth[i].price = opt.price;
            }
            if (this.productData.ProductPlans[index].specOptionId === opt.ID) {
              this.productData.ProductPlans[index].specData.daysInThisMonth[i].selected = true;
              this.productData.ProductPlans[index].optionDate = opt.date;

              // const dayNames2 = ['日', '一', '二', '三', '四', '五', '六'];
              // this.formattedSelectedDate = opt.date.split('-')[0] + '/' + opt.date.split('-')[1] + '/' +
              //   opt.date.split('-')[2] + '(' + dayNames2[new Date(opt.date).getDay()] + ')';
              this.remainQuantity = this.productData.ProductPlans[index].specData.daysInThisMonth[i].quantity;
            }
          }
        }
      });
    }

    const lastDayThisMonth = new Date(this.productData.ProductPlans[index].specData.defaultDate.getFullYear(),
      this.productData.ProductPlans[index].specData.defaultDate.getMonth() + 1, 0).getDay();
    const nextNumOfDays = new Date(this.productData.ProductPlans[index].specData.defaultDate.getFullYear(),
      this.productData.ProductPlans[index].specData.defaultDate.getMonth() + 2, 0).getDate();

    for (let i = 0; i < (7 - lastDayThisMonth); i++) {
      this.productData.ProductPlans[index].specData.daysInNextMonth.
        push({ id: 0, day: i + 1, quantity: 0, price: 0, today: false, selected: false });
      nextMonthDays = this.productData.ProductPlans[index].specData.daysInNextMonth.length;
    }

    const totalDays = lastMonthDays + thisMonthDays + nextMonthDays;
    if (totalDays < 36) {
      for (let i = (7 - lastDayThisMonth); i < ((7 - lastDayThisMonth) + 7); i++) {
        this.productData.ProductPlans[index].specData.daysInNextMonth.
          push({ id: 0, day: i, quantity: 0, price: 0, today: false, selected: false });
      }
    }

  }

  goToLastMonth(index: number) {
    this.productData.ProductPlans[index].specData.defaultDate =
      new Date(this.productData.ProductPlans[index].specData.defaultDate.getFullYear(),
        this.productData.ProductPlans[index].specData.defaultDate.getMonth(), 0);

    this.productData.ProductPlans[index].specData.date =
      new Date(this.productData.ProductPlans[index].specData.date.getFullYear(),
        this.productData.ProductPlans[index].specData.date.getMonth(), 0);
    this.getSpecDaysOfMonth(index);
  }

  goToNextMonth(index: number) {
    this.productData.ProductPlans[index].specData.defaultDate =
      new Date(this.productData.ProductPlans[index].specData.defaultDate.getFullYear(),
        this.productData.ProductPlans[index].specData.defaultDate.getMonth() + 2, 0);

    this.productData.ProductPlans[index].specData.date =
      new Date(this.productData.ProductPlans[index].specData.date.getFullYear(),
        this.productData.ProductPlans[index].specData.date.getMonth() + 2, 0);
    this.getSpecDaysOfMonth(index);
  }

  loadScript(url) {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }


  getCoupon() {
    if (this.code === '') {
      alert('請輸入折扣碼');
      return;
    }
    this.payService.getGroupBuyCoupon(this.queryCoupon, this.code, +this.id).subscribe(v => {
      if (v.coupons.length > 0) {
        this.code = '';
        this.onSelectCoupon = v.coupons[0];
        this.confirmCoupon();
      } else {
        alert('找不到符合的折扣碼');
      }
    });
  }

  selectCoupon(i: number) {
    this.onSelectCoupon = this.coupons[i];
  }

  confirmCoupon() {
    const tmpCoupon: any = this.onSelectCoupon;
    let tmpTotal = 0;
    this.coupon = tmpCoupon;
    console.log('this.coupon :>> ', this.coupon);

    if (tmpCoupon.discountType === 1) {
      this.discountValue = tmpCoupon.discountValue;
      this.payAmount -= tmpCoupon.discountValue;
      return;
    }
    if (tmpCoupon.productId > 0) {
      this.order.map(item => {
        if (item.productId === tmpCoupon.productId) {
          tmpTotal += item.total;
        }
      });
      this.discountValue = Math.round((tmpTotal - this.totalDeduct) * (tmpCoupon.discountValue / 100));
      this.payAmount -= this.discountValue;
      return;
    }

    this.discountValue = Math.round((this.totalAmount - this.totalDeduct) * (tmpCoupon.discountValue / 100));
    this.payAmount -= this.discountValue;
  }

  deleteCoupon() {
    this.onSelectCoupon = {};
    this.coupon = null;
    this.payAmount = this.totalAmount - this.totalDeduct;
    this.discountValue = 0;
  }

  checkTouristForm() {
    let formValid = true;

    this.touristInfo = [];
    this.order[0].touristList.map(tourist => {
      const fieldData = [];
      tourist.requiredFields.map(req => {
        const checkAns = [];
        if (req.fieldType === 'checkbox') {
          req.answer.map(ans => {
            if (ans.value) {
              req.checkValid = true;
              checkAns.push(ans.key);
            }
          });
          if (checkAns.length < 1) {
            formValid = false;
          }
          fieldData.push({ id: req.ID, title: req.title, value: checkAns });
        } else {
          if (req.fieldType !== 'readonly') {
            if (!req.answer) {
              formValid = false;
            }
            fieldData.push({ id: req.ID, title: req.title, value: req.answer });
          }
        }
      });
      tourist.optionalFields.map(opt => {
        const checkAns = [];
        if (opt.fieldType === 'checkbox') {
          opt.answer.map(ans => {
            if (ans.value) {
              checkAns.push(ans.key);
            }
          });
          fieldData.push({ id: opt.ID, title: opt.title, value: checkAns });
        } else {
          if (opt.fieldType !== 'readonly') {
            fieldData.push({ id: opt.ID, title: opt.title, value: opt.answer });
          }
        }
      });
      this.touristInfo.push(fieldData);
    });

    if (formValid) {
      this.stepper.next();
    } else {
      alert('請檢查欄位是否正確填寫');
    }
  }

  onSubmit() {
    this.submitClicked = true;
    const orderDetail = [];

    this.order.map(product => {
      let discount = 0;
      let couponID = 0;

      if (this.coupon && this.coupon.productId === product.productId) {
        couponID = this.coupon.ID;
        if (this.coupon.discountType === 1) { // 折價
          let tmpA = [];
          let tmpATotal = 0;
          tmpA = this.order.filter(p => p.productId === this.coupon.productId);
          if (tmpA.length > 1) {
            tmpA.map(a => {
              tmpATotal += a.total;
            });
            const tmpTotal = product.total;
            discount = Math.round(tmpTotal / tmpATotal * this.coupon.discountValue);
          } else {
            discount = this.coupon.discountValue;
          }
        }
        if (this.coupon.discountType === 2) {
          discount = Math.round((product.total) * (this.coupon.discountValue / 100));
        }
      }

      if (this.coupon && this.coupon.conditionType !== 3) {
        couponID = this.coupon.ID;
        if (this.coupon.discountType === 1) {
          const tmpTotal = product.total;
          discount = Math.round(tmpTotal / (this.totalAmount - this.totalDeduct) * this.coupon.discountValue);
        }
        if (this.coupon.discountType === 2) {
          discount = Math.round(product.total * (this.coupon.discountValue / 100));
        }
      }

      if (this.payMethod !== 7) {
        this.selectedCurrency = 'TWD';
        this.currencyRate = 1.0000;
      }

      orderDetail.push({
        productId: product.productId,
        productPlanId: product.planId,
        planSpecificationId: product.specId,
        extraPurchaseId: product.extraId,
        specOptionId: product.specOptionId,
        extraOptionId: product.extraOptionId,
        price: product.price - (product.groupBuy.deductValue / product.quantity),
        quantity: product.quantity,
        payStatus: -1,
        couponID,
        discount,
        touristInfo: this.touristInfo,
        productData: product.productData,
        groupBuyInitiatorId: this.groupBuyData.GroupBuyProduct.groupBuyInitiatorId,
        groupBuyId: +this.id,
        groupBuyProductId: this.groupBuyData.GroupBuyProduct.ID,
        currency: this.selectedCurrency,
        rate: this.currencyRate
      });
    });

    const orderData = this.formBuilder.group({
      ordererInfo: [this.firstFormGroup.value],
      orderDetails: [orderDetail],
      payStatus: -1,
      orderStatus: -1,
      memberId: 0,
      amount: this.totalAmount - this.totalDeduct,
      payMethod: this.payMethod,
      orderByAdmin: 0,
      couponID: this.coupon && this.coupon.ID ? this.coupon.ID : 0,
      discount: this.discountValue,
      discountStatus: this.coupon && this.coupon.ID ? 1 : 0,
      groupBuyInitiatorId: this.groupBuyData.GroupBuyProduct.groupBuyInitiatorId,
      groupBuyId: +this.id,
      groupBuyProductId: this.groupBuyData.GroupBuyProduct.ID,
      currency: this.selectedCurrency,
      rate: this.currencyRate
    });
    console.log('orderData.value :>> ', orderData.value);

    if (this.payMethod === 2) {
      this.payService.linepayForGroupBuy(orderData.value).subscribe(
        r => {
          if (r.result) {
            location.href = r.url;
          } else {
            alert(r.message);
            this.submitClicked = false;
          }
        },
        e => {
          console.log(e);
          alert(e.data);
          this.submitClicked = false;
        });
    }

    if (this.payMethod === 3 || this.payMethod === 4 || this.payMethod === 5 || this.payMethod === 6) {
      this.toastr.info('將導到契約第三方付款金流（藍新金流）頁面...請稍候', '系統訊息');
      // alert('將導到契約第三方付款金流（藍新金流）頁面...請稍候');
      // this.showAlert('將導到契約第三方付款金流（藍新金流）頁面...請稍候', '系統提示');
      this.payService.newebPayWithNumberSystemForGroupBuy(orderData.value).subscribe(
        r => {
          if (r.result) {
            const dataForNewebPay = {
              formHtml: r.data.formHtml,
            };
            localStorage.setItem('dataForNewebPay', JSON.stringify(dataForNewebPay));
            const formHtml = r.data;
            this.divForm.nativeElement.innerHTML = `${formHtml}`;
            // tslint:disable-next-line:only-arrow-functions
            $(document).ready(function () {
              $('#newebpaySubmit').click();
              // tslint:disable-next-line:only-arrow-functions
              setTimeout(function () {
                $('#newebpaySubmit').trigger('click');
              }, 500);
            });
          } else {
            this.submitClicked = false;
            $('.modal-dialog').hide();
            if (r.message) {
              alert(r.message);
            } else {
              if (r.data) {
                alert(r.data);
              } else {
                alert('契約第三方付款金流（藍新金流）異常，請聯絡客服人員，謝謝您。');
              }
            }
            // tslint:disable-next-line:only-arrow-functions
            setTimeout(function () {
              // window.location.reload();
            }, 2000);
          }
        },
        e => {
          alert(e.message);
        });
    }

    if (this.payMethod === 7) {
      this.payService.createGroupBuyOrder(orderData.value).subscribe(
        r => {
          if (r.result) {
            this.closebutton.nativeElement.click();
            this.toastr.success('訂單已成立。', '系統訊息');
            this.router.navigate(['/groupBuy/orderInfo/' + r.order.orderNumber]);
          } else {
            alert(r.message);
            this.submitClicked = false;
          }
        },
        e => {
          this.submitClicked = false;
          console.log(e);
          alert(e.message);
        });
    }
  }

  changeCurrency(currency: string) {
    this.selectedCurrency = currency;
    localStorage.setItem('selectedCurrency' + this.initiatorData.ID, currency);
    if (this.selectedCurrency === 'TWD') {
      this.currencyRate = 1.0000;
      this.layoutService.renewCurrency({
        currency,
      });
    }

    if (this.selectedCurrency !== 'TWD') {
      this.layoutService.getCurrencyRate().subscribe((v: any) => {
        if (v.result) {
          console.log('v :>> ', v);
          this.currencyRate = this.selectedCurrency === 'USD' ? v.data.USDTWD.Exrate / v.data[this.selectedCurrency].Exrate :
            v.data.USDTWD.Exrate / v.data['USD' + this.selectedCurrency].Exrate;
          console.log('this.currencyRate :>> ', this.currencyRate);

          this.layoutService.renewCurrency({
            currency,
            USD: v.data.USD.Exrate / v.data.USDTWD.Exrate,
            AUD: v.data.USDAUD.Exrate / v.data.USDTWD.Exrate,
            CAD: v.data.USDCAD.Exrate / v.data.USDTWD.Exrate,
            CNY: v.data.USDCNY.Exrate / v.data.USDTWD.Exrate,
            EUR: v.data.USDEUR.Exrate / v.data.USDTWD.Exrate,
            GBP: v.data.USDGBP.Exrate / v.data.USDTWD.Exrate,
            HKD: v.data.USDHKD.Exrate / v.data.USDTWD.Exrate,
            IDR: v.data.USDIDR.Exrate / v.data.USDTWD.Exrate,
            INR: v.data.USDINR.Exrate / v.data.USDTWD.Exrate,
            JPY: v.data.USDJPY.Exrate / v.data.USDTWD.Exrate,
            KHR: v.data.USDKHR.Exrate / v.data.USDTWD.Exrate,
            KRW: v.data.USDKRW.Exrate / v.data.USDTWD.Exrate,
            MAD: v.data.USDMAD.Exrate / v.data.USDTWD.Exrate,
            MMK: v.data.USDMMK.Exrate / v.data.USDTWD.Exrate,
            MOP: v.data.USDMOP.Exrate / v.data.USDTWD.Exrate,
            MYR: v.data.USDMYR.Exrate / v.data.USDTWD.Exrate,
            MXN: v.data.USDMXN.Exrate / v.data.USDTWD.Exrate,
            NPR: v.data.USDNPR.Exrate / v.data.USDTWD.Exrate,
            NZD: v.data.USDNZD.Exrate / v.data.USDTWD.Exrate,
            PHP: v.data.USDPHP.Exrate / v.data.USDTWD.Exrate,
            RUB: v.data.USDRUB.Exrate / v.data.USDTWD.Exrate,
            SGD: v.data.USDSGD.Exrate / v.data.USDTWD.Exrate,
            THB: v.data.USDTHB.Exrate / v.data.USDTWD.Exrate,
            TRY: v.data.USDTRY.Exrate / v.data.USDTWD.Exrate,
            VND: v.data.USDVND.Exrate / v.data.USDTWD.Exrate,
            ZAR: v.data.USDZAR.Exrate / v.data.USDTWD.Exrate,
          });
        }

      },
        (e) => { console.log(e); },
      );
    }
  }

  changeLanguage(lang: string) {
    this.layoutService.renewLanguage(lang);
    this.translateService.use(lang);

    location.reload();
  }

  showAlert(content: string, title: string = '系統錯誤') {
    const config = {
      initialState: {
        title,
        content,
        btnClass: 'btn btn-danger'
      },
      class: 'modal-danger'
    };
    this.bsModalRef = this.modalService.show(AlertDialogComponent, config);
  }

}

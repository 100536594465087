<alert type="danger" *ngIf="!isMemberDone">
  <strong>{{"注意" | translate}}!</strong> {{"需填寫完會員基本資料才能使用會員功能喔" | translate}}！
</alert>

<div class="bg-white panel">
  <h5 class="panel-title">{{"帳號設定" | translate}}</h5>
  <div class="panel-content">
    <div class="row mt-n3 mt-md-n4">
      <div class="col-12">
        <div>
          <ul class="nav nav-tabs-custom mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link active" id="coupon-available" data-toggle="tab" href="#couupon-available" role="tab"
                aria-controls="couupon-available" aria-selected="true">{{"基本資料" | translate}}</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-profile-tab" data-toggle="tab" href="#coupon-unavailable" role="tab"
                aria-controls="coupon-unavailable" aria-selected="false">{{"常用旅客" | translate}}</a>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="couupon-available" role="tabpanel"
              aria-labelledby="coupon-available">
              <form action="" method="post" class="form-horizontal" [formGroup]="form" *ngIf="form">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{"護照名(英文)" | translate}} <sub class="text-price">*</sub></label>
                      <input type="text" class="form-control" formControlName="firstNameEn"
                        [ngClass]="{ 'is-invalid': f.firstNameEn.errors && f.firstNameEn.touched }">
                      <span *ngIf="f.firstNameEn.errors" class="invalid-feedback">{{"護照名為必填" | googleTranslate:true |
                        async}}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{"護照姓(英文)" | translate}} <sub class="text-price">*</sub></label>
                      <input type="text" class="form-control" formControlName="lastNameEn"
                        [ngClass]="{ 'is-invalid': f.lastNameEn.errors && f.lastNameEn.touched }">
                      <span *ngIf="f.lastNameEn.errors" class="invalid-feedback">{{"護照姓為必填" | googleTranslate:true |
                        async}}</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{"本國名" | translate}} <sub class="text-price">*</sub></label>
                      <input type="text" class="form-control" formControlName="firstName"
                        [ngClass]="{ 'is-invalid': f.firstName.errors && f.firstName.touched }">
                      <span *ngIf="f.firstName.errors" class="invalid-feedback">{{"本國名" | translate}}{{"為必填" | translate}}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{"本國姓" | translate}} <sub class="text-price">*</sub></label>
                      <input type="text" class="form-control" formControlName="lastName"
                        [ngClass]="{ 'is-invalid': f.lastName.errors && f.lastName.touched }">
                      <span *ngIf="f.lastName.errors" class="invalid-feedback">{{"本國姓" | translate}}{{"為必填" | translate}}</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group" formGroupName="memberInfo">
                      <label>{{"國家" | translate}} <sub class="text-price">*</sub></label>
                      <select class="form-control select-arrow" formControlName="country" [(ngModel)]="country">
                        <option [value]="country.cn" *ngFor="let country of countries">{{country.cn | googleTranslate |
                          async}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{"聯絡電話" | translate}} <sub class="text-price">*</sub></label>
                      <input type="text" class="form-control" formControlName="phone"
                        [ngClass]="{ 'is-invalid': f.phone.errors && f.phone.touched }">
                      <span *ngIf="f.phone.errors" class="invalid-feedback">{{"聯絡電話" | translate}}{{"為必填" | translate}}</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label>Email <sub class="text-price">*</sub></label>
                      <input type="email" class="form-control" formControlName="email" disabled
                        [ngClass]="{ 'is-invalid': f.email.errors && f.email.touched }" *ngIf="emailLogin">
                      <input type="email" class="form-control" formControlName="email"
                        [ngClass]="{ 'is-invalid': f.email.errors && f.email.touched }" *ngIf="!emailLogin">
                      <span *ngIf="f.email.errors" class="invalid-feedback">Email{{"為必填" | translate}}</span>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-center">
                  <div class="col-4 col-md-3 col-lg-2">
                    <button class="btn btn-primary btn-block rounded-0" (click)="onSubmit()" [disabled]="!form.valid">
                      {{"儲存" | translate}}
                    </button>
                  </div>
                </div>

              </form>
            </div>
            <!-- available -->
            <div class="tab-pane fade" id="coupon-unavailable" role="tabpanel" aria-labelledby="coupon-unavailable-tab">

              <div class="mb-3">
                <a href="#" data-toggle="modal" data-target="#passengerModal"
                  (click)="getCommonTouristFields(); actionString='新增'">
                  <span class="d-flex align-items-center">
                    <span class="material-icons">control_point</span>
                    <span class="pl-2 text-dark">{{"新增常用旅客" | translate}}</span>
                  </span>
                </a>
              </div>


              <div *ngIf="commonTourist && commonTourist.length > 0">
                <div class="card-radio-btn mb-3" *ngFor="let tourist of member.commonTourist; let i = index;">
                  <div class="card card-body flex-row justify-content-between text-dark">
                    {{tourist.displayName}}
                    <div>
                      <a href="#" data-toggle="modal" data-target="#passengerModal"
                        (click)="getCommonTouristFields(i); actionString='修改'">
                        <span class="material-icons text-primary">edit</span>
                      </a>
                      <a style="cursor:pointer;" class="pl-2" (click)="deleteCommonTourist(i)">
                        <span class="material-icons text-primary">delete_outline</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <!-- /unavailavle -->
          </div>
        </div>
      </div>
    </div>

  </div>

</div>

<!-- Modal -->
<div class="modal fade" id="passengerModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-custom modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{actionString}}{{"常用旅客" | translate}}</h5>
        <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">

            <!-- <div class="px-3"> -->
            <div class="form-group">
              <label>{{"顯示名稱" | translate}} <sub class="text-price">*</sub></label><br>
              <input type="text" class="form-control" [(ngModel)]="displayName">
              <small class="text-muted">{{"例：爸爸、媽媽、姓名、暱稱...等" | translate}}</small>
            </div>
            <!-- </div> -->

            <div *ngFor="let field of fields">
              <div *ngIf="field.fieldType == 'input'">
                <div class="form-group">
                  <label>{{field.title | googleTranslate | async}}</label>
                  <input type="text" class="form-control" [(ngModel)]="field.answer">
                </div>
              </div>
              <div *ngIf="field.fieldType == 'radio'">
                <div class="form-group">
                  <label>{{field.title | googleTranslate | async}}</label><br>
                  <mat-radio-group [(ngModel)]="field.answer">
                    <mat-radio-button [value]="data" class="mr-3" *ngFor="let data of field.fieldData">{{data |
                      googleTranslate | async}}
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <div *ngIf="field.fieldType == 'textarea'">
                <div class="form-group">
                  <label>{{field.title | googleTranslate | async}}</label><br>
                  <textarea rows="5" class="form-control" [(ngModel)]="field.answer"></textarea>
                </div>
              </div>
              <div *ngIf="field.fieldType == 'readonly'">
                <div class="form-group">
                  <label>{{field.title | googleTranslate | async}}</label><br>
                  <textarea rows="5" class="form-control"
                    disabled>{{field.fieldData | googleTranslate:true | async}}</textarea>
                </div>
              </div>
              <div *ngIf="field.fieldType == 'select'">
                <div class="form-group">
                  <label>{{field.title | googleTranslate | async}}</label><br>
                  <select class="form-control select-arrow" [(ngModel)]="field.answer">
                    <option [value]="field" *ngFor="let field of field.fieldData">{{field | googleTranslate | async}}
                    </option>
                  </select>

                </div>
              </div>
              <div *ngIf="field.fieldType == 'checkbox'">
                <div class="form-group">
                  <label>{{field.title | googleTranslate | async}}</label>
                  <div *ngFor="let data of field.answer">
                    <mat-checkbox [(ngModel)]="data.value">{{data.key | googleTranslate | async}}</mat-checkbox><br>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer border-0">
        <button type="button" class="btn btn-link text-muted" data-dismiss="modal">{{"取消" | translate}}</button>
        <button type="button" class="btn btn-primary rounded-0" data-dismiss="modal"
          (click)="updateCommonTourist()">{{"儲存" | translate}}</button>
      </div>
    </div>
  </div>
</div>

import { IndexService } from './../../index/index.service';
import { GetLoginURLReponse, GetUserInfoReponse } from './../../../core/services/login.service';
import { ConfirmDialogComponent } from './../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { PayService } from './../pay.service';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Router } from '@angular/router';


@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  cartDataAble: Array<any> = [];
  cartDataUnable: Array<any> = [];
  totalAmount: number;
  totalDeduct = 0;
  bsModalRef: BsModalRef;
  resp: GetLoginURLReponse = { result: false, url: '' };
  userResp: GetUserInfoReponse = { result: false, user_info: undefined };

  constructor(
    private payService: PayService,
    private indexService: IndexService,
    private router: Router,
    private modalService: BsModalService) { }

  ngOnInit(): void {
    this.getCartItems();
  }

  getCartItems() {
    this.totalAmount = 0;
    this.cartDataAble = [];
    this.cartDataUnable = [];
    this.payService.getItems('CartItems').map(item => {
      if (item.sellingTimeBeforeHours % 24 === 0) {
        item.sellingTimeBeforeHours += 24;
      }
      const tt = new Date(item.date + 'T24:00');
      tt.setHours(tt.getHours() - item.sellingTimeBeforeHours);
      item.canBuy = item.sellingTimeType === 1 ? tt.getTime() > new Date().getTime() : tt.getTime() >= new Date().getTime();
      if (item.canBuy) {
        this.totalAmount += item.total;
        this.cartDataAble.push(item);

        this.indexService.getGrabToday()
          .subscribe((r: any) => {
            if (r.result && r.data) {
              this.totalDeduct = 0;
              this.cartDataAble.map(product => {
                product.isSale = false;
                product.deduct = 0;
                product.totalDeduct = 0;

                r.data.GrabTodayPlan.map(sales => {
                  if (sales.product.ID === product.productId) {
                    product.isSale = true;
                    product.deduct = sales.product.deduct;
                    product.totalDeduct = sales.product.deduct * product.quantity;
                  }
                });
                this.totalDeduct += product.totalDeduct;
              });
            }
          });
      } else {
        this.cartDataUnable.push(item);
      }
    });
  }

  deleteItem(index: number) {
    const config = {
      initialState: {
        title: '刪除商品',
        content: '您確定要刪除所選商品嗎？',
        btnDanger: 'btn btn-danger',
        btnSecondary: 'btn btn-Secondary',
      },
      class: 'modal-danger'
    };
    this.bsModalRef = this.modalService.show(ConfirmDialogComponent, config);
    this.bsModalRef.content.event.subscribe(result => {
      if (result.action) {
        this.payService.deleteCartItem(index);
        this.getCartItems();
      }
    });
  }

  placeOrder() {
    this.payService.placeOrder(this.cartDataAble);
    this.payService.orderFromCart('true');
    this.router.navigate(['/pay/info/']);
  }

}

<footer>
  <div class="footer-top"></div>
  <div class="footer-wrapper">
    <div class="container-fluid">
      <div class="row ">
        <div class="col-12 col-md-2 col-lg-2 footer-sec-1">
          <ul class="list-unstyled footer-link row">
            <li class="col-4 col-md-12">
              <a [routerLink]="['/about']">{{"關於禾藤" | translate}}</a>
            </li>
            <li class="col-4 col-md-12">
              <a [routerLink]="['/faq']">{{"常見問題" | translate}}</a>
            </li>
            <li class="col-4 col-md-12">
              <a [routerLink]="['/privacy']">{{"隱私權政策" | translate}}</a>
            </li>
            <li class="col-4 col-md-12">
              <a [routerLink]="['/instruction']">{{"訂購說明" | translate}}</a>
            </li>
            <li class="col-4 col-md-12">
              <a [routerLink]="['/fraud']">{{"防詐宣導" | translate}}</a>
            </li>
          </ul>
        </div>

        <div class="col-12 col-md-4 col-lg-5 text-center footer-sec-2">
          <div class="footer-title">{{"公司資訊" | translate}}</div>
          <div [innerHTML]="companyInfo"></div>
        </div>

        <div class="col-12 col-md-6 col-lg-5 footer-sec-3">
          <div class="footer-title">{{"服務特色" | translate}}</div>
          <ul class="row footer-service">
            <li class="col-6 col-md-5 col-lg-6" *ngFor="let item of features">{{item | googleTranslate | async}}</li>
          </ul>
        </div>
      </div>
      <div class="copyright">{{"Copyright © 2020 禾藤旅遊網版權所有，建議使用Chrome、Firefox觀看" | translate}}</div>
    </div>
  </div>

  <div class="footer-logo">
    <img src="assets/images/footer-logo.png" class="img-fluid">
  </div>
  <div class="footer-balloon">
    <img src="assets/images/footer-balloon.png" class="img-fluid">
  </div>
  <div class="footer-plane">
    <img src="assets/images/footer-plane.png" class="img-fluid">
  </div>

</footer>

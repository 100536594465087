import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { AlertDialogComponent } from './../dialogs/alert-dialog/alert-dialog.component';
import { LoginService } from './../../core/services/login.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  loginForm: FormGroup;
  emailForm: FormGroup;
  headto: string;
  redirectUrl: string;
  showForget = false;

  public readonly siteKey = environment.siteKey;
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  constructor(
    public loginService: LoginService,
    private cdr: ChangeDetectorRef,
    private modalService: BsModalService,
    private router: Router,
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    switch (this.headto) {
      case 'cart':
        this.redirectUrl = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/pay/cart';
        break;
      case 'payment':
        this.redirectUrl = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/pay/info';
        break;
      default:
        this.redirectUrl = this.headto;
    }

    this.form = this.formBuilder.group({
      loginMethod: 'email',
      email: ['', [Validators.required, Validators.email]],
      pswd: ['', [Validators.required, Validators.minLength(6)]],
      status: [-1],
      recaptcha: ['', Validators.required]
    });

    this.loginForm = this.formBuilder.group({
      loginMethod: 'email',
      email: ['', [Validators.required, Validators.email]],
      pswd: ['', [Validators.required, Validators.minLength(6)]],
    });

    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  get f(): { [key: string]: AbstractControl; } { return this.form.controls; }
  get lf(): { [key: string]: AbstractControl; } { return this.loginForm.controls; }
  get ef(): { [key: string]: AbstractControl; } { return this.emailForm.controls; }

  googleLogin() {
    this.loginService.google(this.redirectUrl).subscribe(resp => {
      if (resp.result) {
        location.href = resp.url;
      }
    }, err => { console.log(err); }, () => { });
  }

  facebookLogin() {
    this.loginService.facebook(this.redirectUrl).subscribe(resp => {
      if (resp.result) {
        location.href = resp.url;
      } else {
      }
    }, err => { console.log(err); }, () => { });
  }

  register() {
    this.loginService.register(this.form.value).subscribe(resp => {
      if (resp.result) {
        this.form = this.formBuilder.group({
          loginMethod: 'email',
          email: ['', [Validators.required, Validators.email]],
          status: [-1],
          pswd: ['', [Validators.required, Validators.minLength(6)]]
        });
        this.showAlert('系統提醒', '帳戶已建立，請重新登入', 'btn btn-success', 'modal-success');
      } else {
        this.showAlert('系統錯誤', resp.message, 'btn btn-danger', 'modal-danger');
      }
    }, err => { console.log(err); }, () => { });
  }

  accountLogin() {
    this.loginService.accountLogin(this.loginForm.value, this.redirectUrl).subscribe(resp => {
      if (resp.result) {
        if (resp.isMember === 1) {
          location.href = resp.url;
        } else {
          // alert('需填寫完會員基本資料才能使用會員功能喔！');
          location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/member/setting';
        }
      } else {
        this.showAlert('系統錯誤', resp.message, 'btn btn-danger', 'modal-danger');
      }
    }, err => { console.log(err); }, () => { });
  }

  forgetPswd() {
    this.loginService.resetPswd(this.emailForm.value).subscribe(resp => {
      if (resp.result) {
        this.emailForm = this.formBuilder.group({
          email: ['', [Validators.required, Validators.email]]
        });
        this.showForget = false;
        this.showAlert('系統提醒', '已發送密碼重置信件，請至信箱查收。', 'btn btn-success', 'modal-success');
      } else {
        this.showAlert('系統錯誤', resp.message, 'btn btn-danger', 'modal-danger');
      }
    }, err => { console.log(err); }, () => { });
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }

  copyToClipboard() {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', ('https://www.eztogo.tw'));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }

  showAlert(title: string, content: string, btnClass: string, modalClass: string) {
    const config = {
      initialState: {
        title,
        content,
        btnClass
      },
      class: modalClass
    };
    this.bsModalRef = this.modalService.show(AlertDialogComponent, config);
  }

}

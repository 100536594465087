<div class="bg-white panel">
  <h5 class="panel-title">{{"票卷訂單" | translate}}</h5>
  <div class="panel-content">
    <div class="row mt-n3 mt-md-n4">
      <div class="col-12">
        <div>
          <ul class="nav nav-tabs-custom mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link" [ngClass]="{'active': selectedTab == 1}" style="cursor: pointer"
                (click)="selectedTab = 1; switchTab()" aria-selected="true">{{"可使用" | translate}}</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" [ngClass]="{'active': selectedTab == 2}" style="cursor: pointer"
                (click)="selectedTab = 2; switchTab()" aria-selected="false">{{"未付款" | translate}}</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" [ngClass]="{'active': selectedTab == 3}" style="cursor: pointer"
                (click)="selectedTab = 3; switchTab()" aria-selected="false">{{"全訂單" | translate}}</a>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" role="tabpanel">
              <div class="card card-member-order mb-4" *ngFor="let order of orderData">
                <div class="card-header">
                  <div class="row">
                    <div class="col-9 col-md-10">
                      <div class="d-block d-md-flex">
                        <div class="">{{"訂單編號" | translate}} <span class="text-primary">{{order.orderNumber}}</span>
                        </div>
                        <div class=" pl-md-4">{{"訂購日期" | translate}} {{order.CreatedAt | date: 'yyyy/MM/dd'}}</div>
                      </div>
                      <p class="mb-lg-0 font-size-sm my-md-2">
                        {{"訂單金額" | translate}} <span class="font-weight-bold"
                          [innerHTML]="order.amount - order.discount | currencyTransform:order.currency:order.rate"></span>
                      </p>
                    </div>
                    <div class="col-3 col-md-2 d-flex justify-content-end">
                      <div>
                        <span class="badge badge-pill badge-danger" style="font-size: 1em;"
                          *ngIf="order.orderStatus === 2">{{"訂單作廢" | translate}}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-body">
                  <div class="row no-gutters cart-item" *ngFor="let item of order.OrderDetail; let i = index">
                    <div class="col-3 col-md-2 text-center">
                      <div class="product-img img-bg"
                        style="background-image: url(/api/images/{{item.productData.product.coverImg}});"></div>
                    </div>
                    <div class="col-9 col-md-10 d-md-flex">
                      <div class="flex-grow-1">
                        <div class="product-name" [routerLink]="['/product', item.productData.product.ID]">
                          {{item.productData.product.title | googleTranslate | async}}
                        </div>
                        <div class="product-option">{{item.productData.plan.name | googleTranslate | async}}</div>
                        <div>
                          <small class="text-black-50">
                            <ul class="pl-4">
                              <li>{{item.productData.planSpecification.name | googleTranslate | async}}</li>
                              <li *ngIf="item.productData.extraOption && item.productData.extraOption.id !== 0">
                                {{item.productData.extraPurchase.name | googleTranslate | async}}
                              </li>
                            </ul>
                          </small>
                        </div>
                        <div class="product-quant d-flex align-items-center">
                          <span class="material-icons px-1">shopping_cart</span>
                          X {{item.quantity}} {{item.productData.planSpecification.unit | googleTranslate | async}}
                          <span class="material-icons px-1 ml-3">calendar_today</span>
                          {{item.productData.specOption.date | date: 'yyyy/MM/dd'}}
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-md-between cart-action">
                        <div class="d-flex justify-content-md-end" *ngIf="order.orderStatus !== 2">
                          <small class="text-primary">
                            <span *ngIf="item.payStatus == 1">{{"可使用" | translate}}</span>
                          </small>
                          <small class="text-warning">
                            <span *ngIf="item.payStatus == -1">{{"未付款" | translate}}</span>
                            <span *ngIf="item.payStatus == 2">{{"未付完" | translate}}</span>
                            <span *ngIf="item.payStatus == 3 || item.payStatus == 4">{{"退款處理中" | translate}}</span>
                            <span *ngIf="item.payStatus == 5">{{"部分退款已完成" | translate}}</span>
                            <span *ngIf="item.payStatus == 6">{{"全額退款已完成" | translate}}</span>
                            <span *ngIf="item.payStatus == 7">{{"退款申請中" | translate}}</span>
                          </small>
                        </div>

                        <div class="d-flex flex-row justify-content-end align-self-md-end"
                          *ngIf="item.payStatus !== -1 && order.orderStatus !== 2">
                          <a href="#" data-toggle="modal" [attr.data-target]="'#comment'+item.ID"
                            *ngIf="item.Comment.ID == 0" class="btn btn-outline-primary btn-sm rounded-0"
                            (click)="openModal(order.ID, item)">{{"給予評論" | translate}}</a>

                          <div class="d-flex flex-column" *ngIf="item.Comment.ID !== 0">
                            <div class="user-rating small d-flex flex-row">
                              <div class="d-flex flex-row" *ngFor="let s of scores; let i = index;">
                                <span class="material-icons checked" *ngIf="i < item.Comment.score">star</span>
                                <span class="material-icons unchecked" *ngIf="i >= item.Comment.score">star</span>
                              </div>
                            </div>
                            <small class="text-muted align-self-md-center">
                              {{item.Comment.CreatedAt | date: 'yyyy/MM/dd HH:mm'}}</small>
                          </div>
                          <a class="btn btn-primary btn-sm btn-dl rounded-0 ml-2 dropdown-toggle text-white"
                            data-toggle="dropdown" *ngIf="item.voucherFile.length >= 1">
                            <i class="material-icons">get_app</i>{{"下載憑證" | translate}}
                          </a>
                          <div class="dropdown-menu dropdown-menu-right dropdown-style" role="menu">
                            <a class="dropdown-item" *ngFor="let file of item.voucherFile" target="_blank"
                              href="/api/site/auth/orderDetail/voucherFile/{{file.file}}?detailId={{item.ID}}">{{file.fileName}}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-footer">
                  <div class="row">
                    <div class="col-4 col-md-3 d-none d-md-block">
                      <a href="https://line.me/ti/p/%40unz8114z" target="_blank"
                        class="btn btn-outline-secondary btn-block rounded-0">
                        {{"聯繫客服" | translate}}
                      </a>
                    </div>

                    <div class="col-6 col-md-3 ml-auto"
                      *ngIf="order.payStatus == -1 && payService.isWithin24Hours(order)">
                      <a data-toggle="modal" [attr.data-target]="'#payMethod'"
                        (click)="showPayModal = true; selectedOrder = order;"
                        class="btn btn-warning btn-block rounded-0">{{"立即付款" | translate}}
                      </a>
                    </div>

                    <div class="col-6 col-md-3"
                      [ngClass]="{'ml-auto': order.payStatus !== -1 || !payService.isWithin24Hours(order)}">
                      <a [routerLink]="['/member/orderInfo', order.orderNumber]"
                        class="btn btn-outline-primary btn-block rounded-0">{{"查看訂單" | translate}}
                      </a>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- commentModal -->
  <div class="modal fade" id="comment{{selectedOrder.ID}}" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true" *ngIf="selectedOrder">
    <div class="modal-dialog modal-lg modal-custom modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{"給予評論" | translate}}</h5>
          <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <form action="" method="post" class="form-horizontal" [formGroup]="form" *ngIf="form">
            <div class="row no-gutters cart-item">
              <div class="col-4 col-md-3 text-center">
                <div class="product-img img-bg"
                  style="background-image: url(/api/images/{{selectedOrder.productData.product.coverImg}});"></div>
              </div>
              <div class="col-8 col-md-9 d-md-flex">
                <div class="flex-grow-1">
                  <div class="product-name">{{selectedOrder.productData.product.title | googleTranslate | async}}</div>
                  <div class="product-option">{{selectedOrder.productData.plan.name | googleTranslate | async}}</div>
                </div>
              </div>
            </div>
            <div class="row mt-3 mb-4">
              <div class="col-12">{{"旅遊人" | translate}}</div>

              <div class="col-12 mt-2">
                <button type="button" class="btn btn-sm rounded-0 mr-2 my-1" (click)="tourist = '單人旅遊'"
                  [ngClass]="{'btn-primary': tourist === '單人旅遊', 'btn-outline-secondary': tourist !== '單人旅遊'}">
                  {{"單人旅遊" | translate}}</button>
                <button type="button" class="btn btn-sm rounded-0 mr-2 my-1" (click)="tourist = '好友'"
                  [ngClass]="{'btn-primary': tourist === '好友', 'btn-outline-secondary': tourist !== '好友'}">
                  {{"好友" | translate}}</button>
                <button type="button" class="btn btn-sm rounded-0 mr-2 my-1" (click)="tourist = '家人'"
                  [ngClass]="{'btn-primary': tourist === '家人', 'btn-outline-secondary': tourist !== '家人'}">
                  {{"家人" | translate}}</button>
                <button type="button" class="btn btn-sm rounded-0 mr-2 my-1" (click)="tourist = '情侶夫妻'"
                  [ngClass]="{'btn-primary': tourist === '情侶夫妻', 'btn-outline-secondary': tourist !== '情侶夫妻'}">
                  {{"情侶夫妻" | translate}}</button>
                <button type="button" class="btn btn-sm rounded-0 mr-2 my-1" (click)="tourist = '其他'"
                  [ngClass]="{'btn-primary': tourist === '其他', 'btn-outline-secondary': tourist !== '其他'}">
                  {{"其他" | translate}}</button>
              </div>

            </div>
            <div class="row mb-2">
              <div class="col-12">{{"評分" | translate}}</div>
              <div class="rate mt-2">
                <ngx-input-star-rating [(ngModel)]="score" [ngModelOptions]="{standalone: true}">
                </ngx-input-star-rating>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-2">
                {{"評論" | translate}}
                <textarea name="" id="" cols="30" rows="3" class="form-control" [(ngModel)]="content"
                  [ngModelOptions]="{standalone: true}"></textarea>
              </div>
              <div class="col-12">
                <label class="input input--file">
                  <label class="input__support" [for]="'input-file-id'+i">
                    <span class="input__helper">{{"上傳檔案" | translate}}</span>
                  </label>
                  <input class="ng-hide" [id]="'input-file-id'+i" type="file" accept="image/jpeg, image/png"
                    (change)="onFileSelect($event, i)" name="image" />
                </label>
              </div>

              <div class="col-12" *ngIf="photo.length > 0">
                <div class="d-flex flex-wrap review-img-wrapper no-gutters mx-n1">
                  <div class="col-3 col-md-2 p-1" *ngFor="let pic of photo;let i = index">
                    <img [src]="photo[i]" class="img-thumbnail">
                    <a class="delete">
                      <span class="material-icons" (click)="deleteImg(i)">close</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer border-0">
          <button type="button" class="btn btn-link text-muted" data-dismiss="modal">{{"取消" | translate}}</button>
          <button type="button" class="btn btn-primary rounded-0" [disabled]="score <= 0 || commentSend"
            (click)="onSubmit()">{{"確定" | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="payMethod" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    *ngIf="selectedOrder && showPayModal">
    <div class="modal-dialog modal-lg modal-custom modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content" *ngIf="selectedOrder.payStatus === -1 || selectedOrder.payStatus === 2">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{"選擇付款方式" | translate}}</h5>
          <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedOrder.payMethod">
            <div class="form-check ml-4 p-4" *ngIf="PayMethod2">
              <mat-radio-button [value]="2">
                <img src="assets/images/pay-line.png" class="pl-1" style="margin-top: -3px;">
              </mat-radio-button>
            </div>
            <div *ngIf="PayMethod6">
              <div class="divider"></div>
              <div class="form-check ml-4 p-4">
                <mat-radio-button [value]="6">{{"信用卡付款" | translate}}
                  <img src="assets/images/mpg_card_icon.png" class="pl-1" style="margin-top: -3px;">
                </mat-radio-button>
              </div>
            </div>
            <div *ngIf="PayMethod7">
              <div class="divider"></div>
              <div class="form-check ml-4 p-4">
                <mat-radio-button [value]="7">
                  <div class="d-flex flex-row align-items-center">
                    <span>{{"匯款" | translate}}</span>
                  </div>
                </mat-radio-button>
                <div class="card mt-3" *ngIf="selectedOrder.payMethod == 7">
                  <div class="card-body">
                    <p class="card-text text-muted" [innerHTML]="remitInfo"></p>
                  </div>
                </div>
              </div>
            </div>
          </mat-radio-group>
        </div>
        <div class="modal-footer border-0" *ngIf="selectedOrder.payMethod">
          <button type="button" class="btn btn-link text-muted" data-dismiss="modal">{{"取消" | translate}}</button>
          <button type="button" class="btn btn-primary rounded-0" data-dismiss="modal"
            (click)="showPayModal = false; completePayment()">
            <span *ngIf="selectedOrder.payMethod !== 7">{{"前往付款" | translate}}</span>
            <span *ngIf="selectedOrder.payMethod == 7">{{"以匯款方式付款" | translate}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div #divForm></div>

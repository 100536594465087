import { IndexService } from './../index/index.service';
import { DomSanitizer } from '@angular/platform-browser';
import { VideoService } from './video.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare let $: any;

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  videoData: any[] = [];
  selectedVideo: any;
  showMore = false;

  constructor(
    public sanitizer: DomSanitizer,
    private indexService: IndexService,
    private videoService: VideoService,
    private router: Router) { }

  ngOnInit(): void {
    this.getVideos();
    $(document).on('click', '.popup-modal-dismiss', (e) => {
      e.preventDefault();
      $.magnificPopup.close();
    });
  }

  updateViewCount(id: number) {
    this.videoService.updateViewCount(id)
    .subscribe((v: any) => { });
    this.videoData.map(video => {
      if (video.ID === id) {
        video.viewCount++;
      }
    });
  }

  getVideos() {
    this.videoService.getVideos()
      .subscribe((v: any) => {
        if (v.result && v.data) {
          this.videoData = v.data;

          this.indexService.getGrabToday()
            .subscribe((r: any) => {
              if (r.result && r.data) {
                this.videoData.map(product => {
                  product.isSale = false;
                  product.deduct = 0;

                  r.data.GrabTodayPlan.map(sales => {
                    if (sales.product.ID === product.Product.ID) {
                      product.isSale = true;
                      product.deduct = sales.product.deduct;
                    }
                  });
                });
              }
            });
        }
        this.loadScript('assets/js/video.js');
      });
  }

  closeModal() {
    $.magnificPopup.close();
    // this.router.navigate(['/product/' + this.selectedVideo.Product.ID]);
  }

  loadScript(url) {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

}

import { IndexService } from './../../index/index.service';
import { MemberService } from './../member.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-member-saved',
  templateUrl: './member-saved.component.html',
  styleUrls: ['./member-saved.component.scss']
})
export class MemberSavedComponent implements OnInit {

  collectionData: any[] = [];
  totalCount = 0;
  pageIndex = 0;
  numPages = 0;
  countries = [];
  selectedCountry = 0;
  loaded = false;

  constructor(private memberService: MemberService, private indexService: IndexService) { }

  ngOnInit(): void {
    this.getMemberSaved();
  }

  getMemberSaved() {
    this.loaded = false;
    this.pageIndex = 0;
    this.memberService.getMemberSavedAll()
      .subscribe((v: any) => {
        if (v.result) {
          this.collectionData = v.data;
          if (+this.selectedCountry !== 0) {
            this.collectionData = this.collectionData.filter(data => data.countryID == this.selectedCountry);
          }
          this.totalCount = this.collectionData.length;
          this.countries = [...new Map(v.data.map(item => [item.countryID, item])).values()];
          this.loaded = true;

          this.indexService.getGrabToday()
            .subscribe((r: any) => {
              if (r.result && r.data) {
                this.collectionData.map(product => {
                  product.isSale = false;
                  product.deduct = 0;

                  r.data.GrabTodayPlan.map(sales => {
                    if (sales.product.ID === product.productID) {
                      product.isSale = true;
                      product.deduct = sales.product.deduct;
                    }
                  });
                });
              }
            });
        }
      });
  }

  deleteMemberSaved(id: number, index: number) {
    this.memberService.deleteMemberSaved(id)
      .subscribe((v: any) => {
        if (v.result) {
          this.collectionData.splice(index, 1);
          this.totalCount--;
        }
      });
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface CurrencyInfo {
  currency: string;
  usd?: number;
  jpy?: number;
}

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  public rateInfo = new BehaviorSubject<CurrencyInfo>({ currency: 'TWD' });
  currentRate = this.rateInfo.asObservable();

  public language = localStorage.getItem('language') ?
    new BehaviorSubject<any>(localStorage.getItem('language')) : new BehaviorSubject<any>('zh-TW');
  currentLanguage = this.language.asObservable();

  constructor(private http: HttpClient, public sanitizer: DomSanitizer) {
  }

  getMenu() {
    return this.http.get('/api/site/menuSettings');
  }

  renewCurrency(data: any) {
    this.rateInfo.next(data);
  }

  getCurrencyRate() {
    return this.http.get('/api/site/currencyRate');
  }

  getCurrencies() {
    return this.http.get('/api/site/currencies');
  }

  getSystemSetting() {
    return this.http.get('/api/site/systemSetting');
  }

  renewLanguage(data: any) {
    this.language.next(data);
    localStorage.setItem('language', data); // 存入localStorage
  }

  translateText(text: string) {
    if (this.language.value === 'zh-TW' || text === '' || text === undefined) {
      return of(text);
    } else {
      const requestBody = {
        q: text,
        target: this.language.value
      };
      return this.http.post('https://translation.googleapis.com/language/translate/v2?key=' + environment.googleTranslationApiKey,
        requestBody).pipe(
          map((response: any) => {
            if (response && response.data && response.data.translations) {
              return response.data.translations[0].translatedText;
            }
            return text;
          })
        );
    }
  }

  translateHTML(text: string) {
    if (this.language.value === 'zh-TW' || text === '' || text === undefined || text === null) {
      return of(this.sanitizer.bypassSecurityTrustHtml(text));
    } else {
      const requestBody = {
        q: text,
        target: this.language.value
      };
      return this.http.post('https://translation.googleapis.com/language/translate/v2?key=' + environment.googleTranslationApiKey,
        requestBody).pipe(
          map((response: any) => {
            if (response && response.data && response.data.translations) {
              return this.sanitizer.bypassSecurityTrustHtml(response.data.translations[0].translatedText);
            }
            return this.sanitizer.bypassSecurityTrustHtml(text);
          })
        );
    }
  }
}
